export default 
[
  {
    "komidashi": "0.ジオグラフかんたん解説動画",
    "setsumei_1": "",
    "setsumei_2": "",
    "setsumei_3": "",
    "setsumei_4": "",
    "setsumei_5": "",
    "soemono_1": "使い方動画1",
    "soemono_2": "使い方動画2",
    "soemono_3": "使い方動画3",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  },
  {
    "komidashi": "1. ジオグラフの画面構成",
    "setsumei_1": "ジオグラフでは、地域の状況を３次元グラフで「見える化」させた地図が配置されています。",
    "setsumei_2": "【トップページ】で【ジオグラフをはじめる】をクリックし、遷移先の【テーマ選択ページ】で見たいテーマを選び終えると、こちらの【自分で地域を選ぶ】モードが表示されます。",
    "setsumei_3": "【自分で地域を選ぶ】モードでは、まず、画面内の上側の【自治体名が記されているパネル】をクリック（タップ）すると出てくる自治体選択画面で、見たい自治体を都道府県、市町村の順に選びましょう。３次元グラフの描画が始まります。",
    "setsumei_4": "なお、最初は、データ量が小さくなる面積の小さな市町村で、試しに入力してみると良いでしょう。県内全域を見たい場合は、市町村選択の際に【全体】を選んでください（お使いの端末の性能によっては県内全域の描画が難しい場合もございます）。東京都では、市町村選択の際に【東京23区】という選択も可能です。",
    "setsumei_5": "",
    "soemono_1": "11-04で福岡県糸島市を映した画面",
    "soemono_2": "",
    "soemono_3": "",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  },
  {
    "komidashi": "2. ジオグラフの凡例",
    "setsumei_1": "3次元グラフが描画され始めたら、【凡例】を確認しましょう。こちらの例では、〈高さ〉は人口、〈色〉は公共交通機関へのアクセスのしやすさになっていることがわかります。",
    "setsumei_2": "凡例内の左下側では〈年次〉が示されており、こちらの例では人口総数について2種類の年次を扱っていることが分かります。",
    "setsumei_3": "凡例内の右上側にある「1人:1m」は〈高さ表示の換算式〉を表しており、あるグラフの範囲の人口が3,000人であれば、画面上の地図のなかでの3kmの高さで、グラフの高さを表示していることを示しています。その後ろの「×1」は〈高さ表示の倍率〉を表していますが、こちらは、凡例の下側にある【設定】から【グラフの高さ設定】を選んだ先で変更でき、3次元グラフをお好みの高さに伸縮できます。",
    "setsumei_4": "凡例の詳細な説明は、凡例内の右下側にある【詳細を見る】ボタンから確認できます。こちらの例では「公共交通利用圏」の定義の説明が確認でき、データの出典元へのリンクも備わっています。",
    "setsumei_5": "最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。\n凡例の下側にある【TRY】も、読み取りの指針となることでしょう。",
    "soemono_1": "11-04の凡例",
    "soemono_2": "",
    "soemono_3": "",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  },
  {
    "komidashi": "3. ジオグラフの地図と３次元グラフ",
    "setsumei_1": "地図と３次元グラフを確認しましょう。\nジオグラフでは、地図上で国が定めた区画（メッシュ）ごとに、人口や産業などのグラフが３次元で可視化されています。\n国土全体を表示する場合には1辺の長さが約10㎞の「二次メッシュ」を、それ以外の場合には1辺の長さが約1㎞の「三次メッシュ」を用いています。",
    "setsumei_2": "画面内の上側の【自治体名が記されているパネル】の下に並ぶ、下記の3種のパネルでは、以下の操作が可能です。\n●【４ケタの数字】複数の年次の統計データが備わっている場合、表示する年次を変更できます。3次元グラフを消すこともできます。\n●【写真】背景を変更できます。衛星写真と2種類の地形図が選べます。\n●【回転】中心を固定して回転します。際立った3次元グラフが立っている場所を探す際に便利です。",
    "setsumei_3": "【地図画面を直接タッチ】すると、地図を見る視点を自由に動かせます。地図を見る視点は、画面内の右端にある円形のパネルでも操作できます。\n【各3次元グラフの天井をダブルクリック（タップ）】すると、視点がズームインして、該当の場所に関する下記の情報が得られます。\n●3次元グラフの基となる実際の統計数値。\n●位置情報（国で定めたメッシュコード）。\n●【場所を確認する】ボタン（該当の場所がGoogleMapで呼び出され住所確認できます）。",
    "setsumei_4": "なお、【3次元グラフが無いところをダブルクリック（タップ）】すると、ある程度の高さから真北に向かって眺める、初期設定の視点に戻ります。",
    "setsumei_5": "",
    "soemono_1": "11-04で地図部分にクローズアップした画面",
    "soemono_2": "",
    "soemono_3": "",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  },
  {
    "komidashi": "4. ジオグラフのモード",
    "setsumei_1": "ジオグラフでは他にも、２つの地域を比較したり、国土全体で眺めたりもできる、５種類のモードを備えています。\nこの「モード」は、上段のタブで変えられます。\nはじめは【自分で地域を選ぶ】モードとなっています。\n気になるタブを開いてみましょう。\nなお、【事例とその解説】モードでは、選んだテーマについての解説も読むことができます。",
    "setsumei_2": "ほか、画面右上にある4種の黒地のボタンからは、左から順に【テーマ選択ページ】【ジオグラフの使い方ページ（こちらのページ）】【FAQページ】【トップページ】に移ることができます。【テーマ選択ページ】を通じて、様々な視点から地域への理解を深めていきましょう。",
    "setsumei_3": "",
    "setsumei_4": "",
    "setsumei_5": "",
    "soemono_1": "11-04の「他の地域と比べる」モードで糸島市と飯塚市を比較した画面",
    "soemono_2": "",
    "soemono_3": "",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  },
  {
    "komidashi": "5. さあ、ジオグラフを使ってみよう",
    "setsumei_1": "さあ、それではジオグラフを使って地域を探究していきましょう。\n右の画面をクリック（タップ）すると、SDGsに基づいて見たいテーマを選べるページに移ります。",
    "setsumei_2": "",
    "setsumei_3": "",
    "setsumei_4": "",
    "setsumei_5": "",
    "soemono_1": "SDGsのテーマ選択画面（リンクとして機能させる）",
    "soemono_2": "",
    "soemono_3": "",
    "soemono_4": "",
    "soemono_5": "",
    "soemono_6": "",
    "soemono_7": "",
    "soemono_8": "",
    "soemono_9": "",
    "soemono_10": "",
    "soemono_11": "",
    "soemono_12": ""
  }
]