import React, { useContext } from "react";
import PrefOption from "./kashikapreflist";
import PrefCityOption from "./kashikacitylist";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { Area1Set, Area2Set } from "./App";
import { useForm } from "react-hook-form";

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    (typeof window !== "undefined" &&
      window.localStorage.getItem(localStorageKey)) ||
      ""
  );
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
  return [value, setValue];
};

export default function App() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  // console.log(errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        placeholder="areaset"
        {...register("areaset", {
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "メールアドレスの形式が違います",
          },
        })}
      />
      <input type="submit" />
    </form>
  );
}

export const Area0set = (props) => {
  const { area1set, setArea1Set } = useContext(Area1Set);
  const { area2set, setArea2Set } = useContext(Area2Set);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data.areaset);
    setArea1Set(data.areaset);
    setArea2Set(data.areaset);
  };
  return (
    <>
      <div className="max-w-lg mx-auto">
        <details className="open:bg-white dark:open:bg-gray-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-2 rounded-lg">
          <summary className="text-sm leading-6 text-gray-900 dark:text-white font-semibold select-none">
            {area1set}
          </summary>
          <div className="mt-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input placeholder="福岡県糸島市" {...register("areaset", {})} />
              <button
                type="button"
                className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm mx-2 px-2 text-center mr-2 mb-2"
              >
                <input type="submit" />
              </button>
            </form>
          </div>
        </details>
      </div>
    </>
  );
};

export const Area1set = (props) => {
  const { area1set, setArea1Set } = useContext(Area1Set);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data.areaset);
    setArea1Set(data.areaset);
  };
  return (
    <>
      <div className="max-w-lg mx-auto">
        <details className="open:bg-white dark:open:bg-gray-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-2 rounded-lg">
          <summary className="text-sm leading-6 text-gray-900 dark:text-white font-semibold select-none">
            {area1set}
          </summary>
          <div className="mt-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="福岡県糸島市"
                {...register("areaset", {})}
              />
              <button
                type="button"
                className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm mx-2 px-2 text-center mr-2 mb-2"
              >
                <input type="submit" />
              </button>
            </form>
          </div>
        </details>
      </div>
    </>
  );
};

export const Area2set = (props) => {
  const { area2set, setArea2Set } = useContext(Area2Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setRendouSet(false);
    setSyukurenSet(true);
    setArea2Set(data.areaset);
  };
  return (
    <>
      <div className="max-w-lg mx-auto">
        <details className="open:bg-white dark:open:bg-gray-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-2 rounded-lg">
          <summary className="text-sm leading-6 text-gray-900 dark:text-white font-semibold select-none">
            {area2set}
          </summary>
          <div className="mt-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="福岡県糸島市"
                {...register("areaset", {})}
              />
              <button
                type="button"
                className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm mx-2 px-2 text-center mr-2 mb-2"
              >
                <input type="submit" />
              </button>
            </form>
          </div>
        </details>
      </div>
    </>
  );
};

export const Area1set2 = (props) => {
  const { area1set, setArea1Set } = useContext(Area1Set);
  const [pref, setPref] = useStateWithLocalStorage("area1pref");
  const onChange = (event) => setArea1Set(event.target.value);
  const onChange2 = (event) => {
    setPref(event.target.value);
  };
  return (
    <>
      <select id="prefselect" name="pref" value={pref} onChange={onChange2}>
        {PrefOption.map((d) => (
          <option value={d.value}>{d.value}</option>
        ))}
      </select>
      <br />
      <select id="select" name="city" value={area1set} onChange={onChange}>
        {PrefCityOption.flatMap((d) =>
          d.value.includes(pref) ? (
            <option value={d.value}>{d.label}</option>
          ) : (
            []
          )
        )}
      </select>
    </>
  );
};
