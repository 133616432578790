import { useContext, 
  // useState 
} from "react";
// import { MultiSetting } from "./kashikabuttons";
// import { population_now, population_old } from "./kashikasetting";
// import {
//   photo,
//   palemap,
//   stdmap,
//   blankmap,
//   engmap,
//   oldphoto,
//   oldmap,
// } from "./cesiumsetting";
// import Sdgs1 from './img/sdg_1.webp';
// import Sdgs2 from './img/sdg_2.webp';
// import Sdgs3 from './img/sdg_3.webp';
// import Sdgs4 from './img/sdg_4.webp';
// import Sdgs5 from './img/sdg_5.webp';
// import Sdgs6 from './img/sdg_6.webp';
// import Sdgs7 from './img/sdg_7.webp';
// import Sdgs8 from './img/sdg_8.webp';
// import Sdgs9 from './img/sdg_9.webp';
// import Sdgs10 from './img/sdg_10.webp';
// import Sdgs11 from './img/sdg_11.webp';
// import Sdgs12 from './img/sdg_12.webp';
// import Sdgs13 from './img/sdg_13.webp';
// import Sdgs14 from './img/sdg_14.webp';
// import Sdgs15 from './img/sdg_15.webp';
// import Sdgs16 from './img/sdg_16.webp';
// import Sdgs17 from './img/sdg_17.webp';
// import Sdgs18 from './img/sdg_18.webp';
// import { Tags } from "./tags";
// import { Link } from "react-router-dom";
// import { Theme1Set } from "./App";
// import preflist from "./list_pref";
// import list_city from "./list_city";
// import { Area1Set, Area2Set } from "./App";
// import { useParams } from "react-router-dom";
import { Ratio1Set, 
  // Ratio2Set 
} from "./App";

// const Citybutton = ({ city }) => {
//   return (
//     <>
//       <button className="w-full bg-green-700 hover:bg-green-500 text-white py-2 px-4 rounded">
//         {city}
//       </button>
//     </>
//   );
// };

export function Modalratio0set({ show, setShow }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  // const [preftmp, setPreftmp] = useState("");
  // const { area1set, setArea1Set } = useContext(Area1Set);
  // const { area2set, setArea2Set } = useContext(Area2Set);
  const { ratio1set, setRatio1Set } = useContext(Ratio1Set);
  // const { ratio2set, setRatio2Set } = useContext(Ratio2Set);

  // const params = useParams();
  // console.log(params);

  const onChange = (event) => setRatio1Set(event.target.value);

  // const setratio0Set = (data) => {
  //   setRatio1Set(data);
  //   setRatio2Set(data);
  //   setPreftmp("");
  //   setShow(false);
  // };

  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full z-50"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            // justifyContent:" center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              // zIndex:"10002",
              width: "50%",
              padding: "",
              // background:"#fff",
            }}
            className="max-h-[70vh] overflow-y-auto mt-6"
          >
            <div>
              <div>
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-8 bg-gradient-to-r from-black/80 via-black/80 to-black/70 rounded-lg">
                  <div className="text-center pb-6">
                    <h1 className="font-bold text-xl md:text-2xl lg:text-3xl font-heading text-gray-100">
                      高さ設定
                    </h1>
                    <div
                      style={{ textAlign: "center" }}
                      className="text-gray-100"
                    >
                      <button onClick={() => setShow(false)}>戻る</button>
                    </div>

                    <div>
                      <>
                        <div className="py-4" style={{ zIndex: "1000" }}>
                          <span className="font-bold text-xl md:text-2xl lg:text-3xl">
                            {ratio1set}倍
                          </span>
                        </div>

                        <div className="py-2" style={{ zIndex: "1000" }}>
                          <span style={{ color: "white" }}>
                            縮小（0.0-1.0）
                          </span>
                          <input
                            type="range"
                            id="input_volume"
                            name="upload_file"
                            min="0"
                            max="1"
                            step="0.1"
                            list="tickmarks1"
                            style={{ color: "white" }}
                            value={ratio1set}
                            onChange={onChange}
                          />
                          <datalist id="tickmarks1">
                            <option value="0" label="1">
                              0
                            </option>
                            <option value="1" label="1">
                              1
                            </option>
                          </datalist>
                        </div>
                        <div className="py-2" style={{ zIndex: "1000" }}>
                          <span style={{ color: "white" }}>
                            拡大（ 1 - 10 ）
                          </span>
                          <input
                            type="range"
                            id="input_volume"
                            name="upload_file"
                            min="1"
                            max="10"
                            step="1"
                            list="tickmarks2"
                            style={{ color: "white" }}
                            value={ratio1set}
                            onChange={onChange}
                          />
                          <datalist id="tickmarks2">
                            <option value="1" label="1">
                              1
                            </option>
                            <option value="10" label="5">
                              10
                            </option>
                          </datalist>
                        </div>
                        <button
                          onClick={() => setRatio1Set(1)}
                          className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800 mt-5"
                        >
                          <span className="relative px-1 py-1 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                            リセット
                          </span>
                        </button>
                      </>
                    </div>
                  </div>
                  <button
                    onClick={() => setShow(false)}
                    className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800"
                  >
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      設定完了
                    </span>
                  </button>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
