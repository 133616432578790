import React, { Children } from "react";


// KML作成関数fetch版
function getkml2(no,year,areatmp,csv_filenametmp,meta) {
  let area = areatmp.normalize("NFD");
  let csv_name = csv_filenametmp.normalize("NFD")
  let filename = `/csv/${no}/${area}_${csv_name}_${year}.csv`
  return fetch(filename,{})
  .then((r) => {
    if(!r.ok){throw new Error();}
    return r.text();
  })
  .then(
    (r) =>r.split('\n')
    // (r) =>r.split("\r\n")
  )
  .then((r) => r.map((r) => r.split(",")))
  .then((r)=>{
    let tmp="";
    for(let i = 0, len = r.length; i< len ; i++){
      // var minmin = Number(meta.min);
      if (r[1][1]>Number(meta.min)) {
        tmp += mesh3rd2kml3(r[i][0],r[i][1],r[i][2],meta.ratio,meta.shikii1,meta.shikii2,meta.shikii3)
      }
    }
    return tmp
  })
};






  /// /// KMLの要素を作成 /////////////////////////
  function mesh3rd2kml3( meshcode3rd , h, c, ratio, shikii1, shikii2, shikii3){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
    var lon2 = lon+0.0125;
    var lat2 = lat+0.008333333;
    var latc = (lat+lat2)/2;
    var lonc = (lon+lon2)/2;
    var hdis = h*ratio+5;
  
  // if(latmax<lat){latmax = lat;}
  // if(lonmax<lon){lonmax = lon;}
  // if(latmin>lat){latmin = lat;}
  // if(lonmin>lon){lonmin = lon;}
  
    var colorkml1;
    if (c >= shikii1) {colorkml1 = "1"; }
    else if (c >= shikii2) {colorkml1 = "2"; }
    else if (c >= shikii3) {colorkml1 = "3"; }
    else {colorkml1 = "4"; }
  
    var ans_kml = `
  <Placemark>
  <name>メッシュ番号：${strMeshcoe}</name>
  <description>高さの値：${h}</description>
  <styleUrl>#PolyCol${colorkml1}</styleUrl>
  <Polygon>
  <altitudeMode>relativeToGround</altitudeMode>
  <extrude>1</extrude>
  <outerBoundaryIs>
  <LinearRing>
  <coordinates>${lon},${lat},${hdis}
  ${lon2},${lat},${hdis}
  ${lon2},${lat2},${hdis}
  ${lon},${lat2},${hdis}
  ${lon},${lat},${hdis}
  </coordinates>
  </LinearRing>
  </outerBoundaryIs>
  </Polygon>
  <LookAt>
  <latitude>${latc}</latitude>
  <longitude>${lonc}</longitude>
  <altitude>0</altitude>
  <range>1000</range>
  <tilt>0</tilt>
  <heading>0</heading>
  <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  </Placemark>
  `;
  
    return ans_kml ;
  }
  /// /// KMLの要素を作成 /////////////////////////
  

























/// KMLを統合してタイムスパン生成、整形 /////
function makekml(data1995kml,data2000kml,data2005kml,data2010kml,data2015kml,viewarray,meta,area){

  /// KML生成準備 ////////////
  // var kmlpre = [];
  var kml1_colora = meta.kml1_color;
  var kml2_colora = meta.kml2_color;
  var kml3_colora = meta.kml3_color;
  var kml4_colora = meta.kml4_color;
  
  var kml1_color = "c8"+kml1_colora.slice(5,7)+kml1_colora.slice(3,5)+kml1_colora.slice(1,3);
  var kml2_color = "c8"+kml2_colora.slice(5,7)+kml2_colora.slice(3,5)+kml2_colora.slice(1,3);
  var kml3_color = "c8"+kml3_colora.slice(5,7)+kml3_colora.slice(3,5)+kml3_colora.slice(1,3);
  var kml4_color = "c8"+kml4_colora.slice(5,7)+kml4_colora.slice(3,5)+kml4_colora.slice(1,3);
  
    var year1kml = meta.year1;
    var year2kml = meta.year2;
    var year3kml = meta.year3;
    var year4kml = meta.year4;
    var year5kml = meta.year5;
    var year5_6kml = Number(year5kml) - Number(2005);
    var year6kml = Number(year5kml) + year5_6kml;
    // var year7kml = Number(year6kml) + (Number(year5kml) - Number(year1kml))/4;
  
    var year1_b = year1kml+'-04-03';
    var year2_b = year2kml+'-04-03';
    var year3_b = year3kml+'-04-03';
    var year4_b = year4kml+'-04-03';
    var year5_b = year5kml+'-04-03';
    
    var year1_e = year2kml+'-04-03';
    var year2_e = year3kml+'-04-03';
    var year3_e = year4kml+'-04-03';
    var year4_e = year5kml+'-04-03';
    var year5_e = year6kml+'-04-03';
    
    if(year1kml>0){ var year1_begin = year1_b } else {  var year1_begin = year5_b };
    if(year2kml>0){ var year2_begin = year2_b } else {  var year2_begin = year5_b };
    if(year3kml>0){ var year3_begin = year3_b } else {  var year3_begin = year5_b };
    if(year4kml>0){ var year4_begin = year4_b } else {  var year4_begin = year5_b };
    if(year5kml>0){ var year5_begin = year5_b } else {  var year5_begin = year5_b };
  
    if(year2kml>0){ var year1_end = year1_e } else { var year1_end = year5_b };
    if(year3kml>0){ var year2_end = year2_e } else { var year2_end = year5_b };
    if(year4kml>0){ var year3_end = year3_e } else { var year3_end = year5_b };
    if(year5kml>0){ var year4_end = year4_e } else { var year4_end = year5_b };
    if(year6kml>0){ var year5_end = year5_e } else { var year5_end = year5_b };
  
    var no = meta.no;
    var title = meta.title;
    var cityname = area;
  ////////////// KML生成準備 ///
  
    let latcenter = viewarray[0]
    let loncenter = viewarray[1];
    let lonheightratio = viewarray[2];
    return new Promise(resolve => {
  var kmlll = `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>${cityname+"_"+title}</name>  
  <Style id="PolyCol1"><LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle><PolyStyle><color>${kml1_color}</color><fill>1</fill><outline>1</outline></PolyStyle></Style>
  <Style id="PolyCol2"><LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle><PolyStyle>
  <color>${kml2_color}</color><fill>1</fill><outline>1</outline></PolyStyle></Style>
  <Style id="PolyCol3"><LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle><PolyStyle><color>${kml3_color}</color><fill>1</fill><outline>1</outline></PolyStyle></Style>
  <Style id="PolyCol4"><LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle><PolyStyle>
  <color>${kml4_color}</color><fill>1</fill><outline>1</outline></PolyStyle></Style>
  
  <LookAt><longitude>${loncenter}</longitude><latitude>${latcenter}</latitude><altitude>0</altitude><range>${lonheightratio}</range><tilt>30</tilt><heading>-25</heading><altitudeMode>absolute</altitudeMode></LookAt>
  
  <Folder><name>${year1kml}</name><TimeSpan><begin>${year1_begin}</begin><end>${year1_end}</end></TimeSpan>
  <ScreenOverlay><name>凡例表示</name><visibility>1</visibility>
  <Icon><href>https://www.geograph.teikokushoin.co.jp/hanrei/${no}_${year1kml}.png</href></Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>
  ${data1995kml}
  </Folder>
  <Folder><name>${year2kml}</name><TimeSpan><begin>${year2_begin}</begin><end>${year2_end}</end></TimeSpan>
  <ScreenOverlay><name>凡例表示</name><visibility>1</visibility>
  <Icon><href>https://www.geograph.teikokushoin.co.jp/hanrei/${no}_${year2kml}.png</href></Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/></ScreenOverlay>
  ${data2000kml}
  </Folder>
  <Folder><name>${year3kml}</name><TimeSpan><begin>${year3_begin}</begin><end>${year3_end}</end></TimeSpan>
  <ScreenOverlay><name>凡例表示</name><visibility>1</visibility>
  <Icon><href>https://www.geograph.teikokushoin.co.jp/hanrei/${no}_${year3kml}.png</href></Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/></ScreenOverlay>
  ${data2005kml}
  </Folder>
  <Folder><name>${year4kml}</name><TimeSpan><begin>${year4_begin}</begin><end>${year4_end}</end></TimeSpan>
  <ScreenOverlay><name>凡例表示</name><visibility>1</visibility>
  <Icon><href>https://www.geograph.teikokushoin.co.jp/hanrei/${no}_${year4kml}.png</href></Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/></ScreenOverlay>
  ${data2010kml}
  </Folder>
  <Folder><name>${year5kml}</name><TimeSpan><begin>${year5_begin}</begin><end>${year5_end}</end></TimeSpan>
  <ScreenOverlay><name>凡例表示</name><visibility>1</visibility>
  <Icon><href>https://www.geograph.teikokushoin.co.jp/hanrei/${no}_${year5kml}.png</href></Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/></ScreenOverlay>
  ${data2015kml}
  </Folder>
  </Document></kml>
  `;
    resolve(kmlll)
  })}
  
  
  













  
  
// 全体の中心座標などKMLの初期視点（中心点と高さ）を計算 //////////////////////////
function getcenter(no,year,areatmp,csv_filenametmp,min) {
  let area = areatmp.normalize("NFD");
  let csv_name = csv_filenametmp.normalize("NFD")
  let filename = `/csv/${no}/${area}_${csv_name}_${year}.csv`
  return fetch(filename,{})
  .then((r) => {
    if(!r.ok){throw new Error();}
    return r.text();
  })
  .then(
    (r) =>r.split('\n')
    // (r) =>r.split("\r\n")
  )
  .then((r) => r.map((r) => r.split(",")))
  .then((r)=>{
    var latmax = 0;
    var latmin = 200;
    var lonmax = 0;
    var lonmin = 200;
    for(let i = 0, len = r.length; i< len ; i++){
      // var minmin = Number(meta.min);
      if (r[1][1]>Number(min)) {
        let ans = mesh3rdcenter(r[i][0])
        let lat = ans[0]
        let lon = ans[1]
        if(latmax<lat){latmax = lat;}
        if(lonmax<lon){lonmax = lon;}
        if(latmin>lat){latmin = lat;}
        if(lonmin>lon){lonmin = lon;}
      }
    }
    var latcenter = (latmax+latmin)/2;
    var loncenter = (lonmax+lonmin)/2;
    var lonheightratio = 170000*(lonmax-lonmin);
  return [latcenter,loncenter,lonheightratio]
  })
};
/// /// 全メッシュの中心座標を計算 /////////////////////////
function mesh3rdcenter( meshcode3rd ){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  var r = parseInt(strMeshcoe.slice(6,7));
  var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;
  var lon2 = lon+0.0125;
  var lat2 = lat+0.008333333;
  var latc = (lat+lat2)/2;
  var lonc = (lon+lon2)/2;
  return [latc,lonc] ;
}
/// /// 全メッシュの中心座標を計算 /////////////////////////
// 全体の中心座標などKMLの初期視点（中心点と高さ）を計算 //////////////////////////






















// ボタンを押したらKML生成 ///////////////////////////////////

const Csv2kml = ({meta, area, children}) => {

const handlerClickDownloadButton = async (e) => {
  e.preventDefault();    
  Promise.all([
    getkml2(meta.no,meta.year1,area,meta.csv_filename,meta),
    getkml2(meta.no,meta.year2,area,meta.csv_filename,meta),
    getkml2(meta.no,meta.year3,area,meta.csv_filename,meta),
    getkml2(meta.no,meta.year4,area,meta.csv_filename,meta),
    getkml2(meta.no,meta.year5,area,meta.csv_filename,meta),
    getcenter(meta.no,meta.year5,area,meta.csv_filename,meta.min)
  ]).then((r)=>{
    return makekml(r[0],r[1],r[2],r[3],r[4],r[5],meta,area);
  }).then((kmlll)=>{
      const blob = new Blob([kmlll], {type: 'application/vnd.google-earth.kml+xml kml'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = `${area}_${meta.csv_filename}.kml`;
      a.href = url;
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
  })}

return <>
<button onClick={(e) => handlerClickDownloadButton(e, "kml")}>
  {children}
</button>
</>
}
// ボタンを押したらKML生成 ///////////////////////////////////

export default Csv2kml