import React from "react";
import { Link } from "react-router-dom";
import bg from "./img/top_bg.webp";
import logo_l from "./img/logo_l.webp";
import logo_b from "./img/logo_b.webp";
import logo_d from "./img/logo_d.webp";
import syoei1 from "./img/syoei1.webp";
import syoei2 from "./img/syoei2.webp";
import syoei3 from "./img/syoei3.webp";
import syoei4 from "./img/syoei4.webp";
import syoei5 from "./img/syoei5.webp";
import jpnicon from "./img/jpnicon.svg";
import { Link2about } from "./buttons";

export const Home = (props) => {
  return (
    <>
      <div
        className="absolute z-20 font-mono text-white w-full h-screen"
        // style={{ backgroundImage: `url(${bg})` }}
      >

      <div className="h-1/6 flex flex-wrap flex-row">
        <div className="z-30 w-1/6 bg-tani_gray">
        </div>
        <div className="z-30 w-5/6 bg-tani_white text-right text-3xl">
          JSCEインフラ体力診断　<br/>
          地域公共交通WG　<br/>
          Version0.2 / 7th, April. 2022　<br/>
        </div>
      </div>
      <div className="z-30 h-1/6 w-full bg-tani_blue text-tani_white text-7xl">
      </div>
      <div className="z-30 h-1/6 w-full bg-tani_blue text-tani_white text-7xl">
          <span className="text-tani_yellow">地域公共交通</span>の体力診断<br/>
      </div>
      <div className="z-30 h-1/6 w-full bg-tani_blue text-tani_white text-5xl">
          データ編
      </div>
      <div className="z-30 h-1/6 w-full bg-tani_blue text-tani_white text-5xl">
      <Link
            to="/select"
            data-tip="サイトを使い始めます"
            className="w-1/4 lg:w-1/2"
          >
      <button type="button" class="text-tani_black bg-tani_lightgreen hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">スタート！</button>
          </Link>
      </div>
      <div className="z-30 h-1/6 w-full bg-tani_green text-left text-2xl text-tani_white">
        主査：筑波大学　谷口綾子<br/>
        メンバー　赤星 健太郎(国土交通省)、五十嵐 達哉(建技研)、小美野 智紀、諸星健二、松村葵(ドーコン)、神田 佑亮(呉高専)、渡邊芳樹(筑波大学)、山室 良徳、山根優生(中央復建)、吉田 樹(福島大)
      </div>

      </div>
    </>
  );
};
