// メタデータcsv
export const metadata = "./meta/metadata_20220202.csv";

// LOD
export const lod = [100000, 50000, 20000, 10000, 5000];

// 指示csv
export const instruction = "./csv/instruction_20210420.csv";

// 今の人口
export const population_now = "./csv/data_population_2015.csv";
// 昔の人口
export const population_old = "./csv/data_population_1970.csv";
// export const population_old = "./csv/data_population_1995.csv";
// 今の高齢人口
export const agedpop_now = "./csv/data_agedpop_2015.csv";
// 昔の高齢人口
export const agedpop_old = "./csv/data_agedpop_1970.csv";
// export const agedpop_old = "./csv/data_agedpop_1995.csv";
// 今の就業者人口（第二次第三次）
export const worker23_now = "./csv/data_worker23_2015.csv";
// 昔の就業者人口（第二次第三次）
export const worker23_old = "./csv/data_worker23_1975.csv";
// export const worker23_old = "./csv/data_worker23_1995.csv";
// 今の昼間人口
export const daypop_now = "./csv/data_daypop_2015.csv";
// 昔の昼間人口
export const daypop_old = "./csv/data_daypop_1980.csv";
// export const daypop_old = "./csv/data_daypop_1995.csv";
// 今の販売額
export const sales_now = "./csv/data_sales_2014.csv";
// 昔の販売額
export const sales_old = "./csv/data_sales_1985.csv";

export const datalist = {
  population_now: {
    name: "人口<br/>（今）",
    url: "./csv/data_population_2015.csv",
  },
  population_old: {
    name: "人口<br/>（昔）pop_old",
    url: "./csv/data_population_1995.csv",
  },
};

export const datalist2 = [
  {
    name: "population_now",
    title: "人口<br/>（今）",
    url: "./csv/data_population_2015.csv",
  },
  {
    name: "population_old",
    title: "人口<br/>（昔）pop_old",
    url: "./csv/data_population_1995.csv",
  },
];

// 以下不要
