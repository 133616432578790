import React, { forwardRef, useContext } from "react";
import ReactTooltip from "react-tooltip";
import { Link, useLocation } from "react-router-dom";
import { FullscreenSet } from "./App";
import { FullviewerSet } from "./App";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { Rotate0Set } from "./App";
import { Rotate1Set } from "./App";
import { Rotate2Set } from "./App";
import { Map1Set } from "./App";
import { Map2Set } from "./App";
import {
  photo,
  palemap,
  stdmap,
  blankmap,
  engmap,
  oldphoto,
  oldmap,
} from "./cesiumsetting";

export const Link2top = () => {
  return (
    <>
      <Link to="/" data-tip="最初の画面に戻ります">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          ></path>
        </svg>
      </Link>
    </>
  );
};

export const Link2map = () => {
  return (
    <>
      <Link to="/map" data-tip="マップ画面に戻ります">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
      </Link>
    </>
  );
};

export const Link2about = () => {
  return (
    <>
      <Link to="/about" data-tip="このサイトについて">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </Link>
    </>
  );
};

export const Link2users = () => {
  return (
    <>
      <Link to="/users" data-tip="活用事例のご紹介">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          ></path>
        </svg>
      </Link>
    </>
  );
};

export const Link2faq = () => {
  return (
    <>
      <Link to="/faq" data-tip="FAQ">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </Link>
    </>
  );
};

export const Link2howto = () => {
  return (
    <>
      <Link to="/howto" data-tip="使い方">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />

<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
</svg>
      </Link>
    </>
  );
};

export const Link2select = () => {
  return (
    <>
      <Link to="/select" data-tip="選択画面">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
      </Link>
    </>
  );
};

export const Startbutton = (props) => {
  // const { fullscreenset, setFullscreenSet } = useContext(FullscreenSet);
  return (
    <>
      <Link to="/select" data-tip="サイトを使い始めます">
        <div
          onClick={() => {
            // setFullscreenSet(true);
          }}
          data-tip=""
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          <svg
            className="w-16 h-16 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          Start!
        </div>
      </Link>
    </>
  );
};

export const Fullscreenbutton = (props) => {
  const { fullscreenset, setFullscreenSet } = useContext(FullscreenSet);
  /**
   * フルスクリーンかどうかを返す
   * @return {Boolean} フルスクリーンなら true、そうでないなら false
   */
  function isFullScreen() {
    if (
      (document.fullscreenElement !== undefined &&
        document.fullscreenElement !== null) || // HTML5 標準
      (document.mozFullScreenElement !== undefined &&
        document.mozFullScreenElement !== null) || // Firefox
      (document.webkitFullscreenElement !== undefined &&
        document.webkitFullscreenElement !== null) || // Chrome・Safari
      (document.webkitCurrentFullScreenElement !== undefined &&
        document.webkitCurrentFullScreenElement !== null) || // Chrome・Safari (old)
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement !== null)
    ) {
      // IE・Edge Legacy
      return true; // fullscreenElement に何か入ってる = フルスクリーン中
    } else {
      return false; // フルスクリーンではない or フルスクリーン非対応の環境（iOS Safari など）
    }
  }
  const fullcheck = isFullScreen();

  return (
    <>
      {fullscreenset && fullcheck && (
        <div
          onClick={() => {
            setFullscreenSet(!fullscreenset);
          }}
          data-tip="全画面モードから抜けます"
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          <svg
            className="w-8 h-8 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            ></path>
          </svg>
          <div className="text-xs">全画面をやめる</div>
        </div>
      )}
      {(!fullscreenset || !fullcheck) && (
        <Link to="/select" data-tip="サイトを使い始めます">
          <div
            onClick={() => {
              setFullscreenSet(true);
            }}
            data-tip=""
          >
            <ReactTooltip
              effect="float"
              type="info"
              place="right"
              delayShow={1000}
            />
            <svg
              className="w-16 h-16 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Start!
          </div>
        </Link>
      )}
    </>
  );
};

export const Fullviewerset = ({ xl }) => {
  const { fullviewerset, setFullviewerSet } = useContext(FullviewerSet);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  // console.log(fullviewerset);
  // console.log(xl);
  return (
    <>
      {fullviewerset === 1 && (
        <div
          onClick={() => {
            setFullviewerSet(0);
          }}
          data-tip="２画面モードに戻ります"
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
              ></path>
            </svg>
          )}
        </div>
      )}
      {fullviewerset === 0 && (
        <div
          onClick={() => {
            setFullviewerSet(1);
          }}
          data-tip=""
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          )}
        </div>
      )}
    </>
  );
};

export const Map0setbak = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const location = useLocation();
  const map1button = () => {
    if (map1set === photo) {
      setMap1Set(palemap);
      setMap2Set(palemap);
    } else if (map1set === palemap) {
      setMap1Set(stdmap);
      setMap2Set(stdmap);
    } else if (map1set === stdmap) {
      setMap1Set(oldphoto);
      setMap2Set(oldphoto);
    } else if (map1set === oldphoto) {
      setMap1Set(oldmap);
      setMap2Set(oldmap);
    } else {
      setMap1Set(photo);
      setMap2Set(photo);
    }
  };

  var to = to || location.pathname;
  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />
        {xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
        {!xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
      </Link>
    );
  };
  return (
    <>
      {map1set === photo && (
        <h2 onClick={map1button}>
          <Link2m />
          航空
          <br />
          写真
        </h2>
      )}
      {map1set === palemap && (
        <h2 onClick={map1button}>
          <Link2m />
          淡色
          <br />
          地図
        </h2>
      )}
      {map1set === stdmap && (
        <h2 onClick={map1button}>
          <Link2m />
          標準
          <br />
          地図
        </h2>
      )}
      {map1set === oldphoto && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          写真
        </h2>
      )}
      {map1set === oldmap && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          地図
        </h2>
      )}
    </>
  );
};

export const Map0set = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const location = useLocation();
  const map1button = () => {
    if (map1set === photo) {
      setMap1Set(stdmap);
      setMap2Set(oldmap);
    } else if (map1set === stdmap) {
      setMap1Set(photo);
      setMap2Set(oldphoto);
      // } else if (map1set === stdmap) {
      //   setMap1Set(oldphoto);
      //   setMap2Set(oldphoto);
      // } else if (map1set === oldphoto) {
      //   setMap1Set(oldmap);
      //   setMap2Set(oldmap);
    } else {
      setMap1Set(photo);
      setMap2Set(oldphoto);
    }
  };

  var to = to || location.pathname;
  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />
        {xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
        {!xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
      </Link>
    );
  };
  return (
    <>
      {map1set === photo && (
        <h2 onClick={map1button}>
          <Link2m />
          写真
        </h2>
      )}
      {map1set === palemap && (
        <h2 onClick={map1button}>
          <Link2m />
          地図
        </h2>
      )}
      {map1set === stdmap && (
        <h2 onClick={map1button}>
          <Link2m />
          地図
        </h2>
      )}
      {map1set === oldphoto && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          写真
        </h2>
      )}
      {map1set === oldmap && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          地図
        </h2>
      )}
    </>
  );
};

export const Map0oldnewset = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const location = useLocation();
  const map1button = () => {
    if (map1set === photo) {
      setMap1Set(oldmap);
      setMap2Set(stdmap);
    } else if (map1set === oldmap) {
      setMap1Set(oldphoto);
      setMap2Set(photo);
    } else {
      setMap1Set(oldmap);
      setMap2Set(stdmap);
    }
  };
  var to = to || location.pathname;
  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />
        {xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
        {!xl && (
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
            ></path>
          </svg>
        )}
      </Link>
    );
  };
  return (
    <>
      {map1set === photo && (
        <h2 onClick={map1button}>
          <Link2m xl={xl} to={to} />
          写真
        </h2>
      )}
      {map1set === palemap && (
        <h2 onClick={map1button}>
          <Link2m xl={xl} to={to} />
          地図
        </h2>
      )}
      {map1set === stdmap && (
        <h2 onClick={map1button}>
          <Link2m xl={xl} to={to} />
          地図
        </h2>
      )}
      {map1set === oldphoto && (
        <h2 onClick={map1button}>
          <Link2m xl={xl} to={to} />
          写真
        </h2>
      )}
      {map1set === oldmap && (
        <h2 onClick={map1button}>
          <Link2m xl={xl} to={to} />
          地図
        </h2>
      )}
    </>
  );
};

export const Map1setbak = (props) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const map1button = () => {
    if (map1set === photo) {
      setMap1Set(palemap);
    } else if (map1set === palemap) {
      setMap1Set(stdmap);
    } else if (map1set === stdmap) {
      setMap1Set(oldphoto);
    } else if (map1set === oldphoto) {
      setMap1Set(oldmap);
    } else {
      setMap1Set(photo);
    }
  };
  const location = useLocation();
  var to = to || location.pathname;

  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />{" "}
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
      </Link>
    );
  };
  return (
    <>
      {map1set === photo && (
        <h2 onClick={map1button}>
          <Link2m />
          航空
          <br />
          写真
        </h2>
      )}
      {map1set === palemap && (
        <h2 onClick={map1button}>
          <Link2m />
          淡色
          <br />
          地図
        </h2>
      )}
      {map1set === stdmap && (
        <h2 onClick={map1button}>
          <Link2m />
          標準
          <br />
          地図
        </h2>
      )}
      {map1set === oldphoto && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          写真
        </h2>
      )}
      {map1set === oldmap && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          地図
        </h2>
      )}
    </>
  );
};

export const Map1set = (props) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const map1button = () => {
    if (map1set === photo) {
      setMap1Set(palemap);
    } else if (map1set === palemap) {
      setMap1Set(stdmap);
      // } else if (map1set === stdmap) {
      //   setMap1Set(oldphoto);
      // } else if (map1set === oldphoto) {
      //   setMap1Set(oldmap);
    } else {
      setMap1Set(photo);
    }
  };
  const location = useLocation();
  var to = to || location.pathname;

  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />{" "}
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
      </Link>
    );
  };
  return (
    <>
      {map1set === photo && (
        <h2 onClick={map1button}>
          <Link2m />
          写　真
        </h2>
      )}
      {map1set === palemap && (
        <h2 onClick={map1button}>
          <Link2m />
          地図１
        </h2>
      )}
      {map1set === stdmap && (
        <h2 onClick={map1button}>
          <Link2m />
          地図２
        </h2>
      )}
      {map1set === oldphoto && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          写真
        </h2>
      )}
      {map1set === oldmap && (
        <h2 onClick={map1button}>
          <Link2m />
          昔の
          <br />
          地図
        </h2>
      )}
    </>
  );
};

export const Map2set = (props) => {
  const { map2set, setMap2Set } = useContext(Map2Set);
  const map2button = () => {
    if (map2set === photo) {
      setMap2Set(palemap);
    } else if (map2set === palemap) {
      setMap2Set(stdmap);
    } else if (map2set === stdmap) {
      setMap2Set(oldphoto);
    } else if (map2set === oldphoto) {
      setMap2Set(oldmap);
    } else {
      setMap2Set(photo);
    }
  };
  const location = useLocation();
  var to = to || location.pathname;

  const Link2m = () => {
    return (
      <Link to={to} data-tip="地図の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={500}
        />{" "}
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
      </Link>
    );
  };

  return (
    <>
      {map2set === photo && (
        <h2 onClick={map2button}>
          <Link2m />
          航空
          <br />
          写真
        </h2>
      )}
      {map2set === palemap && (
        <h2 onClick={map2button}>
          <Link2m />
          淡色
          <br />
          地図
        </h2>
      )}
      {map2set === stdmap && (
        <h2 onClick={map2button}>
          <Link2m />
          標準
          <br />
          地図
        </h2>
      )}
      {map2set === oldphoto && (
        <h2 onClick={map2button}>
          <Link2m />
          昔の
          <br />
          写真
        </h2>
      )}
      {map2set === oldmap && (
        <h2 onClick={map2button}>
          <Link2m />
          昔の
          <br />
          地図
        </h2>
      )}
    </>
  );
};

export const Syukurendou = ({ xl }) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  return (
    <>
      {rendouset && (
        <div
          onClick={() => {
            setRotate1Set(false);
            setRotate2Set(false);
            setRendouSet(false);
            setSyukurenSet(true);
          }}
          data-tip="左右の操作は連動しています"
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {/* 操作連動中 */}
          {xl && (
            <svg
              className="w-16 h-16 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              ></path>
            </svg>
          )}
        </div>
      )}
      {!rendouset && syukurenset && (
        <div
          onClick={() => {
            setRotate1Set(false);
            setRotate2Set(false);
            setSyukurenSet(false);
            setRendouSet(false);
          }}
          data-tip="左右の縮尺は一致しています"
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {/* 縮尺連動中 */}
          {xl && (
            <svg
              className="w-16 h-16 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
          )}
        </div>
      )}
      {!rendouset && !syukurenset && (
        <div
          onClick={() => {
            setRotate1Set(false);
            setRotate2Set(false);
            setSyukurenSet(false);
            setRendouSet(true);
          }}
          data-tip="左右はそれぞれ独立して操作できます"
        >
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-16 h-16 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20 12H4"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20 12H4"
              ></path>
            </svg>
          )}
        </div>
      )}
    </>
  );
};

export const Syukurendourotate = (props) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { rotate0set, setRotate0Set } = useContext(Rotate0Set);

  return (
    <>
      {rendouset && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(true);
            setRotate0Set(false);
          }}
        >
          操作連動中
        </h2>
      )}
      {!rendouset && syukurenset && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate0Set(false);
          }}
        >
          縮尺連動中
        </h2>
      )}
      {!rendouset && !syukurenset && !rotate0set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate0Set(true);
          }}
        >
          連動OFF
        </h2>
      )}
      {!rendouset && !syukurenset && rotate0set && (
        <h2
          onClick={() => {
            setRendouSet(true);
            setSyukurenSet(false);
            setRotate0Set(false);
          }}
        >
          回転
        </h2>
      )}
      {/* {
    !rendouset && !syukurenset && !rotate0set &&
    <h2 onClick={()=>{
      setRendouSet(true);
      setSyukurenSet(false);
      setRotate0Set(false)
    }} >回転OFF</h2>
  } */}
    </>
  );
};

export const Rotate1set = (props) => {
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);

  const ReturnHtml = (rotate1set) => {
    if (rotate1set) {
      return (
        <h2
          onClick={() => {
            setRotate1Set(false);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          ON
        </h2>
      );
    } else {
      return (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      );
    }
  };
  return <>{ReturnHtml(rotate1set)}</>;
};

export const Rotate2set = (props) => {
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);

  return (
    <>
      {rotate2set && (
        <h2
          onClick={() => {
            setRotate2Set(false);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          ON
        </h2>
      )}
      {!rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate2Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      )}
    </>
  );
};

export const Rotate0jset = ({ xl }) => {
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);

  return (
    <>
      {rotate1set && rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(true);
            setRotate1Set(false);
            setRotate2Set(false);
          }}
        >
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          ON
        </h2>
      )}
      {!rotate1set && !rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          OFF
        </h2>
      )}
      {rotate1set && !rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      )}
      {!rotate1set && rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      )}
    </>
  );
};

export const Rotate0set = ({ xl }) => {
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);

  return (
    <>
      {rotate1set && rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(true);
            setRotate1Set(false);
            setRotate2Set(false);
          }}
        >
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          ON
        </h2>
      )}
      {!rotate1set && !rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          )}
          OFF
        </h2>
      )}
      {rotate1set && !rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      )}
      {!rotate1set && rotate2set && (
        <h2
          onClick={() => {
            setRendouSet(false);
            setSyukurenSet(false);
            setRotate1Set(true);
            setRotate2Set(true);
          }}
        >
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          OFF
        </h2>
      )}
    </>
  );
};

export const Syukuren = (props) => {
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  return (
    <>
      {syukurenset && (
        <h2
          onClick={() => {
            setSyukurenSet(!syukurenset);
          }}
        >
          縮尺連動ON
        </h2>
      )}
      {!syukurenset && (
        <h2
          onClick={() => {
            setSyukurenSet(!syukurenset);
          }}
        >
          縮尺連動OFF
        </h2>
      )}
    </>
  );
};

export const Rendou = (props) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);

  return (
    <>
      {rendouset && (
        <h2
          onClick={() => {
            setRendouSet(!rendouset);
          }}
        >
          操作連動中
        </h2>
      )}
      {!rendouset && (
        <h2
          onClick={() => {
            setRendouSet(!rendouset);
          }}
        >
          操作連動OFF
        </h2>
      )}
    </>
  );
};
