import { UrlTemplateImageryProvider } from "cesium";

// 航空写真
export const imageryProvider = new UrlTemplateImageryProvider({
  // url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
  url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
});

// 航空写真
export const photo = new UrlTemplateImageryProvider({
  // url: '',
  url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
});

// 淡色地図
export const palemap = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
});

// 標準画像
export const stdmap = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
});

// 白地図
export const blankmap = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png",
});

// 英語地図
export const engmap = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/english/{z}/{x}/{y}.png",
});

// 昔の写真
export const oldphoto = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/gazo1/{z}/{x}/{y}.jpg",
});

// 昔の土地利用
export const oldmap = new UrlTemplateImageryProvider({
  url: "https://cyberjapandata.gsi.go.jp/xyz/lum200k/{z}/{x}/{y}.png",
});
