import React from "react";

export default [
  {
    value: "全国",
    label: "全国",
  },
  {
    value: "北海道札幌市",
    label: "札幌市",
  },
  {
    value: "北海道函館市",
    label: "函館市",
  },
  {
    value: "北海道小樽市",
    label: "小樽市",
  },
  {
    value: "北海道旭川市",
    label: "旭川市",
  },
  {
    value: "北海道室蘭市",
    label: "室蘭市",
  },
  {
    value: "北海道釧路市",
    label: "釧路市",
  },
  {
    value: "北海道帯広市",
    label: "帯広市",
  },
  {
    value: "北海道北見市",
    label: "北見市",
  },
  {
    value: "北海道夕張市",
    label: "夕張市",
  },
  {
    value: "北海道岩見沢市",
    label: "岩見沢市",
  },
  {
    value: "北海道網走市",
    label: "網走市",
  },
  {
    value: "北海道留萌市",
    label: "留萌市",
  },
  {
    value: "北海道苫小牧市",
    label: "苫小牧市",
  },
  {
    value: "北海道稚内市",
    label: "稚内市",
  },
  {
    value: "北海道美唄市",
    label: "美唄市",
  },
  {
    value: "北海道芦別市",
    label: "芦別市",
  },
  {
    value: "北海道江別市",
    label: "江別市",
  },
  {
    value: "北海道赤平市",
    label: "赤平市",
  },
  {
    value: "北海道紋別市",
    label: "紋別市",
  },
  {
    value: "北海道士別市",
    label: "士別市",
  },
  {
    value: "北海道名寄市",
    label: "名寄市",
  },
  {
    value: "北海道三笠市",
    label: "三笠市",
  },
  {
    value: "北海道根室市",
    label: "根室市",
  },
  {
    value: "北海道千歳市",
    label: "千歳市",
  },
  {
    value: "北海道滝川市",
    label: "滝川市",
  },
  {
    value: "北海道砂川市",
    label: "砂川市",
  },
  {
    value: "北海道歌志内市",
    label: "歌志内市",
  },
  {
    value: "北海道深川市",
    label: "深川市",
  },
  {
    value: "北海道富良野市",
    label: "富良野市",
  },
  {
    value: "北海道登別市",
    label: "登別市",
  },
  {
    value: "北海道恵庭市",
    label: "恵庭市",
  },
  {
    value: "北海道伊達市",
    label: "伊達市",
  },
  {
    value: "北海道北広島市",
    label: "北広島市",
  },
  {
    value: "北海道石狩市",
    label: "石狩市",
  },
  {
    value: "北海道北斗市",
    label: "北斗市",
  },
  {
    value: "北海道当別町",
    label: "当別町",
  },
  {
    value: "北海道新篠津村",
    label: "新篠津村",
  },
  {
    value: "北海道松前町",
    label: "松前町",
  },
  {
    value: "北海道福島町",
    label: "福島町",
  },
  {
    value: "北海道知内町",
    label: "知内町",
  },
  {
    value: "北海道木古内町",
    label: "木古内町",
  },
  {
    value: "北海道七飯町",
    label: "七飯町",
  },
  {
    value: "北海道鹿部町",
    label: "鹿部町",
  },
  {
    value: "北海道森町",
    label: "森町",
  },
  {
    value: "北海道八雲町",
    label: "八雲町",
  },
  {
    value: "北海道長万部町",
    label: "長万部町",
  },
  {
    value: "北海道江差町",
    label: "江差町",
  },
  {
    value: "北海道上ノ国町",
    label: "上ノ国町",
  },
  {
    value: "北海道厚沢部町",
    label: "厚沢部町",
  },
  {
    value: "北海道乙部町",
    label: "乙部町",
  },
  {
    value: "北海道奥尻町",
    label: "奥尻町",
  },
  {
    value: "北海道今金町",
    label: "今金町",
  },
  {
    value: "北海道せたな町",
    label: "せたな町",
  },
  {
    value: "北海道島牧村",
    label: "島牧村",
  },
  {
    value: "北海道寿都町",
    label: "寿都町",
  },
  {
    value: "北海道黒松内町",
    label: "黒松内町",
  },
  {
    value: "北海道蘭越町",
    label: "蘭越町",
  },
  {
    value: "北海道ニセコ町",
    label: "ニセコ町",
  },
  {
    value: "北海道真狩村",
    label: "真狩村",
  },
  {
    value: "北海道留寿都村",
    label: "留寿都村",
  },
  {
    value: "北海道喜茂別町",
    label: "喜茂別町",
  },
  {
    value: "北海道京極町",
    label: "京極町",
  },
  {
    value: "北海道倶知安町",
    label: "倶知安町",
  },
  {
    value: "北海道共和町",
    label: "共和町",
  },
  {
    value: "北海道岩内町",
    label: "岩内町",
  },
  {
    value: "北海道泊村",
    label: "泊村",
  },
  {
    value: "北海道神恵内村",
    label: "神恵内村",
  },
  {
    value: "北海道積丹町",
    label: "積丹町",
  },
  {
    value: "北海道古平町",
    label: "古平町",
  },
  {
    value: "北海道仁木町",
    label: "仁木町",
  },
  {
    value: "北海道余市町",
    label: "余市町",
  },
  {
    value: "北海道赤井川村",
    label: "赤井川村",
  },
  {
    value: "北海道南幌町",
    label: "南幌町",
  },
  {
    value: "北海道奈井江町",
    label: "奈井江町",
  },
  {
    value: "北海道上砂川町",
    label: "上砂川町",
  },
  {
    value: "北海道由仁町",
    label: "由仁町",
  },
  {
    value: "北海道長沼町",
    label: "長沼町",
  },
  {
    value: "北海道栗山町",
    label: "栗山町",
  },
  {
    value: "北海道月形町",
    label: "月形町",
  },
  {
    value: "北海道浦臼町",
    label: "浦臼町",
  },
  {
    value: "北海道新十津川町",
    label: "新十津川町",
  },
  {
    value: "北海道妹背牛町",
    label: "妹背牛町",
  },
  {
    value: "北海道秩父別町",
    label: "秩父別町",
  },
  {
    value: "北海道雨竜町",
    label: "雨竜町",
  },
  {
    value: "北海道北竜町",
    label: "北竜町",
  },
  {
    value: "北海道沼田町",
    label: "沼田町",
  },
  {
    value: "北海道鷹栖町",
    label: "鷹栖町",
  },
  {
    value: "北海道東神楽町",
    label: "東神楽町",
  },
  {
    value: "北海道当麻町",
    label: "当麻町",
  },
  {
    value: "北海道比布町",
    label: "比布町",
  },
  {
    value: "北海道愛別町",
    label: "愛別町",
  },
  {
    value: "北海道上川町",
    label: "上川町",
  },
  {
    value: "北海道東川町",
    label: "東川町",
  },
  {
    value: "北海道美瑛町",
    label: "美瑛町",
  },
  {
    value: "北海道上富良野町",
    label: "上富良野町",
  },
  {
    value: "北海道中富良野町",
    label: "中富良野町",
  },
  {
    value: "北海道南富良野町",
    label: "南富良野町",
  },
  {
    value: "北海道占冠村",
    label: "占冠村",
  },
  {
    value: "北海道和寒町",
    label: "和寒町",
  },
  {
    value: "北海道剣淵町",
    label: "剣淵町",
  },
  {
    value: "北海道下川町",
    label: "下川町",
  },
  {
    value: "北海道美深町",
    label: "美深町",
  },
  {
    value: "北海道音威子府村",
    label: "音威子府村",
  },
  {
    value: "北海道中川町",
    label: "中川町",
  },
  {
    value: "北海道幌加内町",
    label: "幌加内町",
  },
  {
    value: "北海道増毛町",
    label: "増毛町",
  },
  {
    value: "北海道小平町",
    label: "小平町",
  },
  {
    value: "北海道苫前町",
    label: "苫前町",
  },
  {
    value: "北海道羽幌町",
    label: "羽幌町",
  },
  {
    value: "北海道初山別村",
    label: "初山別村",
  },
  {
    value: "北海道遠別町",
    label: "遠別町",
  },
  {
    value: "北海道天塩町",
    label: "天塩町",
  },
  {
    value: "北海道猿払村",
    label: "猿払村",
  },
  {
    value: "北海道浜頓別町",
    label: "浜頓別町",
  },
  {
    value: "北海道中頓別町",
    label: "中頓別町",
  },
  {
    value: "北海道枝幸町",
    label: "枝幸町",
  },
  {
    value: "北海道豊富町",
    label: "豊富町",
  },
  {
    value: "北海道礼文町",
    label: "礼文町",
  },
  {
    value: "北海道利尻町",
    label: "利尻町",
  },
  {
    value: "北海道利尻富士町",
    label: "利尻富士町",
  },
  {
    value: "北海道幌延町",
    label: "幌延町",
  },
  {
    value: "北海道美幌町",
    label: "美幌町",
  },
  {
    value: "北海道津別町",
    label: "津別町",
  },
  {
    value: "北海道斜里町",
    label: "斜里町",
  },
  {
    value: "北海道清里町",
    label: "清里町",
  },
  {
    value: "北海道小清水町",
    label: "小清水町",
  },
  {
    value: "北海道訓子府町",
    label: "訓子府町",
  },
  {
    value: "北海道置戸町",
    label: "置戸町",
  },
  {
    value: "北海道佐呂間町",
    label: "佐呂間町",
  },
  {
    value: "北海道遠軽町",
    label: "遠軽町",
  },
  {
    value: "北海道湧別町",
    label: "湧別町",
  },
  {
    value: "北海道滝上町",
    label: "滝上町",
  },
  {
    value: "北海道興部町",
    label: "興部町",
  },
  {
    value: "北海道西興部村",
    label: "西興部村",
  },
  {
    value: "北海道雄武町",
    label: "雄武町",
  },
  {
    value: "北海道大空町",
    label: "大空町",
  },
  {
    value: "北海道豊浦町",
    label: "豊浦町",
  },
  {
    value: "北海道壮瞥町",
    label: "壮瞥町",
  },
  {
    value: "北海道白老町",
    label: "白老町",
  },
  {
    value: "北海道厚真町",
    label: "厚真町",
  },
  {
    value: "北海道洞爺湖町",
    label: "洞爺湖町",
  },
  {
    value: "北海道安平町",
    label: "安平町",
  },
  {
    value: "北海道むかわ町",
    label: "むかわ町",
  },
  {
    value: "北海道日高町",
    label: "日高町",
  },
  {
    value: "北海道平取町",
    label: "平取町",
  },
  {
    value: "北海道新冠町",
    label: "新冠町",
  },
  {
    value: "北海道浦河町",
    label: "浦河町",
  },
  {
    value: "北海道様似町",
    label: "様似町",
  },
  {
    value: "北海道えりも町",
    label: "えりも町",
  },
  {
    value: "北海道新ひだか町",
    label: "新ひだか町",
  },
  {
    value: "北海道音更町",
    label: "音更町",
  },
  {
    value: "北海道士幌町",
    label: "士幌町",
  },
  {
    value: "北海道上士幌町",
    label: "上士幌町",
  },
  {
    value: "北海道鹿追町",
    label: "鹿追町",
  },
  {
    value: "北海道新得町",
    label: "新得町",
  },
  {
    value: "北海道清水町",
    label: "清水町",
  },
  {
    value: "北海道芽室町",
    label: "芽室町",
  },
  {
    value: "北海道中札内村",
    label: "中札内村",
  },
  {
    value: "北海道更別村",
    label: "更別村",
  },
  {
    value: "北海道大樹町",
    label: "大樹町",
  },
  {
    value: "北海道広尾町",
    label: "広尾町",
  },
  {
    value: "北海道幕別町",
    label: "幕別町",
  },
  {
    value: "北海道池田町",
    label: "池田町",
  },
  {
    value: "北海道豊頃町",
    label: "豊頃町",
  },
  {
    value: "北海道本別町",
    label: "本別町",
  },
  {
    value: "北海道足寄町",
    label: "足寄町",
  },
  {
    value: "北海道陸別町",
    label: "陸別町",
  },
  {
    value: "北海道浦幌町",
    label: "浦幌町",
  },
  {
    value: "北海道釧路町",
    label: "釧路町",
  },
  {
    value: "北海道厚岸町",
    label: "厚岸町",
  },
  {
    value: "北海道浜中町",
    label: "浜中町",
  },
  {
    value: "北海道標茶町",
    label: "標茶町",
  },
  {
    value: "北海道弟子屈町",
    label: "弟子屈町",
  },
  {
    value: "北海道鶴居村",
    label: "鶴居村",
  },
  {
    value: "北海道白糠町",
    label: "白糠町",
  },
  {
    value: "北海道別海町",
    label: "別海町",
  },
  {
    value: "北海道中標津町",
    label: "中標津町",
  },
  {
    value: "北海道標津町",
    label: "標津町",
  },
  {
    value: "北海道羅臼町",
    label: "羅臼町",
  },
  {
    value: "北海道色丹村",
    label: "色丹村",
  },
  {
    value: "北海道泊村",
    label: "泊村",
  },
  {
    value: "北海道留夜別村",
    label: "留夜別村",
  },
  {
    value: "北海道留別村",
    label: "留別村",
  },
  {
    value: "北海道紗那村",
    label: "紗那村",
  },
  {
    value: "北海道蘂取村",
    label: "蘂取村",
  },
  {
    value: "青森県青森市",
    label: "青森市",
  },
  {
    value: "青森県弘前市",
    label: "弘前市",
  },
  {
    value: "青森県八戸市",
    label: "八戸市",
  },
  {
    value: "青森県黒石市",
    label: "黒石市",
  },
  {
    value: "青森県五所川原市",
    label: "五所川原市",
  },
  {
    value: "青森県十和田市",
    label: "十和田市",
  },
  {
    value: "青森県三沢市",
    label: "三沢市",
  },
  {
    value: "青森県むつ市",
    label: "むつ市",
  },
  {
    value: "青森県つがる市",
    label: "つがる市",
  },
  {
    value: "青森県平川市",
    label: "平川市",
  },
  {
    value: "青森県平内町",
    label: "平内町",
  },
  {
    value: "青森県今別町",
    label: "今別町",
  },
  {
    value: "青森県蓬田村",
    label: "蓬田村",
  },
  {
    value: "青森県外ヶ浜町",
    label: "外ヶ浜町",
  },
  {
    value: "青森県鰺ヶ沢町",
    label: "鰺ヶ沢町",
  },
  {
    value: "青森県深浦町",
    label: "深浦町",
  },
  {
    value: "青森県西目屋村",
    label: "西目屋村",
  },
  {
    value: "青森県藤崎町",
    label: "藤崎町",
  },
  {
    value: "青森県大鰐町",
    label: "大鰐町",
  },
  {
    value: "青森県田舎館村",
    label: "田舎館村",
  },
  {
    value: "青森県板柳町",
    label: "板柳町",
  },
  {
    value: "青森県鶴田町",
    label: "鶴田町",
  },
  {
    value: "青森県中泊町",
    label: "中泊町",
  },
  {
    value: "青森県野辺地町",
    label: "野辺地町",
  },
  {
    value: "青森県七戸町",
    label: "七戸町",
  },
  {
    value: "青森県六戸町",
    label: "六戸町",
  },
  {
    value: "青森県横浜町",
    label: "横浜町",
  },
  {
    value: "青森県東北町",
    label: "東北町",
  },
  {
    value: "青森県六ヶ所村",
    label: "六ヶ所村",
  },
  {
    value: "青森県おいらせ町",
    label: "おいらせ町",
  },
  {
    value: "青森県大間町",
    label: "大間町",
  },
  {
    value: "青森県東通村",
    label: "東通村",
  },
  {
    value: "青森県風間浦村",
    label: "風間浦村",
  },
  {
    value: "青森県佐井村",
    label: "佐井村",
  },
  {
    value: "青森県三戸町",
    label: "三戸町",
  },
  {
    value: "青森県五戸町",
    label: "五戸町",
  },
  {
    value: "青森県田子町",
    label: "田子町",
  },
  {
    value: "青森県南部町",
    label: "南部町",
  },
  {
    value: "青森県階上町",
    label: "階上町",
  },
  {
    value: "青森県新郷村",
    label: "新郷村",
  },
  {
    value: "岩手県盛岡市",
    label: "盛岡市",
  },
  {
    value: "岩手県宮古市",
    label: "宮古市",
  },
  {
    value: "岩手県大船渡市",
    label: "大船渡市",
  },
  {
    value: "岩手県花巻市",
    label: "花巻市",
  },
  {
    value: "岩手県北上市",
    label: "北上市",
  },
  {
    value: "岩手県久慈市",
    label: "久慈市",
  },
  {
    value: "岩手県遠野市",
    label: "遠野市",
  },
  {
    value: "岩手県一関市",
    label: "一関市",
  },
  {
    value: "岩手県陸前高田市",
    label: "陸前高田市",
  },
  {
    value: "岩手県釜石市",
    label: "釜石市",
  },
  {
    value: "岩手県二戸市",
    label: "二戸市",
  },
  {
    value: "岩手県八幡平市",
    label: "八幡平市",
  },
  {
    value: "岩手県奥州市",
    label: "奥州市",
  },
  {
    value: "岩手県滝沢市",
    label: "滝沢市",
  },
  {
    value: "岩手県雫石町",
    label: "雫石町",
  },
  {
    value: "岩手県葛巻町",
    label: "葛巻町",
  },
  {
    value: "岩手県岩手町",
    label: "岩手町",
  },
  {
    value: "岩手県紫波町",
    label: "紫波町",
  },
  {
    value: "岩手県矢巾町",
    label: "矢巾町",
  },
  {
    value: "岩手県西和賀町",
    label: "西和賀町",
  },
  {
    value: "岩手県金ケ崎町",
    label: "金ケ崎町",
  },
  {
    value: "岩手県平泉町",
    label: "平泉町",
  },
  {
    value: "岩手県住田町",
    label: "住田町",
  },
  {
    value: "岩手県大槌町",
    label: "大槌町",
  },
  {
    value: "岩手県山田町",
    label: "山田町",
  },
  {
    value: "岩手県岩泉町",
    label: "岩泉町",
  },
  {
    value: "岩手県田野畑村",
    label: "田野畑村",
  },
  {
    value: "岩手県普代村",
    label: "普代村",
  },
  {
    value: "岩手県軽米町",
    label: "軽米町",
  },
  {
    value: "岩手県野田村",
    label: "野田村",
  },
  {
    value: "岩手県九戸村",
    label: "九戸村",
  },
  {
    value: "岩手県洋野町",
    label: "洋野町",
  },
  {
    value: "岩手県一戸町",
    label: "一戸町",
  },
  {
    value: "宮城県仙台市",
    label: "仙台市",
  },
  {
    value: "宮城県石巻市",
    label: "石巻市",
  },
  {
    value: "宮城県塩竈市",
    label: "塩竈市",
  },
  {
    value: "宮城県気仙沼市",
    label: "気仙沼市",
  },
  {
    value: "宮城県白石市",
    label: "白石市",
  },
  {
    value: "宮城県名取市",
    label: "名取市",
  },
  {
    value: "宮城県角田市",
    label: "角田市",
  },
  {
    value: "宮城県多賀城市",
    label: "多賀城市",
  },
  {
    value: "宮城県岩沼市",
    label: "岩沼市",
  },
  {
    value: "宮城県登米市",
    label: "登米市",
  },
  {
    value: "宮城県栗原市",
    label: "栗原市",
  },
  {
    value: "宮城県東松島市",
    label: "東松島市",
  },
  {
    value: "宮城県大崎市",
    label: "大崎市",
  },
  {
    value: "宮城県富谷市",
    label: "富谷市",
  },
  {
    value: "宮城県蔵王町",
    label: "蔵王町",
  },
  {
    value: "宮城県七ヶ宿町",
    label: "七ヶ宿町",
  },
  {
    value: "宮城県大河原町",
    label: "大河原町",
  },
  {
    value: "宮城県村田町",
    label: "村田町",
  },
  {
    value: "宮城県柴田町",
    label: "柴田町",
  },
  {
    value: "宮城県川崎町",
    label: "川崎町",
  },
  {
    value: "宮城県丸森町",
    label: "丸森町",
  },
  {
    value: "宮城県亘理町",
    label: "亘理町",
  },
  {
    value: "宮城県山元町",
    label: "山元町",
  },
  {
    value: "宮城県松島町",
    label: "松島町",
  },
  {
    value: "宮城県七ヶ浜町",
    label: "七ヶ浜町",
  },
  {
    value: "宮城県利府町",
    label: "利府町",
  },
  {
    value: "宮城県大和町",
    label: "大和町",
  },
  {
    value: "宮城県大郷町",
    label: "大郷町",
  },
  {
    value: "宮城県大衡村",
    label: "大衡村",
  },
  {
    value: "宮城県色麻町",
    label: "色麻町",
  },
  {
    value: "宮城県加美町",
    label: "加美町",
  },
  {
    value: "宮城県涌谷町",
    label: "涌谷町",
  },
  {
    value: "宮城県美里町",
    label: "美里町",
  },
  {
    value: "宮城県女川町",
    label: "女川町",
  },
  {
    value: "宮城県南三陸町",
    label: "南三陸町",
  },
  {
    value: "秋田県秋田市",
    label: "秋田市",
  },
  {
    value: "秋田県能代市",
    label: "能代市",
  },
  {
    value: "秋田県横手市",
    label: "横手市",
  },
  {
    value: "秋田県大館市",
    label: "大館市",
  },
  {
    value: "秋田県男鹿市",
    label: "男鹿市",
  },
  {
    value: "秋田県湯沢市",
    label: "湯沢市",
  },
  {
    value: "秋田県鹿角市",
    label: "鹿角市",
  },
  {
    value: "秋田県由利本荘市",
    label: "由利本荘市",
  },
  {
    value: "秋田県潟上市",
    label: "潟上市",
  },
  {
    value: "秋田県大仙市",
    label: "大仙市",
  },
  {
    value: "秋田県北秋田市",
    label: "北秋田市",
  },
  {
    value: "秋田県にかほ市",
    label: "にかほ市",
  },
  {
    value: "秋田県仙北市",
    label: "仙北市",
  },
  {
    value: "秋田県小坂町",
    label: "小坂町",
  },
  {
    value: "秋田県上小阿仁村",
    label: "上小阿仁村",
  },
  {
    value: "秋田県藤里町",
    label: "藤里町",
  },
  {
    value: "秋田県三種町",
    label: "三種町",
  },
  {
    value: "秋田県八峰町",
    label: "八峰町",
  },
  {
    value: "秋田県五城目町",
    label: "五城目町",
  },
  {
    value: "秋田県八郎潟町",
    label: "八郎潟町",
  },
  {
    value: "秋田県井川町",
    label: "井川町",
  },
  {
    value: "秋田県大潟村",
    label: "大潟村",
  },
  {
    value: "秋田県美郷町",
    label: "美郷町",
  },
  {
    value: "秋田県羽後町",
    label: "羽後町",
  },
  {
    value: "秋田県東成瀬村",
    label: "東成瀬村",
  },
  {
    value: "山形県山形市",
    label: "山形市",
  },
  {
    value: "山形県米沢市",
    label: "米沢市",
  },
  {
    value: "山形県鶴岡市",
    label: "鶴岡市",
  },
  {
    value: "山形県酒田市",
    label: "酒田市",
  },
  {
    value: "山形県新庄市",
    label: "新庄市",
  },
  {
    value: "山形県寒河江市",
    label: "寒河江市",
  },
  {
    value: "山形県上山市",
    label: "上山市",
  },
  {
    value: "山形県村山市",
    label: "村山市",
  },
  {
    value: "山形県長井市",
    label: "長井市",
  },
  {
    value: "山形県天童市",
    label: "天童市",
  },
  {
    value: "山形県東根市",
    label: "東根市",
  },
  {
    value: "山形県尾花沢市",
    label: "尾花沢市",
  },
  {
    value: "山形県南陽市",
    label: "南陽市",
  },
  {
    value: "山形県山辺町",
    label: "山辺町",
  },
  {
    value: "山形県中山町",
    label: "中山町",
  },
  {
    value: "山形県河北町",
    label: "河北町",
  },
  {
    value: "山形県西川町",
    label: "西川町",
  },
  {
    value: "山形県朝日町",
    label: "朝日町",
  },
  {
    value: "山形県大江町",
    label: "大江町",
  },
  {
    value: "山形県大石田町",
    label: "大石田町",
  },
  {
    value: "山形県金山町",
    label: "金山町",
  },
  {
    value: "山形県最上町",
    label: "最上町",
  },
  {
    value: "山形県舟形町",
    label: "舟形町",
  },
  {
    value: "山形県真室川町",
    label: "真室川町",
  },
  {
    value: "山形県大蔵村",
    label: "大蔵村",
  },
  {
    value: "山形県鮭川村",
    label: "鮭川村",
  },
  {
    value: "山形県戸沢村",
    label: "戸沢村",
  },
  {
    value: "山形県高畠町",
    label: "高畠町",
  },
  {
    value: "山形県川西町",
    label: "川西町",
  },
  {
    value: "山形県小国町",
    label: "小国町",
  },
  {
    value: "山形県白鷹町",
    label: "白鷹町",
  },
  {
    value: "山形県飯豊町",
    label: "飯豊町",
  },
  {
    value: "山形県三川町",
    label: "三川町",
  },
  {
    value: "山形県庄内町",
    label: "庄内町",
  },
  {
    value: "山形県遊佐町",
    label: "遊佐町",
  },
  {
    value: "福島県福島市",
    label: "福島市",
  },
  {
    value: "福島県会津若松市",
    label: "会津若松市",
  },
  {
    value: "福島県郡山市",
    label: "郡山市",
  },
  {
    value: "福島県いわき市",
    label: "いわき市",
  },
  {
    value: "福島県白河市",
    label: "白河市",
  },
  {
    value: "福島県須賀川市",
    label: "須賀川市",
  },
  {
    value: "福島県喜多方市",
    label: "喜多方市",
  },
  {
    value: "福島県相馬市",
    label: "相馬市",
  },
  {
    value: "福島県二本松市",
    label: "二本松市",
  },
  {
    value: "福島県田村市",
    label: "田村市",
  },
  {
    value: "福島県南相馬市",
    label: "南相馬市",
  },
  {
    value: "福島県伊達市",
    label: "伊達市",
  },
  {
    value: "福島県本宮市",
    label: "本宮市",
  },
  {
    value: "福島県桑折町",
    label: "桑折町",
  },
  {
    value: "福島県国見町",
    label: "国見町",
  },
  {
    value: "福島県川俣町",
    label: "川俣町",
  },
  {
    value: "福島県大玉村",
    label: "大玉村",
  },
  {
    value: "福島県鏡石町",
    label: "鏡石町",
  },
  {
    value: "福島県天栄村",
    label: "天栄村",
  },
  {
    value: "福島県下郷町",
    label: "下郷町",
  },
  {
    value: "福島県檜枝岐村",
    label: "檜枝岐村",
  },
  {
    value: "福島県只見町",
    label: "只見町",
  },
  {
    value: "福島県南会津町",
    label: "南会津町",
  },
  {
    value: "福島県北塩原村",
    label: "北塩原村",
  },
  {
    value: "福島県西会津町",
    label: "西会津町",
  },
  {
    value: "福島県磐梯町",
    label: "磐梯町",
  },
  {
    value: "福島県猪苗代町",
    label: "猪苗代町",
  },
  {
    value: "福島県会津坂下町",
    label: "会津坂下町",
  },
  {
    value: "福島県湯川村",
    label: "湯川村",
  },
  {
    value: "福島県柳津町",
    label: "柳津町",
  },
  {
    value: "福島県三島町",
    label: "三島町",
  },
  {
    value: "福島県金山町",
    label: "金山町",
  },
  {
    value: "福島県昭和村",
    label: "昭和村",
  },
  {
    value: "福島県会津美里町",
    label: "会津美里町",
  },
  {
    value: "福島県西郷村",
    label: "西郷村",
  },
  {
    value: "福島県泉崎村",
    label: "泉崎村",
  },
  {
    value: "福島県中島村",
    label: "中島村",
  },
  {
    value: "福島県矢吹町",
    label: "矢吹町",
  },
  {
    value: "福島県棚倉町",
    label: "棚倉町",
  },
  {
    value: "福島県矢祭町",
    label: "矢祭町",
  },
  {
    value: "福島県塙町",
    label: "塙町",
  },
  {
    value: "福島県鮫川村",
    label: "鮫川村",
  },
  {
    value: "福島県石川町",
    label: "石川町",
  },
  {
    value: "福島県玉川村",
    label: "玉川村",
  },
  {
    value: "福島県平田村",
    label: "平田村",
  },
  {
    value: "福島県浅川町",
    label: "浅川町",
  },
  {
    value: "福島県古殿町",
    label: "古殿町",
  },
  {
    value: "福島県三春町",
    label: "三春町",
  },
  {
    value: "福島県小野町",
    label: "小野町",
  },
  {
    value: "福島県広野町",
    label: "広野町",
  },
  {
    value: "福島県楢葉町",
    label: "楢葉町",
  },
  {
    value: "福島県富岡町",
    label: "富岡町",
  },
  {
    value: "福島県川内村",
    label: "川内村",
  },
  {
    value: "福島県大熊町",
    label: "大熊町",
  },
  {
    value: "福島県双葉町",
    label: "双葉町",
  },
  {
    value: "福島県浪江町",
    label: "浪江町",
  },
  {
    value: "福島県葛尾村",
    label: "葛尾村",
  },
  {
    value: "福島県新地町",
    label: "新地町",
  },
  {
    value: "福島県飯舘村",
    label: "飯舘村",
  },
  {
    value: "茨城県水戸市",
    label: "水戸市",
  },
  {
    value: "茨城県日立市",
    label: "日立市",
  },
  {
    value: "茨城県土浦市",
    label: "土浦市",
  },
  {
    value: "茨城県古河市",
    label: "古河市",
  },
  {
    value: "茨城県石岡市",
    label: "石岡市",
  },
  {
    value: "茨城県結城市",
    label: "結城市",
  },
  {
    value: "茨城県龍ケ崎市",
    label: "龍ケ崎市",
  },
  {
    value: "茨城県下妻市",
    label: "下妻市",
  },
  {
    value: "茨城県常総市",
    label: "常総市",
  },
  {
    value: "茨城県常陸太田市",
    label: "常陸太田市",
  },
  {
    value: "茨城県高萩市",
    label: "高萩市",
  },
  {
    value: "茨城県北茨城市",
    label: "北茨城市",
  },
  {
    value: "茨城県笠間市",
    label: "笠間市",
  },
  {
    value: "茨城県取手市",
    label: "取手市",
  },
  {
    value: "茨城県牛久市",
    label: "牛久市",
  },
  {
    value: "茨城県つくば市",
    label: "つくば市",
  },
  {
    value: "茨城県ひたちなか市",
    label: "ひたちなか市",
  },
  {
    value: "茨城県鹿嶋市",
    label: "鹿嶋市",
  },
  {
    value: "茨城県潮来市",
    label: "潮来市",
  },
  {
    value: "茨城県守谷市",
    label: "守谷市",
  },
  {
    value: "茨城県常陸大宮市",
    label: "常陸大宮市",
  },
  {
    value: "茨城県那珂市",
    label: "那珂市",
  },
  {
    value: "茨城県筑西市",
    label: "筑西市",
  },
  {
    value: "茨城県坂東市",
    label: "坂東市",
  },
  {
    value: "茨城県稲敷市",
    label: "稲敷市",
  },
  {
    value: "茨城県かすみがうら市",
    label: "かすみがうら市",
  },
  {
    value: "茨城県桜川市",
    label: "桜川市",
  },
  {
    value: "茨城県神栖市",
    label: "神栖市",
  },
  {
    value: "茨城県行方市",
    label: "行方市",
  },
  {
    value: "茨城県鉾田市",
    label: "鉾田市",
  },
  {
    value: "茨城県つくばみらい市",
    label: "つくばみらい市",
  },
  {
    value: "茨城県小美玉市",
    label: "小美玉市",
  },
  {
    value: "茨城県茨城町",
    label: "茨城町",
  },
  {
    value: "茨城県大洗町",
    label: "大洗町",
  },
  {
    value: "茨城県城里町",
    label: "城里町",
  },
  {
    value: "茨城県東海村",
    label: "東海村",
  },
  {
    value: "茨城県大子町",
    label: "大子町",
  },
  {
    value: "茨城県美浦村",
    label: "美浦村",
  },
  {
    value: "茨城県阿見町",
    label: "阿見町",
  },
  {
    value: "茨城県河内町",
    label: "河内町",
  },
  {
    value: "茨城県八千代町",
    label: "八千代町",
  },
  {
    value: "茨城県五霞町",
    label: "五霞町",
  },
  {
    value: "茨城県境町",
    label: "境町",
  },
  {
    value: "茨城県利根町",
    label: "利根町",
  },
  {
    value: "栃木県宇都宮市",
    label: "宇都宮市",
  },
  {
    value: "栃木県足利市",
    label: "足利市",
  },
  {
    value: "栃木県栃木市",
    label: "栃木市",
  },
  {
    value: "栃木県佐野市",
    label: "佐野市",
  },
  {
    value: "栃木県鹿沼市",
    label: "鹿沼市",
  },
  {
    value: "栃木県日光市",
    label: "日光市",
  },
  {
    value: "栃木県小山市",
    label: "小山市",
  },
  {
    value: "栃木県真岡市",
    label: "真岡市",
  },
  {
    value: "栃木県大田原市",
    label: "大田原市",
  },
  {
    value: "栃木県矢板市",
    label: "矢板市",
  },
  {
    value: "栃木県那須塩原市",
    label: "那須塩原市",
  },
  {
    value: "栃木県さくら市",
    label: "さくら市",
  },
  {
    value: "栃木県那須烏山市",
    label: "那須烏山市",
  },
  {
    value: "栃木県下野市",
    label: "下野市",
  },
  {
    value: "栃木県上三川町",
    label: "上三川町",
  },
  {
    value: "栃木県益子町",
    label: "益子町",
  },
  {
    value: "栃木県茂木町",
    label: "茂木町",
  },
  {
    value: "栃木県市貝町",
    label: "市貝町",
  },
  {
    value: "栃木県芳賀町",
    label: "芳賀町",
  },
  {
    value: "栃木県壬生町",
    label: "壬生町",
  },
  {
    value: "栃木県野木町",
    label: "野木町",
  },
  {
    value: "栃木県塩谷町",
    label: "塩谷町",
  },
  {
    value: "栃木県高根沢町",
    label: "高根沢町",
  },
  {
    value: "栃木県那須町",
    label: "那須町",
  },
  {
    value: "栃木県那珂川町",
    label: "那珂川町",
  },
  {
    value: "群馬県前橋市",
    label: "前橋市",
  },
  {
    value: "群馬県高崎市",
    label: "高崎市",
  },
  {
    value: "群馬県桐生市",
    label: "桐生市",
  },
  {
    value: "群馬県伊勢崎市",
    label: "伊勢崎市",
  },
  {
    value: "群馬県太田市",
    label: "太田市",
  },
  {
    value: "群馬県沼田市",
    label: "沼田市",
  },
  {
    value: "群馬県館林市",
    label: "館林市",
  },
  {
    value: "群馬県渋川市",
    label: "渋川市",
  },
  {
    value: "群馬県藤岡市",
    label: "藤岡市",
  },
  {
    value: "群馬県富岡市",
    label: "富岡市",
  },
  {
    value: "群馬県安中市",
    label: "安中市",
  },
  {
    value: "群馬県みどり市",
    label: "みどり市",
  },
  {
    value: "群馬県榛東村",
    label: "榛東村",
  },
  {
    value: "群馬県吉岡町",
    label: "吉岡町",
  },
  {
    value: "群馬県上野村",
    label: "上野村",
  },
  {
    value: "群馬県神流町",
    label: "神流町",
  },
  {
    value: "群馬県下仁田町",
    label: "下仁田町",
  },
  {
    value: "群馬県南牧村",
    label: "南牧村",
  },
  {
    value: "群馬県甘楽町",
    label: "甘楽町",
  },
  {
    value: "群馬県中之条町",
    label: "中之条町",
  },
  {
    value: "群馬県長野原町",
    label: "長野原町",
  },
  {
    value: "群馬県嬬恋村",
    label: "嬬恋村",
  },
  {
    value: "群馬県草津町",
    label: "草津町",
  },
  {
    value: "群馬県高山村",
    label: "高山村",
  },
  {
    value: "群馬県東吾妻町",
    label: "東吾妻町",
  },
  {
    value: "群馬県片品村",
    label: "片品村",
  },
  {
    value: "群馬県川場村",
    label: "川場村",
  },
  {
    value: "群馬県昭和村",
    label: "昭和村",
  },
  {
    value: "群馬県みなかみ町",
    label: "みなかみ町",
  },
  {
    value: "群馬県玉村町",
    label: "玉村町",
  },
  {
    value: "群馬県板倉町",
    label: "板倉町",
  },
  {
    value: "群馬県明和町",
    label: "明和町",
  },
  {
    value: "群馬県千代田町",
    label: "千代田町",
  },
  {
    value: "群馬県大泉町",
    label: "大泉町",
  },
  {
    value: "群馬県邑楽町",
    label: "邑楽町",
  },
  {
    value: "埼玉県さいたま市",
    label: "さいたま市",
  },
  {
    value: "埼玉県川越市",
    label: "川越市",
  },
  {
    value: "埼玉県熊谷市",
    label: "熊谷市",
  },
  {
    value: "埼玉県川口市",
    label: "川口市",
  },
  {
    value: "埼玉県行田市",
    label: "行田市",
  },
  {
    value: "埼玉県秩父市",
    label: "秩父市",
  },
  {
    value: "埼玉県所沢市",
    label: "所沢市",
  },
  {
    value: "埼玉県飯能市",
    label: "飯能市",
  },
  {
    value: "埼玉県加須市",
    label: "加須市",
  },
  {
    value: "埼玉県本庄市",
    label: "本庄市",
  },
  {
    value: "埼玉県東松山市",
    label: "東松山市",
  },
  {
    value: "埼玉県春日部市",
    label: "春日部市",
  },
  {
    value: "埼玉県狭山市",
    label: "狭山市",
  },
  {
    value: "埼玉県羽生市",
    label: "羽生市",
  },
  {
    value: "埼玉県鴻巣市",
    label: "鴻巣市",
  },
  {
    value: "埼玉県深谷市",
    label: "深谷市",
  },
  {
    value: "埼玉県上尾市",
    label: "上尾市",
  },
  {
    value: "埼玉県草加市",
    label: "草加市",
  },
  {
    value: "埼玉県越谷市",
    label: "越谷市",
  },
  {
    value: "埼玉県蕨市",
    label: "蕨市",
  },
  {
    value: "埼玉県戸田市",
    label: "戸田市",
  },
  {
    value: "埼玉県入間市",
    label: "入間市",
  },
  {
    value: "埼玉県朝霞市",
    label: "朝霞市",
  },
  {
    value: "埼玉県志木市",
    label: "志木市",
  },
  {
    value: "埼玉県和光市",
    label: "和光市",
  },
  {
    value: "埼玉県新座市",
    label: "新座市",
  },
  {
    value: "埼玉県桶川市",
    label: "桶川市",
  },
  {
    value: "埼玉県久喜市",
    label: "久喜市",
  },
  {
    value: "埼玉県北本市",
    label: "北本市",
  },
  {
    value: "埼玉県八潮市",
    label: "八潮市",
  },
  {
    value: "埼玉県富士見市",
    label: "富士見市",
  },
  {
    value: "埼玉県三郷市",
    label: "三郷市",
  },
  {
    value: "埼玉県蓮田市",
    label: "蓮田市",
  },
  {
    value: "埼玉県坂戸市",
    label: "坂戸市",
  },
  {
    value: "埼玉県幸手市",
    label: "幸手市",
  },
  {
    value: "埼玉県鶴ヶ島市",
    label: "鶴ヶ島市",
  },
  {
    value: "埼玉県日高市",
    label: "日高市",
  },
  {
    value: "埼玉県吉川市",
    label: "吉川市",
  },
  {
    value: "埼玉県ふじみ野市",
    label: "ふじみ野市",
  },
  {
    value: "埼玉県白岡市",
    label: "白岡市",
  },
  {
    value: "埼玉県伊奈町",
    label: "伊奈町",
  },
  {
    value: "埼玉県三芳町",
    label: "三芳町",
  },
  {
    value: "埼玉県毛呂山町",
    label: "毛呂山町",
  },
  {
    value: "埼玉県越生町",
    label: "越生町",
  },
  {
    value: "埼玉県滑川町",
    label: "滑川町",
  },
  {
    value: "埼玉県嵐山町",
    label: "嵐山町",
  },
  {
    value: "埼玉県小川町",
    label: "小川町",
  },
  {
    value: "埼玉県川島町",
    label: "川島町",
  },
  {
    value: "埼玉県吉見町",
    label: "吉見町",
  },
  {
    value: "埼玉県鳩山町",
    label: "鳩山町",
  },
  {
    value: "埼玉県ときがわ町",
    label: "ときがわ町",
  },
  {
    value: "埼玉県横瀬町",
    label: "横瀬町",
  },
  {
    value: "埼玉県皆野町",
    label: "皆野町",
  },
  {
    value: "埼玉県長瀞町",
    label: "長瀞町",
  },
  {
    value: "埼玉県小鹿野町",
    label: "小鹿野町",
  },
  {
    value: "埼玉県東秩父村",
    label: "東秩父村",
  },
  {
    value: "埼玉県美里町",
    label: "美里町",
  },
  {
    value: "埼玉県神川町",
    label: "神川町",
  },
  {
    value: "埼玉県上里町",
    label: "上里町",
  },
  {
    value: "埼玉県寄居町",
    label: "寄居町",
  },
  {
    value: "埼玉県宮代町",
    label: "宮代町",
  },
  {
    value: "埼玉県杉戸町",
    label: "杉戸町",
  },
  {
    value: "埼玉県松伏町",
    label: "松伏町",
  },
  {
    value: "千葉県千葉市",
    label: "千葉市",
  },
  {
    value: "千葉県銚子市",
    label: "銚子市",
  },
  {
    value: "千葉県市川市",
    label: "市川市",
  },
  {
    value: "千葉県船橋市",
    label: "船橋市",
  },
  {
    value: "千葉県館山市",
    label: "館山市",
  },
  {
    value: "千葉県木更津市",
    label: "木更津市",
  },
  {
    value: "千葉県松戸市",
    label: "松戸市",
  },
  {
    value: "千葉県野田市",
    label: "野田市",
  },
  {
    value: "千葉県茂原市",
    label: "茂原市",
  },
  {
    value: "千葉県成田市",
    label: "成田市",
  },
  {
    value: "千葉県佐倉市",
    label: "佐倉市",
  },
  {
    value: "千葉県東金市",
    label: "東金市",
  },
  {
    value: "千葉県旭市",
    label: "旭市",
  },
  {
    value: "千葉県習志野市",
    label: "習志野市",
  },
  {
    value: "千葉県柏市",
    label: "柏市",
  },
  {
    value: "千葉県勝浦市",
    label: "勝浦市",
  },
  {
    value: "千葉県市原市",
    label: "市原市",
  },
  {
    value: "千葉県流山市",
    label: "流山市",
  },
  {
    value: "千葉県八千代市",
    label: "八千代市",
  },
  {
    value: "千葉県我孫子市",
    label: "我孫子市",
  },
  {
    value: "千葉県鴨川市",
    label: "鴨川市",
  },
  {
    value: "千葉県鎌ケ谷市",
    label: "鎌ケ谷市",
  },
  {
    value: "千葉県君津市",
    label: "君津市",
  },
  {
    value: "千葉県富津市",
    label: "富津市",
  },
  {
    value: "千葉県浦安市",
    label: "浦安市",
  },
  {
    value: "千葉県四街道市",
    label: "四街道市",
  },
  {
    value: "千葉県袖ケ浦市",
    label: "袖ケ浦市",
  },
  {
    value: "千葉県八街市",
    label: "八街市",
  },
  {
    value: "千葉県印西市",
    label: "印西市",
  },
  {
    value: "千葉県白井市",
    label: "白井市",
  },
  {
    value: "千葉県富里市",
    label: "富里市",
  },
  {
    value: "千葉県南房総市",
    label: "南房総市",
  },
  {
    value: "千葉県匝瑳市",
    label: "匝瑳市",
  },
  {
    value: "千葉県香取市",
    label: "香取市",
  },
  {
    value: "千葉県山武市",
    label: "山武市",
  },
  {
    value: "千葉県いすみ市",
    label: "いすみ市",
  },
  {
    value: "千葉県大網白里市",
    label: "大網白里市",
  },
  {
    value: "千葉県酒々井町",
    label: "酒々井町",
  },
  {
    value: "千葉県栄町",
    label: "栄町",
  },
  {
    value: "千葉県神崎町",
    label: "神崎町",
  },
  {
    value: "千葉県多古町",
    label: "多古町",
  },
  {
    value: "千葉県東庄町",
    label: "東庄町",
  },
  {
    value: "千葉県九十九里町",
    label: "九十九里町",
  },
  {
    value: "千葉県芝山町",
    label: "芝山町",
  },
  {
    value: "千葉県横芝光町",
    label: "横芝光町",
  },
  {
    value: "千葉県一宮町",
    label: "一宮町",
  },
  {
    value: "千葉県睦沢町",
    label: "睦沢町",
  },
  {
    value: "千葉県長生村",
    label: "長生村",
  },
  {
    value: "千葉県白子町",
    label: "白子町",
  },
  {
    value: "千葉県長柄町",
    label: "長柄町",
  },
  {
    value: "千葉県長南町",
    label: "長南町",
  },
  {
    value: "千葉県大多喜町",
    label: "大多喜町",
  },
  {
    value: "千葉県御宿町",
    label: "御宿町",
  },
  {
    value: "千葉県鋸南町",
    label: "鋸南町",
  },
  {
    value: "東京都千代田区",
    label: "千代田区",
  },
  {
    value: "東京都中央区",
    label: "中央区",
  },
  {
    value: "東京都港区",
    label: "港区",
  },
  {
    value: "東京都新宿区",
    label: "新宿区",
  },
  {
    value: "東京都文京区",
    label: "文京区",
  },
  {
    value: "東京都台東区",
    label: "台東区",
  },
  {
    value: "東京都墨田区",
    label: "墨田区",
  },
  {
    value: "東京都江東区",
    label: "江東区",
  },
  {
    value: "東京都品川区",
    label: "品川区",
  },
  {
    value: "東京都目黒区",
    label: "目黒区",
  },
  {
    value: "東京都大田区",
    label: "大田区",
  },
  {
    value: "東京都世田谷区",
    label: "世田谷区",
  },
  {
    value: "東京都渋谷区",
    label: "渋谷区",
  },
  {
    value: "東京都中野区",
    label: "中野区",
  },
  {
    value: "東京都杉並区",
    label: "杉並区",
  },
  {
    value: "東京都豊島区",
    label: "豊島区",
  },
  {
    value: "東京都北区",
    label: "北区",
  },
  {
    value: "東京都荒川区",
    label: "荒川区",
  },
  {
    value: "東京都板橋区",
    label: "板橋区",
  },
  {
    value: "東京都練馬区",
    label: "練馬区",
  },
  {
    value: "東京都足立区",
    label: "足立区",
  },
  {
    value: "東京都葛飾区",
    label: "葛飾区",
  },
  {
    value: "東京都江戸川区",
    label: "江戸川区",
  },
  {
    value: "東京都八王子市",
    label: "八王子市",
  },
  {
    value: "東京都立川市",
    label: "立川市",
  },
  {
    value: "東京都武蔵野市",
    label: "武蔵野市",
  },
  {
    value: "東京都三鷹市",
    label: "三鷹市",
  },
  {
    value: "東京都青梅市",
    label: "青梅市",
  },
  {
    value: "東京都府中市",
    label: "府中市",
  },
  {
    value: "東京都昭島市",
    label: "昭島市",
  },
  {
    value: "東京都調布市",
    label: "調布市",
  },
  {
    value: "東京都町田市",
    label: "町田市",
  },
  {
    value: "東京都小金井市",
    label: "小金井市",
  },
  {
    value: "東京都小平市",
    label: "小平市",
  },
  {
    value: "東京都日野市",
    label: "日野市",
  },
  {
    value: "東京都東村山市",
    label: "東村山市",
  },
  {
    value: "東京都国分寺市",
    label: "国分寺市",
  },
  {
    value: "東京都国立市",
    label: "国立市",
  },
  {
    value: "東京都福生市",
    label: "福生市",
  },
  {
    value: "東京都狛江市",
    label: "狛江市",
  },
  {
    value: "東京都東大和市",
    label: "東大和市",
  },
  {
    value: "東京都清瀬市",
    label: "清瀬市",
  },
  {
    value: "東京都東久留米市",
    label: "東久留米市",
  },
  {
    value: "東京都武蔵村山市",
    label: "武蔵村山市",
  },
  {
    value: "東京都多摩市",
    label: "多摩市",
  },
  {
    value: "東京都稲城市",
    label: "稲城市",
  },
  {
    value: "東京都羽村市",
    label: "羽村市",
  },
  {
    value: "東京都あきる野市",
    label: "あきる野市",
  },
  {
    value: "東京都西東京市",
    label: "西東京市",
  },
  {
    value: "東京都瑞穂町",
    label: "瑞穂町",
  },
  {
    value: "東京都日の出町",
    label: "日の出町",
  },
  {
    value: "東京都檜原村",
    label: "檜原村",
  },
  {
    value: "東京都奥多摩町",
    label: "奥多摩町",
  },
  {
    value: "東京都大島町",
    label: "大島町",
  },
  {
    value: "東京都利島村",
    label: "利島村",
  },
  {
    value: "東京都新島村",
    label: "新島村",
  },
  {
    value: "東京都神津島村",
    label: "神津島村",
  },
  {
    value: "東京都三宅村",
    label: "三宅村",
  },
  {
    value: "東京都御蔵島村",
    label: "御蔵島村",
  },
  {
    value: "東京都八丈町",
    label: "八丈町",
  },
  {
    value: "東京都青ヶ島村",
    label: "青ヶ島村",
  },
  {
    value: "東京都小笠原村",
    label: "小笠原村",
  },
  {
    value: "神奈川県横浜市",
    label: "横浜市",
  },
  {
    value: "神奈川県川崎市",
    label: "川崎市",
  },
  {
    value: "神奈川県相模原市",
    label: "相模原市",
  },
  {
    value: "神奈川県横須賀市",
    label: "横須賀市",
  },
  {
    value: "神奈川県平塚市",
    label: "平塚市",
  },
  {
    value: "神奈川県鎌倉市",
    label: "鎌倉市",
  },
  {
    value: "神奈川県藤沢市",
    label: "藤沢市",
  },
  {
    value: "神奈川県小田原市",
    label: "小田原市",
  },
  {
    value: "神奈川県茅ヶ崎市",
    label: "茅ヶ崎市",
  },
  {
    value: "神奈川県逗子市",
    label: "逗子市",
  },
  {
    value: "神奈川県三浦市",
    label: "三浦市",
  },
  {
    value: "神奈川県秦野市",
    label: "秦野市",
  },
  {
    value: "神奈川県厚木市",
    label: "厚木市",
  },
  {
    value: "神奈川県大和市",
    label: "大和市",
  },
  {
    value: "神奈川県伊勢原市",
    label: "伊勢原市",
  },
  {
    value: "神奈川県海老名市",
    label: "海老名市",
  },
  {
    value: "神奈川県座間市",
    label: "座間市",
  },
  {
    value: "神奈川県南足柄市",
    label: "南足柄市",
  },
  {
    value: "神奈川県綾瀬市",
    label: "綾瀬市",
  },
  {
    value: "神奈川県葉山町",
    label: "葉山町",
  },
  {
    value: "神奈川県寒川町",
    label: "寒川町",
  },
  {
    value: "神奈川県大磯町",
    label: "大磯町",
  },
  {
    value: "神奈川県二宮町",
    label: "二宮町",
  },
  {
    value: "神奈川県中井町",
    label: "中井町",
  },
  {
    value: "神奈川県大井町",
    label: "大井町",
  },
  {
    value: "神奈川県松田町",
    label: "松田町",
  },
  {
    value: "神奈川県山北町",
    label: "山北町",
  },
  {
    value: "神奈川県開成町",
    label: "開成町",
  },
  {
    value: "神奈川県箱根町",
    label: "箱根町",
  },
  {
    value: "神奈川県真鶴町",
    label: "真鶴町",
  },
  {
    value: "神奈川県湯河原町",
    label: "湯河原町",
  },
  {
    value: "神奈川県愛川町",
    label: "愛川町",
  },
  {
    value: "神奈川県清川村",
    label: "清川村",
  },
  {
    value: "新潟県新潟市",
    label: "新潟市",
  },
  {
    value: "新潟県長岡市",
    label: "長岡市",
  },
  {
    value: "新潟県三条市",
    label: "三条市",
  },
  {
    value: "新潟県柏崎市",
    label: "柏崎市",
  },
  {
    value: "新潟県新発田市",
    label: "新発田市",
  },
  {
    value: "新潟県小千谷市",
    label: "小千谷市",
  },
  {
    value: "新潟県加茂市",
    label: "加茂市",
  },
  {
    value: "新潟県十日町市",
    label: "十日町市",
  },
  {
    value: "新潟県見附市",
    label: "見附市",
  },
  {
    value: "新潟県村上市",
    label: "村上市",
  },
  {
    value: "新潟県燕市",
    label: "燕市",
  },
  {
    value: "新潟県糸魚川市",
    label: "糸魚川市",
  },
  {
    value: "新潟県妙高市",
    label: "妙高市",
  },
  {
    value: "新潟県五泉市",
    label: "五泉市",
  },
  {
    value: "新潟県上越市",
    label: "上越市",
  },
  {
    value: "新潟県阿賀野市",
    label: "阿賀野市",
  },
  {
    value: "新潟県佐渡市",
    label: "佐渡市",
  },
  {
    value: "新潟県魚沼市",
    label: "魚沼市",
  },
  {
    value: "新潟県南魚沼市",
    label: "南魚沼市",
  },
  {
    value: "新潟県胎内市",
    label: "胎内市",
  },
  {
    value: "新潟県聖籠町",
    label: "聖籠町",
  },
  {
    value: "新潟県弥彦村",
    label: "弥彦村",
  },
  {
    value: "新潟県田上町",
    label: "田上町",
  },
  {
    value: "新潟県阿賀町",
    label: "阿賀町",
  },
  {
    value: "新潟県出雲崎町",
    label: "出雲崎町",
  },
  {
    value: "新潟県湯沢町",
    label: "湯沢町",
  },
  {
    value: "新潟県津南町",
    label: "津南町",
  },
  {
    value: "新潟県刈羽村",
    label: "刈羽村",
  },
  {
    value: "新潟県関川村",
    label: "関川村",
  },
  {
    value: "新潟県粟島浦村",
    label: "粟島浦村",
  },
  {
    value: "富山県富山市",
    label: "富山市",
  },
  {
    value: "富山県高岡市",
    label: "高岡市",
  },
  {
    value: "富山県魚津市",
    label: "魚津市",
  },
  {
    value: "富山県氷見市",
    label: "氷見市",
  },
  {
    value: "富山県滑川市",
    label: "滑川市",
  },
  {
    value: "富山県黒部市",
    label: "黒部市",
  },
  {
    value: "富山県砺波市",
    label: "砺波市",
  },
  {
    value: "富山県小矢部市",
    label: "小矢部市",
  },
  {
    value: "富山県南砺市",
    label: "南砺市",
  },
  {
    value: "富山県射水市",
    label: "射水市",
  },
  {
    value: "富山県舟橋村",
    label: "舟橋村",
  },
  {
    value: "富山県上市町",
    label: "上市町",
  },
  {
    value: "富山県立山町",
    label: "立山町",
  },
  {
    value: "富山県入善町",
    label: "入善町",
  },
  {
    value: "富山県朝日町",
    label: "朝日町",
  },
  {
    value: "石川県金沢市",
    label: "金沢市",
  },
  {
    value: "石川県七尾市",
    label: "七尾市",
  },
  {
    value: "石川県小松市",
    label: "小松市",
  },
  {
    value: "石川県輪島市",
    label: "輪島市",
  },
  {
    value: "石川県珠洲市",
    label: "珠洲市",
  },
  {
    value: "石川県加賀市",
    label: "加賀市",
  },
  {
    value: "石川県羽咋市",
    label: "羽咋市",
  },
  {
    value: "石川県かほく市",
    label: "かほく市",
  },
  {
    value: "石川県白山市",
    label: "白山市",
  },
  {
    value: "石川県能美市",
    label: "能美市",
  },
  {
    value: "石川県野々市市",
    label: "野々市市",
  },
  {
    value: "石川県川北町",
    label: "川北町",
  },
  {
    value: "石川県津幡町",
    label: "津幡町",
  },
  {
    value: "石川県内灘町",
    label: "内灘町",
  },
  {
    value: "石川県志賀町",
    label: "志賀町",
  },
  {
    value: "石川県宝達志水町",
    label: "宝達志水町",
  },
  {
    value: "石川県中能登町",
    label: "中能登町",
  },
  {
    value: "石川県穴水町",
    label: "穴水町",
  },
  {
    value: "石川県能登町",
    label: "能登町",
  },
  {
    value: "福井県福井市",
    label: "福井市",
  },
  {
    value: "福井県敦賀市",
    label: "敦賀市",
  },
  {
    value: "福井県小浜市",
    label: "小浜市",
  },
  {
    value: "福井県大野市",
    label: "大野市",
  },
  {
    value: "福井県勝山市",
    label: "勝山市",
  },
  {
    value: "福井県鯖江市",
    label: "鯖江市",
  },
  {
    value: "福井県あわら市",
    label: "あわら市",
  },
  {
    value: "福井県越前市",
    label: "越前市",
  },
  {
    value: "福井県坂井市",
    label: "坂井市",
  },
  {
    value: "福井県永平寺町",
    label: "永平寺町",
  },
  {
    value: "福井県池田町",
    label: "池田町",
  },
  {
    value: "福井県南越前町",
    label: "南越前町",
  },
  {
    value: "福井県越前町",
    label: "越前町",
  },
  {
    value: "福井県美浜町",
    label: "美浜町",
  },
  {
    value: "福井県高浜町",
    label: "高浜町",
  },
  {
    value: "福井県おおい町",
    label: "おおい町",
  },
  {
    value: "福井県若狭町",
    label: "若狭町",
  },
  {
    value: "山梨県甲府市",
    label: "甲府市",
  },
  {
    value: "山梨県富士吉田市",
    label: "富士吉田市",
  },
  {
    value: "山梨県都留市",
    label: "都留市",
  },
  {
    value: "山梨県山梨市",
    label: "山梨市",
  },
  {
    value: "山梨県大月市",
    label: "大月市",
  },
  {
    value: "山梨県韮崎市",
    label: "韮崎市",
  },
  {
    value: "山梨県南アルプス市",
    label: "南アルプス市",
  },
  {
    value: "山梨県北杜市",
    label: "北杜市",
  },
  {
    value: "山梨県甲斐市",
    label: "甲斐市",
  },
  {
    value: "山梨県笛吹市",
    label: "笛吹市",
  },
  {
    value: "山梨県上野原市",
    label: "上野原市",
  },
  {
    value: "山梨県甲州市",
    label: "甲州市",
  },
  {
    value: "山梨県中央市",
    label: "中央市",
  },
  {
    value: "山梨県市川三郷町",
    label: "市川三郷町",
  },
  {
    value: "山梨県早川町",
    label: "早川町",
  },
  {
    value: "山梨県身延町",
    label: "身延町",
  },
  {
    value: "山梨県南部町",
    label: "南部町",
  },
  {
    value: "山梨県富士川町",
    label: "富士川町",
  },
  {
    value: "山梨県昭和町",
    label: "昭和町",
  },
  {
    value: "山梨県道志村",
    label: "道志村",
  },
  {
    value: "山梨県西桂町",
    label: "西桂町",
  },
  {
    value: "山梨県忍野村",
    label: "忍野村",
  },
  {
    value: "山梨県山中湖村",
    label: "山中湖村",
  },
  {
    value: "山梨県鳴沢村",
    label: "鳴沢村",
  },
  {
    value: "山梨県富士河口湖町",
    label: "富士河口湖町",
  },
  {
    value: "山梨県小菅村",
    label: "小菅村",
  },
  {
    value: "山梨県丹波山村",
    label: "丹波山村",
  },
  {
    value: "長野県長野市",
    label: "長野市",
  },
  {
    value: "長野県松本市",
    label: "松本市",
  },
  {
    value: "長野県上田市",
    label: "上田市",
  },
  {
    value: "長野県岡谷市",
    label: "岡谷市",
  },
  {
    value: "長野県飯田市",
    label: "飯田市",
  },
  {
    value: "長野県諏訪市",
    label: "諏訪市",
  },
  {
    value: "長野県須坂市",
    label: "須坂市",
  },
  {
    value: "長野県小諸市",
    label: "小諸市",
  },
  {
    value: "長野県伊那市",
    label: "伊那市",
  },
  {
    value: "長野県駒ヶ根市",
    label: "駒ヶ根市",
  },
  {
    value: "長野県中野市",
    label: "中野市",
  },
  {
    value: "長野県大町市",
    label: "大町市",
  },
  {
    value: "長野県飯山市",
    label: "飯山市",
  },
  {
    value: "長野県茅野市",
    label: "茅野市",
  },
  {
    value: "長野県塩尻市",
    label: "塩尻市",
  },
  {
    value: "長野県佐久市",
    label: "佐久市",
  },
  {
    value: "長野県千曲市",
    label: "千曲市",
  },
  {
    value: "長野県東御市",
    label: "東御市",
  },
  {
    value: "長野県安曇野市",
    label: "安曇野市",
  },
  {
    value: "長野県小海町",
    label: "小海町",
  },
  {
    value: "長野県川上村",
    label: "川上村",
  },
  {
    value: "長野県南牧村",
    label: "南牧村",
  },
  {
    value: "長野県南相木村",
    label: "南相木村",
  },
  {
    value: "長野県北相木村",
    label: "北相木村",
  },
  {
    value: "長野県佐久穂町",
    label: "佐久穂町",
  },
  {
    value: "長野県軽井沢町",
    label: "軽井沢町",
  },
  {
    value: "長野県御代田町",
    label: "御代田町",
  },
  {
    value: "長野県立科町",
    label: "立科町",
  },
  {
    value: "長野県青木村",
    label: "青木村",
  },
  {
    value: "長野県長和町",
    label: "長和町",
  },
  {
    value: "長野県下諏訪町",
    label: "下諏訪町",
  },
  {
    value: "長野県富士見町",
    label: "富士見町",
  },
  {
    value: "長野県原村",
    label: "原村",
  },
  {
    value: "長野県辰野町",
    label: "辰野町",
  },
  {
    value: "長野県箕輪町",
    label: "箕輪町",
  },
  {
    value: "長野県飯島町",
    label: "飯島町",
  },
  {
    value: "長野県南箕輪村",
    label: "南箕輪村",
  },
  {
    value: "長野県中川村",
    label: "中川村",
  },
  {
    value: "長野県宮田村",
    label: "宮田村",
  },
  {
    value: "長野県松川町",
    label: "松川町",
  },
  {
    value: "長野県高森町",
    label: "高森町",
  },
  {
    value: "長野県阿南町",
    label: "阿南町",
  },
  {
    value: "長野県阿智村",
    label: "阿智村",
  },
  {
    value: "長野県平谷村",
    label: "平谷村",
  },
  {
    value: "長野県根羽村",
    label: "根羽村",
  },
  {
    value: "長野県下條村",
    label: "下條村",
  },
  {
    value: "長野県売木村",
    label: "売木村",
  },
  {
    value: "長野県天龍村",
    label: "天龍村",
  },
  {
    value: "長野県泰阜村",
    label: "泰阜村",
  },
  {
    value: "長野県喬木村",
    label: "喬木村",
  },
  {
    value: "長野県豊丘村",
    label: "豊丘村",
  },
  {
    value: "長野県大鹿村",
    label: "大鹿村",
  },
  {
    value: "長野県上松町",
    label: "上松町",
  },
  {
    value: "長野県南木曽町",
    label: "南木曽町",
  },
  {
    value: "長野県木祖村",
    label: "木祖村",
  },
  {
    value: "長野県王滝村",
    label: "王滝村",
  },
  {
    value: "長野県大桑村",
    label: "大桑村",
  },
  {
    value: "長野県木曽町",
    label: "木曽町",
  },
  {
    value: "長野県麻績村",
    label: "麻績村",
  },
  {
    value: "長野県生坂村",
    label: "生坂村",
  },
  {
    value: "長野県山形村",
    label: "山形村",
  },
  {
    value: "長野県朝日村",
    label: "朝日村",
  },
  {
    value: "長野県筑北村",
    label: "筑北村",
  },
  {
    value: "長野県池田町",
    label: "池田町",
  },
  {
    value: "長野県松川村",
    label: "松川村",
  },
  {
    value: "長野県白馬村",
    label: "白馬村",
  },
  {
    value: "長野県小谷村",
    label: "小谷村",
  },
  {
    value: "長野県坂城町",
    label: "坂城町",
  },
  {
    value: "長野県小布施町",
    label: "小布施町",
  },
  {
    value: "長野県高山村",
    label: "高山村",
  },
  {
    value: "長野県山ノ内町",
    label: "山ノ内町",
  },
  {
    value: "長野県木島平村",
    label: "木島平村",
  },
  {
    value: "長野県野沢温泉村",
    label: "野沢温泉村",
  },
  {
    value: "長野県信濃町",
    label: "信濃町",
  },
  {
    value: "長野県小川村",
    label: "小川村",
  },
  {
    value: "長野県飯綱町",
    label: "飯綱町",
  },
  {
    value: "長野県栄村",
    label: "栄村",
  },
  {
    value: "岐阜県岐阜市",
    label: "岐阜市",
  },
  {
    value: "岐阜県大垣市",
    label: "大垣市",
  },
  {
    value: "岐阜県高山市",
    label: "高山市",
  },
  {
    value: "岐阜県多治見市",
    label: "多治見市",
  },
  {
    value: "岐阜県関市",
    label: "関市",
  },
  {
    value: "岐阜県中津川市",
    label: "中津川市",
  },
  {
    value: "岐阜県美濃市",
    label: "美濃市",
  },
  {
    value: "岐阜県瑞浪市",
    label: "瑞浪市",
  },
  {
    value: "岐阜県羽島市",
    label: "羽島市",
  },
  {
    value: "岐阜県恵那市",
    label: "恵那市",
  },
  {
    value: "岐阜県美濃加茂市",
    label: "美濃加茂市",
  },
  {
    value: "岐阜県土岐市",
    label: "土岐市",
  },
  {
    value: "岐阜県各務原市",
    label: "各務原市",
  },
  {
    value: "岐阜県可児市",
    label: "可児市",
  },
  {
    value: "岐阜県山県市",
    label: "山県市",
  },
  {
    value: "岐阜県瑞穂市",
    label: "瑞穂市",
  },
  {
    value: "岐阜県飛騨市",
    label: "飛騨市",
  },
  {
    value: "岐阜県本巣市",
    label: "本巣市",
  },
  {
    value: "岐阜県郡上市",
    label: "郡上市",
  },
  {
    value: "岐阜県下呂市",
    label: "下呂市",
  },
  {
    value: "岐阜県海津市",
    label: "海津市",
  },
  {
    value: "岐阜県岐南町",
    label: "岐南町",
  },
  {
    value: "岐阜県笠松町",
    label: "笠松町",
  },
  {
    value: "岐阜県養老町",
    label: "養老町",
  },
  {
    value: "岐阜県垂井町",
    label: "垂井町",
  },
  {
    value: "岐阜県関ケ原町",
    label: "関ケ原町",
  },
  {
    value: "岐阜県神戸町",
    label: "神戸町",
  },
  {
    value: "岐阜県輪之内町",
    label: "輪之内町",
  },
  {
    value: "岐阜県安八町",
    label: "安八町",
  },
  {
    value: "岐阜県揖斐川町",
    label: "揖斐川町",
  },
  {
    value: "岐阜県大野町",
    label: "大野町",
  },
  {
    value: "岐阜県池田町",
    label: "池田町",
  },
  {
    value: "岐阜県北方町",
    label: "北方町",
  },
  {
    value: "岐阜県坂祝町",
    label: "坂祝町",
  },
  {
    value: "岐阜県富加町",
    label: "富加町",
  },
  {
    value: "岐阜県川辺町",
    label: "川辺町",
  },
  {
    value: "岐阜県七宗町",
    label: "七宗町",
  },
  {
    value: "岐阜県八百津町",
    label: "八百津町",
  },
  {
    value: "岐阜県白川町",
    label: "白川町",
  },
  {
    value: "岐阜県東白川村",
    label: "東白川村",
  },
  {
    value: "岐阜県御嵩町",
    label: "御嵩町",
  },
  {
    value: "岐阜県白川村",
    label: "白川村",
  },
  {
    value: "静岡県静岡市",
    label: "静岡市",
  },
  {
    value: "静岡県浜松市",
    label: "浜松市",
  },
  {
    value: "静岡県沼津市",
    label: "沼津市",
  },
  {
    value: "静岡県熱海市",
    label: "熱海市",
  },
  {
    value: "静岡県三島市",
    label: "三島市",
  },
  {
    value: "静岡県富士宮市",
    label: "富士宮市",
  },
  {
    value: "静岡県伊東市",
    label: "伊東市",
  },
  {
    value: "静岡県島田市",
    label: "島田市",
  },
  {
    value: "静岡県富士市",
    label: "富士市",
  },
  {
    value: "静岡県磐田市",
    label: "磐田市",
  },
  {
    value: "静岡県焼津市",
    label: "焼津市",
  },
  {
    value: "静岡県掛川市",
    label: "掛川市",
  },
  {
    value: "静岡県藤枝市",
    label: "藤枝市",
  },
  {
    value: "静岡県御殿場市",
    label: "御殿場市",
  },
  {
    value: "静岡県袋井市",
    label: "袋井市",
  },
  {
    value: "静岡県下田市",
    label: "下田市",
  },
  {
    value: "静岡県裾野市",
    label: "裾野市",
  },
  {
    value: "静岡県湖西市",
    label: "湖西市",
  },
  {
    value: "静岡県伊豆市",
    label: "伊豆市",
  },
  {
    value: "静岡県御前崎市",
    label: "御前崎市",
  },
  {
    value: "静岡県菊川市",
    label: "菊川市",
  },
  {
    value: "静岡県伊豆の国市",
    label: "伊豆の国市",
  },
  {
    value: "静岡県牧之原市",
    label: "牧之原市",
  },
  {
    value: "静岡県東伊豆町",
    label: "東伊豆町",
  },
  {
    value: "静岡県河津町",
    label: "河津町",
  },
  {
    value: "静岡県南伊豆町",
    label: "南伊豆町",
  },
  {
    value: "静岡県松崎町",
    label: "松崎町",
  },
  {
    value: "静岡県西伊豆町",
    label: "西伊豆町",
  },
  {
    value: "静岡県函南町",
    label: "函南町",
  },
  {
    value: "静岡県清水町",
    label: "清水町",
  },
  {
    value: "静岡県長泉町",
    label: "長泉町",
  },
  {
    value: "静岡県小山町",
    label: "小山町",
  },
  {
    value: "静岡県吉田町",
    label: "吉田町",
  },
  {
    value: "静岡県川根本町",
    label: "川根本町",
  },
  {
    value: "静岡県森町",
    label: "森町",
  },
  {
    value: "愛知県名古屋市",
    label: "名古屋市",
  },
  {
    value: "愛知県豊橋市",
    label: "豊橋市",
  },
  {
    value: "愛知県岡崎市",
    label: "岡崎市",
  },
  {
    value: "愛知県一宮市",
    label: "一宮市",
  },
  {
    value: "愛知県瀬戸市",
    label: "瀬戸市",
  },
  {
    value: "愛知県半田市",
    label: "半田市",
  },
  {
    value: "愛知県春日井市",
    label: "春日井市",
  },
  {
    value: "愛知県豊川市",
    label: "豊川市",
  },
  {
    value: "愛知県津島市",
    label: "津島市",
  },
  {
    value: "愛知県碧南市",
    label: "碧南市",
  },
  {
    value: "愛知県刈谷市",
    label: "刈谷市",
  },
  {
    value: "愛知県豊田市",
    label: "豊田市",
  },
  {
    value: "愛知県安城市",
    label: "安城市",
  },
  {
    value: "愛知県西尾市",
    label: "西尾市",
  },
  {
    value: "愛知県蒲郡市",
    label: "蒲郡市",
  },
  {
    value: "愛知県犬山市",
    label: "犬山市",
  },
  {
    value: "愛知県常滑市",
    label: "常滑市",
  },
  {
    value: "愛知県江南市",
    label: "江南市",
  },
  {
    value: "愛知県小牧市",
    label: "小牧市",
  },
  {
    value: "愛知県稲沢市",
    label: "稲沢市",
  },
  {
    value: "愛知県新城市",
    label: "新城市",
  },
  {
    value: "愛知県東海市",
    label: "東海市",
  },
  {
    value: "愛知県大府市",
    label: "大府市",
  },
  {
    value: "愛知県知多市",
    label: "知多市",
  },
  {
    value: "愛知県知立市",
    label: "知立市",
  },
  {
    value: "愛知県尾張旭市",
    label: "尾張旭市",
  },
  {
    value: "愛知県高浜市",
    label: "高浜市",
  },
  {
    value: "愛知県岩倉市",
    label: "岩倉市",
  },
  {
    value: "愛知県豊明市",
    label: "豊明市",
  },
  {
    value: "愛知県日進市",
    label: "日進市",
  },
  {
    value: "愛知県田原市",
    label: "田原市",
  },
  {
    value: "愛知県愛西市",
    label: "愛西市",
  },
  {
    value: "愛知県清須市",
    label: "清須市",
  },
  {
    value: "愛知県北名古屋市",
    label: "北名古屋市",
  },
  {
    value: "愛知県弥富市",
    label: "弥富市",
  },
  {
    value: "愛知県みよし市",
    label: "みよし市",
  },
  {
    value: "愛知県あま市",
    label: "あま市",
  },
  {
    value: "愛知県長久手市",
    label: "長久手市",
  },
  {
    value: "愛知県東郷町",
    label: "東郷町",
  },
  {
    value: "愛知県豊山町",
    label: "豊山町",
  },
  {
    value: "愛知県大口町",
    label: "大口町",
  },
  {
    value: "愛知県扶桑町",
    label: "扶桑町",
  },
  {
    value: "愛知県大治町",
    label: "大治町",
  },
  {
    value: "愛知県蟹江町",
    label: "蟹江町",
  },
  {
    value: "愛知県飛島村",
    label: "飛島村",
  },
  {
    value: "愛知県阿久比町",
    label: "阿久比町",
  },
  {
    value: "愛知県東浦町",
    label: "東浦町",
  },
  {
    value: "愛知県南知多町",
    label: "南知多町",
  },
  {
    value: "愛知県美浜町",
    label: "美浜町",
  },
  {
    value: "愛知県武豊町",
    label: "武豊町",
  },
  {
    value: "愛知県幸田町",
    label: "幸田町",
  },
  {
    value: "愛知県設楽町",
    label: "設楽町",
  },
  {
    value: "愛知県東栄町",
    label: "東栄町",
  },
  {
    value: "愛知県豊根村",
    label: "豊根村",
  },
  {
    value: "三重県津市",
    label: "津市",
  },
  {
    value: "三重県四日市市",
    label: "四日市市",
  },
  {
    value: "三重県伊勢市",
    label: "伊勢市",
  },
  {
    value: "三重県松阪市",
    label: "松阪市",
  },
  {
    value: "三重県桑名市",
    label: "桑名市",
  },
  {
    value: "三重県鈴鹿市",
    label: "鈴鹿市",
  },
  {
    value: "三重県名張市",
    label: "名張市",
  },
  {
    value: "三重県尾鷲市",
    label: "尾鷲市",
  },
  {
    value: "三重県亀山市",
    label: "亀山市",
  },
  {
    value: "三重県鳥羽市",
    label: "鳥羽市",
  },
  {
    value: "三重県熊野市",
    label: "熊野市",
  },
  {
    value: "三重県いなべ市",
    label: "いなべ市",
  },
  {
    value: "三重県志摩市",
    label: "志摩市",
  },
  {
    value: "三重県伊賀市",
    label: "伊賀市",
  },
  {
    value: "三重県木曽岬町",
    label: "木曽岬町",
  },
  {
    value: "三重県東員町",
    label: "東員町",
  },
  {
    value: "三重県菰野町",
    label: "菰野町",
  },
  {
    value: "三重県朝日町",
    label: "朝日町",
  },
  {
    value: "三重県川越町",
    label: "川越町",
  },
  {
    value: "三重県多気町",
    label: "多気町",
  },
  {
    value: "三重県明和町",
    label: "明和町",
  },
  {
    value: "三重県大台町",
    label: "大台町",
  },
  {
    value: "三重県玉城町",
    label: "玉城町",
  },
  {
    value: "三重県度会町",
    label: "度会町",
  },
  {
    value: "三重県大紀町",
    label: "大紀町",
  },
  {
    value: "三重県南伊勢町",
    label: "南伊勢町",
  },
  {
    value: "三重県紀北町",
    label: "紀北町",
  },
  {
    value: "三重県御浜町",
    label: "御浜町",
  },
  {
    value: "三重県紀宝町",
    label: "紀宝町",
  },
  {
    value: "滋賀県大津市",
    label: "大津市",
  },
  {
    value: "滋賀県彦根市",
    label: "彦根市",
  },
  {
    value: "滋賀県長浜市",
    label: "長浜市",
  },
  {
    value: "滋賀県近江八幡市",
    label: "近江八幡市",
  },
  {
    value: "滋賀県草津市",
    label: "草津市",
  },
  {
    value: "滋賀県守山市",
    label: "守山市",
  },
  {
    value: "滋賀県栗東市",
    label: "栗東市",
  },
  {
    value: "滋賀県甲賀市",
    label: "甲賀市",
  },
  {
    value: "滋賀県野洲市",
    label: "野洲市",
  },
  {
    value: "滋賀県湖南市",
    label: "湖南市",
  },
  {
    value: "滋賀県高島市",
    label: "高島市",
  },
  {
    value: "滋賀県東近江市",
    label: "東近江市",
  },
  {
    value: "滋賀県米原市",
    label: "米原市",
  },
  {
    value: "滋賀県日野町",
    label: "日野町",
  },
  {
    value: "滋賀県竜王町",
    label: "竜王町",
  },
  {
    value: "滋賀県愛荘町",
    label: "愛荘町",
  },
  {
    value: "滋賀県豊郷町",
    label: "豊郷町",
  },
  {
    value: "滋賀県甲良町",
    label: "甲良町",
  },
  {
    value: "滋賀県多賀町",
    label: "多賀町",
  },
  {
    value: "京都府京都市",
    label: "京都市",
  },
  {
    value: "京都府福知山市",
    label: "福知山市",
  },
  {
    value: "京都府舞鶴市",
    label: "舞鶴市",
  },
  {
    value: "京都府綾部市",
    label: "綾部市",
  },
  {
    value: "京都府宇治市",
    label: "宇治市",
  },
  {
    value: "京都府宮津市",
    label: "宮津市",
  },
  {
    value: "京都府亀岡市",
    label: "亀岡市",
  },
  {
    value: "京都府城陽市",
    label: "城陽市",
  },
  {
    value: "京都府向日市",
    label: "向日市",
  },
  {
    value: "京都府長岡京市",
    label: "長岡京市",
  },
  {
    value: "京都府八幡市",
    label: "八幡市",
  },
  {
    value: "京都府京田辺市",
    label: "京田辺市",
  },
  {
    value: "京都府京丹後市",
    label: "京丹後市",
  },
  {
    value: "京都府南丹市",
    label: "南丹市",
  },
  {
    value: "京都府木津川市",
    label: "木津川市",
  },
  {
    value: "京都府大山崎町",
    label: "大山崎町",
  },
  {
    value: "京都府久御山町",
    label: "久御山町",
  },
  {
    value: "京都府井手町",
    label: "井手町",
  },
  {
    value: "京都府宇治田原町",
    label: "宇治田原町",
  },
  {
    value: "京都府笠置町",
    label: "笠置町",
  },
  {
    value: "京都府和束町",
    label: "和束町",
  },
  {
    value: "京都府精華町",
    label: "精華町",
  },
  {
    value: "京都府南山城村",
    label: "南山城村",
  },
  {
    value: "京都府京丹波町",
    label: "京丹波町",
  },
  {
    value: "京都府伊根町",
    label: "伊根町",
  },
  {
    value: "京都府与謝野町",
    label: "与謝野町",
  },
  {
    value: "大阪府大阪市",
    label: "大阪市",
  },
  {
    value: "大阪府堺市",
    label: "堺市",
  },
  {
    value: "大阪府岸和田市",
    label: "岸和田市",
  },
  {
    value: "大阪府豊中市",
    label: "豊中市",
  },
  {
    value: "大阪府池田市",
    label: "池田市",
  },
  {
    value: "大阪府吹田市",
    label: "吹田市",
  },
  {
    value: "大阪府泉大津市",
    label: "泉大津市",
  },
  {
    value: "大阪府高槻市",
    label: "高槻市",
  },
  {
    value: "大阪府貝塚市",
    label: "貝塚市",
  },
  {
    value: "大阪府守口市",
    label: "守口市",
  },
  {
    value: "大阪府枚方市",
    label: "枚方市",
  },
  {
    value: "大阪府茨木市",
    label: "茨木市",
  },
  {
    value: "大阪府八尾市",
    label: "八尾市",
  },
  {
    value: "大阪府泉佐野市",
    label: "泉佐野市",
  },
  {
    value: "大阪府富田林市",
    label: "富田林市",
  },
  {
    value: "大阪府寝屋川市",
    label: "寝屋川市",
  },
  {
    value: "大阪府河内長野市",
    label: "河内長野市",
  },
  {
    value: "大阪府松原市",
    label: "松原市",
  },
  {
    value: "大阪府大東市",
    label: "大東市",
  },
  {
    value: "大阪府和泉市",
    label: "和泉市",
  },
  {
    value: "大阪府箕面市",
    label: "箕面市",
  },
  {
    value: "大阪府柏原市",
    label: "柏原市",
  },
  {
    value: "大阪府羽曳野市",
    label: "羽曳野市",
  },
  {
    value: "大阪府門真市",
    label: "門真市",
  },
  {
    value: "大阪府摂津市",
    label: "摂津市",
  },
  {
    value: "大阪府高石市",
    label: "高石市",
  },
  {
    value: "大阪府藤井寺市",
    label: "藤井寺市",
  },
  {
    value: "大阪府東大阪市",
    label: "東大阪市",
  },
  {
    value: "大阪府泉南市",
    label: "泉南市",
  },
  {
    value: "大阪府四條畷市",
    label: "四條畷市",
  },
  {
    value: "大阪府交野市",
    label: "交野市",
  },
  {
    value: "大阪府大阪狭山市",
    label: "大阪狭山市",
  },
  {
    value: "大阪府阪南市",
    label: "阪南市",
  },
  {
    value: "大阪府島本町",
    label: "島本町",
  },
  {
    value: "大阪府豊能町",
    label: "豊能町",
  },
  {
    value: "大阪府能勢町",
    label: "能勢町",
  },
  {
    value: "大阪府忠岡町",
    label: "忠岡町",
  },
  {
    value: "大阪府熊取町",
    label: "熊取町",
  },
  {
    value: "大阪府田尻町",
    label: "田尻町",
  },
  {
    value: "大阪府岬町",
    label: "岬町",
  },
  {
    value: "大阪府太子町",
    label: "太子町",
  },
  {
    value: "大阪府河南町",
    label: "河南町",
  },
  {
    value: "大阪府千早赤阪村",
    label: "千早赤阪村",
  },
  {
    value: "兵庫県神戸市",
    label: "神戸市",
  },
  {
    value: "兵庫県姫路市",
    label: "姫路市",
  },
  {
    value: "兵庫県尼崎市",
    label: "尼崎市",
  },
  {
    value: "兵庫県明石市",
    label: "明石市",
  },
  {
    value: "兵庫県西宮市",
    label: "西宮市",
  },
  {
    value: "兵庫県洲本市",
    label: "洲本市",
  },
  {
    value: "兵庫県芦屋市",
    label: "芦屋市",
  },
  {
    value: "兵庫県伊丹市",
    label: "伊丹市",
  },
  {
    value: "兵庫県相生市",
    label: "相生市",
  },
  {
    value: "兵庫県豊岡市",
    label: "豊岡市",
  },
  {
    value: "兵庫県加古川市",
    label: "加古川市",
  },
  {
    value: "兵庫県赤穂市",
    label: "赤穂市",
  },
  {
    value: "兵庫県西脇市",
    label: "西脇市",
  },
  {
    value: "兵庫県宝塚市",
    label: "宝塚市",
  },
  {
    value: "兵庫県三木市",
    label: "三木市",
  },
  {
    value: "兵庫県高砂市",
    label: "高砂市",
  },
  {
    value: "兵庫県川西市",
    label: "川西市",
  },
  {
    value: "兵庫県小野市",
    label: "小野市",
  },
  {
    value: "兵庫県三田市",
    label: "三田市",
  },
  {
    value: "兵庫県加西市",
    label: "加西市",
  },
  {
    value: "兵庫県丹波篠山市",
    label: "丹波篠山市",
  },
  {
    value: "兵庫県養父市",
    label: "養父市",
  },
  {
    value: "兵庫県丹波市",
    label: "丹波市",
  },
  {
    value: "兵庫県南あわじ市",
    label: "南あわじ市",
  },
  {
    value: "兵庫県朝来市",
    label: "朝来市",
  },
  {
    value: "兵庫県淡路市",
    label: "淡路市",
  },
  {
    value: "兵庫県宍粟市",
    label: "宍粟市",
  },
  {
    value: "兵庫県加東市",
    label: "加東市",
  },
  {
    value: "兵庫県たつの市",
    label: "たつの市",
  },
  {
    value: "兵庫県猪名川町",
    label: "猪名川町",
  },
  {
    value: "兵庫県多可町",
    label: "多可町",
  },
  {
    value: "兵庫県稲美町",
    label: "稲美町",
  },
  {
    value: "兵庫県播磨町",
    label: "播磨町",
  },
  {
    value: "兵庫県市川町",
    label: "市川町",
  },
  {
    value: "兵庫県福崎町",
    label: "福崎町",
  },
  {
    value: "兵庫県神河町",
    label: "神河町",
  },
  {
    value: "兵庫県太子町",
    label: "太子町",
  },
  {
    value: "兵庫県上郡町",
    label: "上郡町",
  },
  {
    value: "兵庫県佐用町",
    label: "佐用町",
  },
  {
    value: "兵庫県香美町",
    label: "香美町",
  },
  {
    value: "兵庫県新温泉町",
    label: "新温泉町",
  },
  {
    value: "奈良県奈良市",
    label: "奈良市",
  },
  {
    value: "奈良県大和高田市",
    label: "大和高田市",
  },
  {
    value: "奈良県大和郡山市",
    label: "大和郡山市",
  },
  {
    value: "奈良県天理市",
    label: "天理市",
  },
  {
    value: "奈良県橿原市",
    label: "橿原市",
  },
  {
    value: "奈良県桜井市",
    label: "桜井市",
  },
  {
    value: "奈良県五條市",
    label: "五條市",
  },
  {
    value: "奈良県御所市",
    label: "御所市",
  },
  {
    value: "奈良県生駒市",
    label: "生駒市",
  },
  {
    value: "奈良県香芝市",
    label: "香芝市",
  },
  {
    value: "奈良県葛城市",
    label: "葛城市",
  },
  {
    value: "奈良県宇陀市",
    label: "宇陀市",
  },
  {
    value: "奈良県山添村",
    label: "山添村",
  },
  {
    value: "奈良県平群町",
    label: "平群町",
  },
  {
    value: "奈良県三郷町",
    label: "三郷町",
  },
  {
    value: "奈良県斑鳩町",
    label: "斑鳩町",
  },
  {
    value: "奈良県安堵町",
    label: "安堵町",
  },
  {
    value: "奈良県川西町",
    label: "川西町",
  },
  {
    value: "奈良県三宅町",
    label: "三宅町",
  },
  {
    value: "奈良県田原本町",
    label: "田原本町",
  },
  {
    value: "奈良県曽爾村",
    label: "曽爾村",
  },
  {
    value: "奈良県御杖村",
    label: "御杖村",
  },
  {
    value: "奈良県高取町",
    label: "高取町",
  },
  {
    value: "奈良県明日香村",
    label: "明日香村",
  },
  {
    value: "奈良県上牧町",
    label: "上牧町",
  },
  {
    value: "奈良県王寺町",
    label: "王寺町",
  },
  {
    value: "奈良県広陵町",
    label: "広陵町",
  },
  {
    value: "奈良県河合町",
    label: "河合町",
  },
  {
    value: "奈良県吉野町",
    label: "吉野町",
  },
  {
    value: "奈良県大淀町",
    label: "大淀町",
  },
  {
    value: "奈良県下市町",
    label: "下市町",
  },
  {
    value: "奈良県黒滝村",
    label: "黒滝村",
  },
  {
    value: "奈良県天川村",
    label: "天川村",
  },
  {
    value: "奈良県野迫川村",
    label: "野迫川村",
  },
  {
    value: "奈良県十津川村",
    label: "十津川村",
  },
  {
    value: "奈良県下北山村",
    label: "下北山村",
  },
  {
    value: "奈良県上北山村",
    label: "上北山村",
  },
  {
    value: "奈良県川上村",
    label: "川上村",
  },
  {
    value: "奈良県東吉野村",
    label: "東吉野村",
  },
  {
    value: "和歌山県和歌山市",
    label: "和歌山市",
  },
  {
    value: "和歌山県海南市",
    label: "海南市",
  },
  {
    value: "和歌山県橋本市",
    label: "橋本市",
  },
  {
    value: "和歌山県有田市",
    label: "有田市",
  },
  {
    value: "和歌山県御坊市",
    label: "御坊市",
  },
  {
    value: "和歌山県田辺市",
    label: "田辺市",
  },
  {
    value: "和歌山県新宮市",
    label: "新宮市",
  },
  {
    value: "和歌山県紀の川市",
    label: "紀の川市",
  },
  {
    value: "和歌山県岩出市",
    label: "岩出市",
  },
  {
    value: "和歌山県紀美野町",
    label: "紀美野町",
  },
  {
    value: "和歌山県かつらぎ町",
    label: "かつらぎ町",
  },
  {
    value: "和歌山県九度山町",
    label: "九度山町",
  },
  {
    value: "和歌山県高野町",
    label: "高野町",
  },
  {
    value: "和歌山県湯浅町",
    label: "湯浅町",
  },
  {
    value: "和歌山県広川町",
    label: "広川町",
  },
  {
    value: "和歌山県有田川町",
    label: "有田川町",
  },
  {
    value: "和歌山県美浜町",
    label: "美浜町",
  },
  {
    value: "和歌山県日高町",
    label: "日高町",
  },
  {
    value: "和歌山県由良町",
    label: "由良町",
  },
  {
    value: "和歌山県印南町",
    label: "印南町",
  },
  {
    value: "和歌山県みなべ町",
    label: "みなべ町",
  },
  {
    value: "和歌山県日高川町",
    label: "日高川町",
  },
  {
    value: "和歌山県白浜町",
    label: "白浜町",
  },
  {
    value: "和歌山県上富田町",
    label: "上富田町",
  },
  {
    value: "和歌山県すさみ町",
    label: "すさみ町",
  },
  {
    value: "和歌山県那智勝浦町",
    label: "那智勝浦町",
  },
  {
    value: "和歌山県太地町",
    label: "太地町",
  },
  {
    value: "和歌山県古座川町",
    label: "古座川町",
  },
  {
    value: "和歌山県北山村",
    label: "北山村",
  },
  {
    value: "和歌山県串本町",
    label: "串本町",
  },
  {
    value: "鳥取県鳥取市",
    label: "鳥取市",
  },
  {
    value: "鳥取県米子市",
    label: "米子市",
  },
  {
    value: "鳥取県倉吉市",
    label: "倉吉市",
  },
  {
    value: "鳥取県境港市",
    label: "境港市",
  },
  {
    value: "鳥取県岩美町",
    label: "岩美町",
  },
  {
    value: "鳥取県若桜町",
    label: "若桜町",
  },
  {
    value: "鳥取県智頭町",
    label: "智頭町",
  },
  {
    value: "鳥取県八頭町",
    label: "八頭町",
  },
  {
    value: "鳥取県三朝町",
    label: "三朝町",
  },
  {
    value: "鳥取県湯梨浜町",
    label: "湯梨浜町",
  },
  {
    value: "鳥取県琴浦町",
    label: "琴浦町",
  },
  {
    value: "鳥取県北栄町",
    label: "北栄町",
  },
  {
    value: "鳥取県日吉津村",
    label: "日吉津村",
  },
  {
    value: "鳥取県大山町",
    label: "大山町",
  },
  {
    value: "鳥取県南部町",
    label: "南部町",
  },
  {
    value: "鳥取県伯耆町",
    label: "伯耆町",
  },
  {
    value: "鳥取県日南町",
    label: "日南町",
  },
  {
    value: "鳥取県日野町",
    label: "日野町",
  },
  {
    value: "鳥取県江府町",
    label: "江府町",
  },
  {
    value: "島根県松江市",
    label: "松江市",
  },
  {
    value: "島根県浜田市",
    label: "浜田市",
  },
  {
    value: "島根県出雲市",
    label: "出雲市",
  },
  {
    value: "島根県益田市",
    label: "益田市",
  },
  {
    value: "島根県大田市",
    label: "大田市",
  },
  {
    value: "島根県安来市",
    label: "安来市",
  },
  {
    value: "島根県江津市",
    label: "江津市",
  },
  {
    value: "島根県雲南市",
    label: "雲南市",
  },
  {
    value: "島根県奥出雲町",
    label: "奥出雲町",
  },
  {
    value: "島根県飯南町",
    label: "飯南町",
  },
  {
    value: "島根県川本町",
    label: "川本町",
  },
  {
    value: "島根県美郷町",
    label: "美郷町",
  },
  {
    value: "島根県邑南町",
    label: "邑南町",
  },
  {
    value: "島根県津和野町",
    label: "津和野町",
  },
  {
    value: "島根県吉賀町",
    label: "吉賀町",
  },
  {
    value: "島根県海士町",
    label: "海士町",
  },
  {
    value: "島根県西ノ島町",
    label: "西ノ島町",
  },
  {
    value: "島根県知夫村",
    label: "知夫村",
  },
  {
    value: "島根県隠岐の島町",
    label: "隠岐の島町",
  },
  {
    value: "岡山県岡山市",
    label: "岡山市",
  },
  {
    value: "岡山県倉敷市",
    label: "倉敷市",
  },
  {
    value: "岡山県津山市",
    label: "津山市",
  },
  {
    value: "岡山県玉野市",
    label: "玉野市",
  },
  {
    value: "岡山県笠岡市",
    label: "笠岡市",
  },
  {
    value: "岡山県井原市",
    label: "井原市",
  },
  {
    value: "岡山県総社市",
    label: "総社市",
  },
  {
    value: "岡山県高梁市",
    label: "高梁市",
  },
  {
    value: "岡山県新見市",
    label: "新見市",
  },
  {
    value: "岡山県備前市",
    label: "備前市",
  },
  {
    value: "岡山県瀬戸内市",
    label: "瀬戸内市",
  },
  {
    value: "岡山県赤磐市",
    label: "赤磐市",
  },
  {
    value: "岡山県真庭市",
    label: "真庭市",
  },
  {
    value: "岡山県美作市",
    label: "美作市",
  },
  {
    value: "岡山県浅口市",
    label: "浅口市",
  },
  {
    value: "岡山県和気町",
    label: "和気町",
  },
  {
    value: "岡山県早島町",
    label: "早島町",
  },
  {
    value: "岡山県里庄町",
    label: "里庄町",
  },
  {
    value: "岡山県矢掛町",
    label: "矢掛町",
  },
  {
    value: "岡山県新庄村",
    label: "新庄村",
  },
  {
    value: "岡山県鏡野町",
    label: "鏡野町",
  },
  {
    value: "岡山県勝央町",
    label: "勝央町",
  },
  {
    value: "岡山県奈義町",
    label: "奈義町",
  },
  {
    value: "岡山県西粟倉村",
    label: "西粟倉村",
  },
  {
    value: "岡山県久米南町",
    label: "久米南町",
  },
  {
    value: "岡山県美咲町",
    label: "美咲町",
  },
  {
    value: "岡山県吉備中央町",
    label: "吉備中央町",
  },
  {
    value: "広島県広島市",
    label: "広島市",
  },
  {
    value: "広島県呉市",
    label: "呉市",
  },
  {
    value: "広島県竹原市",
    label: "竹原市",
  },
  {
    value: "広島県三原市",
    label: "三原市",
  },
  {
    value: "広島県尾道市",
    label: "尾道市",
  },
  {
    value: "広島県福山市",
    label: "福山市",
  },
  {
    value: "広島県府中市",
    label: "府中市",
  },
  {
    value: "広島県三次市",
    label: "三次市",
  },
  {
    value: "広島県庄原市",
    label: "庄原市",
  },
  {
    value: "広島県大竹市",
    label: "大竹市",
  },
  {
    value: "広島県東広島市",
    label: "東広島市",
  },
  {
    value: "広島県廿日市市",
    label: "廿日市市",
  },
  {
    value: "広島県安芸高田市",
    label: "安芸高田市",
  },
  {
    value: "広島県江田島市",
    label: "江田島市",
  },
  {
    value: "広島県府中町",
    label: "府中町",
  },
  {
    value: "広島県海田町",
    label: "海田町",
  },
  {
    value: "広島県熊野町",
    label: "熊野町",
  },
  {
    value: "広島県坂町",
    label: "坂町",
  },
  {
    value: "広島県安芸太田町",
    label: "安芸太田町",
  },
  {
    value: "広島県北広島町",
    label: "北広島町",
  },
  {
    value: "広島県大崎上島町",
    label: "大崎上島町",
  },
  {
    value: "広島県世羅町",
    label: "世羅町",
  },
  {
    value: "広島県神石高原町",
    label: "神石高原町",
  },
  {
    value: "山口県下関市",
    label: "下関市",
  },
  {
    value: "山口県宇部市",
    label: "宇部市",
  },
  {
    value: "山口県山口市",
    label: "山口市",
  },
  {
    value: "山口県萩市",
    label: "萩市",
  },
  {
    value: "山口県防府市",
    label: "防府市",
  },
  {
    value: "山口県下松市",
    label: "下松市",
  },
  {
    value: "山口県岩国市",
    label: "岩国市",
  },
  {
    value: "山口県光市",
    label: "光市",
  },
  {
    value: "山口県長門市",
    label: "長門市",
  },
  {
    value: "山口県柳井市",
    label: "柳井市",
  },
  {
    value: "山口県美祢市",
    label: "美祢市",
  },
  {
    value: "山口県周南市",
    label: "周南市",
  },
  {
    value: "山口県山陽小野田市",
    label: "山陽小野田市",
  },
  {
    value: "山口県周防大島町",
    label: "周防大島町",
  },
  {
    value: "山口県和木町",
    label: "和木町",
  },
  {
    value: "山口県上関町",
    label: "上関町",
  },
  {
    value: "山口県田布施町",
    label: "田布施町",
  },
  {
    value: "山口県平生町",
    label: "平生町",
  },
  {
    value: "山口県阿武町",
    label: "阿武町",
  },
  {
    value: "徳島県徳島市",
    label: "徳島市",
  },
  {
    value: "徳島県鳴門市",
    label: "鳴門市",
  },
  {
    value: "徳島県小松島市",
    label: "小松島市",
  },
  {
    value: "徳島県阿南市",
    label: "阿南市",
  },
  {
    value: "徳島県吉野川市",
    label: "吉野川市",
  },
  {
    value: "徳島県阿波市",
    label: "阿波市",
  },
  {
    value: "徳島県美馬市",
    label: "美馬市",
  },
  {
    value: "徳島県三好市",
    label: "三好市",
  },
  {
    value: "徳島県勝浦町",
    label: "勝浦町",
  },
  {
    value: "徳島県上勝町",
    label: "上勝町",
  },
  {
    value: "徳島県佐那河内村",
    label: "佐那河内村",
  },
  {
    value: "徳島県石井町",
    label: "石井町",
  },
  {
    value: "徳島県神山町",
    label: "神山町",
  },
  {
    value: "徳島県那賀町",
    label: "那賀町",
  },
  {
    value: "徳島県牟岐町",
    label: "牟岐町",
  },
  {
    value: "徳島県美波町",
    label: "美波町",
  },
  {
    value: "徳島県海陽町",
    label: "海陽町",
  },
  {
    value: "徳島県松茂町",
    label: "松茂町",
  },
  {
    value: "徳島県北島町",
    label: "北島町",
  },
  {
    value: "徳島県藍住町",
    label: "藍住町",
  },
  {
    value: "徳島県板野町",
    label: "板野町",
  },
  {
    value: "徳島県上板町",
    label: "上板町",
  },
  {
    value: "徳島県つるぎ町",
    label: "つるぎ町",
  },
  {
    value: "徳島県東みよし町",
    label: "東みよし町",
  },
  {
    value: "香川県高松市",
    label: "高松市",
  },
  {
    value: "香川県丸亀市",
    label: "丸亀市",
  },
  {
    value: "香川県坂出市",
    label: "坂出市",
  },
  {
    value: "香川県善通寺市",
    label: "善通寺市",
  },
  {
    value: "香川県観音寺市",
    label: "観音寺市",
  },
  {
    value: "香川県さぬき市",
    label: "さぬき市",
  },
  {
    value: "香川県東かがわ市",
    label: "東かがわ市",
  },
  {
    value: "香川県三豊市",
    label: "三豊市",
  },
  {
    value: "香川県土庄町",
    label: "土庄町",
  },
  {
    value: "香川県小豆島町",
    label: "小豆島町",
  },
  {
    value: "香川県三木町",
    label: "三木町",
  },
  {
    value: "香川県直島町",
    label: "直島町",
  },
  {
    value: "香川県宇多津町",
    label: "宇多津町",
  },
  {
    value: "香川県綾川町",
    label: "綾川町",
  },
  {
    value: "香川県琴平町",
    label: "琴平町",
  },
  {
    value: "香川県多度津町",
    label: "多度津町",
  },
  {
    value: "香川県まんのう町",
    label: "まんのう町",
  },
  {
    value: "愛媛県松山市",
    label: "松山市",
  },
  {
    value: "愛媛県今治市",
    label: "今治市",
  },
  {
    value: "愛媛県宇和島市",
    label: "宇和島市",
  },
  {
    value: "愛媛県八幡浜市",
    label: "八幡浜市",
  },
  {
    value: "愛媛県新居浜市",
    label: "新居浜市",
  },
  {
    value: "愛媛県西条市",
    label: "西条市",
  },
  {
    value: "愛媛県大洲市",
    label: "大洲市",
  },
  {
    value: "愛媛県伊予市",
    label: "伊予市",
  },
  {
    value: "愛媛県四国中央市",
    label: "四国中央市",
  },
  {
    value: "愛媛県西予市",
    label: "西予市",
  },
  {
    value: "愛媛県東温市",
    label: "東温市",
  },
  {
    value: "愛媛県上島町",
    label: "上島町",
  },
  {
    value: "愛媛県久万高原町",
    label: "久万高原町",
  },
  {
    value: "愛媛県松前町",
    label: "松前町",
  },
  {
    value: "愛媛県砥部町",
    label: "砥部町",
  },
  {
    value: "愛媛県内子町",
    label: "内子町",
  },
  {
    value: "愛媛県伊方町",
    label: "伊方町",
  },
  {
    value: "愛媛県松野町",
    label: "松野町",
  },
  {
    value: "愛媛県鬼北町",
    label: "鬼北町",
  },
  {
    value: "愛媛県愛南町",
    label: "愛南町",
  },
  {
    value: "高知県高知市",
    label: "高知市",
  },
  {
    value: "高知県室戸市",
    label: "室戸市",
  },
  {
    value: "高知県安芸市",
    label: "安芸市",
  },
  {
    value: "高知県南国市",
    label: "南国市",
  },
  {
    value: "高知県土佐市",
    label: "土佐市",
  },
  {
    value: "高知県須崎市",
    label: "須崎市",
  },
  {
    value: "高知県宿毛市",
    label: "宿毛市",
  },
  {
    value: "高知県土佐清水市",
    label: "土佐清水市",
  },
  {
    value: "高知県四万十市",
    label: "四万十市",
  },
  {
    value: "高知県香南市",
    label: "香南市",
  },
  {
    value: "高知県香美市",
    label: "香美市",
  },
  {
    value: "高知県東洋町",
    label: "東洋町",
  },
  {
    value: "高知県奈半利町",
    label: "奈半利町",
  },
  {
    value: "高知県田野町",
    label: "田野町",
  },
  {
    value: "高知県安田町",
    label: "安田町",
  },
  {
    value: "高知県北川村",
    label: "北川村",
  },
  {
    value: "高知県馬路村",
    label: "馬路村",
  },
  {
    value: "高知県芸西村",
    label: "芸西村",
  },
  {
    value: "高知県本山町",
    label: "本山町",
  },
  {
    value: "高知県大豊町",
    label: "大豊町",
  },
  {
    value: "高知県土佐町",
    label: "土佐町",
  },
  {
    value: "高知県大川村",
    label: "大川村",
  },
  {
    value: "高知県いの町",
    label: "いの町",
  },
  {
    value: "高知県仁淀川町",
    label: "仁淀川町",
  },
  {
    value: "高知県中土佐町",
    label: "中土佐町",
  },
  {
    value: "高知県佐川町",
    label: "佐川町",
  },
  {
    value: "高知県越知町",
    label: "越知町",
  },
  {
    value: "高知県梼原町",
    label: "梼原町",
  },
  {
    value: "高知県日高村",
    label: "日高村",
  },
  {
    value: "高知県津野町",
    label: "津野町",
  },
  {
    value: "高知県四万十町",
    label: "四万十町",
  },
  {
    value: "高知県大月町",
    label: "大月町",
  },
  {
    value: "高知県三原村",
    label: "三原村",
  },
  {
    value: "高知県黒潮町",
    label: "黒潮町",
  },
  {
    value: "福岡県北九州市",
    label: "北九州市",
  },
  {
    value: "福岡県福岡市",
    label: "福岡市",
  },
  {
    value: "福岡県大牟田市",
    label: "大牟田市",
  },
  {
    value: "福岡県久留米市",
    label: "久留米市",
  },
  {
    value: "福岡県直方市",
    label: "直方市",
  },
  {
    value: "福岡県飯塚市",
    label: "飯塚市",
  },
  {
    value: "福岡県田川市",
    label: "田川市",
  },
  {
    value: "福岡県柳川市",
    label: "柳川市",
  },
  {
    value: "福岡県八女市",
    label: "八女市",
  },
  {
    value: "福岡県筑後市",
    label: "筑後市",
  },
  {
    value: "福岡県大川市",
    label: "大川市",
  },
  {
    value: "福岡県行橋市",
    label: "行橋市",
  },
  {
    value: "福岡県豊前市",
    label: "豊前市",
  },
  {
    value: "福岡県中間市",
    label: "中間市",
  },
  {
    value: "福岡県小郡市",
    label: "小郡市",
  },
  {
    value: "福岡県筑紫野市",
    label: "筑紫野市",
  },
  {
    value: "福岡県春日市",
    label: "春日市",
  },
  {
    value: "福岡県大野城市",
    label: "大野城市",
  },
  {
    value: "福岡県宗像市",
    label: "宗像市",
  },
  {
    value: "福岡県太宰府市",
    label: "太宰府市",
  },
  {
    value: "福岡県古賀市",
    label: "古賀市",
  },
  {
    value: "福岡県福津市",
    label: "福津市",
  },
  {
    value: "福岡県うきは市",
    label: "うきは市",
  },
  {
    value: "福岡県宮若市",
    label: "宮若市",
  },
  {
    value: "福岡県嘉麻市",
    label: "嘉麻市",
  },
  {
    value: "福岡県朝倉市",
    label: "朝倉市",
  },
  {
    value: "福岡県みやま市",
    label: "みやま市",
  },
  {
    value: "福岡県糸島市",
    label: "糸島市",
  },
  {
    value: "福岡県那珂川市",
    label: "那珂川市",
  },
  {
    value: "福岡県宇美町",
    label: "宇美町",
  },
  {
    value: "福岡県篠栗町",
    label: "篠栗町",
  },
  {
    value: "福岡県志免町",
    label: "志免町",
  },
  {
    value: "福岡県須恵町",
    label: "須恵町",
  },
  {
    value: "福岡県新宮町",
    label: "新宮町",
  },
  {
    value: "福岡県久山町",
    label: "久山町",
  },
  {
    value: "福岡県粕屋町",
    label: "粕屋町",
  },
  {
    value: "福岡県芦屋町",
    label: "芦屋町",
  },
  {
    value: "福岡県水巻町",
    label: "水巻町",
  },
  {
    value: "福岡県岡垣町",
    label: "岡垣町",
  },
  {
    value: "福岡県遠賀町",
    label: "遠賀町",
  },
  {
    value: "福岡県小竹町",
    label: "小竹町",
  },
  {
    value: "福岡県鞍手町",
    label: "鞍手町",
  },
  {
    value: "福岡県桂川町",
    label: "桂川町",
  },
  {
    value: "福岡県筑前町",
    label: "筑前町",
  },
  {
    value: "福岡県東峰村",
    label: "東峰村",
  },
  {
    value: "福岡県大刀洗町",
    label: "大刀洗町",
  },
  {
    value: "福岡県大木町",
    label: "大木町",
  },
  {
    value: "福岡県広川町",
    label: "広川町",
  },
  {
    value: "福岡県香春町",
    label: "香春町",
  },
  {
    value: "福岡県添田町",
    label: "添田町",
  },
  {
    value: "福岡県糸田町",
    label: "糸田町",
  },
  {
    value: "福岡県川崎町",
    label: "川崎町",
  },
  {
    value: "福岡県大任町",
    label: "大任町",
  },
  {
    value: "福岡県赤村",
    label: "赤村",
  },
  {
    value: "福岡県福智町",
    label: "福智町",
  },
  {
    value: "福岡県苅田町",
    label: "苅田町",
  },
  {
    value: "福岡県みやこ町",
    label: "みやこ町",
  },
  {
    value: "福岡県吉富町",
    label: "吉富町",
  },
  {
    value: "福岡県上毛町",
    label: "上毛町",
  },
  {
    value: "福岡県築上町",
    label: "築上町",
  },
  {
    value: "佐賀県佐賀市",
    label: "佐賀市",
  },
  {
    value: "佐賀県唐津市",
    label: "唐津市",
  },
  {
    value: "佐賀県鳥栖市",
    label: "鳥栖市",
  },
  {
    value: "佐賀県多久市",
    label: "多久市",
  },
  {
    value: "佐賀県伊万里市",
    label: "伊万里市",
  },
  {
    value: "佐賀県武雄市",
    label: "武雄市",
  },
  {
    value: "佐賀県鹿島市",
    label: "鹿島市",
  },
  {
    value: "佐賀県小城市",
    label: "小城市",
  },
  {
    value: "佐賀県嬉野市",
    label: "嬉野市",
  },
  {
    value: "佐賀県神埼市",
    label: "神埼市",
  },
  {
    value: "佐賀県吉野ヶ里町",
    label: "吉野ヶ里町",
  },
  {
    value: "佐賀県基山町",
    label: "基山町",
  },
  {
    value: "佐賀県上峰町",
    label: "上峰町",
  },
  {
    value: "佐賀県みやき町",
    label: "みやき町",
  },
  {
    value: "佐賀県玄海町",
    label: "玄海町",
  },
  {
    value: "佐賀県有田町",
    label: "有田町",
  },
  {
    value: "佐賀県大町町",
    label: "大町町",
  },
  {
    value: "佐賀県江北町",
    label: "江北町",
  },
  {
    value: "佐賀県白石町",
    label: "白石町",
  },
  {
    value: "佐賀県太良町",
    label: "太良町",
  },
  {
    value: "長崎県長崎市",
    label: "長崎市",
  },
  {
    value: "長崎県佐世保市",
    label: "佐世保市",
  },
  {
    value: "長崎県島原市",
    label: "島原市",
  },
  {
    value: "長崎県諫早市",
    label: "諫早市",
  },
  {
    value: "長崎県大村市",
    label: "大村市",
  },
  {
    value: "長崎県平戸市",
    label: "平戸市",
  },
  {
    value: "長崎県松浦市",
    label: "松浦市",
  },
  {
    value: "長崎県対馬市",
    label: "対馬市",
  },
  {
    value: "長崎県壱岐市",
    label: "壱岐市",
  },
  {
    value: "長崎県五島市",
    label: "五島市",
  },
  {
    value: "長崎県西海市",
    label: "西海市",
  },
  {
    value: "長崎県雲仙市",
    label: "雲仙市",
  },
  {
    value: "長崎県南島原市",
    label: "南島原市",
  },
  {
    value: "長崎県長与町",
    label: "長与町",
  },
  {
    value: "長崎県時津町",
    label: "時津町",
  },
  {
    value: "長崎県東彼杵町",
    label: "東彼杵町",
  },
  {
    value: "長崎県川棚町",
    label: "川棚町",
  },
  {
    value: "長崎県波佐見町",
    label: "波佐見町",
  },
  {
    value: "長崎県小値賀町",
    label: "小値賀町",
  },
  {
    value: "長崎県佐々町",
    label: "佐々町",
  },
  {
    value: "長崎県新上五島町",
    label: "新上五島町",
  },
  {
    value: "熊本県熊本市",
    label: "熊本市",
  },
  {
    value: "熊本県八代市",
    label: "八代市",
  },
  {
    value: "熊本県人吉市",
    label: "人吉市",
  },
  {
    value: "熊本県荒尾市",
    label: "荒尾市",
  },
  {
    value: "熊本県水俣市",
    label: "水俣市",
  },
  {
    value: "熊本県玉名市",
    label: "玉名市",
  },
  {
    value: "熊本県山鹿市",
    label: "山鹿市",
  },
  {
    value: "熊本県菊池市",
    label: "菊池市",
  },
  {
    value: "熊本県宇土市",
    label: "宇土市",
  },
  {
    value: "熊本県上天草市",
    label: "上天草市",
  },
  {
    value: "熊本県宇城市",
    label: "宇城市",
  },
  {
    value: "熊本県阿蘇市",
    label: "阿蘇市",
  },
  {
    value: "熊本県天草市",
    label: "天草市",
  },
  {
    value: "熊本県合志市",
    label: "合志市",
  },
  {
    value: "熊本県美里町",
    label: "美里町",
  },
  {
    value: "熊本県玉東町",
    label: "玉東町",
  },
  {
    value: "熊本県南関町",
    label: "南関町",
  },
  {
    value: "熊本県長洲町",
    label: "長洲町",
  },
  {
    value: "熊本県和水町",
    label: "和水町",
  },
  {
    value: "熊本県大津町",
    label: "大津町",
  },
  {
    value: "熊本県菊陽町",
    label: "菊陽町",
  },
  {
    value: "熊本県南小国町",
    label: "南小国町",
  },
  {
    value: "熊本県小国町",
    label: "小国町",
  },
  {
    value: "熊本県産山村",
    label: "産山村",
  },
  {
    value: "熊本県高森町",
    label: "高森町",
  },
  {
    value: "熊本県西原村",
    label: "西原村",
  },
  {
    value: "熊本県南阿蘇村",
    label: "南阿蘇村",
  },
  {
    value: "熊本県御船町",
    label: "御船町",
  },
  {
    value: "熊本県嘉島町",
    label: "嘉島町",
  },
  {
    value: "熊本県益城町",
    label: "益城町",
  },
  {
    value: "熊本県甲佐町",
    label: "甲佐町",
  },
  {
    value: "熊本県山都町",
    label: "山都町",
  },
  {
    value: "熊本県氷川町",
    label: "氷川町",
  },
  {
    value: "熊本県芦北町",
    label: "芦北町",
  },
  {
    value: "熊本県津奈木町",
    label: "津奈木町",
  },
  {
    value: "熊本県錦町",
    label: "錦町",
  },
  {
    value: "熊本県多良木町",
    label: "多良木町",
  },
  {
    value: "熊本県湯前町",
    label: "湯前町",
  },
  {
    value: "熊本県水上村",
    label: "水上村",
  },
  {
    value: "熊本県相良村",
    label: "相良村",
  },
  {
    value: "熊本県五木村",
    label: "五木村",
  },
  {
    value: "熊本県山江村",
    label: "山江村",
  },
  {
    value: "熊本県球磨村",
    label: "球磨村",
  },
  {
    value: "熊本県あさぎり町",
    label: "あさぎり町",
  },
  {
    value: "熊本県苓北町",
    label: "苓北町",
  },
  {
    value: "大分県大分市",
    label: "大分市",
  },
  {
    value: "大分県別府市",
    label: "別府市",
  },
  {
    value: "大分県中津市",
    label: "中津市",
  },
  {
    value: "大分県日田市",
    label: "日田市",
  },
  {
    value: "大分県佐伯市",
    label: "佐伯市",
  },
  {
    value: "大分県臼杵市",
    label: "臼杵市",
  },
  {
    value: "大分県津久見市",
    label: "津久見市",
  },
  {
    value: "大分県竹田市",
    label: "竹田市",
  },
  {
    value: "大分県豊後高田市",
    label: "豊後高田市",
  },
  {
    value: "大分県杵築市",
    label: "杵築市",
  },
  {
    value: "大分県宇佐市",
    label: "宇佐市",
  },
  {
    value: "大分県豊後大野市",
    label: "豊後大野市",
  },
  {
    value: "大分県由布市",
    label: "由布市",
  },
  {
    value: "大分県国東市",
    label: "国東市",
  },
  {
    value: "大分県姫島村",
    label: "姫島村",
  },
  {
    value: "大分県日出町",
    label: "日出町",
  },
  {
    value: "大分県九重町",
    label: "九重町",
  },
  {
    value: "大分県玖珠町",
    label: "玖珠町",
  },
  {
    value: "宮崎県宮崎市",
    label: "宮崎市",
  },
  {
    value: "宮崎県都城市",
    label: "都城市",
  },
  {
    value: "宮崎県延岡市",
    label: "延岡市",
  },
  {
    value: "宮崎県日南市",
    label: "日南市",
  },
  {
    value: "宮崎県小林市",
    label: "小林市",
  },
  {
    value: "宮崎県日向市",
    label: "日向市",
  },
  {
    value: "宮崎県串間市",
    label: "串間市",
  },
  {
    value: "宮崎県西都市",
    label: "西都市",
  },
  {
    value: "宮崎県えびの市",
    label: "えびの市",
  },
  {
    value: "宮崎県三股町",
    label: "三股町",
  },
  {
    value: "宮崎県高原町",
    label: "高原町",
  },
  {
    value: "宮崎県国富町",
    label: "国富町",
  },
  {
    value: "宮崎県綾町",
    label: "綾町",
  },
  {
    value: "宮崎県高鍋町",
    label: "高鍋町",
  },
  {
    value: "宮崎県新富町",
    label: "新富町",
  },
  {
    value: "宮崎県西米良村",
    label: "西米良村",
  },
  {
    value: "宮崎県木城町",
    label: "木城町",
  },
  {
    value: "宮崎県川南町",
    label: "川南町",
  },
  {
    value: "宮崎県都農町",
    label: "都農町",
  },
  {
    value: "宮崎県門川町",
    label: "門川町",
  },
  {
    value: "宮崎県諸塚村",
    label: "諸塚村",
  },
  {
    value: "宮崎県椎葉村",
    label: "椎葉村",
  },
  {
    value: "宮崎県美郷町",
    label: "美郷町",
  },
  {
    value: "宮崎県高千穂町",
    label: "高千穂町",
  },
  {
    value: "宮崎県日之影町",
    label: "日之影町",
  },
  {
    value: "宮崎県五ヶ瀬町",
    label: "五ヶ瀬町",
  },
  {
    value: "鹿児島県鹿児島市",
    label: "鹿児島市",
  },
  {
    value: "鹿児島県鹿屋市",
    label: "鹿屋市",
  },
  {
    value: "鹿児島県枕崎市",
    label: "枕崎市",
  },
  {
    value: "鹿児島県阿久根市",
    label: "阿久根市",
  },
  {
    value: "鹿児島県出水市",
    label: "出水市",
  },
  {
    value: "鹿児島県指宿市",
    label: "指宿市",
  },
  {
    value: "鹿児島県西之表市",
    label: "西之表市",
  },
  {
    value: "鹿児島県垂水市",
    label: "垂水市",
  },
  {
    value: "鹿児島県薩摩川内市",
    label: "薩摩川内市",
  },
  {
    value: "鹿児島県日置市",
    label: "日置市",
  },
  {
    value: "鹿児島県曽於市",
    label: "曽於市",
  },
  {
    value: "鹿児島県霧島市",
    label: "霧島市",
  },
  {
    value: "鹿児島県いちき串木野市",
    label: "いちき串木野市",
  },
  {
    value: "鹿児島県南さつま市",
    label: "南さつま市",
  },
  {
    value: "鹿児島県志布志市",
    label: "志布志市",
  },
  {
    value: "鹿児島県奄美市",
    label: "奄美市",
  },
  {
    value: "鹿児島県南九州市",
    label: "南九州市",
  },
  {
    value: "鹿児島県伊佐市",
    label: "伊佐市",
  },
  {
    value: "鹿児島県姶良市",
    label: "姶良市",
  },
  {
    value: "鹿児島県三島村",
    label: "三島村",
  },
  {
    value: "鹿児島県十島村",
    label: "十島村",
  },
  {
    value: "鹿児島県さつま町",
    label: "さつま町",
  },
  {
    value: "鹿児島県長島町",
    label: "長島町",
  },
  {
    value: "鹿児島県湧水町",
    label: "湧水町",
  },
  {
    value: "鹿児島県大崎町",
    label: "大崎町",
  },
  {
    value: "鹿児島県東串良町",
    label: "東串良町",
  },
  {
    value: "鹿児島県錦江町",
    label: "錦江町",
  },
  {
    value: "鹿児島県南大隅町",
    label: "南大隅町",
  },
  {
    value: "鹿児島県肝付町",
    label: "肝付町",
  },
  {
    value: "鹿児島県中種子町",
    label: "中種子町",
  },
  {
    value: "鹿児島県南種子町",
    label: "南種子町",
  },
  {
    value: "鹿児島県屋久島町",
    label: "屋久島町",
  },
  {
    value: "鹿児島県大和村",
    label: "大和村",
  },
  {
    value: "鹿児島県宇検村",
    label: "宇検村",
  },
  {
    value: "鹿児島県瀬戸内町",
    label: "瀬戸内町",
  },
  {
    value: "鹿児島県龍郷町",
    label: "龍郷町",
  },
  {
    value: "鹿児島県喜界町",
    label: "喜界町",
  },
  {
    value: "鹿児島県徳之島町",
    label: "徳之島町",
  },
  {
    value: "鹿児島県天城町",
    label: "天城町",
  },
  {
    value: "鹿児島県伊仙町",
    label: "伊仙町",
  },
  {
    value: "鹿児島県和泊町",
    label: "和泊町",
  },
  {
    value: "鹿児島県知名町",
    label: "知名町",
  },
  {
    value: "鹿児島県与論町",
    label: "与論町",
  },
  {
    value: "沖縄県那覇市",
    label: "那覇市",
  },
  {
    value: "沖縄県宜野湾市",
    label: "宜野湾市",
  },
  {
    value: "沖縄県石垣市",
    label: "石垣市",
  },
  {
    value: "沖縄県浦添市",
    label: "浦添市",
  },
  {
    value: "沖縄県名護市",
    label: "名護市",
  },
  {
    value: "沖縄県糸満市",
    label: "糸満市",
  },
  {
    value: "沖縄県沖縄市",
    label: "沖縄市",
  },
  {
    value: "沖縄県豊見城市",
    label: "豊見城市",
  },
  {
    value: "沖縄県うるま市",
    label: "うるま市",
  },
  {
    value: "沖縄県宮古島市",
    label: "宮古島市",
  },
  {
    value: "沖縄県南城市",
    label: "南城市",
  },
  {
    value: "沖縄県国頭村",
    label: "国頭村",
  },
  {
    value: "沖縄県大宜味村",
    label: "大宜味村",
  },
  {
    value: "沖縄県東村",
    label: "東村",
  },
  {
    value: "沖縄県今帰仁村",
    label: "今帰仁村",
  },
  {
    value: "沖縄県本部町",
    label: "本部町",
  },
  {
    value: "沖縄県恩納村",
    label: "恩納村",
  },
  {
    value: "沖縄県宜野座村",
    label: "宜野座村",
  },
  {
    value: "沖縄県金武町",
    label: "金武町",
  },
  {
    value: "沖縄県伊江村",
    label: "伊江村",
  },
  {
    value: "沖縄県読谷村",
    label: "読谷村",
  },
  {
    value: "沖縄県嘉手納町",
    label: "嘉手納町",
  },
  {
    value: "沖縄県北谷町",
    label: "北谷町",
  },
  {
    value: "沖縄県北中城村",
    label: "北中城村",
  },
  {
    value: "沖縄県中城村",
    label: "中城村",
  },
  {
    value: "沖縄県西原町",
    label: "西原町",
  },
  {
    value: "沖縄県与那原町",
    label: "与那原町",
  },
  {
    value: "沖縄県南風原町",
    label: "南風原町",
  },
  {
    value: "沖縄県渡嘉敷村",
    label: "渡嘉敷村",
  },
  {
    value: "沖縄県座間味村",
    label: "座間味村",
  },
  {
    value: "沖縄県粟国村",
    label: "粟国村",
  },
  {
    value: "沖縄県渡名喜村",
    label: "渡名喜村",
  },
  {
    value: "沖縄県南大東村",
    label: "南大東村",
  },
  {
    value: "沖縄県北大東村",
    label: "北大東村",
  },
  {
    value: "沖縄県伊平屋村",
    label: "伊平屋村",
  },
  {
    value: "沖縄県伊是名村",
    label: "伊是名村",
  },
  {
    value: "沖縄県久米島町",
    label: "久米島町",
  },
  {
    value: "沖縄県八重瀬町",
    label: "八重瀬町",
  },
  {
    value: "沖縄県多良間村",
    label: "多良間村",
  },
  {
    value: "沖縄県竹富町",
    label: "竹富町",
  },
  {
    value: "沖縄県与那国町",
    label: "与那国町",
  },
  { value: "北海道", label: "全体" },
  { value: "青森県", label: "全体" },
  { value: "岩手県", label: "全体" },
  { value: "宮城県", label: "全体" },
  { value: "秋田県", label: "全体" },
  { value: "山形県", label: "全体" },
  { value: "福島県", label: "全体" },
  { value: "茨城県", label: "全体" },
  { value: "栃木県", label: "全体" },
  { value: "群馬県", label: "全体" },
  { value: "埼玉県", label: "全体" },
  { value: "千葉県", label: "全体" },
  { value: "東京都東京23区", label: "東京23区" },
  { value: "東京都", label: "全体" },
  { value: "神奈川県", label: "全体" },
  { value: "新潟県", label: "全体" },
  { value: "富山県", label: "全体" },
  { value: "石川県", label: "全体" },
  { value: "福井県", label: "全体" },
  { value: "山梨県", label: "全体" },
  { value: "長野県", label: "全体" },
  { value: "岐阜県", label: "全体" },
  { value: "静岡県", label: "全体" },
  { value: "愛知県", label: "全体" },
  { value: "三重県", label: "全体" },
  { value: "滋賀県", label: "全体" },
  { value: "京都府", label: "全体" },
  { value: "大阪府", label: "全体" },
  { value: "兵庫県", label: "全体" },
  { value: "奈良県", label: "全体" },
  { value: "和歌山県", label: "全体" },
  { value: "鳥取県", label: "全体" },
  { value: "島根県", label: "全体" },
  { value: "岡山県", label: "全体" },
  { value: "広島県", label: "全体" },
  { value: "山口県", label: "全体" },
  { value: "徳島県", label: "全体" },
  { value: "香川県", label: "全体" },
  { value: "愛媛県", label: "全体" },
  { value: "高知県", label: "全体" },
  { value: "福岡県", label: "全体" },
  { value: "佐賀県", label: "全体" },
  { value: "長崎県", label: "全体" },
  { value: "熊本県", label: "全体" },
  { value: "大分県", label: "全体" },
  { value: "宮崎県", label: "全体" },
  { value: "鹿児島県", label: "全体" },
  { value: "沖縄県", label: "全体" },
];
