import React, { useContext, useState } from "react";
import _ from "lodash";

import { Ratio1Set, Ratio2Set } from "./App";
// import { datasyousai, datakanren, brackboard } from './hanrei.module.css'

function SvgComponent(props, csv) {
  const metadata = props.metadata;

  // const count = useContext(UserCount)
  const { ratio1set, setRatio1Set } = useContext(Ratio1Set);
  // const [showm, setShowm] = useState(true)
  const [showm, setShowm] = useState(false);
  const [showh, setShowh] = useState(false);
  const [showtry, setShowtry] = useState(false);

  var hanrei_width = "0 0 960 650";

  function Syosai(props) {
    return (
      <>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-1 lg:mx-1">
            <div className="inline-block py-1 min-w-full">
              <div className=" sm:rounded-lg scroll-y-auto max-h-[calc(70vh)] ">
                <div>
                  <table className="min-w-full">
                    <caption className="text-2xl text-extrabold text-white">
                      データ詳細
                    </caption>
                    <thead className="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          className="py-4 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400"
                        ></th>
                        <th
                          scope="col"
                          className="py-4 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400"
                        >
                          高さ
                        </th>
                        <th
                          scope="col"
                          className="py-4 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400"
                        >
                          色
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          観点
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.title_height_name}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.title_color_name}
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          凡例名
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.hanrei_height_name}
                          {metadata.hanrei_height_name_2}
                          {metadata.hanrei_height_name_3}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.hanrei_color_name}
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          年次
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.year_display}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.year_syousai}
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          出典
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.height_origin}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {metadata.color_origin}
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          定義
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 dark:text-gray-400  break-words">
                          {metadata.height_origin_note}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 break-words dark:text-gray-400">
                          {metadata.color_origin_note}
                        </td>
                      </tr>
                      <tr className="bg-white dark:bg-gray-800">
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          詳細
                        </td>
                        <td className="py-4 px-6 text-sm text-blue-500 underline break-words">
                          <a href={metadata.height_origin_url}>
                            {metadata.height_origin_url_name}
                          </a>{" "}
                          <br />
                          <a href={metadata.height_origin_url2}>
                            {metadata.height_origin_url2_name}
                          </a>
                          <br />
                          <a href={metadata.height_origin_url3}>
                            {metadata.height_origin_url3_name}
                          </a>
                          <br />
                          <a href={metadata.height_origin_url4}>
                            {metadata.height_origin_url4_name}
                          </a>
                        </td>
                        <td className="py-4 px-6 text-sm text-blue-500 underline break-words">
                          <a href={metadata.color_origin_url}>
                            {metadata.color_origin_url_name}
                          </a>
                          <br />
                          <a href={metadata.color_origin_url2}>
                            {metadata.color_origin_url2_name}
                          </a>
                          <br />
                          <a href={metadata.color_origin_url3}>
                            {metadata.color_origin_url3_name}
                          </a>
                          <br />
                          <a href={metadata.color_origin_url4}>
                            {metadata.color_origin_url4_name}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 


      
      <tbody>
    <tr><td></td><td>高さ</td><td>色</td></tr>
    <tr>
    <td>観点</td>
    <td>{metadata.title_height_name}</td>
    <td>{metadata.title_color_name}</td>
    </tr>
    <tr>
    <td>凡例名</td>
    <td>{metadata.hanrei_height_name}</td>
    <td>{metadata.hanrei_color_name}</td>
    </tr>
    <tr>
    <td>年次</td>
    <td>{metadata.year_display}</td>
    <td>{metadata.year_display}</td>
    </tr>
    <tr>
    <td>出典</td>
    <td>{metadata.height_origin}</td>
    <td>{metadata.color_origin}</td>
    </tr>
    <tr>
    <td>定義</td>
    <td>{metadata.height_origin_note}</td>
    <td>{metadata.color_origin_note}</td>
    </tr>
    <tr>
    <td>詳細</td>
    <td>
      <a href={metadata.height_origin_url}>{metadata.height_origin_url_name}</a>
      <br/>
      <a href={metadata.height_origin_url2}>{metadata.height_origin_url2_name}</a>
      <br/>
      <a href={metadata.height_origin_url3}>{metadata.height_origin_url3_name}</a>
      <br/>
      <a href={metadata.height_origin_url4}>{metadata.height_origin_url4_name}</a>
    </td>
    <td>
      <a href={metadata.color_origin_url}>{metadata.color_origin_url_name}</a>
      <br/>
      <a href={metadata.color_origin_url2}>{metadata.color_origin_url2_name}</a>
      <br/>
      <a href={metadata.color_origin_url3}>{metadata.color_origin_url3_name}</a>
      <br/>
      <a href={metadata.color_origin_url4}>{metadata.color_origin_url4_name}</a>
    </td>
    </tr>
    </tbody> */}
      </>
    );
  }

  function Modal({ show, setShow }) {
    if (show) {
      return (
        <div
          id="overlay"
          onClick={() => setShow(false)}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: "center",
            justifyContent: " center",
          }}
          className="z-50"
          // className="pt-28 max-h-fit scroll-auto"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: "10003",
              width: "90%",
            }}
            className="mt-8 text-white bg-gradient-to-r from-black/50 to-black/50 font-medium rounded-lg"
          >
            <div className="text-black">
              {/* <div> */}
              <div className="mx-auto">
                <Syosai post={metadata} />
              </div>
              {/* <div>
            <table>
              <tbody>
                <tr>
                  <td style={{width:"45%"}}>データのダウンロード（メッシュコード,高さ,色）</td>
                  <td>
                    <a href={csv+"_"+metadata.year1+".csv"} download={metadata.year1+".csv"}>{metadata.year1}　</a>
                    <a href={csv+"_"+metadata.year2+".csv"} download={metadata.year2+".csv"}>{metadata.year2}　</a>
                    <a href={csv+"_"+metadata.year3+".csv"} download={metadata.year3+".csv"}>{metadata.year3}　</a>
                    <a href={csv+"_"+metadata.year4+".csv"} download={metadata.year4+".csv"}>{metadata.year4}　</a>
                    <a href={csv+"_"+metadata.year5+".csv"} download={metadata.year5+".csv"}>{metadata.year5}</a>
                  </td>
                </tr>
            </tbody>
          </table>
        </div> */}
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function Modalh({ show, setShow }) {
    if (show) {
      return (
        <div
          id="overlay"
          onClick={() => setShow(false)}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",

            display: "flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: "10002",
              width: "80%",
              padding: "1em",
              background: "#fff",
            }}
          >
            <div className="">
              <div>関連するデータへ</div>
              {metadata.tags == null ? null : (
                <div>
                  {metadata.tags.map((tag, index) => (
                    <div key={index}>
                      <a key={index} href={`/tags/${_.kebabCase(tag)}/`}>
                        # {`${tag}`}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function Modaltry({ show, setShow }) {
    if (show) {
      return (
        <div
          id="overlay"
          onClick={() => setShow(false)}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",

            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: "10002",
              width: "50%",
              // padding:"1em",
              background: "#fff",
            }}
          >
            <div className="" style={{ zIndex: "99" }}>
              {metadata.try}
              <div style={{ textAlign: "center" }}>
                <button onClick={() => setShow(false)}>閉じる</button>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button onClick={() => setShow(false)}>閉じる</button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      {metadata.year_display != metadata.year_syousai && (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 165 85"
        >
          <g id="参考" display="none"></g>
          <g id="背景の白色">
            {/* <rect x="0" y="0" opacity="0.75" fill="#FFFFFF" width="165" height="85"/> */}
          </g>
          <g id="タイトル">
            <text
              transform="matrix(1 0 0 1 5 11.0635)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontWeight="bold"
              fontSize="7"
            >
              {metadata.title_height_name}と{metadata.title_color_name}
            </text>
          </g>
          <g id="赤い棒">
            <polygon
              fill={metadata.hanrei1_color}
              points="104.231,14.064 91.565,15.231 81.899,58.564 84.065,60.064 89.565,60.23 114.731,18.897"
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="114.565,19.231 100.899,19.397 91.732,15.564 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="84.399"
              y1="60.23"
              x2="100.899"
              y2="19.397"
            />
          </g>
          <g id="オレンジ棒">
            <polygon
              fill={metadata.hanrei2_color}
              points="89.065,31.731 84.233,27.731 73.733,28.064 73.565,60.064 76.899,61.896 82.065,60.896 	"
            />
            <path fill="none" d="M90.399,81.064" />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="88.774,31.897 78.565,32.73 73.815,28.314 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="76.733"
              y1="61.73"
              x2="78.565"
              y2="32.73"
            />
          </g>
          <g id="黄色い棒">
            <polygon
              fill={metadata.hanrei3_color}
              points="74.399,46.397 70.399,41.064 61.399,42.73 63.899,59.23 67.233,62.064 72.899,60.396 "
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="61.733,42.897 66.565,47.897 74.065,46.522 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="67.233"
              y1="62.064"
              x2="66.565"
              y2="47.897"
            />
          </g>
          <g id="灰色の棒">
            <polygon
              fill={metadata.hanrei4_color}
              points="63.903,51.641 59.919,48.961 53.521,51.97 58.485,62.356 61.505,63.524 65.415,61.22 "
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="53.901,52.085 58.409,54.354 63.702,51.875 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="61.546"
              y1="63.512"
              x2="58.409"
              y2="54.354"
            />
          </g>
          <g id="右の引き出し線と高さ凡例">
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="116.565"
              y1="19.189"
              x2="121.691"
              y2="19.189"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="119.316"
              y1="19.189"
              x2="119.316"
              y2="60.439"
            />
            <polygon points="121.566,23.564 116.94,23.439 119.316,18.939 " />
            <polygon points="117.065,55.939 121.691,56.064 119.316,60.564 " />
            <text
              transform="matrix(1 0 0 1 122.0239 30)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              高さ：
            </text>
            <text
              transform="matrix(1 0 0 1 123 40)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name}
            </text>
            <text
              transform="matrix(1 0 0 1 123 48)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name_2}
            </text>
            <text
              transform="matrix(1 0 0 1 123 56)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name_3}
            </text>
          </g>
          <g id="左上の引き出し線と色の凡例">
            <text
              transform="matrix(1 0 0 1 5 20.2715)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              色：{metadata.hanrei_color_name}
            </text>
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              x1="4.232"
              y1="22.731"
              x2="90.066"
              y2="22.731"
            />
            <circle cx="90" cy="22.897" r="1.083" />
          </g>
          <g id="左赤四角">
            <rect
              x="5"
              y="27.064"
              fill={metadata.hanrei1_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 31.2305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei1_name}
              </tspan>
            </text>
          </g>
          <g id="左オレンジ四角">
            <rect
              x="5"
              y="34.564"
              fill={metadata.hanrei2_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 38.7305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei2_name}
              </tspan>
            </text>
          </g>
          <g id="左黄色四角">
            <rect
              x="5"
              y="42.064"
              fill={metadata.hanrei3_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 46.2305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei3_name}
              </tspan>
            </text>
          </g>
          <g id="左灰色四角">
            <rect
              x="5"
              y="49.564"
              fill={metadata.hanrei4_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 53.7305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei4_name}
              </tspan>
            </text>
          </g>
          <g id="下年次（高さ）">
            {/* <rect x="5" y="69" fill="black" width="5.5" height="5.333"/> */}
            <text transform="matrix(1 0 0 1 5 72)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                高さ年次：{metadata.year_display}
              </tspan>
            </text>
          </g>
          <g id="下年次（色）">
            {/* <rect x="5" y="69" fill="black" width="5.5" height="5.333"/> */}
            <text transform="matrix(1 0 0 1 5 80)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                色の年次：{metadata.year_syousai}
              </tspan>
            </text>
          </g>
          <g id="右上高さ倍率">
            {/* <rect x="115" y="5" opacity="0.75" fill="#FFFFFF" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" width="45.226" height="11.457"/> */}
            <text transform="matrix(1 0 0 1 109 13)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.height_ratio}×{ratio1set}
              </tspan>
            </text>
          </g>

          <g id="右下詳細">
            <a onClick={() => setShowm(true)}>
              <rect
                x="105"
                y="65"
                opacity="0.2"
                fill="blue"
                stroke="blue"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                width="57"
                height="18"
                className="cursor-pointer"
              />
              <text
                transform="matrix(1 0 0 1 109 78)"
                className="cursor-pointer"
              >
                <tspan
                  x="0"
                  y="0"
                  fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                  fontSize="10"
                >
                  詳細を見る
                </tspan>
              </text>
            </a>
          </g>

          {/* <g id="右下詳細">
  <rect x="115" y="70" opacity="0.75" fill="gray" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" width="45.226" height="11.457" />
  <text transform="matrix(1 0 0 1 118 79)"  onClick={()=>setShowm(true)}><tspan x="0" y="0" fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'" fontSize="8">詳細を見る</tspan></text>
</g> */}
        </svg>
      )}

      {metadata.year_display == metadata.year_syousai && (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 165 85"
        >
          <g id="参考" display="none"></g>
          <g id="背景の白色">
            {/* <rect x="0" y="0" opacity="0.75" fill="#FFFFFF" width="165" height="85"/> */}
          </g>
          <g id="タイトル">
            <text
              transform="matrix(1 0 0 1 5 11.0635)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontWeight="bold"
              fontSize="7"
            >
              {metadata.title_height_name}と{metadata.title_color_name}
            </text>
          </g>
          <g id="赤い棒">
            <polygon
              fill={metadata.hanrei1_color}
              points="104.231,14.064 91.565,15.231 81.899,58.564 84.065,60.064 89.565,60.23 114.731,18.897"
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="114.565,19.231 100.899,19.397 91.732,15.564 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="84.399"
              y1="60.23"
              x2="100.899"
              y2="19.397"
            />
          </g>
          <g id="オレンジ棒">
            <polygon
              fill={metadata.hanrei2_color}
              points="89.065,31.731 84.233,27.731 73.733,28.064 73.565,60.064 76.899,61.896 82.065,60.896 	"
            />
            <path fill="none" d="M90.399,81.064" />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="88.774,31.897 78.565,32.73 73.815,28.314 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="76.733"
              y1="61.73"
              x2="78.565"
              y2="32.73"
            />
          </g>
          <g id="黄色い棒">
            <polygon
              fill={metadata.hanrei3_color}
              points="74.399,46.397 70.399,41.064 61.399,42.73 63.899,59.23 67.233,62.064 72.899,60.396 "
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="61.733,42.897 66.565,47.897 74.065,46.522 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="67.233"
              y1="62.064"
              x2="66.565"
              y2="47.897"
            />
          </g>
          <g id="灰色の棒">
            <polygon
              fill={metadata.hanrei4_color}
              points="63.903,51.641 59.919,48.961 53.521,51.97 58.485,62.356 61.505,63.524 65.415,61.22 "
            />
            <polyline
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="53.901,52.085 58.409,54.354 63.702,51.875 "
            />
            <line
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="61.546"
              y1="63.512"
              x2="58.409"
              y2="54.354"
            />
          </g>
          <g id="右の引き出し線と高さ凡例">
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="116.565"
              y1="19.189"
              x2="121.691"
              y2="19.189"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeMiterlimit="10"
              x1="119.316"
              y1="19.189"
              x2="119.316"
              y2="60.439"
            />
            <polygon points="121.566,23.564 116.94,23.439 119.316,18.939 " />
            <polygon points="117.065,55.939 121.691,56.064 119.316,60.564 " />
            <text
              transform="matrix(1 0 0 1 122.0239 30)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              高さ：
            </text>
            <text
              transform="matrix(1 0 0 1 123 40)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name}
            </text>
            <text
              transform="matrix(1 0 0 1 123 48)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name_2}
            </text>
            <text
              transform="matrix(1 0 0 1 123 56)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              {metadata.hanrei_height_name_3}
            </text>
          </g>
          <g id="左上の引き出し線と色の凡例">
            <text
              transform="matrix(1 0 0 1 5 20.2715)"
              fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
              fontSize="5"
            >
              色：{metadata.hanrei_color_name}
            </text>
            <line
              fill="none"
              stroke="#000000"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              x1="4.232"
              y1="22.731"
              x2="90.066"
              y2="22.731"
            />
            <circle cx="90" cy="22.897" r="1.083" />
          </g>
          <g id="左赤四角">
            <rect
              x="5"
              y="27.064"
              fill={metadata.hanrei1_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 31.2305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei1_name}
              </tspan>
            </text>
          </g>
          <g id="左オレンジ四角">
            <rect
              x="5"
              y="34.564"
              fill={metadata.hanrei2_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 38.7305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei2_name}
              </tspan>
            </text>
          </g>
          <g id="左黄色四角">
            <rect
              x="5"
              y="42.064"
              fill={metadata.hanrei3_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 46.2305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei3_name}
              </tspan>
            </text>
          </g>
          <g id="左灰色四角">
            <rect
              x="5"
              y="49.564"
              fill={metadata.hanrei4_color}
              width="5.5"
              height="5.333"
            />
            <text transform="matrix(1 0 0 1 13 53.7305)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.hanrei4_name}
              </tspan>
            </text>
          </g>
          <g id="下年次（高さ）">
            {/* <rect x="5" y="69" fill="black" width="5.5" height="5.333"/> */}
            <text transform="matrix(1 0 0 1 5 76)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                年次：{metadata.year_display}
              </tspan>
            </text>
          </g>
          {/* <g id="下年次（色）">
  <text transform="matrix(1 0 0 1 5 80)"><tspan x="0" y="0" fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'" fontSize="5">色の年次：{metadata.year_syousai}</tspan></text>
</g> */}
          <g id="右上高さ倍率">
            {/* <rect x="115" y="5" opacity="0.75" fill="#FFFFFF" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" width="45.226" height="11.457"/> */}
            <text transform="matrix(1 0 0 1 109 13)">
              <tspan
                x="0"
                y="0"
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
              >
                {metadata.height_ratio}
              </tspan>
              <tspan
                fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                fontSize="5"
                fill="blue"
              >
                ×{ratio1set}
              </tspan>
            </text>
          </g>

          <g id="右下詳細">
            <a onClick={() => setShowm(true)}>
              <rect
                x="105"
                y="63"
                opacity="0.2"
                fill="blue"
                stroke="blue"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                width="57"
                height="18"
                className="cursor-pointer"
              />
              <text
                transform="matrix(1 0 0 1 115 75)"
                className="cursor-pointer"
              >
                <tspan
                  x="0"
                  y="0"
                  fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'"
                  fontWeight="bold"
                  fontSize="7"
                >
                  詳細を見る
                </tspan>
              </text>
            </a>
          </g>

          {/* <g id="右下詳細">
  <rect x="115" y="70" opacity="0.75" fill="gray" stroke="#000000" strokeWidth="0.25" strokeMiterlimit="10" width="45.226" height="11.457" />
  <text transform="matrix(1 0 0 1 118 79)"  onClick={()=>setShowm(true)}><tspan x="0" y="0" fontFamily="'KozGoPro-Regular-90ms-RKSJ-H'" fontSize="8">詳細を見る</tspan></text>
</g> */}
        </svg>
      )}

      {/* <div className="text-white">
<button onClick={()=>setShowm(true)}>詳細を見る</button>
<button onClick={()=>setShowh(true)}>関連するデータを見る</button>
</div> */}

      <Modal show={showm} setShow={setShowm} />
      <Modalh show={showh} setShow={setShowh} />
      <Modaltry show={showtry} setShow={setShowtry} />
    </div>
  );
}

export default SvgComponent;
