import React from "react";
import metadata from "../meta_data_20220328"
// import { Dev_hanrei } from "./dev_hanrei"
// import Hanrei_canvas from "./hanrei_canvas"
import { Hanrei_canvas_list } from "./hanrei_canvas_list";
import Csv2kml from "../csv2kml";
export const Devtools = () => {
  // console.log(metadata);
  return (
    <>
      <div className="absolute z-30 font-mono text-black w-full h-screen bg-white">
        <div className="min-h-[8vh] mt-20 text-2xl text-left w-full text-black text-extrabold bg-gradient-to-r from-cyan-200 to-blue-400 hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium px-5 py-2.5">
        凡例一覧
        </div>
        <Hanrei_canvas_list metadata={metadata}>
          凡例一括ダウンロード
        </Hanrei_canvas_list>

<br/>







<div class="flex flex-col text-left m-4 w-11/12">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle dark:bg-gray-800">
            <div class="p-4">
                <label for="table-search" class="sr-only">Search</label>
                <div class="relative mt-1">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                    </div>
                    <input type="text" id="table-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="市町村名を検索"/>
                </div>
            </div>
            <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                    <thead class="bg-gray-100 dark:bg-gray-700">
                        <tr>
                            <th scope="col" class="p-4">
                                <div class="flex items-center">
                                    <input id="checkbox-search-all" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-search-all" class="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                テーマ
                            </th>
                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                高さ
                            </th>
                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                色
                            </th>
                            <th scope="col" class="p-4">
                                <span class="sr-only">KML</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">


                    {metadata.map((r)=>{
    return <>
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
                            <td class="p-4 w-4">
                                <div class="flex items-center">
                                    <input id="checkbox-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-search-1" class="sr-only">checkbox</label>
                                </div>
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">{r.title}</td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">{r.hanrei_height_name}</td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">{r.hanrei_color_name}</td>
                            <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                <a href="#" class="text-blue-600 dark:text-blue-500 hover:underline">
                                <Csv2kml
            meta = {r}
            area = {"福岡県糸島市"}
          >
           <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <span>
    Download
    </span>
</button>

          </Csv2kml>

                                  </a>
                            </td>
                        </tr>
                        </>

  })}


                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>




  {metadata.map((r)=>{
    return <div>
      {r.title}
      <Csv2kml
            meta = {r}
            area = {"福岡県糸島市"}
          >
           <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <span>
    Download
    </span>
</button>

          </Csv2kml>
          </div>
  })}

        {/* <div className="flex" id="hanreilist">
          <div className="">
            {metadata.map((r)=>
            <>
            <Hanrei_canvas metadata={r} year={r.year5}/>
            <Dev_hanrei metadata={r} />
            </>
            )}
          </div>
        </div> */}

      </div>
    </>
  )
}