import React, { createContext, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  // useLocation,
  // useParams
} from "react-router-dom";
import logoe from "./img/logo_e.png";
// import "./App.css";
import { CesiumContainer } from "./regtra_cesiumcontainer";
import { Home } from "./regtra_home";
import { Map } from "./regtra_map";
import { About } from "./about";
import { Select } from "./regtra_select";
import { Howto } from "./howto";
import { Faq } from "./faq";
import { Tags } from "./tags";
// import { Mapj } from "./mapj";
// import { Users } from "./users";
// import { Selectj } from "./selectj";
// import { Db } from "./db";
import {
  Link2top,
  Link2faq,
  Link2select,
  Link2howto
  // Map1set, Map2set, Rendou, Syukuren,Syukurendou, Syukurendourotate, Rotation, Rotate0set, Rotate1set, Rotate2set, Fullviewerset, Link2map,
} from "./buttons";
import {
  photo,
  palemap, stdmap, blankmap, engmap, oldphoto, oldmap
} from "./cesiumsetting";
// import { population_now, population_old } from "./kashikasetting";
// import { Static2Map1set } from "./kashikabuttons";
// import {LoadMetadataFunc} from "./kashikafunction"
// import { metadata } from "./kashikasetting";
import data_meta from "./regtra_meta_data_20220407";
// import { Modalstart } from "./startmodal";

import {Devtools} from "./devtools/index"





export const ModeSet = createContext(1);
export const StartmodalSet = createContext(true);
export const KakudaimodalSet = createContext(true);
export const MetadataSet = createContext([]);
export const FullscreenSet = createContext(false);
export const FullviewerSet = createContext(0);
export const RendouSet = createContext(true);
export const SyukurenSet = createContext(false);
export const Rotate0Set = createContext(false);
export const Rotate1Set = createContext(false);
export const Rotate2Set = createContext(false);
export const Map1Set = createContext(stdmap);
export const Map2Set = createContext(oldmap);
export const Theme1Set = createContext("");
export const Theme2Set = createContext("");
export const Zoomlevel1Set = createContext();
export const Zoomlevel2Set = createContext();
export const Zoomhanrei1Set = createContext();
export const Zoomhanrei2Set = createContext();
export const Static2Map1Set = createContext([]);
export const Static2Map2Set = createContext([]);
export const Area1Set = createContext("");
export const Area2Set = createContext("");
export const Year1Set = createContext(0);
export const Year2Set = createContext(0);

export const Ratio1Set = createContext(1);
export const Ratio2Set = createContext(1);

export const Footer = () => {
  return (
    <>
      <div className="absolute z-20 bottom-0 min-w-full font-bold text-sm lg:text-base text-gray-100 py-1 text-center">
        © 2022{" "}
        <a
          href="http://www.jsce.or.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          JSCE
        </a>{" "}
        ＆{" "}
        <a
          href="https://www.kashika.or.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          USVPO
        </a>
      </div>
    </>
  );
};

export const Header = () => {
  return (
    <>
      <div className="text-white bg-gradient-to-r from-blue-600 to-blue-800 font-medium text-base md:text-2xl text-center pointer-events-auto absolute z-20 text-black/80 flex w-full">
        <Link to="/">
          <img src={logoe} className="p-1 md:p-1 m-1 h-8 md:h-12 max-w-1/3" alt="logo" />
        </Link>
        <div className="grow"></div>
        <div className="text-white ">
          <div className="z-40 flex flex-row-reverse text-xs md:text-base">
            <div className="bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 p-1 md:p-2 mr-1 my-2 md:my-2 rounded">
              <Link2top />
            </div>
            <div className="bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 p-1 md:p-2 mr-1 my-2 md:my-2 rounded">
              <Link2faq />
            </div>
            <div className="bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 p-1 md:p-2 mr-1 my-2 md:my-2 rounded">
              <Link2howto />
            </div>
            <div className="bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 p-1 md:p-2 mr-1 my-2 md:my-2 rounded">
              <Link2select />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    (typeof window !== "undefined" &&
      window.localStorage.getItem(localStorageKey)) ||
      ""
  );
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
  return [value, setValue];
};

const App = () => {
  const [modeset, setModeSet] = useStateWithLocalStorage("modeset");
  const [startmodalset, setStartmodalSet] = useStateWithLocalStorage(
    "modal_start"
  );
  const [kakudaimodalset, setKakudaimodalSet] = useStateWithLocalStorage(
    "modal_kakudai"
  );
  const [metadataset, setMetadataSet] = useState("");
  const [fullscreenset, setFullscreenSet] = useState(false);
  const [fullviewerset, setFullviewerSet] = useState(0);
  const [rendouset, setRendouSet] = useState(true);
  const [syukurenset, setSyukurenSet] = useState(false);
  const [rotate0set, setRotate0Set] = useState(false);
  const [rotate1set, setRotate1Set] = useState(false);
  const [rotate2set, setRotate2Set] = useState(false);
  const [map1set, setMap1Set] = useState(stdmap);
  const [map2set, setMap2Set] = useState(oldmap);
  const [theme1set, setTheme1Set] = useState("");
  const [theme2set, setTheme2Set] = useState("");
  const [zoomlevel1set, setZoomlevel1Set] = useState(1);
  const [zoomlevel2set, setZoomlevel2Set] = useState(1);
  const [zoomhanrei1set, setZoomhanrei1Set] = useState();
  const [zoomhanrei2set, setZoomhanrei2Set] = useState();
  const [static2map1set, setStatic2Map1Set] = useStateWithLocalStorage(
    "static1map"
  );
  const [static2map2set, setStatic2Map2Set] = useStateWithLocalStorage(
    "static1map"
  );
  const [area1set, setArea1Set] = useStateWithLocalStorage("area1set");
  const [area2set, setArea2Set] = useStateWithLocalStorage("area2set");
  if (!area1set) {
    setArea1Set("福岡県糸島市");
  }
  if (!area2set) {
    setArea2Set("福岡県糸島市");
  }

  const modesetvalue = { modeset, setModeSet };
  const startmodalvalue = { startmodalset, setStartmodalSet };
  const kakudaimodalvalue = { kakudaimodalset, setKakudaimodalSet };
  const metadatasetvalue = { metadataset, setMetadataSet };
  const fullscreensetvalue = { fullscreenset, setFullscreenSet };
  const fullviewersetvalue = { fullviewerset, setFullviewerSet };
  const rendousetvalue = { rendouset, setRendouSet };
  const syukurensetvalue = { syukurenset, setSyukurenSet };
  const rotate0value = { rotate0set, setRotate0Set };
  const rotate1value = { rotate1set, setRotate1Set };
  const rotate2value = { rotate2set, setRotate2Set };
  const map1value = { map1set, setMap1Set };
  const map2value = { map2set, setMap2Set };
  const theme1value = { theme1set, setTheme1Set };
  const theme2value = { theme2set, setTheme2Set };
  const zoomlevel1value = { zoomlevel1set, setZoomlevel1Set };
  const zoomlevel2value = { zoomlevel2set, setZoomlevel2Set };
  const zoomhanrei1value = { zoomhanrei1set, setZoomhanrei1Set };
  const zoomhanrei2value = { zoomhanrei2set, setZoomhanrei2Set };
  const static2map1value = { static2map1set, setStatic2Map1Set };
  const static2map2value = { static2map2set, setStatic2Map2Set };
  const area1setvalue = { area1set, setArea1Set };
  const area2setvalue = { area2set, setArea2Set };

  const [ratio1set, setRatio1Set] = useState(1);
  const ratio1value = { ratio1set, setRatio1Set };
  const [ratio2set, setRatio2Set] = useState(1);
  const ratio2value = { ratio2set, setRatio2Set };

  const [year1set, setYear1Set] = useState(0);
  const year1value = { year1set, setYear1Set };
  const [year2set, setYear2Set] = useState(0);
  const year2value = { year2set, setYear2Set };

  if (!metadataset) {
    setMetadataSet(data_meta);
  }

  // 各ページの設定
  return (
    <div className="text-center z-10">
      {/* 操作レイヤ */}
      <BrowserRouter>
        <Header />

        <Routes>
          <Route
            path="/"
            element={
              <FullscreenSet.Provider value={fullscreensetvalue}>
                <MetadataSet.Provider value={metadatasetvalue}>
                  <Home />
                </MetadataSet.Provider>
              </FullscreenSet.Provider>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About />
              </>
            }
          />
          <Route
            path="/howto"
            element={
              <>
                <Howto />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Faq />
              </>
            }
          />
          <Route
            path="/devtools"
            element={
              <>
                <Devtools />
              </>
            }
          />

          <Route
            path="/tags/:tag"
            element={
              <FullviewerSet.Provider value={fullviewersetvalue}>
                <Static2Map1Set.Provider value={static2map1value}>
                  <Static2Map2Set.Provider value={static2map2value}>
                    <Map1Set.Provider value={map1value}>
                      <Map2Set.Provider value={map2value}>
                        <Theme1Set.Provider value={theme1value}>
                          <Theme2Set.Provider value={theme2value}>
                            <RendouSet.Provider value={rendousetvalue}>
                              <SyukurenSet.Provider value={syukurensetvalue}>
                                <Rotate1Set.Provider value={rotate1value}>
                                  <Rotate2Set.Provider value={rotate2value}>
                                    <Area1Set.Provider value={area1setvalue}>
                                      <Area2Set.Provider value={area2setvalue}>
                                        <ModeSet.Provider value={modesetvalue}>
                                          <MetadataSet.Provider
                                            value={metadatasetvalue}
                                          >
                                            <Tags />
                                          </MetadataSet.Provider>
                                        </ModeSet.Provider>
                                      </Area2Set.Provider>
                                    </Area1Set.Provider>
                                  </Rotate2Set.Provider>
                                </Rotate1Set.Provider>
                              </SyukurenSet.Provider>
                            </RendouSet.Provider>
                          </Theme2Set.Provider>
                        </Theme1Set.Provider>
                      </Map2Set.Provider>
                    </Map1Set.Provider>
                  </Static2Map2Set.Provider>
                </Static2Map1Set.Provider>
              </FullviewerSet.Provider>
            }
          />

          <Route
            path="/select"
            element={
              <FullviewerSet.Provider value={fullviewersetvalue}>
                <Static2Map1Set.Provider value={static2map1value}>
                  <Static2Map2Set.Provider value={static2map2value}>
                    <Map1Set.Provider value={map1value}>
                      <Map2Set.Provider value={map2value}>
                        <Theme1Set.Provider value={theme1value}>
                          <Theme2Set.Provider value={theme2value}>
                            <RendouSet.Provider value={rendousetvalue}>
                              <SyukurenSet.Provider value={syukurensetvalue}>
                                <Rotate1Set.Provider value={rotate1value}>
                                  <Rotate2Set.Provider value={rotate2value}>
                                    <Area1Set.Provider value={area1setvalue}>
                                      <Area2Set.Provider value={area2setvalue}>
                                        <ModeSet.Provider value={modesetvalue}>
                                          <Select />
                                        </ModeSet.Provider>
                                      </Area2Set.Provider>
                                    </Area1Set.Provider>
                                  </Rotate2Set.Provider>
                                </Rotate1Set.Provider>
                              </SyukurenSet.Provider>
                            </RendouSet.Provider>
                          </Theme2Set.Provider>
                        </Theme1Set.Provider>
                      </Map2Set.Provider>
                    </Map1Set.Provider>
                  </Static2Map2Set.Provider>
                </Static2Map1Set.Provider>
              </FullviewerSet.Provider>
            }
          />

          <Route
            path="/map/:mode/:no/:area"
            element={
              <FullviewerSet.Provider value={fullviewersetvalue}>
                <Zoomlevel1Set.Provider value={zoomlevel1value}>
                  <Zoomlevel2Set.Provider value={zoomlevel2value}>
                    <Zoomhanrei1Set.Provider value={zoomhanrei1value}>
                      <Zoomhanrei2Set.Provider value={zoomhanrei2value}>
                        <Static2Map1Set.Provider value={static2map1value}>
                          <Static2Map2Set.Provider value={static2map2value}>
                            <Theme1Set.Provider value={theme1value}>
                              <Theme2Set.Provider value={theme2value}>
                                <Map1Set.Provider value={map1value}>
                                  <Map2Set.Provider value={map2value}>
                                    <RendouSet.Provider value={rendousetvalue}>
                                      <SyukurenSet.Provider
                                        value={syukurensetvalue}
                                      >
                                        <Rotate1Set.Provider
                                          value={rotate1value}
                                        >
                                          <Rotate2Set.Provider
                                            value={rotate2value}
                                          >
                                            <Area1Set.Provider
                                              value={area1setvalue}
                                            >
                                              <Area2Set.Provider
                                                value={area2setvalue}
                                              >
                                                <Year1Set.Provider
                                                  value={year1value}
                                                >
                                                  <Year2Set.Provider
                                                    value={year2value}
                                                  >
                                                    <ModeSet.Provider
                                                      value={modesetvalue}
                                                    >
                                                      <Ratio1Set.Provider
                                                        value={ratio1value}
                                                      >
                                                        <Ratio2Set.Provider
                                                          value={ratio2value}
                                                        >
                                                          <StartmodalSet.Provider
                                                            value={
                                                              startmodalvalue
                                                            }
                                                          >
                                                            <KakudaimodalSet.Provider
                                                              value={
                                                                kakudaimodalvalue
                                                              }
                                                            >
                                                              <Map />
                                                            </KakudaimodalSet.Provider>
                                                          </StartmodalSet.Provider>
                                                        </Ratio2Set.Provider>
                                                      </Ratio1Set.Provider>
                                                    </ModeSet.Provider>
                                                  </Year2Set.Provider>
                                                </Year1Set.Provider>
                                              </Area2Set.Provider>
                                            </Area1Set.Provider>
                                          </Rotate2Set.Provider>
                                        </Rotate1Set.Provider>
                                      </SyukurenSet.Provider>
                                    </RendouSet.Provider>
                                  </Map2Set.Provider>
                                </Map1Set.Provider>
                              </Theme2Set.Provider>
                            </Theme1Set.Provider>
                          </Static2Map2Set.Provider>
                        </Static2Map1Set.Provider>
                      </Zoomhanrei2Set.Provider>
                    </Zoomhanrei1Set.Provider>
                  </Zoomlevel2Set.Provider>
                </Zoomlevel1Set.Provider>
              </FullviewerSet.Provider>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>

      <MetadataSet.Provider value={metadatasetvalue}>
        <FullscreenSet.Provider value={fullscreensetvalue}>
          <FullviewerSet.Provider value={fullviewersetvalue}>
            <Static2Map1Set.Provider value={static2map1value}>
              <Static2Map2Set.Provider value={static2map2value}>
                <Zoomlevel1Set.Provider value={zoomlevel1value}>
                  <Zoomlevel2Set.Provider value={zoomlevel2value}>
                    <Zoomhanrei1Set.Provider value={zoomhanrei1value}>
                      <Zoomhanrei2Set.Provider value={zoomhanrei2value}>
                        <Map1Set.Provider value={map1value}>
                          <Map2Set.Provider value={map2value}>
                            <RendouSet.Provider value={rendousetvalue}>
                              <SyukurenSet.Provider value={syukurensetvalue}>
                                <Rotate1Set.Provider value={rotate1value}>
                                  <Rotate2Set.Provider value={rotate2value}>
                                    <Area1Set.Provider value={area1setvalue}>
                                      <Area2Set.Provider value={area2setvalue}>
                                        <ModeSet.Provider value={modesetvalue}>
                                          <Ratio1Set.Provider
                                            value={ratio1value}
                                          >
                                            <Ratio2Set.Provider
                                              value={ratio2value}
                                            >
                                              <Year1Set.Provider
                                                value={year1value}
                                              >
                                                <Year2Set.Provider
                                                  value={year2value}
                                                >
                                                  <KakudaimodalSet.Provider
                                                    value={kakudaimodalvalue}
                                                  >
                                                    <CesiumContainer />
                                                  </KakudaimodalSet.Provider>
                                                </Year2Set.Provider>
                                              </Year1Set.Provider>
                                            </Ratio2Set.Provider>
                                          </Ratio1Set.Provider>
                                        </ModeSet.Provider>
                                      </Area2Set.Provider>
                                    </Area1Set.Provider>
                                  </Rotate2Set.Provider>
                                </Rotate1Set.Provider>
                              </SyukurenSet.Provider>
                            </RendouSet.Provider>
                          </Map2Set.Provider>
                        </Map1Set.Provider>
                      </Zoomhanrei2Set.Provider>
                    </Zoomhanrei1Set.Provider>
                  </Zoomlevel2Set.Provider>
                </Zoomlevel1Set.Provider>
              </Static2Map2Set.Provider>
            </Static2Map1Set.Provider>
          </FullviewerSet.Provider>
        </FullscreenSet.Provider>
      </MetadataSet.Provider>
    </div>
  );
};

export default App;
