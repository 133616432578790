import React, { useEffect, useLayoutEffect, useState } from 'react'
import JSZip from 'jszip';
import { saveAs } from "file-saver"

const width = 330
const height = 240
let devicePixelRatio;
let pngs = [];
let ctxs = [];
let canvasElems = [];
let imgcontent = [];
let elms = [];

function draw(ctx,metadata,year) {

  ctx.scale(2,2)

  var alpha = ctx.globalAlpha;

  // 1/
  ctx.save();
  ctx.globalAlpha = alpha * 0.75;

  ctx.beginPath();
  ctx.moveTo(160.6, 99.2);
  ctx.lineTo(0.0, 99.2);
  ctx.lineTo(0.0, 0.0);
  ctx.lineTo(160.6, 0.0);
  ctx.lineTo(160.6, 99.2);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();

  // 1/
  ctx.globalAlpha = alpha * 1.00;
  ctx.beginPath();
  ctx.moveTo(105.0, 48.0);
  ctx.lineTo(92.3, 49.2);
  ctx.lineTo(82.7, 92.5);
  ctx.lineTo(84.8, 94.0);
  ctx.lineTo(90.3, 94.2);
  ctx.lineTo(115.5, 52.9);
  ctx.lineTo(105.0, 48.0);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei1_color;
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(89.8, 65.7);
  ctx.lineTo(85.0, 61.7);
  ctx.lineTo(74.5, 62.0);
  ctx.lineTo(74.3, 94.0);
  ctx.lineTo(77.7, 95.9);
  ctx.lineTo(82.8, 94.9);
  ctx.lineTo(89.8, 65.7);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei2_color;
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(71.2, 75.0);

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(89.5, 65.9);
  ctx.lineTo(79.3, 66.7);
  ctx.lineTo(74.6, 62.3);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(77.5, 95.7);
  ctx.lineTo(79.3, 66.7);
  ctx.stroke();

  // 1/
  ctx.restore();

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(115.3, 53.2);
  ctx.lineTo(101.7, 53.4);
  ctx.lineTo(92.5, 49.5);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(85.2, 94.2);
  ctx.lineTo(101.7, 53.4);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(75.2, 80.4);
  ctx.lineTo(71.2, 75.0);
  ctx.lineTo(62.2, 76.7);
  ctx.lineTo(64.7, 93.2);
  ctx.lineTo(68.0, 96.0);
  ctx.lineTo(73.7, 94.4);
  ctx.lineTo(75.2, 80.4);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei3_color;
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(62.5, 76.9);
  ctx.lineTo(67.3, 81.9);
  ctx.lineTo(74.8, 80.5);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(68.0, 96.0);
  ctx.lineTo(67.3, 81.9);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(117.3, 53.2);
  ctx.lineTo(122.4, 53.2);
  ctx.lineWidth = 0.5;
  ctx.lineCap = "round";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(120.1, 53.2);
  ctx.lineTo(120.1, 94.4);
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(122.3, 57.5);
  ctx.lineTo(117.7, 57.4);
  ctx.lineTo(120.1, 52.9);
  ctx.lineTo(122.3, 57.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(117.8, 89.9);
  ctx.lineTo(122.4, 90.0);
  ctx.lineTo(120.1, 94.5);
  ctx.lineTo(117.8, 89.9);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText(metadata.height_ratio, 110, 46);

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("高さ：", 122.8, 62);
  ctx.fillText(metadata.hanrei_height_name, 122.8, 70.0);
  ctx.fillText(metadata.hanrei_height_name_2, 122.8, 77.0);
  ctx.fillText(metadata.hanrei_height_name_3, 122.8, 84.0);

  // 1/1haha
  ctx.fillText("色："+metadata.hanrei_color_name, 5.8, 54.3);

  // 1/
  ctx.font = "bold 7.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText(metadata.title_height_name+"と"+metadata.title_color_name, 5, 45.0);

  // 1/
  ctx.beginPath();
  ctx.moveTo(5.0, 56.7);
  ctx.lineTo(90.8, 56.7);
  ctx.lineCap = "butt";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(91.9, 56.9);
  ctx.bezierCurveTo(91.9, 57.5, 91.4, 58.0, 90.8, 58.0);
  ctx.bezierCurveTo(90.2, 58.0, 89.7, 57.5, 89.7, 56.9);
  ctx.bezierCurveTo(89.7, 56.3, 90.2, 55.8, 90.8, 55.8);
  ctx.bezierCurveTo(91.4, 55.8, 91.9, 56.3, 91.9, 56.9);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(11.2, 66.4);
  ctx.lineTo(5.7, 66.4);
  ctx.lineTo(5.7, 61.0);
  ctx.lineTo(11.2, 61.0);
  ctx.lineTo(11.2, 66.4);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei1_color;
  ctx.fill();

  // 1/04
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText(metadata.hanrei1_name, 13.5, 65.2);

  // 1/
  ctx.beginPath();
  ctx.moveTo(11.2, 73.9);
  ctx.lineTo(5.7, 73.9);
  ctx.lineTo(5.7, 68.5);
  ctx.lineTo(11.2, 68.5);
  ctx.lineTo(11.2, 73.9);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei2_color;
  ctx.fill();

  // 1/0204
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText(metadata.hanrei2_name, 13.5, 72.7);

  // 1/
  ctx.beginPath();
  ctx.moveTo(11.2, 81.4);
  ctx.lineTo(5.7, 81.4);
  ctx.lineTo(5.7, 76.0);
  ctx.lineTo(11.2, 76.0);
  ctx.lineTo(11.2, 81.4);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei3_color;
  ctx.fill();

  // 1/0102
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText(metadata.hanrei3_name, 13.5, 80.2);

  // 1/
  ctx.beginPath();
  ctx.moveTo(11.4, 88.9);
  ctx.lineTo(5.9, 88.9);
  ctx.lineTo(5.9, 83.5);
  ctx.lineTo(11.4, 83.5);
  ctx.lineTo(11.4, 88.9);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei4_color;
  ctx.fill();

  // 1/001
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText(metadata.hanrei4_name, 13.7, 87.7);

  // 1/
  ctx.beginPath();
  ctx.moveTo(64.7, 85.6);
  ctx.lineTo(60.7, 82.9);
  ctx.lineTo(54.3, 86.0);
  ctx.lineTo(59.2, 96.3);
  ctx.lineTo(62.3, 97.5);
  ctx.lineTo(66.2, 95.2);
  ctx.lineTo(64.7, 85.6);
  ctx.closePath();
  ctx.fillStyle = metadata.hanrei4_color;
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(54.7, 86.1);
  ctx.lineTo(59.2, 88.3);
  ctx.lineTo(64.5, 85.9);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(62.3, 97.5);
  ctx.lineTo(59.2, 88.3);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(156.0, 97.4);
  ctx.lineTo(123.8, 97.4);
  ctx.lineTo(123.8, 85.9);
  ctx.lineTo(156.0, 85.9);
  ctx.lineTo(156.0, 97.4);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.stroke();

  // 1/2015
  ctx.globalAlpha = alpha * 1.00;
  ctx.font = "9.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText(year, 128.0, 95.1);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(601.3, 1913.5);
  ctx.lineTo(440.7, 1913.5);
  ctx.lineTo(440.7, 1850.5);
  ctx.lineTo(601.3, 1850.5);
  ctx.lineTo(601.3, 1913.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.1;
  ctx.stroke();

  // 1/
  ctx.globalAlpha = alpha * 1.00;
  ctx.beginPath();
  ctx.moveTo(545.7, 1862.4);
  ctx.lineTo(533.0, 1863.5);
  ctx.lineTo(523.3, 1906.9);
  ctx.lineTo(525.5, 1908.4);
  ctx.lineTo(531.0, 1908.5);
  ctx.lineTo(556.2, 1867.2);
  ctx.lineTo(545.7, 1862.4);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(530.5, 1880.0);
  ctx.lineTo(525.7, 1876.0);
  ctx.lineTo(515.2, 1876.4);
  ctx.lineTo(515.0, 1908.4);
  ctx.lineTo(518.3, 1910.2);
  ctx.lineTo(523.5, 1909.2);
  ctx.lineTo(530.5, 1880.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(511.8, 1889.4);

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(530.2, 1880.2);
  ctx.lineTo(520.0, 1881.0);
  ctx.lineTo(515.2, 1876.6);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(518.2, 1910.0);
  ctx.lineTo(520.0, 1881.0);
  ctx.stroke();

  // 1/
  ctx.restore();

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(556.0, 1867.5);
  ctx.lineTo(542.3, 1867.7);
  ctx.lineTo(533.2, 1863.9);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(525.8, 1908.5);
  ctx.lineTo(542.3, 1867.7);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(515.8, 1894.7);
  ctx.lineTo(511.8, 1889.4);
  ctx.lineTo(502.8, 1891.0);
  ctx.lineTo(505.3, 1907.5);
  ctx.lineTo(508.7, 1910.4);
  ctx.lineTo(514.3, 1908.7);
  ctx.lineTo(515.8, 1894.7);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(503.2, 1891.2);
  ctx.lineTo(508.0, 1896.2);
  ctx.lineTo(515.5, 1894.8);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(508.7, 1910.4);
  ctx.lineTo(508.0, 1896.2);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(558.0, 1867.5);
  ctx.lineTo(563.1, 1867.5);
  ctx.lineWidth = 0.5;
  ctx.lineCap = "round";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(560.7, 1867.5);
  ctx.lineTo(560.7, 1908.8);
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(563.0, 1871.9);
  ctx.lineTo(558.4, 1871.8);
  ctx.lineTo(560.7, 1867.3);
  ctx.lineTo(563.0, 1871.9);
  ctx.closePath();
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(558.5, 1904.3);
  ctx.lineTo(563.1, 1904.4);
  ctx.lineTo(560.7, 1908.9);
  ctx.lineTo(558.5, 1904.3);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("456", 563.4, 1879.6);

  // 1/
  ctx.fillText("459", 446.4, 1868.6);

  // 1/
  ctx.font = "7.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("463", 443.3, 1859.4);

  // 1/
  ctx.beginPath();
  ctx.moveTo(445.7, 1871.0);
  ctx.lineTo(531.5, 1871.0);
  ctx.lineCap = "butt";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(532.6, 1871.2);
  ctx.bezierCurveTo(532.6, 1871.8, 532.1, 1872.3, 531.5, 1872.3);
  ctx.bezierCurveTo(530.9, 1872.3, 530.4, 1871.8, 530.4, 1871.2);
  ctx.bezierCurveTo(530.4, 1870.6, 530.9, 1870.1, 531.5, 1870.1);
  ctx.bezierCurveTo(532.1, 1870.1, 532.6, 1870.6, 532.6, 1871.2);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(451.8, 1880.7);
  ctx.lineTo(446.3, 1880.7);
  ctx.lineTo(446.3, 1875.4);
  ctx.lineTo(451.8, 1875.4);
  ctx.lineTo(451.8, 1880.7);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/60
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("496 60%〜", 454.2, 1879.5);

  // 1/
  ctx.beginPath();
  ctx.moveTo(451.8, 1888.2);
  ctx.lineTo(446.3, 1888.2);
  ctx.lineTo(446.3, 1882.9);
  ctx.lineTo(451.8, 1882.9);
  ctx.lineTo(451.8, 1888.2);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/4060
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("511 40〜60%", 454.2, 1887.0);

  // 1/
  ctx.beginPath();
  ctx.moveTo(451.8, 1895.7);
  ctx.lineTo(446.3, 1895.7);
  ctx.lineTo(446.3, 1890.4);
  ctx.lineTo(451.8, 1890.4);
  ctx.lineTo(451.8, 1895.7);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/2040
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("526 20〜40%", 454.2, 1894.5);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.1, 1903.2);
  ctx.lineTo(446.6, 1903.2);
  ctx.lineTo(446.6, 1897.9);
  ctx.lineTo(452.1, 1897.9);
  ctx.lineTo(452.1, 1903.2);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/020
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("541 0〜20%", 454.4, 1902.0);

  // 1/
  ctx.beginPath();
  ctx.moveTo(505.3, 1900.0);
  ctx.lineTo(501.3, 1897.3);
  ctx.lineTo(494.9, 1900.3);
  ctx.lineTo(499.9, 1910.7);
  ctx.lineTo(502.9, 1911.8);
  ctx.lineTo(506.8, 1909.5);
  ctx.lineTo(505.3, 1900.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(495.3, 1900.4);
  ctx.lineTo(499.8, 1902.7);
  ctx.lineTo(505.1, 1900.2);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(503.0, 1911.8);
  ctx.lineTo(499.8, 1902.7);
  ctx.stroke();

  // 1/888
  ctx.restore();
  ctx.font = "12.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("579 8-88", 442.3, 1846.2);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(599.7, 1911.7);
  ctx.lineTo(564.4, 1911.7);
  ctx.lineTo(564.4, 1900.3);
  ctx.lineTo(599.7, 1900.3);
  ctx.lineTo(599.7, 1911.7);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.stroke();

  // 1/2015
  ctx.globalAlpha = alpha * 1.00;
  ctx.font = "9.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("2015�", 566.7, 1909.4);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(602.1, 1996.3);
  ctx.lineTo(441.4, 1996.3);
  ctx.lineTo(441.4, 1933.3);
  ctx.lineTo(602.1, 1933.3);
  ctx.lineTo(602.1, 1996.3);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.1;
  ctx.stroke();

  // 1/
  ctx.globalAlpha = alpha * 1.00;
  ctx.beginPath();
  ctx.moveTo(539.4, 1945.1);
  ctx.lineTo(526.8, 1946.3);
  ctx.lineTo(517.1, 1989.6);
  ctx.lineTo(519.3, 1991.1);
  ctx.lineTo(524.8, 1991.3);
  ctx.lineTo(549.9, 1950.0);
  ctx.lineTo(539.4, 1945.1);
  ctx.closePath();
  ctx.fillStyle = "rgb(230, 30, 25)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(524.3, 1962.8);
  ctx.lineTo(519.4, 1958.8);
  ctx.lineTo(508.9, 1959.1);
  ctx.lineTo(508.8, 1991.1);
  ctx.lineTo(512.1, 1993.0);
  ctx.lineTo(517.3, 1992.0);
  ctx.lineTo(524.3, 1962.8);
  ctx.closePath();
  ctx.fillStyle = "rgb(244, 177, 188)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(505.6, 1972.1);

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(524.0, 1963.0);
  ctx.lineTo(513.8, 1963.8);
  ctx.lineTo(509.0, 1959.4);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(511.9, 1992.8);
  ctx.lineTo(513.8, 1963.8);
  ctx.stroke();

  // 1/
  ctx.restore();

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(549.8, 1950.3);
  ctx.lineTo(536.1, 1950.5);
  ctx.lineTo(526.9, 1946.6);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(519.6, 1991.3);
  ctx.lineTo(536.1, 1950.5);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(509.6, 1977.5);
  ctx.lineTo(505.6, 1972.1);
  ctx.lineTo(496.6, 1973.8);
  ctx.lineTo(499.1, 1990.3);
  ctx.lineTo(502.4, 1993.1);
  ctx.lineTo(508.1, 1991.5);
  ctx.lineTo(509.6, 1977.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(44, 178, 232)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(496.9, 1974.0);
  ctx.lineTo(501.8, 1979.0);
  ctx.lineTo(509.3, 1977.6);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(502.4, 1993.1);
  ctx.lineTo(501.8, 1979.0);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(551.8, 1950.3);
  ctx.lineTo(556.9, 1950.3);
  ctx.lineWidth = 0.5;
  ctx.lineCap = "round";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(554.5, 1950.3);
  ctx.lineTo(554.5, 1991.5);
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(556.8, 1954.6);
  ctx.lineTo(552.1, 1954.5);
  ctx.lineTo(554.5, 1950.0);
  ctx.lineTo(556.8, 1954.6);
  ctx.closePath();
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(552.3, 1987.0);
  ctx.lineTo(556.9, 1987.1);
  ctx.lineTo(554.5, 1991.6);
  ctx.lineTo(552.3, 1987.0);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�����F", 558.2, 1962.4);
  ctx.fillText("�@�@�@�", 558.2, 1968.1);

  // 1/
  ctx.beginPath();
  ctx.moveTo(446.4, 1955.8);
  ctx.lineTo(525.8, 1955.8);
  ctx.lineCap = "butt";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(526.3, 1956.0);
  ctx.bezierCurveTo(526.3, 1956.6, 525.9, 1957.1, 525.3, 1957.1);
  ctx.bezierCurveTo(524.7, 1957.1, 524.2, 1956.6, 524.2, 1956.0);
  ctx.bezierCurveTo(524.2, 1955.4, 524.7, 1954.9, 525.3, 1954.9);
  ctx.bezierCurveTo(525.9, 1954.9, 526.3, 1955.4, 526.3, 1956.0);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.6, 1963.5);
  ctx.lineTo(447.1, 1963.5);
  ctx.lineTo(447.1, 1958.1);
  ctx.lineTo(452.6, 1958.1);
  ctx.lineTo(452.6, 1963.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(230, 30, 25)";
  ctx.fill();

  // 1/50
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("50%�", 454.9, 1962.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.6, 1971.0);
  ctx.lineTo(447.1, 1971.0);
  ctx.lineTo(447.1, 1965.6);
  ctx.lineTo(452.6, 1965.6);
  ctx.lineTo(452.6, 1971.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(244, 177, 188)";
  ctx.fill();

  // 1/050
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("0�50%", 454.9, 1969.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.6, 1978.5);
  ctx.lineTo(447.1, 1978.5);
  ctx.lineTo(447.1, 1973.1);
  ctx.lineTo(452.6, 1973.1);
  ctx.lineTo(452.6, 1978.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(44, 178, 232)";
  ctx.fill();

  // 1/500
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("-50�0%", 454.9, 1977.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 1986.0);
  ctx.lineTo(447.3, 1986.0);
  ctx.lineTo(447.3, 1980.6);
  ctx.lineTo(452.8, 1980.6);
  ctx.lineTo(452.8, 1986.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(26, 67, 153)";
  ctx.fill();

  // 1/50
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("�-50%", 455.2, 1984.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(499.1, 1982.7);
  ctx.lineTo(495.1, 1980.0);
  ctx.lineTo(488.7, 1983.1);
  ctx.lineTo(493.7, 1993.4);
  ctx.lineTo(496.7, 1994.6);
  ctx.lineTo(500.6, 1992.3);
  ctx.lineTo(499.1, 1982.7);
  ctx.closePath();
  ctx.fillStyle = "rgb(26, 67, 153)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(489.1, 1983.2);
  ctx.lineTo(493.6, 1985.4);
  ctx.lineTo(498.9, 1983.0);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(496.7, 1994.6);
  ctx.lineTo(493.6, 1985.4);
  ctx.stroke();

  // 1/981
  ctx.restore();
  ctx.font = "12.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("9-81", 443.1, 1929.0);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(600.8, 1995.1);
  ctx.lineTo(565.6, 1995.1);
  ctx.lineTo(565.6, 1983.6);
  ctx.lineTo(600.8, 1983.6);
  ctx.lineTo(600.8, 1995.1);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.stroke();

  // 1/2014
  ctx.globalAlpha = alpha * 1.00;
  ctx.font = "9.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("2014�", 567.8, 1992.8);

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�F�F�S�Y�Ǝ", 447.1, 1952.6);

  // 1/
  ctx.font = "7.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�S�Y�Ǝ��Ə����Ƒ", 444.0, 1942.4);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(602.3, 2077.8);
  ctx.lineTo(441.7, 2077.8);
  ctx.lineTo(441.7, 2014.8);
  ctx.lineTo(602.3, 2014.8);
  ctx.lineTo(602.3, 2077.8);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.1;
  ctx.stroke();

  // 1/
  ctx.globalAlpha = alpha * 1.00;
  ctx.beginPath();
  ctx.moveTo(546.7, 2026.6);
  ctx.lineTo(534.0, 2027.8);
  ctx.lineTo(524.3, 2071.1);
  ctx.lineTo(526.5, 2072.6);
  ctx.lineTo(532.0, 2072.8);
  ctx.lineTo(557.2, 2031.5);
  ctx.lineTo(546.7, 2026.6);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(531.5, 2044.3);
  ctx.lineTo(526.7, 2040.3);
  ctx.lineTo(516.2, 2040.6);
  ctx.lineTo(516.0, 2072.6);
  ctx.lineTo(519.3, 2074.5);
  ctx.lineTo(524.5, 2073.5);
  ctx.lineTo(531.5, 2044.3);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(512.8, 2053.6);

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(531.2, 2044.5);
  ctx.lineTo(521.0, 2045.3);
  ctx.lineTo(516.3, 2040.9);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(519.2, 2074.3);
  ctx.lineTo(521.0, 2045.3);
  ctx.stroke();

  // 1/
  ctx.restore();

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(557.0, 2031.8);
  ctx.lineTo(543.3, 2032.0);
  ctx.lineTo(534.2, 2028.1);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(526.8, 2072.8);
  ctx.lineTo(543.3, 2032.0);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(516.8, 2059.0);
  ctx.lineTo(512.8, 2053.6);
  ctx.lineTo(503.8, 2055.3);
  ctx.lineTo(506.3, 2071.8);
  ctx.lineTo(509.7, 2074.6);
  ctx.lineTo(515.3, 2073.0);
  ctx.lineTo(516.8, 2059.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(504.2, 2055.5);
  ctx.lineTo(509.0, 2060.5);
  ctx.lineTo(516.5, 2059.1);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(509.7, 2074.6);
  ctx.lineTo(509.0, 2060.5);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(559.0, 2031.8);
  ctx.lineTo(564.1, 2031.8);
  ctx.lineWidth = 0.5;
  ctx.lineCap = "round";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(561.8, 2031.8);
  ctx.lineTo(561.8, 2073.0);
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(564.0, 2036.1);
  ctx.lineTo(559.4, 2036.0);
  ctx.lineTo(561.8, 2031.5);
  ctx.lineTo(564.0, 2036.1);
  ctx.closePath();
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(559.5, 2068.5);
  ctx.lineTo(564.1, 2068.6);
  ctx.lineTo(561.8, 2073.1);
  ctx.lineTo(559.5, 2068.5);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�����F", 564.5, 2043.9);
  ctx.fillText("�@�@�@�", 564.5, 2050.6);

  // 1/2
  ctx.fillText("�F�掟�Y�Ǝ���", 447.5, 2032.9);

  // 1/2
  ctx.font = "7.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�S�Y�Ǝ��掟�Y�Ǝ���", 444.3, 2023.6);

  // 1/
  ctx.beginPath();
  ctx.moveTo(446.7, 2035.3);
  ctx.lineTo(532.5, 2035.3);
  ctx.lineCap = "butt";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(533.6, 2035.5);
  ctx.bezierCurveTo(533.6, 2036.1, 533.1, 2036.6, 532.5, 2036.6);
  ctx.bezierCurveTo(531.9, 2036.6, 531.4, 2036.1, 531.4, 2035.5);
  ctx.bezierCurveTo(531.4, 2034.9, 531.9, 2034.4, 532.5, 2034.4);
  ctx.bezierCurveTo(533.1, 2034.4, 533.6, 2034.9, 533.6, 2035.5);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2045.0);
  ctx.lineTo(447.3, 2045.0);
  ctx.lineTo(447.3, 2039.6);
  ctx.lineTo(452.8, 2039.6);
  ctx.lineTo(452.8, 2045.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/60
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("60%�", 455.2, 2043.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2052.5);
  ctx.lineTo(447.3, 2052.5);
  ctx.lineTo(447.3, 2047.1);
  ctx.lineTo(452.8, 2047.1);
  ctx.lineTo(452.8, 2052.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/3060
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("30�60%", 455.2, 2051.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2060.0);
  ctx.lineTo(447.3, 2060.0);
  ctx.lineTo(447.3, 2054.6);
  ctx.lineTo(452.8, 2054.6);
  ctx.lineTo(452.8, 2060.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/1030
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("10�30%", 455.2, 2058.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(453.1, 2067.5);
  ctx.lineTo(447.6, 2067.5);
  ctx.lineTo(447.6, 2062.1);
  ctx.lineTo(453.1, 2062.1);
  ctx.lineTo(453.1, 2067.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/010
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("0�10%", 455.4, 2066.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(506.3, 2064.2);
  ctx.lineTo(502.4, 2061.5);
  ctx.lineTo(496.0, 2064.6);
  ctx.lineTo(500.9, 2074.9);
  ctx.lineTo(503.9, 2076.1);
  ctx.lineTo(507.9, 2073.8);
  ctx.lineTo(506.3, 2064.2);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(496.3, 2064.7);
  ctx.lineTo(500.8, 2066.9);
  ctx.lineTo(506.1, 2064.5);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(504.0, 2076.1);
  ctx.lineTo(500.8, 2066.9);
  ctx.stroke();

  // 1/986
  ctx.restore();
  ctx.font = "12.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("9-86", 443.3, 2010.5);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(600.7, 2076.0);
  ctx.lineTo(565.5, 2076.0);
  ctx.lineTo(565.5, 2064.5);
  ctx.lineTo(600.7, 2064.5);
  ctx.lineTo(600.7, 2076.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.stroke();

  // 1/2016
  ctx.globalAlpha = alpha * 1.00;
  ctx.font = "9.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("2016�", 567.7, 2073.7);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(602.3, 2158.8);
  ctx.lineTo(441.7, 2158.8);
  ctx.lineTo(441.7, 2095.8);
  ctx.lineTo(602.3, 2095.8);
  ctx.lineTo(602.3, 2158.8);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.1;
  ctx.stroke();

  // 1/
  ctx.globalAlpha = alpha * 1.00;
  ctx.beginPath();
  ctx.moveTo(546.7, 2107.6);
  ctx.lineTo(534.0, 2108.8);
  ctx.lineTo(524.3, 2152.1);
  ctx.lineTo(526.5, 2153.6);
  ctx.lineTo(532.0, 2153.8);
  ctx.lineTo(557.2, 2112.5);
  ctx.lineTo(546.7, 2107.6);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(531.5, 2125.3);
  ctx.lineTo(526.7, 2121.3);
  ctx.lineTo(516.2, 2121.6);
  ctx.lineTo(516.0, 2153.6);
  ctx.lineTo(519.3, 2155.5);
  ctx.lineTo(524.5, 2154.5);
  ctx.lineTo(531.5, 2125.3);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(512.8, 2134.6);

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(531.2, 2125.5);
  ctx.lineTo(521.0, 2126.3);
  ctx.lineTo(516.3, 2121.9);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(519.2, 2155.3);
  ctx.lineTo(521.0, 2126.3);
  ctx.stroke();

  // 1/
  ctx.restore();

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(557.0, 2112.8);
  ctx.lineTo(543.3, 2113.0);
  ctx.lineTo(534.2, 2109.1);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(526.8, 2153.8);
  ctx.lineTo(543.3, 2113.0);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(516.8, 2140.0);
  ctx.lineTo(512.8, 2134.6);
  ctx.lineTo(503.8, 2136.3);
  ctx.lineTo(506.3, 2152.8);
  ctx.lineTo(509.7, 2155.6);
  ctx.lineTo(515.3, 2154.0);
  ctx.lineTo(516.8, 2140.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(504.2, 2136.5);
  ctx.lineTo(509.0, 2141.5);
  ctx.lineTo(516.5, 2140.1);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(509.7, 2155.6);
  ctx.lineTo(509.0, 2141.5);
  ctx.stroke();

  // 1/
  ctx.restore();
  ctx.beginPath();
  ctx.moveTo(559.0, 2112.8);
  ctx.lineTo(564.1, 2112.8);
  ctx.lineWidth = 0.5;
  ctx.lineCap = "round";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(561.8, 2112.8);
  ctx.lineTo(561.8, 2154.0);
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(564.0, 2117.1);
  ctx.lineTo(559.4, 2117.0);
  ctx.lineTo(561.8, 2112.5);
  ctx.lineTo(564.0, 2117.1);
  ctx.closePath();
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(559.5, 2149.5);
  ctx.lineTo(564.1, 2149.6);
  ctx.lineTo(561.8, 2154.1);
  ctx.lineTo(559.5, 2149.5);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�����F", 564.5, 2124.9);
  ctx.fillText("�@�@�@�", 564.5, 2131.6);

  // 1/3
  ctx.fillText("�F�掟�Y�Ǝ���", 447.5, 2113.9);

  // 1/3
  ctx.font = "7.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillText("�S�Y�Ǝ��掟�Y�Ǝ���", 444.3, 2104.6);

  // 1/
  ctx.beginPath();
  ctx.moveTo(446.7, 2116.3);
  ctx.lineTo(532.5, 2116.3);
  ctx.lineCap = "butt";
  ctx.stroke();

  // 1/
  ctx.beginPath();
  ctx.moveTo(533.6, 2116.5);
  ctx.bezierCurveTo(533.6, 2117.1, 533.1, 2117.6, 532.5, 2117.6);
  ctx.bezierCurveTo(531.9, 2117.6, 531.4, 2117.1, 531.4, 2116.5);
  ctx.bezierCurveTo(531.4, 2115.9, 531.9, 2115.4, 532.5, 2115.4);
  ctx.bezierCurveTo(533.1, 2115.4, 533.6, 2115.9, 533.6, 2116.5);
  ctx.closePath();
  ctx.fill();

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2126.0);
  ctx.lineTo(447.3, 2126.0);
  ctx.lineTo(447.3, 2120.6);
  ctx.lineTo(452.8, 2120.6);
  ctx.lineTo(452.8, 2126.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(214, 32, 24)";
  ctx.fill();

  // 1/80
  ctx.font = "5.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("80%�", 455.2, 2124.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2133.5);
  ctx.lineTo(447.3, 2133.5);
  ctx.lineTo(447.3, 2128.1);
  ctx.lineTo(452.8, 2128.1);
  ctx.lineTo(452.8, 2133.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(229, 121, 22)";
  ctx.fill();

  // 1/4080
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("40�80%", 455.2, 2132.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(452.8, 2141.0);
  ctx.lineTo(447.3, 2141.0);
  ctx.lineTo(447.3, 2135.6);
  ctx.lineTo(452.8, 2135.6);
  ctx.lineTo(452.8, 2141.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(217, 193, 25)";
  ctx.fill();

  // 1/2040
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("20�40%", 455.2, 2139.8);

  // 1/
  ctx.beginPath();
  ctx.moveTo(453.1, 2148.5);
  ctx.lineTo(447.6, 2148.5);
  ctx.lineTo(447.6, 2143.1);
  ctx.lineTo(453.1, 2143.1);
  ctx.lineTo(453.1, 2148.5);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/020
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("0�20%", 455.4, 2147.3);

  // 1/
  ctx.beginPath();
  ctx.moveTo(506.3, 2145.2);
  ctx.lineTo(502.4, 2142.5);
  ctx.lineTo(496.0, 2145.6);
  ctx.lineTo(500.9, 2155.9);
  ctx.lineTo(503.9, 2157.1);
  ctx.lineTo(507.9, 2154.8);
  ctx.lineTo(506.3, 2145.2);
  ctx.closePath();
  ctx.fillStyle = "rgb(98, 116, 132)";
  ctx.fill();

  // 1/

  // 1//
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(496.3, 2145.7);
  ctx.lineTo(500.8, 2147.9);
  ctx.lineTo(506.1, 2145.5);
  ctx.lineWidth = 0.8;
  ctx.strokeStyle = "rgb(254, 254, 254)";
  ctx.lineCap = "round";
  ctx.stroke();

  // 1//
  ctx.beginPath();
  ctx.moveTo(504.0, 2157.1);
  ctx.lineTo(500.8, 2147.9);
  ctx.stroke();

  // 1/987
  ctx.restore();
  ctx.font = "12.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillText("9-87", 443.3, 2091.5);

  // 1/
  ctx.globalAlpha = alpha * 0.75;
  ctx.beginPath();
  ctx.moveTo(600.7, 2157.0);
  ctx.lineTo(565.5, 2157.0);
  ctx.lineTo(565.5, 2145.5);
  ctx.lineTo(600.7, 2145.5);
  ctx.lineTo(600.7, 2157.0);
  ctx.closePath();
  ctx.fillStyle = "rgb(254, 254, 254)";
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.stroke();

  // 1/2016
  // ctx.globalAlpha = alpha * 1.00;
  // ctx.font = "9.0px 'KozGoPro-Regular-90ms-RKSJ-H'";
  // ctx.fillStyle = "rgb(0, 0, 0)";
  // ctx.fillText("2016�", 567.7, 2154.7);
  // ctx.restore();
}

export function Hanrei_canvas_list({metadata, children}) {
  const [png, setPng] = useState([])
  const [elm, setElm] = useState([])
  const [imgcont, setImgcont] = useState("")
  const [csswidth, setCsswidth] = useState(330)
  const [cssheight, setCssheight] = useState(240)

  useLayoutEffect(()=>{
    devicePixelRatio = window.devicePixelRatio
  })

  useEffect(() => {
    for (let i = 0; i < metadata.length; i++) {

    canvasElems[i] = document.createElement('canvas')
    ctxs[i] = canvasElems[i].getContext('2d');
    canvasElems[i].width = width * devicePixelRatio
    canvasElems[i].height = height * devicePixelRatio
    ctxs[i].scale(2,2)
    setCsswidth(canvasElems[i].width / devicePixelRatio)
    setCssheight(canvasElems[i].height / devicePixelRatio)
    draw(ctxs[i],metadata[i],metadata[i].year5);
    pngs[i] = canvasElems[i].toDataURL('image/png');

    elms[i] = canvasElems[i];
    imgcontent[i] = `<img alt="icon" src={${pngs[i]}} width={330} height={240}/>`
    }

    setPng(pngs)
    setImgcont(imgcontent)
    setElm(elms)

    return () =>{
    }
  }, [])

  function multi_download() {
    var date = new Date();
    var today = date.getFullYear()+"_"+(date.getMonth()+1)+"_"+date.getDate()
    
    var zip = new JSZip();
    // zip.file('a.pdf', content, {base64: true});
    zip.file("readme.txt", "凡例一括変換20220315版\n");
    var img = zip.folder("hanrei_3rdmesh");

    for (let i = 0; i < png.length; i++) {
      var idx = png[i].indexOf('base64,') + 'base64,'.length; 
      var content = png[i].substring(idx);
      img.file(metadata[i].no+".png", content, {base64: true});
    }
    
    // var idx = png[1].indexOf('base64,') + 'base64,'.length; 
    // var content = png[1].substring(idx);
    // img.file("smile2.png", content, {base64: true});

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        // see FileSaver.js
        saveAs(content, "凡例_"+today+".zip");
    });
    // download(png[0],metadata[0].no+"_"+metadata[0].year5+".png");
    // download(png[1],metadata[1].no+"_"+metadata[0].year5+".png");
    // download(png[2],metadata[2].no+"_"+metadata[0].year5+".png");
  }

  // function download(png,name) {
  //   // chrome,firefox ただし、毎回確認ダイアログが出るかどうかはブラウザの設定による。
  //   var a = document.createElement('a');
  //   a.download = name;
  //   a.href = png;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }




  return (
  <>
    {png && (
      <>
      <button onClick={multi_download} >{children}</button>
      <div className='flex w-full'>
          {png && png.map((r)=><img alt="icon" src={r} width="165" height="120" />)}
      </div>

        {/* <a href={png[0]} download={metadata[0].no+".png"}>
          <img alt="icon" src={png[0]} width={csswidth} height={cssheight}/>
        </a>

        <a href={png[1]} download={metadata[1].no+".png"}>
          <img alt="icon" src={png[1]} width={csswidth} height={cssheight}/>
        </a>
        <a href={png[2]} download={metadata[2].no+".png"}>
          <img alt="icon" src={png[2]} width={csswidth} height={cssheight}/>
        </a>
        <a href={png[3]} download={metadata[3].no+".png"}>
          <img alt="icon" src={png[3]} width={csswidth} height={cssheight}/>
        </a> */}
      </>
    )}
  </>
  )
}