import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import "./App.css";
import {
  Map0set,
  Map1set,
  Map2set,
  Syukurendou,
  Rotate0set,
  Rotate1set,
  Rotate2set,
  Fullviewerset,
  // Rendou,
  // Syukuren,
  // Map0oldnewset,
  // Syukurendourotate,
  // Rotation,
  // Link2map,
  // Link2top,
  // Link2about,
  // Link2users,
} from "./buttons";

import {
  Static2Map0set,
  Static2Map0aset,
  Static2Map1set,
  Static2Map2set,
} from "./kashikabuttons";
import { Year0set, Year1set, Year2set } from "./kashikabuttons";
import { MultiSetting } from "./kashikabuttons";

import { Area0set, Area1set, Area2set } from "./kashikacityset";
import {
  photo,
  palemap,
  stdmap,
  blankmap,
  engmap,
  oldphoto,
  oldmap,
} from "./cesiumsetting";

import {
  FullviewerSet,
  ModeSet,
  Area1Set,
  Area2Set,
  Theme1Set,
  Theme2Set,
} from "./App";
import { Static2Map1Set, Static2Map2Set } from "./App";
import { Zoomlevel1Set } from "./App";
import { Zoomhanrei1Set, Zoomhanrei2Set } from "./App";
import { Year1Set, Year2Set } from "./App";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { StartmodalSet } from "./App";
// import { Area1Set, Area2Set } from "./App";
// import { Theme1Set, Theme2Set } from "./App";

import { Modalcityset } from "./citysetmodal";
import data_meta from "./regtra_meta_data_20220407";
import Hanrei from "./hanrei";
import Hanreikokudo from "./hanreikokudo";
import { Hanreikakudai } from "./hanreikakudai";
import { Modalcity0set } from "./citysetmodal";
import { Modalratio0set } from "./ratiosetmodal";
import { Modalstart } from "./startmodal";

import Csv2kml from "./csv2kml"
// import {Csv2kmltest} from "./csv2kmltest"



let filecheck = 0;

export const Map = () => {
  const { fullviewerset, setFullviewerSet } = useContext(FullviewerSet);
  const { modeset, setModeSet } = useContext(ModeSet);
  const { startmodalset, setStartmodalSet } = useContext(StartmodalSet);
  const [showcityset, setShowcityset] = useState(false);
  const [showratioset, setShowratioset] = useState(false);
  const [filecheckset, setFilecheckset] = useState(0);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { area1set, setArea1Set } = useContext(Area1Set);
  const { area2set, setArea2Set } = useContext(Area2Set);
  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);
  const { theme1set, setTheme1Set } = useContext(Theme1Set);
  const { zoomlevel1set, setZoomlevel1Set } = useContext(Zoomlevel1Set);
  const { zoomhanrei1set, setZoomhanrei1Set } = useContext(Zoomhanrei1Set);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);

  const params = useParams();
  const metatmp = data_meta.filter((r) => {
    return r.no == params.no;
  });
  const meta = metatmp[0];
  let kokudo_onoff = meta.kokudo_onoff;

  useLayoutEffect(() => {
    if (params.mode == 1 || params.mode == 4 || params.mode == 5) {
      setFullviewerSet(true);
    } else {
      setFullviewerSet(false);
    }
    if (params.mode == 3) {
      setRendouSet(false);
      setSyukurenSet(true);
    }
    if (params.mode == 5) {
      setArea1Set("日本全国");
    }
    if (params.mode) {
      var mode = params.mode;
      setModeSet(Number(mode));
    }
    // if (params.mode == 1 || params.mode == 2 || params.mode == 3) {
    //   if(params.area == "日本全国"){
    //     setArea1Set(area2set)
    //     setArea2Set(area2set)
    //   }
    // }
    if (params.mode == 6) {
      setRendouSet(true);
      setSyukurenSet(false);
      setArea1Set(meta.tenkei_city);
      setArea2Set(meta.tenkei_city);
      setYear1Set(meta.year1 || meta.year5);
      setYear2Set(meta.year5);
    }
    if (params.mode == 7) {
      setRendouSet(false);
      setSyukurenSet(true);
      setArea1Set(meta.tenkei_city);
      setArea2Set(meta.tenkei_city_2);
    }
  }, [params.mode]);


  // ファイルなしアラート //////////////

  const metadata = static2map1set;
  // const metadatatmp = JSON.stringify(static2map1set);
  // const metadata = JSON.parse(metadatatmp);
  const year = year1set || metadata.year5;

  let area = "福岡県糸島市";
  if (area1set) {
    area = area1set.normalize("NFD");
    // console.log(area);
  }

  // let csv_name;
  let csv_name = "人口総数×人口総数";
    if (metadata.csv_filename) {
      csv_name = metadata.csv_filename.normalize("NFD");
    }
  
  useEffect(() => {
        function csvcheck(no, year, area, csv_name){
          fetch( "/csv/" +
          no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          year +
          ".csv"
          , {})
            .then((r) => {
              if (!r.ok) {
                throw new Error();
              }
              return r.text();
            })
            .then(
              (r) => {
                // console.log(r);
                if(r.startsWith("<")){
                  // console.log("なし");
                  // console.log(filecheck);
                  filecheck = filecheck + 1
                  setFilecheckset(filecheckset+2)
                } else {
                  // console.log("あり");
                  filecheck = filecheck + 100
                  setFilecheckset(filecheckset+100)
                }
                return r
              }
            )
          }
          csvcheck(metadata.no, metadata.year5, area, csv_name);

          
          
        }, [
          // filecheckset
          static2map1set, 
          area1set, 
          modeset,
        ]);



        // ファイルなしアラート 20220406動作します//////////////
        // useEffect(() => {
        //   setTimeout(() => {
        //     if(filecheck>1){
        //       if(filecheck<50){
        //         window.alert("この地域にはデータがありません。市町村名を確認してください。")
        //       }
        //     }
        //   }, 3000)
        // }, [])

        
        
        





  // useLayoutEffect(() => {
  //   if (params.mode == 2) {
  //     setYear1Set(meta.year1);
  //   }
  // }, [params.mode, area1set]);

  // useLayoutEffect(() => {
  //   if (area1set) {
  //     if (params.area) {
  //       setArea1Set(params.area);
  //     }
  //   }
  // }, [area1set]);

  useLayoutEffect(() => {
    // if (params.no != static2map1set.no) {
      const metatmpt = data_meta.filter((r) => {
        return r.no == params.no;
      });
      setStatic2Map1Set(metatmpt[0]);
    // }
  }, [static2map1set]);

  useLayoutEffect(() => {
    const metatmpt = data_meta.filter((r) => {
      return r.no == params.no;
    });
    setStatic2Map2Set(metatmpt[0]);
  }, [static2map2set]);

  // タイトルモジュール
  let title = (
    <>
      <div className="m-1 mr-40 md:mr-48 pointer-events-auto">
        <Link to={"/select"}>
          <div className="h-10 md:h-12 p-1 md:p-3 flex bg-tani_green  rounded text-xs sm:text-sm md:text-xl font-extrabold">
            <div className="m-auto">{meta["no"]}</div>
            {/* <div className="m-auto">{meta["title"]}</div>
            <div className="m-auto">
              {meta["title_height_name"]}　×　
              {meta["title_color_name"]}
            </div> */}
          </div>
        </Link>
      </div>
    </>
  );

  // タブモジュール
  let tabs = (
    <>
      <main className="flex flex-wrap mt-2">
        <div className="w-[20%] pointer-events-auto cursor-pointer  font-extrabold">
          <MultiSetting
            modeset={2}
            fullviewerset={0}
            rendouset={true}
            syukurenset={false}
            map1set={stdmap}
            map2set={oldmap}
            static2map1set={meta}
            static2map2set={meta}
            rotate1set={false}
            rotate2set={false}
            area1set={area1set}
            area2set={area2set}
            year1set={meta.year5}
            year2set={meta.year4}
            to={"/map"}
            no={"人口"}
          >
            <div className="bg-gradient-to-r from-rose-400 via-rose-500 to-rose-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-rose-300 dark:focus:ring-rose-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
              <span className="hidden md:contents"></span>人口
              <span className="hidden md:contents"></span>
            </div>
          </MultiSetting>
        </div>

        <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
          <MultiSetting
            modeset={2}
            fullviewerset={0}
            rendouset={true}
            syukurenset={false}
            map1set={stdmap}
            map2set={oldmap}
            static2map1set={meta}
            static2map2set={meta}
            rotate1set={false}
            rotate2set={false}
            area1set={
              (area1set != "日本全国" && area1set) ||
              (area2set != "日本全国" && area2set) ||
              "福岡県糸島市"
            }
            area2set={
              (area1set != "日本全国" && area1set) ||
              (area2set != "日本全国" && area2set) ||
              "福岡県糸島市"
            }
            year1set={meta.year5}
            year2set={meta.year4}
            to={"/map"}
            no={"産業"}
          >
            <div className="bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-orange-300 dark:focus:ring-orange-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
              <span className="hidden md:contents"></span>産業
              <span className="hidden md:contents"></span>
            </div>
          </MultiSetting>
        </div>

        <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
          <MultiSetting
            modeset={2}
            fullviewerset={0}
            rendouset={true}
            syukurenset={false}
            map1set={stdmap}
            map2set={oldmap}
            static2map1set={meta}
            static2map2set={meta}
            rotate1set={false}
            rotate2set={false}
            area1set={
              (area1set != "日本全国" && area1set) ||
              (area2set != "日本全国" && area2set) ||
              "福岡県糸島市"
            }
            area2set={
              (area2set != "日本全国" && area2set) ||
              (area1set != "日本全国" && area1set) ||
              "福岡県糸島市"
            }
            year1set={meta.year5}
            year2set={meta.year4}
            to={"/map"}
            no={"商業"}
          >
            <div className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
              <span className="hidden md:contents"></span>商業
              <span className="hidden md:contents"></span>
            </div>
          </MultiSetting>
        </div>

        {!meta.tenkei_mode && (
          <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
            <MultiSetting
              modeset={2}
              fullviewerset={0}
              rendouset={true}
              syukurenset={false}
              map1set={stdmap}
              map2set={oldmap}
              static2map1set={meta}
              static2map2set={meta}
              rotate1set={false}
              rotate2set={false}
              area1set={area1set}
              area2set={area2set}
              year1set={meta.year5}
              year2set={meta.year4}
              to={"/map"}
              no={"昼間人口"}
            >
              <div className="bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
                昼間人口<span className="hidden md:contents"></span>
              </div>
            </MultiSetting>
          </div>
        )}

        {meta.tenkei_mode == 6 && (
          <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
            <MultiSetting
              modeset={6}
              fullviewerset={0}
              rendouset={true}
              syukurenset={false}
              map1set={photo}
              map2set={photo}
              static2map1set={meta}
              static2map2set={meta}
              rotate1set={false}
              rotate2set={false}
              area1set={meta.tenkei_city}
              area2set={area2set}
              to={"/map"}
              no={meta.no}
            >
              <div className="bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
                事例<span className="hidden md:contents">とその解説</span>
              </div>
            </MultiSetting>
          </div>
        )}
        {meta.tenkei_mode == 7 && (
          <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
            <MultiSetting
              modeset={7}
              fullviewerset={1}
              rendouset={false}
              syukurenset={true}
              map1set={photo}
              map2set={photo}
              static2map1set={meta}
              static2map2set={meta}
              rotate1set={false}
              rotate2set={false}
              area1set={meta.tenkei_city}
              area2set={meta.tenkei_city_2}
              to={"/map"}
              no={meta.no}
            >
              <div className="bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
                事例<span className="hidden md:contents">とその解説</span>
              </div>
            </MultiSetting>
          </div>
        )}

          <div className="w-[20%] pointer-events-auto cursor-pointer text-black font-extrabold">
            <MultiSetting
              modeset={2}
              fullviewerset={0}
              rendouset={true}
              syukurenset={false}
              map1set={stdmap}
              map2set={oldmap}
              static2map1set={meta}
              static2map2set={meta}
              rotate1set={false}
              rotate2set={false}
              area1set={area1set}
              area2set={area2set}
              year1set={meta.year5}
              year2set={meta.year4}
              to={"/map"}
              no={"高齢人口"}
            >
              <div className="bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800 text-xs md:text-sm lg:text-base px-4 py-2 h-full text-center rounded-tl-2xl rounded-tr-2xl">
                高齢人口<span className="hidden md:contents"></span>
              </div>
            </MultiSetting>
          </div>
      </main>
    </>
  );

  // 高度な機能モジュール
  let highfunc = (
    <>
      <details>
        <summary className="cursor-pointer">設定</summary>
        <div>
          <button
            onClick={() => setShowratioset(true)}
            className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-xl text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw] m-1 z-30"
          >
            <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              高さ設定
            </span>
          </button>

          {/* <button onClick={()=>setShowratioset(true)} className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-xl text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw] m-1">
        <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
          KML
        </span>
      </button> */}

          <Link to="/">
            <button
              onClick={() => localStorage.clear()}
              className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-xs lg:text-xs text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 m-1"
            >
              <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto ">
                リセットして戻る
              </span>
            </button>
          </Link>



























{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}



  {/* <Csv2kml
    meta = {meta}
    area = {area1set}
    >
    KML
  </Csv2kml> */}




{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}
{/* v2.2対応予定、KML化ボタン */}









        </div>
      </details>
    </>
  );

  // 凡例（３次）モジュール
  let hanrei_module_3 = (
    <>
      <details open className="">
        <summary className="cursor-pointer">凡例</summary>
        {!zoomhanrei1set && (
          <div className="overflow-auto max-h-[25vh] bg-gradient-to-r from-white/70 via-white/80 to-white/90">
            <Hanrei metadata={meta} csv={"dataのURL"} />
          </div>
        )}
        {zoomhanrei1set && (
          <div className="overflow-auto max-h-[50vh]">
            <Hanreikakudai metadata={meta} csv={"dataのURL"} />
          </div>
        )}
      </details>
    </>
  );

  // 凡例（２次）モジュール
  let hanrei_module_2 = (
    <>
      <details open className="">
        <summary className="cursor-pointer">凡例</summary>
        {!zoomhanrei1set && (
          <div className="overflow-auto max-h-[25vh] bg-gradient-to-r from-white/70 via-white/80 to-white/90">
            <Hanreikokudo metadata={meta} csv={"dataのURL"} />
          </div>
        )}
        {zoomhanrei1set && (
          <div className="overflow-auto max-h-[50vh]">
            <Hanreikakudai metadata={meta} csv={"dataのURL"} />
          </div>
        )}
      </details>
    </>
  );

  let buttons = <></>;

  if (params.mode == 1) {
    // 地域を選ぶ
    buttons = (
      <>
        {/* タイトルとタブ */}
        <div className="w-full absolute z-30 pointer-events-none bg-tani_blue h-1/6">
          {title}
          {tabs}
        </div>

        {/* 凡例 */}
        <div className="absolute z-30 w-full my-24 pointer-events-none">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-30">
              <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_3}

                  {zoomlevel1set == 1 && (
                    <>
                      {/* <details open className="">
                        <summary className="cursor-pointer">TRY</summary>
                        <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
                          {meta.try}
                        </div>
                      </details> */}
                      <div className="">{highfunc}</div>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
                <Modalstart show={!startmodalset} setShow={setStartmodalSet} />
              </div>
            </div>

            <div className="pointer-events-none">
              {!showcityset && !showratioset && (
                <div>
                  <div onClick={() => setShowcityset(212)} className="mx-2">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800 w-full mb-1 z-0">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto w-full">
                        {params.area}
                      </span>
                    </button>
                  </div>

                  <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year0set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Map1set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Rotate1set />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );

    // 昔と比べる
    // 昔と比べる
  } else if (params.mode == 2) {
    // 昔と比べる
    buttons = (
      <>
        {/* 凡例 */}
        <div className="z-30 w-full pointer-events-none ">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-30">
              <div className="text-sm md:text-base 
              bg-tani_lightgreen hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_3}

                  {zoomlevel1set == 1 && (
                    <>
                      {/* <details open className="">
                        <summary className="cursor-pointer">TRY</summary>
                        <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
                          {meta.try}
                        </div>
                      </details> */}
                      <div className="">{highfunc}</div>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
                <Modalstart show={!startmodalset} setShow={setStartmodalSet} />
              </div>
            </div>
          </div>
        </div>



        {/* 左側パネル */}
        <div className="absolute z-40 w-full my-24 pointer-events-none">
          <div className="text-left my-2">
            {/* <div className='w-[15vw] sm:w-[10vw] max-h-[10vh]'> */}
            {/* <div onClick={() => setShowcityset(212)} className="mb-1">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 mx-2">
                <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto">
                  {params.area}
                </span>
              </button>
            </div> */}

            {/* <div className="text-sm md:text-base pointer-events-auto mx-2">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                  <Year2set />
                </span>
              </button>
            </div> */}

            <div className="text-sm md:text-base mx-2">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 pointer-events-auto">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  {/* <Year1set /> */}
                  {/* <Map0set /> */}
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base mx-2">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  {/* <Rotate0set /> */}
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
              <Modalcity0set show={showcityset} setShow={setShowcityset} />
              <Modalratio0set show={showratioset} setShow={setShowratioset} />
            </div>
          </div>
        </div>

        {/* 右側パネル */}
        {/* <div className="absolute z-40 w-1/2 left-1/2 my-24 pointer-events-none">
          <div className="text-left my-2">
            <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
              <Modalcity0set show={showcityset} setShow={setShowcityset} />
              <Modalratio0set show={showratioset} setShow={setShowratioset} />
            </div>
          </div>
        </div> */}

        {/* 
{!showcityset && !showratioset && 
    <div className="absolute z-30 w-full my-28 pointer-events-none">

      <div>
        <div onClick={()=>setShowcityset(12)} className="">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto">
              {params.area}
            </span>
          </button>
        </div>
      </div>

      <div className="text-sm md:text-base mx-2">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 pointer-events-auto">
          <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
            <Map0oldnewset />
          </span>
        </button>
      </div>
      <div className="text-sm md:text-base mx-2">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
          <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
            <Rotate0set />
          </span>
        </button>
      </div>
    </div>
  } */}

        {/* <div className="absolute z-30 pointer-events-none right-0">
      <main className='w-full flex flex-wrap my-28'>
        <div className=''>
          <div className="text-sm md:text-base pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Year2set />
              </span>
            </button>
          </div>
        </div>
      </main>
    </div> */}
      </>
    );
  } else if (params.mode == 3) {
    // 他と比べる

    buttons = (
      <>
        {/* タイトルバーとタブ */}
        <div className="w-full absolute z-50 pointer-events-none">
          {/* タイトルバー */}
          {title}
          {/* <div className="m-1 mr-10 md:mr-12 pointer-events-auto">
        <Link to={"/select"}>
          <div className="h-12 p-3 flex bg-gradient-to-r from-slate-50 to-slate-100 rounded text-xs sm:text-sm md:text-xl font-extrabold">
            <div className="m-auto">
                {meta["no"]}
            </div>
            <div className="m-auto">
              {meta["title"]}
            </div>
            <div className="m-auto">
              {meta["title_height_name"]}　×　
              {meta["title_color_name"]}
            </div>
            <div className="">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 md:h-8 w-6 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
            </div>
          </div>
        </Link>
      </div> */}
          {/* タブ */}
          {tabs}
          <div className="w-full h-1 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-800"></div>
        </div>

        {/* 左側パネル */}
        <div className="absolute z-40 w-full my-24 pointer-events-none">
          <div className="w-[50vw] sm:w-[50vw] max-h-[50vh] my-2">
            <div
              onClick={() => setShowcityset(2)}
              className="text-left mx-2 mb-1"
            >
              <button className="relative inline-flex p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto">
                  {area2set}
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                  <Year0set />
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base mx-2 text-left">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 pointer-events-auto">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  <Map0set />
                </span>
              </button>
            </div>
            <div className="text-sm md:text-base mx-2 text-left">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  <Rotate0set />
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
              {/* <>
          <details className="">
            <summary className="cursor-pointer">
              凡例
            </summary>
            <div className="overflow-auto max-h-[25vh]">

  { !zoomhanrei1set &&
              <Hanrei
              metadata={meta}
              csv={"dataのURL"}
              />
  }
  { zoomhanrei1set &&
              <Hanreikakudai
              metadata={meta}
              csv={"dataのURL"}
              />
  }

            </div>
          </details>
{ zoomlevel1set == 1 &&
        <>
          <details className="">
             <summary className="cursor-pointer">
              TRY
            </summary>
            <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
              {meta.try}
            </div>
          </details>
          <details className="">
             <summary className="cursor-pointer">
              設定
            </summary>
            <div onClick={()=>setShowratioset(true)} className="cursor-pointer">
              高さ設定
            </div>
          </details>
        </>
}
      </> */}

              <Modalcity0set show={showcityset} setShow={setShowcityset} />
              <Modalratio0set show={showratioset} setShow={setShowratioset} />
            </div>
          </div>
        </div>

        {/* 中央パネル */}

        <div className="absolute z-40 w-1/2 left-1/2 my-24 pointer-events-none">
          <div className="text-left my-2">
            <div onClick={() => setShowcityset(1)} className="text-left">
              <button className="relative inline-flex p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto">
                  {area1set}
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* 
{!showcityset && !showratioset && 
    <div className="absolute z-30 w-full my-28 pointer-events-none">
      <div>
        <div className="text-sm md:text-base pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Year0set />
            </span>
          </button>
        </div>

      </div>

      <div className="text-sm md:text-base mx-2">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 pointer-events-auto">
          <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
            <Map0set />
          </span>
        </button>
      </div>
      <div className="text-sm md:text-base mx-2">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
          <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
            <Rotate0set />
          </span>
        </button>
      </div>
    </div>
  } */}

        {/* <div className="absolute z-30 pointer-events-none right-0">
      <main className='w-full flex flex-wrap my-28'>
        <div className=''>
          <div onClick={()=>setShowcityset(2)} className="text-left">
            <button className="relative inline-flex p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto">
                {area2set}
              </span>
            </button>
          </div>
        </div>
      </main>
    </div> */}

        {!fullviewerset && (
          <>
            <div className="absolute z-30 w-full my-40 pointer-events-none">
              {/* <div className='w-1/4 mx-auto'>
        <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto">
          <div className="m-1 rounded cursor-pointer">
            <Area0set />
          </div>
        </div>
      </div> */}

              <div className="relative text-sm md:text-base bg-black/30 rounded-lg w-16 mx-auto pointer-events-auto">
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Fullviewerset />
        </div>
        <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Map0set />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Static2Map0set />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer text-center">
          <Syukurendou />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Rotate0set />
        </div> */}
              </div>
            </div>

            <div className="absolute z-30 pointer-events-none right-0">
              {/* <main className='w-full flex flex-wrap my-40'>
        <div className=''>
          <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
            <div className="m-1 rounded cursor-pointer p-2 text-white">
              現在（2015頃）
            </div>
          </div>

        </div>
      </main> */}
            </div>
          </>
        )}
      </>
    );
  } else if (params.mode == 4) {
    // 典型例とその解説
    buttons = (
      <>
        {/* タイトルとタブ */}
        <div className="w-full absolute z-30 pointer-events-none">
          {title}
          {tabs}
          <div className="w-full h-1 bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800"></div>
        </div>
        {/* 凡例 */}
        <div className="absolute z-30 w-full my-24 pointer-events-none">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-40">
              <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_3}
                  {zoomlevel1set == 1 && (
                    <>
                      <details open className="">
                        <summary className="cursor-pointer">解説</summary>
                        <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
                          {meta.kaisetsu}
                        </div>
                      </details>
                      <div className="z-30">{highfunc}</div>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
              </div>
            </div>

            <div className="pointer-events-none z-0">
              {!showcityset && !showratioset && (
                <div>
                  <div className="mx-1">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800 w-full mb-1">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md w-full">
                        {params.area}
                      </span>
                    </button>
                  </div>

                  <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year0set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Map1set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Rotate1set />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* 
{!showcityset && !showratioset && 
    <div className="absolute z-30 w-full my-28 pointer-events-none">
      <div>
        <div className="">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md">
              {params.area}
            </span>
          </button>
        </div>
      </div>
    </div>
  } */}

          {/*   
    <div className="absolute z-30 pointer-events-none right-0">
      <main className='w-full flex flex-wrap my-28'>
        <div className=''>
          <div className="text-sm md:text-base pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Year0set />
              </span>
            </button>
          </div>
          <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Map1set />
              </span>
            </button>
          </div>
          <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Rotate1set />
              </span>
            </button>
          </div>
        </div>
      </main>
    </div> */}
        </div>
      </>
    );
  } else if (params.mode == 5) {
    // 国土全体で見る
    buttons = (
      <>
        {/* タイトルとタブ */}
        <div className="w-full absolute z-30 pointer-events-none">
          {title}
          {tabs}
          <div className="w-full h-1 bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800"></div>
        </div>

        {/* 凡例 */}
        <div className="absolute z-30 w-full my-24 pointer-events-none">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-40">
              <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_2}
                  {zoomlevel1set == 1 && (
                    <>
                      {/* <details open className="">
             <summary className="cursor-pointer">
              解説
            </summary>
            <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
              {meta.kaisetsu}
            </div>
          </details> */}
                      <div className="z-30">{highfunc}</div>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
              </div>
            </div>
            <div className="pointer-events-none z-0">
              {!showcityset && !showratioset && (
                <div>
                  <div className="mx-1">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800 w-full mb-1">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md w-full">
                        {params.area}
                      </span>
                    </button>
                  </div>

                  <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year0set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Map1set />
                      </span>
                    </button>
                    {/* </div>
          <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Rotate1set />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* {!showcityset && !showratioset && 
    <div className="absolute z-30 w-full my-28 pointer-events-none">
      <div>
        <div className="">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md">
              {params.area}
            </span>
          </button>
        </div>
      </div>
    </div>
  } */}

          {/* <div className="absolute z-30 pointer-events-none right-0">
      <main className='w-full flex flex-wrap my-28'>
        <div className=''>
          <div className="text-sm md:text-base pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Year0set />
              </span>
            </button>
          </div>
          <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Map1set />
              </span>
            </button>
          </div>
          <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
              <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                <Rotate1set />
              </span>
            </button>
          </div>
        </div>
      </main>
    </div> */}
        </div>
      </>
    );
  } else if (params.mode == 6) {
    // 典型例とその解説
    buttons = (
      <>
        {/* タイトルとタブ */}
        <div className="w-full absolute z-30 pointer-events-none">
          {title}
          {tabs}
          <div className="w-full h-1 bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800"></div>
        </div>
        {/* 凡例 */}
        <div className="absolute z-30 w-full my-24 pointer-events-none">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-40">
              <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_3}
                  {zoomlevel1set == 1 && (
                    <>
                      <details open className="">
                        <summary className="cursor-pointer">解説</summary>
                        <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
                          {meta.kaisetsu}
                        </div>
                      </details>
                      <details className="">
                        <summary className="cursor-pointer">設定</summary>
                        <div
                          onClick={() => setShowratioset(true)}
                          className="cursor-pointer"
                        >
                          高さ設定
                        </div>
                      </details>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
              </div>
            </div>

            <div className="pointer-events-none z-0">
              {!showcityset && !showratioset && (
                <div>
                  <div className="mx-1">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800 w-full mb-1">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md w-full">
                        {params.area}
                      </span>
                    </button>
                  </div>

                  <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year2set />
                      </span>
                    </button>
                    {/* </div>
        <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Map1set />
                      </span>
                    </button>
                    {/* </div>
        <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Rotate1set />
                      </span>
                    </button>
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year1set />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* 
{!showcityset && !showratioset && 
  <div className="absolute z-30 w-full my-28 pointer-events-none">
    <div>
      <div className="">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md">
            {params.area}
          </span>
        </button>
      </div>
    </div>
  </div>
} */}

          {/*   
  <div className="absolute z-30 pointer-events-none right-0">
    <main className='w-full flex flex-wrap my-28'>
      <div className=''>
        <div className="text-sm md:text-base pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Year0set />
            </span>
          </button>
        </div>
        <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Map1set />
            </span>
          </button>
        </div>
        <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Rotate1set />
            </span>
          </button>
        </div>
      </div>
    </main>
  </div> */}
        </div>
      </>
    );
  } else if (params.mode == 7) {
    // 典型例とその解説
    buttons = (
      <>
        {/* タイトルとタブ */}
        <div className="w-full absolute z-30 pointer-events-none">
          {title}
          {tabs}
          <div className="w-full h-1 bg-gradient-to-r from-lime-400 via-lime-500 to-lime-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800"></div>
        </div>
        {/* 凡例 */}
        <div className="absolute z-30 w-full my-24 pointer-events-none">
          <div className="flex my-2">
            <div className="w-1/4 lg:w-1/4 max-h-[10vh] z-40">
              <div className="text-sm md:text-base bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-lg pointer-events-auto text-white">
                <>
                  {hanrei_module_3}

                  {zoomlevel1set == 1 && (
                    <>
                      <details open className="">
                        <summary className="cursor-pointer">解説</summary>
                        <div className="overflow-auto max-h-[20vh] text-white bg-gradient-to-r from-green-800 via-green-800 to-green-900 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800 dark:focus:ring-green-800 font-medium text-xs md:text-sm px-1 py-2">
                          {meta.kaisetsu}
                        </div>
                      </details>
                      <details className="">
                        <summary className="cursor-pointer">設定</summary>
                        <div
                          onClick={() => setShowratioset(true)}
                          className="cursor-pointer"
                        >
                          高さ設定
                        </div>
                      </details>
                    </>
                  )}
                </>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
                <Modalratio0set show={showratioset} setShow={setShowratioset} />
              </div>
            </div>

            <div className="pointer-events-none z-0">
              {!showcityset && !showratioset && (
                <div>
                  <div className="mx-1">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800 w-full mb-1">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md w-full">
                        {meta.tenkei_city_2}・{params.area}
                      </span>
                    </button>
                  </div>

                  <div className="text-sm md:text-base pointer-events-auto mx-2 text-left">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Year0set />
                      </span>
                    </button>
                    {/* </div>
        <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Map1set />
                      </span>
                    </button>
                    {/* </div>
        <div className="text-sm md:text-base pointer-events-auto mx-2 text-left"> */}
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                      <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
                        <Rotate1set />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* 
{!showcityset && !showratioset && 
  <div className="absolute z-30 w-full my-28 pointer-events-none">
    <div>
      <div className="">
        <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-2xl font-extrabold text-white rounded-lg group dark:text-white dark:focus:ring-cyan-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md">
            {params.area}
          </span>
        </button>
      </div>
    </div>
  </div>
} */}

          {/*   
  <div className="absolute z-30 pointer-events-none right-0">
    <main className='w-full flex flex-wrap my-28'>
      <div className=''>
        <div className="text-sm md:text-base pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Year0set />
            </span>
          </button>
        </div>
        <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 min-w-[10vw]">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Map1set />
            </span>
          </button>
        </div>
        <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
            <span className="relative px-2.5 py-2.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[15vw] sm:min-w-[10vw]">
              <Rotate1set />
            </span>
          </button>
        </div>
      </div>
    </main>
  </div> */}
        </div>
      </>
    );
  } else if (modeset == "gg1") {
    buttons = (
      <>
        <div className="w-full absolute z-50 pointer-events-none">
          <div className="h-12 p-2 flex bg-yellow-100">
            <div>{static2map1set["no"]}</div>
            <div>{static2map1set["title"]}</div>
            <div>{static2map1set["title_height_name"]}</div>
            <div>{static2map1set["title_color_name"]}</div>
          </div>

          <main className="w-full flex flex-wrap my-0">
            {/* <div className=''> */}

            <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
              <div className="m-1 rounded cursor-pointer p-2 text-white">
                自分で地域を選ぶ
              </div>
            </div>

            <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
              <div className="m-1 rounded cursor-pointer p-2 text-white">
                地域の昔と比べる
              </div>
            </div>

            {/* </div> */}
          </main>
        </div>

        {fullviewerset === 0 && (
          <>
            <div className="absolute z-30 w-full my-40 pointer-events-none">
              <div className="w-1/4 mx-auto">
                <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto">
                  <div className="m-1 rounded cursor-pointer">
                    <Area0set />
                  </div>
                </div>
              </div>

              <div className="relative text-sm md:text-base bg-black/30 rounded-lg w-16 mx-auto pointer-events-auto">
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Fullviewerset />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Map0set />
                </div>
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Static2Map0set />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer text-center">
          <Syukurendou />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Rotate0set />
        </div> */}
              </div>
            </div>

            <div className="absolute z-30 pointer-events-none right-0">
              <main className="w-full flex flex-wrap my-40">
                <div className="">
                  <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
                    <div className="m-1 rounded cursor-pointer p-2 text-white">
                      現在（2015頃）
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </>
        )}
        {fullviewerset === 1 && (
          <div className="absolute z-30 right-0 pointer-events-none">
            <div className="relative text-sm md:text-base my-40 bg-black/30 rounded-lg w-16 pointer-events-auto">
              <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                <Fullviewerset />
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else if (modeset === 11120) {
    buttons = (
      <>
        <div className="w-5/12 absolute z-30 pointer-events-none">
          <main className="w-full flex flex-wrap my-20">
            <div className="">
              <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
              <Map1set />
            </div>
            <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
              <Static2Map1set />
            </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
              <Rotate1set />
            </div> */}
              </div>
            </div>
            <div className="">
              <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
                <div className="m-1 rounded cursor-pointer p-2 text-white">
                  過去（1980頃）
                  {static2map1set[0]}
                </div>
              </div>
            </div>
          </main>
        </div>

        {fullviewerset === 0 && (
          <>
            <div className="absolute z-30 w-full my-20 pointer-events-none">
              <div className="w-1/4 mx-auto">
                <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto">
                  <div className="m-1 rounded cursor-pointer">
                    <Area0set />
                  </div>
                </div>
              </div>

              <div className="relative text-sm md:text-base bg-black/30 rounded-lg w-16 mx-auto pointer-events-auto">
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Fullviewerset />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Map0set />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Static2Map0set />
                </div>
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer text-center">
          <Syukurendou />
        </div> */}
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
          <Rotate0set />
        </div> */}
              </div>
            </div>

            <div className="absolute z-30 pointer-events-none right-0">
              <main className="w-full flex flex-wrap my-20">
                <div className="">
                  <div className="text-sm md:text-base bg-black/70 rounded-lg pointer-events-auto mx-2">
                    <div className="m-1 rounded cursor-pointer p-2 text-white">
                      現在（2015頃）
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </>
        )}
        {fullviewerset === 1 && (
          <div className="absolute z-30 right-0 pointer-events-none">
            <div className="relative text-sm md:text-base my-20 bg-black/30 rounded-lg w-16 pointer-events-auto">
              <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                <Fullviewerset />
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else if (modeset === 12220) {
    buttons = (
      <>
        <div className="w-5/12 absolute z-30 pointer-events-none">
          <main className="w-full flex flex-wrap my-20">
            <div className="">
              {/* <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
          <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
            <Map1set />
          </div>
          <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
            <Static2Map1set />
          </div>
          <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
            <Rotate1set />
          </div>
        </div> */}
            </div>
            <div className="">
              <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                <div className="m-1 rounded cursor-pointer">
                  <Area1set />
                </div>
              </div>
            </div>
          </main>
        </div>

        {fullviewerset === 0 && (
          <>
            <div className="absolute z-30 w-full my-20 pointer-events-none">
              <div className="w-1/4 mx-auto">
                <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                  {/* <div className="m-1 rounded cursor-pointer">
          <Area0set />
        </div> */}
                </div>
              </div>
              <div className="relative text-sm md:text-base bg-black/30 rounded-lg w-16 mx-auto pointer-events-auto">
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Fullviewerset />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Map0set />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Static2Map0aset />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer text-center">
                  <Syukurendou />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Rotate0set />
                </div>
              </div>
            </div>

            <div className="absolute z-30 pointer-events-none right-0">
              <main className="w-full flex flex-wrap my-20">
                <div className="">
                  <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                    <div className="m-1 rounded cursor-pointer">
                      <Area2set />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </>
        )}
        {fullviewerset === 1 && (
          <div className="absolute z-30 right-0 pointer-events-none">
            <div className="relative text-sm md:text-base my-20 bg-black/30 rounded-lg w-16 pointer-events-auto">
              <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                <Fullviewerset />
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    buttons = (
      <>
        <div className="w-5/12 absolute z-30 pointer-events-none">
          <main className="w-full flex flex-wrap my-20">
            <div className="">
              <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Map1set />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Static2Map1set />
                </div>
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Rotate1set />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                <div className="m-1 rounded cursor-pointer">
                  <Area1set />
                </div>
              </div>
            </div>
          </main>
        </div>

        {fullviewerset === 0 && (
          <>
            <div className="absolute z-30 w-full my-20 pointer-events-none">
              <div className="w-1/4 mx-auto">
                <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                  <div className="m-1 rounded cursor-pointer">
                    <Area0set />
                  </div>
                </div>
              </div>
              <div className="relative text-sm md:text-base bg-black/30 rounded-lg w-16 mx-auto pointer-events-auto">
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Fullviewerset />
                </div>
                {/* <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer text-center">
        <Syukurendou />
      </div>
      <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
        <Map0set />
      </div>
      <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
        <Static2Map0set />
      </div> */}
                <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                  <Rotate0set />
                </div>
              </div>
            </div>

            <div className="absolute z-30 pointer-events-none right-0">
              <main className="w-full flex flex-wrap my-20">
                <div className="">
                  <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                    <div className="m-1 rounded cursor-pointer">
                      <Area2set />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="text-sm md:text-base bg-black/30 rounded-lg pointer-events-auto">
                    <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                      <Map2set />
                    </div>
                    <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                      <Static2Map2set />
                    </div>
                    <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                      <Rotate2set />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </>
        )}
        {fullviewerset === 1 && (
          <div className="absolute z-30 right-0 pointer-events-none">
            <div className="relative text-sm md:text-base my-20 bg-black/30 rounded-lg w-16 pointer-events-auto">
              <div className="bg-teal-100/80 p-1 md:p-2 m-1 rounded cursor-pointer">
                <Fullviewerset />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return <>
          <div className="h-1/6 flex flex-wrap flex-row">
            <div className="z-30 w-1/12 bg-tani_orange">
            <Link to="/">
            <div className="z-30 w-full h-full bg-tani_orange">　
            </div>
            </Link>
            </div>
            <div className="z-30 w-11/12 bg-tani_blue text-left text-tani_white text-6xl items-center pointer-events-none">

            {!showcityset && !showratioset && (
                <div className="flex">
                  <div onClick={() => setShowcityset(212)} className="mx-2">
                    <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm md:text-6xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800 w-full mb-1 z-0">
                      <span className="relative px-5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto w-full">
                        {params.area}<span className="text-sm">　[変更]</span>
                      </span>
                    </button>
                  </div>
                  <div className="grow">
                  </div>
                  <div className="text-sm md:text-base mx-2">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50 pointer-events-auto">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  {/* <Year1set /> */}
                  <Map0set />
                </span>
              </button>
            </div>

            <div className="text-sm md:text-base mx-2">
              <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-xs md:text-base lg:text-2xl font-extrabold text-white rounded-lg group bg-gradient-to-br from-cyan-500/50 to-blue-500/50 group-hover:from-cyan-500/50 group-hover:to-blue-500/50 hover:text-white dark:text-white focus:ring-4 focus:ring-cyan-200/50 dark:focus:ring-cyan-800/50">
                <span className="relative px-2.5 py-0.5 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50  rounded-md group-hover:bg-opacity-0 pointer-events-auto min-w-[10vw] sm:min-w-[5vw]">
                  <Rotate0set />
                </span>
              </button>
            </div>
                </div>
              )}

              {tabs}
            </div>
                <Modalcity0set show={showcityset} setShow={setShowcityset} />
          </div>

  {buttons}
  </>;
};
