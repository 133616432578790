import React, {
  useEffect,
  useState,
  useContext,
  // useRef,
  // useLayoutEffect
} from "react";
// import { Viewer, Entity, PointGraphics, EntityDescription, ImageryLayer } from "resium";
import {
  Cartesian3,
  Cartesian2,
  SceneMode,
  Math,
  Cartographic,
  Transforms,
  // Rectangle, Camera,
  // Ellipsoid,
  // HeadingPitchRange,
  Matrix4,
  Fullscreen,
} from "cesium";
import { FullscreenSet } from "./App";
import { FullviewerSet } from "./App";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { Rotate1Set } from "./App";
import { Rotate2Set } from "./App";
import { Map1Set } from "./App";
import { Map2Set } from "./App";
// import HeadingPitchRange from "cesium/Source/Core/HeadingPitchRange";
// import { Fullscreen } from 'cesium';

// フルスクリーンにする ////////////////////
export const FullscreenFunc = () => {
  const { fullscreenset, setFullscreenSet } = useContext(FullscreenSet);

  useEffect(() => {
    if (fullscreenset) {
      Fullscreen.requestFullscreen(document.body);
    } else {
      // Fullscreen.exitFullscreen();
    }
    return () => {
      if (fullscreenset) {
        Fullscreen.exitFullscreen();
      }
    };
  }, [fullscreenset]);
};
//////////////////// フルスクリーンにする //

// １画面表示の切り替え ////////////////////
export const FullviwerFunc = () => {
  const { fullviewerset, setFullviewerSet } = useContext(FullviewerSet);
  const normalStyle = "z-10 w-1/2 ml-auto";
  const activeStyle = "z-10";
  const style = fullviewerset ? activeStyle : normalStyle;
  // let style = [];
  // const style2 = () => {
  //   if(fullviewerset === 0) {
  //     style[0] = "w-1/2"
  //     style[1] = "w-2/4 ml-auto"
  //   } else if (fullviewerset === 1) {
  //     style[0] = "z-10"
  //     style[1] = "z-0 w-1/2 ml-auto"
  //   } else if (fullviewerset === 2) {
  //     style[0] = "z-0 w-1/2"
  //     style[1] = "z-10"
  //   }
  // }
  // console.log(style);
  return style;
};
//////////////////// １画面表示の切り替え //

// 回転エフェクト（左） ////////////////////
export const Rotate1Func = (rfcs1) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const [mousecount, setMouseCount] = useState(true);
  useEffect(() => {
    function rotate() {
      rfcs1.current.cesiumElement.scene.camera.rotateRight(0.005);
    }
    function rotate1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        if (rotate1set) {
          // if(rendouset || syukurenset){
          //   setRendouSet(false);
          //   setSyukurenSet(false);
          //   setRotate1Set(false);
          // } else {
          var windowPosition = new Cartesian2(
            rfcs1.current.cesiumElement.container.clientWidth / 2,
            rfcs1.current.cesiumElement.container.clientHeight / 2
          );
          var ellipsoid = rfcs1.current.cesiumElement.scene.globe.ellipsoid;
          var cartesian = rfcs1.current.cesiumElement.camera.pickEllipsoid(
            windowPosition,
            ellipsoid
          );
          var carto2 = Cartographic.fromCartesian(cartesian);
          var center_lon = Math.toDegrees(carto2.longitude);
          var center_lat = Math.toDegrees(carto2.latitude);
          var cameraposition = rfcs1.current.cesiumElement.camera.position;

          var center = Cartesian3.fromDegrees(center_lon, center_lat, 0);
          var transform = Transforms.eastNorthUpToFixedFrame(center);

          rfcs1.current.cesiumElement.scene.camera.lookAtTransform(
            transform,
            cameraposition
          );

          rfcs1.current.cesiumElement.clock.onTick.addEventListener(rotate);
          // }
        }
      }
    }
    rotate1();
    return () => {
      if (rotate1set) {
        rfcs1.current.cesiumElement.clock.onTick.removeEventListener(rotate);
        rfcs1.current.cesiumElement.camera.lookAtTransform(Matrix4.IDENTITY);
      } else {
        // console.log("何もしない");
      }
    };
  }, [rotate1set]);
};
//////////////////// 回転エフェクト（左） //

// 回転エフェクト（右） ////////////////////
export const Rotate2Func = (rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const [mousecount, setMouseCount] = useState(true);
  useEffect(() => {
    function rotate() {
      rfcs2.current.cesiumElement.scene.camera.rotateRight(0.005);
    }
    function rotate2(e) {
      if (rfcs2.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        if (rotate2set) {
          // if(rendouset || syukurenset){
          //   setRendouSet(false);
          //   setSyukurenSet(false);
          //   setRotate2Set(false);
          // } else {
          var windowPosition = new Cartesian2(
            rfcs2.current.cesiumElement.container.clientWidth / 2,
            rfcs2.current.cesiumElement.container.clientHeight / 2
          );
          var ellipsoid = rfcs2.current.cesiumElement.scene.globe.ellipsoid;
          var cartesian = rfcs2.current.cesiumElement.camera.pickEllipsoid(
            windowPosition,
            ellipsoid
          );
          var carto2 = Cartographic.fromCartesian(cartesian);
          var center_lon = Math.toDegrees(carto2.longitude);
          var center_lat = Math.toDegrees(carto2.latitude);
          var cameraposition = rfcs2.current.cesiumElement.camera.position;

          var center = Cartesian3.fromDegrees(center_lon, center_lat, 0);
          var transform = Transforms.eastNorthUpToFixedFrame(center);

          rfcs2.current.cesiumElement.scene.camera.lookAtTransform(
            transform,
            cameraposition
          );

          rfcs2.current.cesiumElement.clock.onTick.addEventListener(rotate);
          // }
        }
      }
    }
    rotate2();
    return () => {
      if (rotate2set) {
        rfcs2.current.cesiumElement.clock.onTick.removeEventListener(rotate);
        rfcs2.current.cesiumElement.camera.lookAtTransform(Matrix4.IDENTITY);
      } else {
        // console.log("何もしない");
      }
    };
  }, [rotate2set]);
};
//////////////////// 回転エフェクト（右） //

// 縮尺合わせるエフェクト ////////////////////
export const SyukurenFunc = (rfcs1, rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const [mousecount, setMouseCount] = useState(true);

  useEffect(() => {
    if (
      rfcs1.current &&
      rfcs1.current.cesiumElement &&
      rfcs2.current &&
      rfcs2.current.cesiumElement
    ) {
      // console.log("縮尺連動ファンクション起動確認");

      rfcs1.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("左にマウスオン（縮尺合わせ）");
          setMouseCount(true);
        }
      );
      rfcs2.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("右にマウスオン（縮尺合わせ）");
          setMouseCount(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    var masterCamera = rfcs1.current.cesiumElement.scene.camera;
    var slaveCamera = rfcs2.current.cesiumElement.scene.camera;
    // console.log("左右連動ifの前（縮尺合わせ）");

    function rendou1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        var mastercarto = Cartographic.fromCartesian(masterCamera.position);
        var masterlon = Math.toDegrees(mastercarto.longitude);
        var masterlat = Math.toDegrees(mastercarto.latitude);
        var slavecarto = Cartographic.fromCartesian(slaveCamera.position);
        var slaveheight = slavecarto.height;
        var syukurenposition = Cartesian3.fromDegrees(
          masterlon,
          masterlat,
          slaveheight
        );

        masterCamera.setView({
          destination: {
            x: syukurenposition.x,
            y: syukurenposition.y,
            z: syukurenposition.z,
          },
          orientation: {
            heading: slaveCamera.heading,
            pitch: slaveCamera.pitch,
            roll: slaveCamera.roll,
          },
        });
      }
    }
    function rendou2(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        var slavecarto = Cartographic.fromCartesian(slaveCamera.position);
        var slavelon = Math.toDegrees(slavecarto.longitude);
        var slavelat = Math.toDegrees(slavecarto.latitude);
        var mastercarto = Cartographic.fromCartesian(masterCamera.position);
        var masterheight = mastercarto.height;
        var syukurenposition = Cartesian3.fromDegrees(
          slavelon,
          slavelat,
          masterheight
        );

        slaveCamera.setView({
          destination: {
            x: syukurenposition.x,
            y: syukurenposition.y,
            z: syukurenposition.z,
          },
          orientation: {
            heading: masterCamera.heading,
            pitch: masterCamera.pitch,
            roll: masterCamera.roll,
          },
        });
      }
    }
    const rendou = () => {
      if (syukurenset) {
        // console.log("連動オン設定");
        if (
          rfcs1.current &&
          rfcs1.current.cesiumElement &&
          rfcs2.current &&
          rfcs2.current.cesiumElement
        ) {
          if (mousecount) {
            // console.log("左の動きを右に伝える");
            rfcs1.current.cesiumElement.scene.preRender.addEventListener(
              rendou2
            );
          }
          if (!mousecount) {
            // console.log("右の動きを左に伝える");
            rfcs2.current.cesiumElement.scene.preRender.addEventListener(
              rendou1
            );
          }
        }
      } else {
        // console.log("連動オフ設定");
      }
    };

    rendou();

    return () => {
      // console.log("連動解除");
      if (mousecount) {
        rfcs1.current.cesiumElement.scene.preRender.removeEventListener(
          rendou2
        );
      }
      if (!mousecount) {
        rfcs2.current.cesiumElement.scene.preRender.removeEventListener(
          rendou1
        );
      }
    };
  }, [syukurenset, mousecount]);
};
//////////////////// 縮尺合わせるエフェクト //

// 左右連動エフェクト ////////////////////
export const RendouFunc = (rfcs1, rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const [mousecount, setMouseCount] = useState(true);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);

  useEffect(() => {
    if (
      rfcs1.current &&
      rfcs1.current.cesiumElement &&
      rfcs2.current &&
      rfcs2.current.cesiumElement
    ) {
      // console.log("左右連動エフェクトフラグ付与チェック");
      rfcs1.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("左にマウスオン");
          setMouseCount(true);
        }
      );
      rfcs2.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("右にマウスオン");
          setMouseCount(false);
        }
      );
    }
  }, []);
  useEffect(() => {
    var masterCamera = rfcs1.current.cesiumElement.scene.camera;
    var slaveCamera = rfcs2.current.cesiumElement.scene.camera;
    // console.log("左右連動ifの前");

    function rendou1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        // if (rotate1set || rotate2set){
        //   setRotate1Set(false);
        //   setRotate2Set(false);
        masterCamera.setView({
          destination: {
            x: slaveCamera.position.x,
            y: slaveCamera.position.y,
            z: slaveCamera.position.z,
          },
          orientation: {
            heading: slaveCamera.heading,
            pitch: slaveCamera.pitch,
            roll: slaveCamera.roll,
          },
        });
        // }
      }
    }
    function rendou2(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        // if (rotate1set || rotate2set){
        //   setRotate1Set(false);
        //   setRotate2Set(false);
        slaveCamera.setView({
          destination: {
            x: masterCamera.position.x,
            y: masterCamera.position.y,
            z: masterCamera.position.z,
          },
          orientation: {
            heading: masterCamera.heading,
            pitch: masterCamera.pitch,
            roll: masterCamera.roll,
          },
        });
        // }
      }
    }
    const rendou = () => {
      if (rendouset) {
        // console.log("連動オン設定");
        if (
          rfcs1.current &&
          rfcs1.current.cesiumElement &&
          rfcs2.current &&
          rfcs2.current.cesiumElement
        ) {
          if (mousecount) {
            // console.log("左の動きを右に伝える");
            rfcs1.current.cesiumElement.scene.preRender.addEventListener(
              rendou2
            );
          }
          if (!mousecount) {
            // console.log("右の動きを左に伝える");
            rfcs2.current.cesiumElement.scene.preRender.addEventListener(
              rendou1
            );
          }
        }
      } else {
        // console.log("連動オフ設定");
      }
    };
    rendou();
    return () => {
      // console.log("連動解除");
      if (mousecount) {
        rfcs1.current.cesiumElement.scene.preRender.removeEventListener(
          rendou2
        );
      }
      if (!mousecount) {
        rfcs2.current.cesiumElement.scene.preRender.removeEventListener(
          rendou1
        );
      }
    };
  }, [rendouset, mousecount]);
};
//////////////////// 左右連動エフェクト //
