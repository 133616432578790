import React from "react";
// import logo from "./regtra_logo2.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FAQ1, FAQ2, FAQ3, FAQ4, FAQ5 } from "./faqmodal";
import logo1 from "./img/faq_1.webp";
import logo2 from "./img/faq_2.webp";
import logo3 from "./img/faq_3.webp";
import logo4 from "./img/faq_4.webp";
import logo5 from "./img/faq_5.webp";
import faqmeta from "./meta_faq_20220328";

import { Header } from "./App";

const Panel = ({ img, title, subtitle, description, themenum }) => {
  return (
    <>
      <div
        className="
          cursor-pointer
          bg-white
          shadow-md
          h-[40vh]
          rounded-3xl
          flex flex-col
          justify-around
          items-center
          overflow-hidden
          sm:flex-row sm:h-[23vh] 
          lg:w-[48vw] mx-[1vw] my-[1vh]
        "
      >
        <img
          className="h-1/2 w-full sm:h-full sm:w-5/12 object-cover"
          src={img}
          alt="panel"
        />
        <div
          className="
            flex-1
            w-full
            flex flex-col
            items-baseline
            justify-around
            h-1/2
            pl-6
            sm:h-full sm:items-baseline sm:w-1/2
          "
        >
          <div className="flex flex-col justify-start items-baseline">
            <h1 className="text-4xl font-normal mb-0 text-gray-600 font-sans">
              {title}
            </h1>
            <span className="text-xs text-indigo-300 mt-0">{subtitle}</span>
          </div>
          <p className="text-xs text-gray-500 w-4/5">{description}</p>
          <div className="w-full flex justify-between items-center">
            <h1 className="font-bold text-gray-500">{themenum}</h1>
            <button className="bg-gray-700 mr-5 text-white px-3 py-1 rounded-sm shadow-md">
              選択
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const Faq = () => {
  const [showsdgs, setShowsdgs] = useState(false);
  const [showsougou, setShowsougou] = useState(false);
  const [showtankyu, setShowtankyu] = useState(false);
  const [showchiriteki, setShowchiriteki] = useState(false);
  const [showsyutten, setShowsyutten] = useState(false);

  // console.log(faqmeta);

  return (
    <>
      <div>
        <div className="absolute z-30 font-mono text-black w-full h-screen bg-blue-100">
          <div className=" z-30">
            <Header />
          </div>

          <div className="min-h-[8vh] mt-20 text-2xl text-left w-full text-black text-extrabold bg-gradient-to-r from-cyan-200 to-blue-400 hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium px-5 py-2.5">
            FAQ：　よくある質問とその回答
          </div>

          <div className="lg:flex lg:flex-wrap w-full min-h-[76vh]">
            <div onClick={() => setShowsdgs(true)}>
              <Panel
                img={logo1}
                title="データについて"
                subtitle="Frequently asked questions for our data"
                description="データについてのよくある質問と回答です"
                themenum="全5問"
                show={showsdgs}
                setShow={setShowsdgs}
              />
            </div>

            <div onClick={() => setShowsougou(true)}>
              <Panel
                img={logo2}
                title="操作について"
                subtitle="Frequently asked questions for your operation"
                description="操作についてのよくある質問と回答です"
                themenum="全13問"
                show={showsougou}
                setShow={setShowsougou}
              />
            </div>

            <div onClick={() => setShowtankyu(true)}>
              <Panel
                img={logo3}
                title="ご利用の際の留意点"
                subtitle="Frequently asked questions for the problems (example: copyrights)"
                description="ご利用の際の留意点（著作権など）についてのよくある質問と回答です"
                themenum="全3問"
                show={showtankyu}
                setShow={setShowtankyu}
              />
            </div>

            <div onClick={() => setShowchiriteki(true)}>
              <Panel
                img={logo4}
                title="内容の改善に向けて"
                subtitle="Frequently asked questions for our development"
                description="内容の改善に向けてのよくある質問と回答です"
                themenum="全2問"
                show={showchiriteki}
                setShow={setShowchiriteki}
              />
            </div>

            <div onClick={() => setShowsyutten(true)}>
              <Panel
                img={logo5}
                title="当サイトの成り立ち"
                subtitle="Frequently asked questions for our process of creation"
                description="当サイトの成り立ちについてのよくある質問と回答です"
                themenum="全2問"
                show={showsyutten}
                setShow={setShowsyutten}
              />
            </div>
          </div>

          <FAQ1 show={showsdgs} setShow={setShowsdgs} title="データについて" />
          <FAQ1
            show={showsougou}
            setShow={setShowsougou}
            title="操作について"
          />
          <FAQ1
            show={showtankyu}
            setShow={setShowtankyu}
            title="ご利用の際の留意点（著作権など）"
          />
          <FAQ1
            show={showchiriteki}
            setShow={setShowchiriteki}
            title="内容の改善に向けて"
          />
          <FAQ1
            show={showsyutten}
            setShow={setShowsyutten}
            title="当サイトの成り立ち"
          />

          <Link to="/">トップページに戻る</Link>
        </div>
      </div>
    </>
  );
};
