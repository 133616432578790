import React, {
  useContext,
  // useState,
  //  useEffect
} from "react";
// import { Modalsdgs } from "./selectmodal";
// import localforage from "localforage";
// import { Static2Map1Set } from "./App";
// import { Static2Map2Set } from "./App";
// import { Theme1Set } from "./App";
// import { Theme2Set } from "./App";
import { MetadataSet } from "./App";
// import { metadata } from "./kashikasetting";
// import useSWR from "swr";
import { MultiSetting } from "./kashikabuttons";
import {
  //  oldphoto,
  photo,
} from "./cesiumsetting";
// import { population_now,
//    population_old
//    } from "./kashikasetting";
import sdgs1 from "./img/sdg_1.webp";
import sdgs2 from "./img/sdg_2.webp";
import sdgs3 from "./img/sdg_3.webp";
import sdgs4 from "./img/sdg_4.webp";
import sdgs5 from "./img/sdg_5.webp";
import sdgs6 from "./img/sdg_6.webp";
import sdgs7 from "./img/sdg_7.webp";
import sdgs8 from "./img/sdg_8.webp";
import sdgs9 from "./img/sdg_9.webp";
import sdgs10 from "./img/sdg_10.webp";
import sdgs11 from "./img/sdg_11.webp";
import sdgs12 from "./img/sdg_12.webp";
import sdgs13 from "./img/sdg_13.webp";
import sdgs14 from "./img/sdg_14.webp";
import sdgs15 from "./img/sdg_15.webp";
import sdgs16 from "./img/sdg_16.webp";
import sdgs17 from "./img/sdg_17.webp";
import sdgs18 from "./img/sdg_18.webp";
import { Header } from "./App";
// import { Footer } from "./App";
import { Area1Set, Area2Set } from "./App";
import { Year1Set, Year2Set } from "./App";
import { ModeSet } from "./App";
import { useParams } from "react-router-dom";

export const Tags = (props) => {
  // const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  // const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  // const { theme2set, setTheme2Set } = useContext(Theme2Set);
  const {
    metadataset,
    // setMetadataSet
  } = useContext(MetadataSet);
  const {
    area1set,
    // setArea1Set
  } = useContext(Area1Set);
  const {
    area2set,
    // setArea2Set
  } = useContext(Area2Set);
  const {
    year1set,
    // setYear1Set
  } = useContext(Year1Set);
  const {
    year2set,
    // setYear2Set
  } = useContext(Year2Set);

  const { modeset, setModeSet } = useContext(ModeSet);

  const params = useParams();
  // console.log(params.tag);

  // const metatmp = data_meta.filter((r)=>{
  //   return r.no == params.no;
  // })
  // const meta = metatmp[0]

  if (metadataset) {
    var tmplist = metadataset.filter(function (value) {
      return value[params.tag] === 1;
    });
  }


  const panel = (r) => {
    return <>
    <div className="flex space-x-6 mb-4">
      <div className="flex-none w-1/4">
        {r["sdgs_no"] === 1 && (
          <img className="w-80" src={sdgs1} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 2 && (
          <img className="w-80" src={sdgs2} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 3 && (
          <img className="w-80" src={sdgs3} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 4 && (
          <img className="w-80" src={sdgs4} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 5 && (
          <img className="w-80" src={sdgs5} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 6 && (
          <img className="w-80" src={sdgs6} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 7 && (
          <img className="w-80" src={sdgs7} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 8 && (
          <img className="w-80" src={sdgs8} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 9 && (
          <img className="w-80" src={sdgs9} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 10 && (
          <img className="w-80" src={sdgs10} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 11 && (
          <img className="w-80" src={sdgs11} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 12 && (
          <img className="w-80" src={sdgs12} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 13 && (
          <img className="w-80" src={sdgs13} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 14 && (
          <img className="w-80" src={sdgs14} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 15 && (
          <img className="w-80" src={sdgs15} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 16 && (
          <img className="w-80" src={sdgs16} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 17 && (
          <img className="w-80" src={sdgs17} alt="sdgsicon" />
        )}
        {r["sdgs_no"] === 18 && (
          <img className="w-80" src={sdgs18} alt="sdgsicon" />
        )}
      </div>
      <div className="">
        <p className="text-xl text-gray-700 font-normal mb-1 text-left">
          {r["no"]}
        </p>
        <p className="text-3xl text-gray-700 font-normal mb-1">
          {r["title"]}
        </p>
        <div>
          <div className="text-base text-blue-600 font-normal">
            {r["title_height_name"]}＆{r["title_color_name"]}
          </div>
        </div>
      </div>
    </div>
    <div>
      <p className="text-gray-800 leading-loose font-normal text-base">
        {r["try"]}
      </p>
    </div>
  </>
  } 





  // console.log(tmplist);

  return (
    <>
      <div className="absolute z-50 font-mono text-black w-full h-screen bg-blue-100">
        <div className="h-[12vh] z-60">
          <Header />
        </div>
        <div className="min-h-[84vh] bg-blue-100">
          <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
            <div className="w-full text-center pb-8">
              <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 pb-2">
                {params.tag}
              </h1>
              <p className="text-gray-400 font-normal text-base">
                {params.tag}に関連するテーマを選ぶことができます。
              </p>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
              {tmplist.map((r, i) => (
                <div key={i} className="bg-white rounded-lg p-4">
                  <div className="flex">

{modeset == 1 && 
    <MultiSetting
      modeset={modeset || 1 }
      fullviewerset={0}
      rendouset={true}
      syukurenset={false}
      map1set={photo}
      map2set={photo}
      static2map1set={r}
      static2map2set={r}
      rotate1set={false}
      rotate2set={false}
      area1set={area1set}
      area2set={area2set}
      year1set={year1set || r.year5}
      year2set={year2set || r.year5}
      no={r.no}
    >
      {panel(r)}
    </MultiSetting>
}
{modeset == 2 && 
    <MultiSetting
      modeset={modeset || 1 }
      fullviewerset={0}
      rendouset={true}
      syukurenset={false}
      map1set={photo}
      map2set={photo}
      static2map1set={r}
      static2map2set={r}
      rotate1set={false}
      rotate2set={false}
      area1set={area1set}
      area2set={area2set}
      year1set={year1set || r.year5}
      year2set={year2set || r.year5}
      no={r.no}
    >
      {panel(r)}
    </MultiSetting>
}
{modeset == 3 && 
    <MultiSetting
      modeset={modeset || 1 }
      fullviewerset={0}
      rendouset={true}
      syukurenset={false}
      map1set={photo}
      map2set={photo}
      static2map1set={r}
      static2map2set={r}
      rotate1set={false}
      rotate2set={false}
      area1set={area1set}
      area2set={area2set}
      year1set={year1set || r.year5}
      year2set={year2set || r.year5}
      no={r.no}
    >
      {panel(r)}
    </MultiSetting>
}
{modeset == 4 &&
                    <MultiSetting
                      modeset={r.tenkei_mode || 4 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={r.tenkei_city}
                      area2set={r.tenkei_city}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}
{modeset == 6 &&
                    <MultiSetting
                      modeset={r.tenkei_mode || 4 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={r.tenkei_city}
                      area2set={r.tenkei_city}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}
{modeset == 7 &&
                    <MultiSetting
                      modeset={r.tenkei_mode || 4 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={r.tenkei_city}
                      area2set={r.tenkei_city}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}                
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}
{modeset == 5 && r.kokudo_onoff == 1 &&
                    <MultiSetting
                      modeset={ 5 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={"日本全国"}
                      area2set={area2set}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}                
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}
{modeset == 5 && r.kokudo_onoff == 0 &&
                    <MultiSetting
                      modeset={r.tenkei_mode || 4 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={r.tenkei_city}
                      area2set={r.tenkei_city}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}                
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}
{!modeset && 
                    <MultiSetting
                      modeset={ 1 }
                      fullviewerset={0}
                      rendouset={true}
                      syukurenset={false}
                      map1set={photo}
                      map2set={photo}
                      static2map1set={r}
                      static2map2set={r}
                      rotate1set={false}
                      rotate2set={false}
                      area1set={r.tenkei_city}
                      area2set={r.tenkei_city}
                      year1set={year1set || r.year5}
                      year2set={year2set || r.year5}                
                      no={r.no}
                    >
                      {panel(r)}
                    </MultiSetting>
}





                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>

        <div className="absolute z-50 min-w-full font-bold text-sm lg:text-base text-gray-100 bg-gradient-to-r from-blue-600 to-blue-800  focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700 py-1 text-center">
          © 2022{" "}
          <a
            href="https://www.teikokushoin.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            帝国書院
          </a>{" "}
          ＆{" "}
          <a
            href="https://www.kashika.or.jp/geograph/"
            target="_blank"
            rel="noopener noreferrer"
          >
            都市構造可視化推進機構
          </a>
        </div>
      </div>
    </>
  );
};
