export default 
[
  {
    "genre": "データについて",
    "midashi_english": "Frequently asked questions for our data",
    "midashi_japanese": "データについてのよくある質問と回答です",
    "count": "全5問",
    "photo": "アルパカ",
    "torf": 1,
    "question": "メッシュデータとは何ですか？",
    "answer_1": "メッシュデータとは、国で定めた区画「統計に用いる標準地域メッシュ」ごとに整理されたデータのことです。標準地域メッシュのうち、1辺の長さが約1㎞になるものを「三次メッシュ」、約10㎞になるものを「二次メッシュ」と呼びます。これらにつきましては、以下に示します「総務省統計局」のウェブサイトが参考になります。",
    "answer_2": "総務省統計局",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://www.stat.go.jp/data/mesh/m_tuite.html",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "データについて",
    "midashi_english": "Frequently asked questions for our data",
    "midashi_japanese": "データについてのよくある質問と回答です",
    "count": "全5問",
    "photo": "アルパカ",
    "torf": 1,
    "question": "３次元グラフが示しているデータの、出典を教えてください。",
    "answer_1": "当サイトでは、各テーマの凡例の中に「詳細を見る」ボタンを設けており、そちらをクリック（タップ）するとデータの出典および出典元へのリンクが表示されます。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "データについて",
    "midashi_english": "Frequently asked questions for our data",
    "midashi_japanese": "データについてのよくある質問と回答です",
    "count": "全5問",
    "photo": "アルパカ",
    "torf": 1,
    "question": "各メッシュのデータの具体的な数値が知りたいのですが。",
    "answer_1": "当サイトで可能です。各3次元グラフの天井をダブルクリック（タップ）すると、視点がズームインしたうえで、該当のメッシュに関する下記の情報が得られます。",
    "answer_2": "・位置情報（国で定めたメッシュコード）。",
    "answer_3": "・3次元グラフの基となる実際の統計数値。",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "データについて",
    "midashi_english": "Frequently asked questions for our data",
    "midashi_japanese": "データについてのよくある質問と回答です",
    "count": "全5問",
    "photo": "アルパカ",
    "torf": 1,
    "question": "分析をより深めるためにはどうしたらよいですか？",
    "answer_1": "メッシュ統計データは、様々な機関で整備と公開が進められております。当サイトでは、各テーマの凡例の中にある「詳細を見る」ボタンから開く、「データ詳細」ウィンドウのなかで貼られている外部リンクが、一つの参考になります。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "データについて",
    "midashi_english": "Frequently asked questions for our data",
    "midashi_japanese": "データについてのよくある質問と回答です",
    "count": "全5問",
    "photo": "アルパカ",
    "torf": 1,
    "question": "３次元グラフが描画されている画面の、背景の地図の出典を教えてください。",
    "answer_1": "衛星写真と2種類の地形図とありますが、いずれも国土地理院から提供されているものになります。詳しくは、以下のサイトをご覧ください。",
    "answer_2": "地理院タイル",
    "answer_3": "なお、衛星写真と2種類の地形図は、「写真」と記されているボタンをクリック（タップ）することで、切り替わります。",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://maps.gsi.go.jp/development/ichiran.html",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "基本的な使い方を教えてください。",
    "answer_1": "基本的な操作方法は、トップページの右上側にある「ジオグラフの使い方」から入れるページで説明しています。また、通常画面の右上に4つ並んでいる黒地のボタンのうち、左から2つ目のボタンからも行くことができます。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "各メッシュの範囲がどのような地域か、詳しく知りたいのですが。",
    "answer_1": "可能です。各3次元グラフの天井をダブルクリックすると、該当のメッシュの地域にズームインされます。画面左側に表示されるウィンドウ内で「場所を確認する」ボタンを押すと、該当の地域がGoogleMapで呼び出され、住所も確認できます。3次元グラフの表示の無いところをダブルクリックすると、ズームアウトして元の視点に戻ります。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "画面を触るなどして動かしてしまった視点を、元に戻したいのですが。",
    "answer_1": "可能です。3次元グラフが描画されていないところで、ダブルクリック（タップ）してください。ある程度の高さから真北を向いた初期設定の視点位置に戻ります。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "複数の年次でデータがある場合に、それらはどうやったら表示されるのですか。",
    "answer_1": "画面上側にある、４ケタの数字が記されたボタンをクリック（タップ）すると、年次が切り替わります。",
    "answer_2": "なお、凡例の中の下側で、そのテーマが取り扱うデータの年次が示されています。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "地図を見るために３次元グラフを消したいのですが。",
    "answer_1": "可能です。画面上側にある、４ケタの数字が記されたボタンをクリック（タップ）して「OFF」と表示されると、３次元グラフが消えた状態になります。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "昔と今の３次元グラフを、一つの画面上で同時に描画させたいのですが。",
    "answer_1": "当サイトで可能です。 当サイトでは、３次元グラフを描画させるページ内の上側に、「地域の昔と比べる」タブがございます。これにより、同じ自治体の異なる年次のデータを左右で比較することができます。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "二つの自治体の３次元グラフを、比較して見たいのですが。",
    "answer_1": "当サイトで可能です。 当サイトでは、３次元グラフを描画させるページ内の上側に、「他の地域と比べる」タブがございます。これにより、二つの地域を左右で比較することができます。",
    "answer_2": "なお、比較するため、左右の画面の縮尺と視点の高さはそろえられていますが、右側の自治体を見る際に適した縮尺と高さに調節されています。そのため、左右の自治体の規模が違い過ぎると、左側の自治体にうまく視点が合わなくなります。そうした場合は、右側に規模の大きな自治体をもってくると、双方が視野に収まりやすくなります。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 0,
    "question": "地続きの自治体を一つの画面内でつなげて描画させたり、三つ以上の市町村の３次元グラフを一つの画面内で同時に描画させたいのですが。",
    "answer_1": "当サイトにあるKMLファイルのダウンロード機能をご活用いただくことで、可能になります。 当サイトでは、凡例の下側にある「高度な機能」のなかに「KML」というボタンがあり、各市町村ごとの３次元グラフ描画結果をKMLファイルとしてダウンロードすることができます。それらをGoogleEarthPro上で呼び出し、いずれをもチェックボックスでonにすれば、同一画面上で合わせて描画されることとなります（バージョン:7.3.3.7786 64-bit の時点)。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "三つ以上の近隣の市町村の３次元グラフを、一つの画面上で同時に描画させたいのですが。",
    "answer_1": "当サイトの源流となるWebGIS「都市構造可視化計画」であれば可能です。詳しくはそちらのサイトをご覧ください。",
    "answer_2": "都市構造可視化計画",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://mieruka.city/",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "県全体の３次元グラフを、一つの画面上で同時に描画させたいのですが。",
    "answer_1": "当サイトで可能です。 画面上側にある、自治体名が記されているボタンをクリック（タップ）すると、描画させたい都道府県を選択するページに移ります。都道府県を選ぶと、各市町村を選択するページに移りますが、その際に末尾にある「全体」を選んでください。県全体の描画が一気に開始されます。",
    "answer_2": "ただし、お使いのパソコンの性能やインターネットの通信環境によっては、描画が遅くなったりフリーズしたりする恐れもございますので、ご留意ください。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "日本全国の３次元グラフを、一つの画面上で同時に描画させたいのですが。",
    "answer_1": "当サイトで可能です。 ３次元グラフを描画させるページ内の右上側に、「国土全体で見る」タブがございます。そちらを選ぶと、自動的に日本全国の３次元グラフ描画が開始されます。",
    "answer_2": "こちらは、描画が重くならないよう、メッシュの大きさを広げてデータを軽くしてあります。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "「地域の昔と比べる」と「他の地域と比べる」では、視点がうまく動かせないのですが。",
    "answer_1": "画面内に左右で２つの地図がありますが、そのうち右側の地図のみで視点のズームイン・ズームアウトを操作することができます。これは、左右の縮尺を連動させているためです。",
    "answer_2": "また、「地域の昔と比べる」では、右側の地図のみで視点の移動を操作することができます。これは、左右の表示地域を連動させているためです。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "凡例内の右上側にある「1人:1m×１」というのはどういう意味ですか？",
    "answer_1": "まず、「1人:1m」の部分は〈高さ表示の換算式〉を表しており、あるグラフの範囲の人口が3,000人であれば、画面上の地図のなかでの3kmの高さで、グラフの高さを表示していることを示しています。",
    "answer_2": "その後ろの「×１」の部分は〈高さ表示の倍率〉を表しています。こちらは、凡例の下側にある【設定】から【グラフの高さ設定】を選んだ先で、変更できます。これにより、3次元グラフをお好みの高さに伸縮できます。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "操作について",
    "midashi_english": "Frequently asked questions for your operation",
    "midashi_japanese": "操作についてのよくある質問と回答です",
    "count": "全13問",
    "photo": "羊",
    "torf": 1,
    "question": "KMLファイルのダウンロードはできますか？",
    "answer_1": "当サイトの源流となるWebGIS「都市構造可視化計画」であれば可能です。詳しくはそちらのサイトをご覧ください。",
    "answer_2": "都市構造可視化計画",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://mieruka.city/",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "ご利用の際の留意点（著作権など）",
    "midashi_english": "Frequently asked questions for the problems (example: copyrights)",
    "midashi_japanese": "ご利用の際の留意点（著作権など）についてのよくある質問と回答です",
    "count": "全3問",
    "photo": "亀",
    "torf": 1,
    "question": "このサイトを授業で使ってもよいですか？",
    "answer_1": "はい、自由にお使いいただけます。高等学校の新設科目「地理総合」や「地理探究」における、地域を展望したり、国土像を構想したりする学びなどに、ご活用いただければ幸いです。",
    "answer_2": "また、文部科学省「社会科、地理歴史科、公民科の指導におけるICTの活用について」にて例示されている、“複数資料の重ね合わせ（を活かした地域分析）”に役立つツールとなれば幸いです。",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "ご利用の際の留意点（著作権など）",
    "midashi_english": "Frequently asked questions for the problems (example: copyrights)",
    "midashi_japanese": "ご利用の際の留意点（著作権など）についてのよくある質問と回答です",
    "count": "全3問",
    "photo": "亀",
    "torf": 1,
    "question": "夏休みの課題のテーマに使ってもよいですか？",
    "answer_1": "はい、もちろんお使いいただけます。高等学校の新設科目「総合的な探究の時間」などに、ご活用いただければ幸いです。",
    "answer_2": "",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "ご利用の際の留意点（著作権など）",
    "midashi_english": "Frequently asked questions for the problems (example: copyrights)",
    "midashi_japanese": "ご利用の際の留意点（著作権など）についてのよくある質問と回答です",
    "count": "全3問",
    "photo": "亀",
    "torf": 1,
    "question": "こちらのサイトの画像を、資料として使ってもよいですか？",
    "answer_1": "教育やまちづくりが目的であれば、自由にお使いいただけます。 ただし、資料には「地域見える化GISジオグラフ（© 2021 株式会社 帝国書院 & 非営利型一般社団法人 都市構造可視化推進機構）」のウェブサイトを参照していることを出典として明示してください。 教育やまちづくり以外の目的でご利用になりたい場合は、下記お問い合わせフォームにてお問い合わせください。",
    "answer_2": "お問い合わせフォーム",
    "answer_3": "",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://www.teikokushoin.co.jp/information/index08.php",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "ご利用の際の留意点（著作権など）",
    "midashi_english": "Frequently asked questions for the problems (example: copyrights)",
    "midashi_japanese": "ご利用の際の留意点（著作権など）についてのよくある質問と回答です",
    "count": "全3問",
    "photo": "亀",
    "torf": 0,
    "question": "Google Earth Pro のソフト上で表示した画像を、資料として使ってもよいですか？",
    "answer_1": "その場合は、Google社による使用条件があります。詳しくは以下をご確認ください。",
    "answer_2": "＊Google Earth 追加利用規約",
    "answer_3": "＊Google Earth の権利帰属表示に関するガイドライン",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://www.google.com/intl/ja/help/terms_maps/",
    "link_3": "https://about.google/brand-resource-center/",
    "link_4": ""
  },
  {
    "genre": "内容の改善に向けて",
    "midashi_english": "Frequently asked questions for our development",
    "midashi_japanese": "内容の改善に向けてのよくある質問と回答です",
    "count": "全2問",
    "photo": "ぶどう",
    "torf": 1,
    "question": "載せてほしいデータがあるのですが？",
    "answer_1": "是非情報をお寄せください。当サイトは、500mまたは1㎞メッシュ単位で集計されたメッシュデータであれば、広く取り扱うことができます。学習素材として効果的な、複数のデータの組み合わせ事例がございましたら、下記のお問い合わせフォームにお寄せいただければ幸いでございます。",
    "answer_2": "お問い合わせフォーム",
    "answer_3": "その際は、以下の項目を明示いただければ幸いでございます。①_3次元グラフの〈高さ〉で表したいデータ、②_3次元グラフの〈色彩〉で表したいデータ、③_①の出典（メッシュデータのウェブサイト上のアドレス）、④_②の出典（メッシュデータのウェブサイト上のアドレス）、⑤_①の出典元が無償公開・有償公開のいずれか、⑥_②の出典元が無償公開・有償公開のいずれか、⑦_①と②を組み合わせるねらい（どのようなことがとらえられるか、あるいは、どのようなことを生徒に学習・探究させたいか）。",
    "answer_4": "なお、当サイトで掲載しているデータ（2022年4月現在）は、全てが公的機関により公開されているデータであり、今後も基本的には公的機関によって公開されたデータが掲載対象となります。また、当サイトを支える専門家の方々は、基本的にプロボノ活動等によるご参画になりますため、一つ一つのご提案にお返事するのは難しいこと、何卒ご了承いただければ幸いでございます。",
    "link_1": "",
    "link_2": "https://www.teikokushoin.co.jp/information/index08.php",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "内容の改善に向けて",
    "midashi_english": "Frequently asked questions for our development",
    "midashi_japanese": "内容の改善に向けてのよくある質問と回答です",
    "count": "全2問",
    "photo": "ぶどう",
    "torf": 1,
    "question": "改善してもらいたい点があるのですが。",
    "answer_1": "是非、下記のお問い合わせフォームにお寄せいただければ幸いでございます。",
    "answer_2": "お問い合わせフォーム",
    "answer_3": "なお、当サイトを支える専門家の方々は、基本的にプロボノ活動等によるご参画になりますため、一つ一つのご提案にお返事するのは難しいこと、何卒ご了承いただければ幸いでございます。",
    "answer_4": "",
    "link_1": "",
    "link_2": "https://www.teikokushoin.co.jp/information/index08.php",
    "link_3": "",
    "link_4": ""
  },
  {
    "genre": "当サイトの成り立ち",
    "midashi_english": "Frequently asked questions for our process of creation",
    "midashi_japanese": "当サイトの成り立ちについてのよくある質問と回答です",
    "count": "全2問",
    "photo": "オーロラ",
    "torf": 1,
    "question": "こちらのサイトは、どういった着想でつくられたのですか？",
    "answer_1": "当サイトは、地方自治体の都市計画の現場などで活用されているWebGIS「都市構造可視化計画」を基として、着想されました。「都市構造可視化計画」の3次元グラフは、「色」と「高さ」の表現を駆使することで、二つの要素が相互作用しているエリアを強調表現することができます。これを基に、学校向けのユーザーインターフェイスへの改良や、高校地理教育向けのデータの掲載を図ったのが、当サイトです。",
    "answer_2": "文部科学省「社会科、地理歴史科、公民科の指導におけるICTの活用について」にて例示されている、“複数のデータの重ね合わせ（を活かした地域分析）”に役立つツールとなれば幸いです。",
    "answer_3": "なお、「都市構造可視化計画」ウェブサイトへのリンクは、下記となります。",
    "answer_4": "都市構造可視化計画",
    "link_1": "",
    "link_2": "",
    "link_3": "",
    "link_4": "https://mieruka.city/"
  },
  {
    "genre": "当サイトの成り立ち",
    "midashi_english": "Frequently asked questions for our process of creation",
    "midashi_japanese": "当サイトの成り立ちについてのよくある質問と回答です",
    "count": "全2問",
    "photo": "オーロラ",
    "torf": 1,
    "question": "こちらのサイトは、どのような方々によってつくられたのですか？",
    "answer_1": "当サイトの、学習指導要領に挙げられている内容に即したデータの整備や、ユーザーインターフェイスの改善などは、「都市構造可視化計画」の開発に携わる一般社団法人（非営利型）都市構造可視化推進機構-地理委員会のプロボノ活動等および、神奈川大学の根元一幸特任准教授のご協力により出来上がっております。",
    "answer_2": "なお、「都市構造可視化推進機構」ウェブサイトへのリンクは、下記となります。",
    "answer_3": "都市構造可視化推進機構",
    "answer_4": "",
    "link_1": "",
    "link_2": "",
    "link_3": "https://www.kashika.or.jp/",
    "link_4": ""
  }
]