import React, { forwardRef, useContext, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
// import logo from "./regtra_logo2.svg";
import { ModeSet } from "./App";
import { FullscreenSet } from "./App";
import { FullviewerSet } from "./App";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { Rotate0Set } from "./App";
import { Rotate1Set } from "./App";
import { Rotate2Set } from "./App";
import { Map1Set } from "./App";
import { Map2Set } from "./App";
import { Static2Map1Set } from "./App";
import { Static2Map2Set } from "./App";
import { Area1Set } from "./App";
import { Area2Set } from "./App";
import {
  photo,
  palemap,
  stdmap,
  blankmap,
  engmap,
  oldphoto,
  oldmap,
} from "./cesiumsetting";
import { Fullscreen } from "cesium";
import {
  population_now,
  population_old,
  agedpop_now,
  agedpop_old,
  worker23_now,
  worker23_old,
  daypop_now,
  daypop_old,
  sales_now,
  sales_old,
} from "./kashikasetting";
import { Year1Set, Year2Set } from "./App";
import { Zoomhanrei1Set, Zoomhanrei2Set } from "./App";
import { useLocation } from "react-router-dom";

export const MultiSetting = (props) => {
  const { modeset, setModeSet } = useContext(ModeSet);
  const { fullviewerset, setFullviewerSet } = useContext(FullviewerSet);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const { area1set, setArea1Set } = useContext(Area1Set);
  const { area2set, setArea2Set } = useContext(Area2Set);
  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);

  // console.log(props.static2map1set.year5);

  const multisetting = () => {
    // console.log(area1set);
    // console.log(props.area1set);
    // console.log(area2set);
    // console.log(props.area2set);
    {props.modeset && 
      setModeSet(props.modeset);
    }
    {props.fullviewerset && 
      setFullviewerSet(props.fullviewerset);
    }
    {props.rendouset && 
      setRendouSet(props.rendouset);
    }
    {props.syukurenset &&
      setSyukurenSet(props.syukurenset);
    }
    {props.map1set && 
      setMap1Set(props.map1set);
    }
    {props.map2set &&
      setMap2Set(props.map2set);
    }
    {props.static2map1set &&
      setStatic2Map1Set(props.static2map1set);
    }
    {props.static2map2set &&
      setStatic2Map2Set(props.static2map2set);
    }
    setRotate1Set(props.rotate1set || false);
    setRotate2Set(props.rotate2set || false);
    setArea1Set(props.area1set || area1set);
    setArea2Set(props.area2set || area2set);
    setYear1Set(props.year1set || props.static2map1set.year5);
    setYear2Set(props.year2set || props.static2map1set.year5);
  };

  var linkto = props.to || "/map";

  return (
    <>
      <Link
        to={
          linkto + "/" + props.modeset + "/" + props.no + "/" + props.area1set
        }
        onClick={multisetting}
      >
        {props.children}
      </Link>
    </>
  );
};

export const Year0set = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);
  const { zoomhanrei1set, setZoomhanrei1Set } = useContext(Zoomhanrei1Set);
  const { zoomhanrei2set, setZoomhanrei2Set } = useContext(Zoomhanrei2Set);

  // console.log(static2map1set.year5);
  // console.log(year1set);

  // 左（rfcs1）統計表示年の配列を更新 ///////////////////////////
  let year1s = [];
  useEffect(() => {
    if (static2map1set.year1) {
      year1s.push(static2map1set.year1);
    }
    if (static2map1set.year2) {
      year1s.push(static2map1set.year2);
    }
    if (static2map1set.year3) {
      year1s.push(static2map1set.year3);
    }
    if (static2map1set.year4) {
      year1s.push(static2map1set.year4);
    }
    if (static2map1set.year5) {
      year1s.push(static2map1set.year5);
    }
    // setYear1Set(year1s)
  }, [static2map1set, year1set]);
  // console.log(year1s);
  // console.log(year1set);

  const year0button = () => {
    if (year1s && year1set == 3000) {
      setYear1Set(year1s[0]);
      setYear2Set(year1s[0]);
    } else if (year1s[1] && year1set == year1s[0]) {
      setYear1Set(year1s[1]);
      setYear2Set(year1s[1]);
    } else if (year1s[2] && year1set == year1s[1]) {
      setYear1Set(year1s[2]);
      setYear2Set(year1s[2]);
    } else if (year1s[3] && year1set == year1s[2]) {
      setYear1Set(year1s[3]);
      setYear2Set(year1s[3]);
    } else if (year1s[4] && year1set == year1s[3]) {
      setYear1Set(year1s[4]);
      setYear2Set(year1s[4]);
      // } else if ( !year1set ){setYear1Set(year1s[4]);setYear2Set(year1s[4]);
    } else {
      setYear1Set(3000);
      setYear2Set(3000);
    }
  };
  const location = useLocation();
  var to = to || location.pathname;
  // var to = to || "/map"
  const Link2m = () => {
    return (
      <>
        <Link to={to} data-tip="統計の年次を切り替えます">
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
        </Link>
      </>
    );
  };
  return (
    <>
      {year1set === static2map1set.year1 && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year1}
        </h2>
      )}
      {year1set === static2map1set.year2 && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year2}
        </h2>
      )}
      {year1set === static2map1set.year3 && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year3}
        </h2>
      )}
      {year1set === static2map1set.year4 && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year4}
        </h2>
      )}
      {year1set === static2map1set.year5 && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year5}
        </h2>
      )}
      {!year1set && (
        <h2 onClick={year0button}>
          <Link2m />
          {static2map1set.year5}
        </h2>
      )}
      {year1set == 3000 && (
        <h2 onClick={year0button}>
          <Link2m />
          OFF
        </h2>
      )}
      {/* { zoomhanrei1set && <h2 onClick={year0button} ><Link2m />OFF</h2> }
{ !zoomhanrei1set && year1set === static2map1set.year1 && <h2 onClick={year0button} ><Link2m />{static2map1set.year1}</h2> }
{ !zoomhanrei1set && year1set === static2map1set.year2 && <h2 onClick={year0button} ><Link2m />{static2map1set.year2}</h2> }
{ !zoomhanrei1set && year1set === static2map1set.year3 && <h2 onClick={year0button} ><Link2m />{static2map1set.year3}</h2> }
{ !zoomhanrei1set && year1set === static2map1set.year4 && <h2 onClick={year0button} ><Link2m />{static2map1set.year4}</h2> }
{ !zoomhanrei1set && year1set === static2map1set.year5 && <h2 onClick={year0button} ><Link2m />{static2map1set.year5}</h2> }
{ !zoomhanrei1set && !year1set && <h2 onClick={year0button} ><Link2m />{static2map1set.year5}</h2> }
{ !zoomhanrei1set && year1set == 3000 && <h2 onClick={year0button} ><Link2m />OFF</h2> } */}
    </>
  );
};

export const Year1set = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);
  const { zoomhanrei1set, setZoomhanrei1Set } = useContext(Zoomhanrei1Set);
  const { zoomhanrei2set, setZoomhanrei2Set } = useContext(Zoomhanrei2Set);

  // console.log(static2map1set.year5);
  // console.log(year1set);

  // 左（rfcs1）統計表示年の配列を更新 ///////////////////////////
  let year1s = [];
  useEffect(() => {
    if (static2map1set.year1) {
      year1s.push(static2map1set.year1);
    }
    if (static2map1set.year2) {
      year1s.push(static2map1set.year2);
    }
    if (static2map1set.year3) {
      year1s.push(static2map1set.year3);
    }
    if (static2map1set.year4) {
      year1s.push(static2map1set.year4);
    }
    if (static2map1set.year5) {
      year1s.push(static2map1set.year5);
    }
    // setYear1Set(year1s)
  }, [static2map1set, year1set]);
  // console.log(year1s);
  // console.log(year1set);

  const year1button = () => {
    if (year1s && year1set == 3000) {
      setYear1Set(year1s[0]);
    } else if (year1s[1] && year1set == year1s[0]) {
      setYear1Set(year1s[1]);
    } else if (year1s[2] && year1set == year1s[1]) {
      setYear1Set(year1s[2]);
    } else if (year1s[3] && year1set == year1s[2]) {
      setYear1Set(year1s[3]);
    } else if (year1s[4] && year1set == year1s[3]) {
      setYear1Set(year1s[4]);
      // } else if ( !year1set ){setYear1Set(year1s[4]);setYear2Set(year1s[4]);
    } else {
      setYear1Set(3000);
    }
    // 一番古い年次とOFFだけにする場合（動作します 20220306）
    // if (year1s && year1set == 3000) {
    //   setYear1Set(year1s[0]);
    // } else {
    //   setYear1Set(3000);
    // }
  };
  const location = useLocation();
  var to = to || location.pathname;
  // var to = to || "/map"
  const Link2m = () => {
    return (
      <>
        <Link to={to} data-tip="統計の年次を切り替えます">
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
        </Link>
      </>
    );
  };
  return (
    <>
      <h2 onClick={year1button}>
        <Link2m />
        {year1set === static2map1set.year1 && <>{static2map1set.year1}</>}
        {year1set === static2map1set.year2 && <>{static2map1set.year2}</>}
        {year1set === static2map1set.year3 && <>{static2map1set.year3}</>}
        {year1set === static2map1set.year4 && <>{static2map1set.year4}</>}
        {year1set === static2map1set.year5 && <>{static2map1set.year5}</>}
        {!year1set && <>{static2map1set.year5}</>}
        {year1set == 3000 && <>OFF</>}
      </h2>
    </>
  );
};

export const Year2set = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);
  const { zoomhanrei1set, setZoomhanrei1Set } = useContext(Zoomhanrei1Set);
  const { zoomhanrei2set, setZoomhanrei2Set } = useContext(Zoomhanrei2Set);

  // console.log(static2map1set.year5);
  // console.log(year1set);

  // 右（rfcs1）統計表示年の配列を更新 ///////////////////////////
  let year1s = [];
  useEffect(() => {
    if (static2map2set.year1) {
      year1s.push(static2map2set.year1);
    }
    if (static2map2set.year2) {
      year1s.push(static2map2set.year2);
    }
    if (static2map2set.year3) {
      year1s.push(static2map2set.year3);
    }
    if (static2map2set.year4) {
      year1s.push(static2map2set.year4);
    }
    if (static2map2set.year5) {
      year1s.push(static2map2set.year5);
    }
    // setYear1Set(year1s)
  }, [static2map2set, year2set]);
  // console.log(year1s);
  // console.log(year1set);

  const year2button = () => {
    if (year1s && year2set == 3000) {
      setYear2Set(year1s[0]);
    } else if (year1s[1] && year2set == year1s[0]) {
      setYear2Set(year1s[1]);
    } else if (year1s[2] && year2set == year1s[1]) {
      setYear2Set(year1s[2]);
    } else if (year1s[3] && year2set == year1s[2]) {
      setYear2Set(year1s[3]);
    } else if (year1s[4] && year2set == year1s[3]) {
      setYear2Set(year1s[4]);
      // } else if ( !year1set ){setYear1Set(year1s[4]);setYear2Set(year1s[4]);
    } else {
      setYear2Set(3000);
    }
    // if (year1s && year2set == 3000) {
    //   setYear2Set(year1s.slice(-1)[0]);
    // } else {
    //   setYear2Set(3000);
    // }
  };
  const location = useLocation();
  var to = to || location.pathname;
  // var to = to || "/map"
  const Link2m = () => {
    return (
      <>
        <Link to={to} data-tip="統計の年次を切り替えます">
          <ReactTooltip
            effect="float"
            type="info"
            place="right"
            delayShow={1000}
          />
          {xl && (
            <svg
              className="w-12 h-12 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
          {!xl && (
            <svg
              className="w-6 h-6 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              ></path>
            </svg>
          )}
        </Link>
      </>
    );
  };
  return (
    <>
      <h2 onClick={year2button}>
        <Link2m />
        {year2set === static2map2set.year1 && <>{static2map2set.year1}</>}
        {year2set === static2map2set.year2 && <>{static2map2set.year2}</>}
        {year2set === static2map2set.year3 && <>{static2map2set.year3}</>}
        {year2set === static2map2set.year4 && <>{static2map2set.year4}</>}
        {year2set === static2map2set.year5 && <>{static2map2set.year5}</>}
        {!year2set && <>{static2map2set.year5}</>}
        {year2set == 3000 && <>OFF</>}
      </h2>
    </>
  );
};

export const Static2Map0aset = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const map0button = () => {
    if (static2map1set === population_now) {
      setStatic2Map1Set(population_old);
      setStatic2Map2Set(population_now);
    } else if (static2map1set === population_old) {
      setStatic2Map1Set(agedpop_now);
      setStatic2Map2Set(agedpop_now);
    } else if (static2map1set === agedpop_now) {
      setStatic2Map1Set(agedpop_old);
      setStatic2Map2Set(agedpop_old);
    } else if (static2map1set === agedpop_old) {
      setStatic2Map1Set(worker23_now);
      setStatic2Map2Set(worker23_now);
    } else if (static2map1set === worker23_now) {
      setStatic2Map1Set(worker23_old);
      setStatic2Map2Set(worker23_old);
    } else if (static2map1set === worker23_old) {
      setStatic2Map1Set(daypop_now);
      setStatic2Map2Set(daypop_now);
    } else if (static2map1set === daypop_now) {
      setStatic2Map1Set(daypop_old);
      setStatic2Map2Set(daypop_old);
    } else if (static2map1set === daypop_old) {
      setStatic2Map1Set(sales_now);
      setStatic2Map2Set(sales_now);
    } else if (static2map1set === sales_now) {
      setStatic2Map1Set(sales_old);
      setStatic2Map2Set(sales_old);
    } else if (static2map1set === sales_old) {
      setStatic2Map1Set(null);
      setStatic2Map2Set(null);
    } else if (static2map1set === null) {
      setStatic2Map1Set(population_now);
      setStatic2Map2Set(population_now);
    } else {
      setStatic2Map1Set(population_now);
      setStatic2Map2Set(population_now);
    }
  };
  var to = to || "/map";
  const Link2m = () => {
    return (
      <Link to={to} data-tip="統計の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        {xl && (
          <svg
            className="w-12 h-12 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
        )}
        {!xl && (
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
        )}
      </Link>
    );
  };
  return (
    <>
      {static2map1set === population_now && (
        <h2 onClick={map0button}>
          <Link2m />
          人口
          <br />
          （今）
          <br />
          　
        </h2>
      )}
      {static2map1set === population_old && (
        <h2 onClick={map0button}>
          <Link2m />
          人口
          <br />
          （昔）
          <br />
          　
        </h2>
      )}
      {static2map1set === agedpop_now && (
        <h2 onClick={map0button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === agedpop_old && (
        <h2 onClick={map0button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === worker23_now && (
        <h2 onClick={map0button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === worker23_old && (
        <h2 onClick={map0button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === daypop_now && (
        <h2 onClick={map0button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === daypop_old && (
        <h2 onClick={map0button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === sales_now && (
        <h2 onClick={map0button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （今）
        </h2>
      )}
      {static2map1set === sales_old && (
        <h2 onClick={map0button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === null && (
        <h2 onClick={map0button}>
          <Link2m />
          地図
          <br />
          表示
        </h2>
      )}
    </>
  );
};

export const Static2Map0set = ({ xl, to }) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const map0button = () => {
    if (
      static2map1set === population_now ||
      static2map1set === population_old
    ) {
      setStatic2Map1Set(agedpop_old);
      setStatic2Map2Set(agedpop_now);
    } else if (
      static2map1set === agedpop_now ||
      static2map1set === agedpop_old
    ) {
      setStatic2Map1Set(worker23_old);
      setStatic2Map2Set(worker23_now);
    } else if (
      static2map1set === worker23_now ||
      static2map1set === worker23_old
    ) {
      setStatic2Map1Set(daypop_old);
      setStatic2Map2Set(daypop_now);
    } else if (static2map1set === daypop_now || static2map1set === daypop_old) {
      setStatic2Map1Set(sales_old);
      setStatic2Map2Set(sales_now);
    } else if (static2map1set === sales_now || static2map1set === sales_old) {
      setStatic2Map1Set(null);
      setStatic2Map2Set(null);
    } else if (static2map1set === null) {
      setStatic2Map1Set(population_old);
      setStatic2Map2Set(population_now);
    } else {
      setStatic2Map1Set(population_old);
      setStatic2Map2Set(population_now);
    }
  };
  var to = to || "/map";
  const Link2m = ({ xl, to }) => {
    return (
      <Link to={to} data-tip="統計の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />
        {xl && (
          <svg
            className="w-12 h-12 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
        )}
        {!xl && (
          <svg
            className="w-6 h-6 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
        )}
      </Link>
    );
  };
  return (
    <>
      {static2map1set === population_now && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          人口
          <br />
          　
        </h2>
      )}
      {static2map1set === population_old && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          人口
          <br />
          　
        </h2>
      )}
      {static2map1set === agedpop_now && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          高齢
          <br />
          人口
        </h2>
      )}
      {static2map1set === agedpop_old && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          高齢
          <br />
          人口
        </h2>
      )}
      {static2map1set === worker23_now && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          就業
          <br />
          人口
        </h2>
      )}
      {static2map1set === worker23_old && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          就業
          <br />
          人口
        </h2>
      )}
      {static2map1set === daypop_now && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          昼間
          <br />
          人口
        </h2>
      )}
      {static2map1set === daypop_old && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          昼間
          <br />
          人口
        </h2>
      )}
      {static2map1set === sales_now && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          販売
          <br />
          金額
        </h2>
      )}
      {static2map1set === sales_old && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          販売
          <br />
          金額
        </h2>
      )}
      {static2map1set === null && (
        <h2 onClick={map0button}>
          <Link2m xl={xl} to={to} />
          地図
          <br />
          表示
        </h2>
      )}
    </>
  );
};

export const Static2Map1set = (props) => {
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const map1button = () => {
    if (static2map1set === population_now) {
      setStatic2Map1Set(population_old);
    } else if (static2map1set === population_old) {
      setStatic2Map1Set(agedpop_now);
    } else if (static2map1set === agedpop_now) {
      setStatic2Map1Set(agedpop_old);
    } else if (static2map1set === agedpop_old) {
      setStatic2Map1Set(worker23_now);
    } else if (static2map1set === worker23_now) {
      setStatic2Map1Set(worker23_old);
    } else if (static2map1set === worker23_old) {
      setStatic2Map1Set(daypop_now);
    } else if (static2map1set === daypop_now) {
      setStatic2Map1Set(daypop_old);
    } else if (static2map1set === daypop_old) {
      setStatic2Map1Set(sales_now);
    } else if (static2map1set === sales_now) {
      setStatic2Map1Set(sales_old);
    } else if (static2map1set === sales_old) {
      setStatic2Map1Set(null);
    } else if (static2map1set === null) {
      setStatic2Map1Set(population_now);
    } else {
      setStatic2Map1Set(population_now);
    }
  };
  const Link2m = () => {
    return (
      <Link to="/map" data-tip="統計の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />{" "}
        <svg
          className="w-6 h-6 mx-auto"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
          ></path>
        </svg>
      </Link>
    );
  };
  return (
    <>
      {static2map1set === population_now && (
        <h2 onClick={map1button}>
          <Link2m />
          人口
          <br />
          （今）
          <br />
          　
        </h2>
      )}
      {static2map1set === population_old && (
        <h2 onClick={map1button}>
          <Link2m />
          人口
          <br />
          （昔）
          <br />
          　
        </h2>
      )}
      {static2map1set === agedpop_now && (
        <h2 onClick={map1button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === agedpop_old && (
        <h2 onClick={map1button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === worker23_now && (
        <h2 onClick={map1button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === worker23_old && (
        <h2 onClick={map1button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === daypop_now && (
        <h2 onClick={map1button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map1set === daypop_old && (
        <h2 onClick={map1button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === sales_now && (
        <h2 onClick={map1button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （今）
        </h2>
      )}
      {static2map1set === sales_old && (
        <h2 onClick={map1button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （昔）
        </h2>
      )}
      {static2map1set === null && (
        <h2 onClick={map1button}>
          <Link2m />
          地図
          <br />
          表示
          <br />
          　
        </h2>
      )}
    </>
  );
};

export const Static2Map2set = (props) => {
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const map2button = () => {
    if (static2map2set === population_now) {
      setStatic2Map2Set(population_old);
    } else if (static2map2set === population_old) {
      setStatic2Map2Set(agedpop_now);
    } else if (static2map2set === agedpop_now) {
      setStatic2Map2Set(agedpop_old);
    } else if (static2map2set === agedpop_old) {
      setStatic2Map2Set(worker23_now);
    } else if (static2map2set === worker23_now) {
      setStatic2Map2Set(worker23_old);
    } else if (static2map2set === worker23_old) {
      setStatic2Map2Set(daypop_now);
    } else if (static2map2set === daypop_now) {
      setStatic2Map2Set(daypop_old);
    } else if (static2map2set === daypop_old) {
      setStatic2Map2Set(sales_now);
    } else if (static2map2set === sales_now) {
      setStatic2Map2Set(sales_old);
    } else if (static2map2set === sales_old) {
      setStatic2Map2Set(null);
    } else if (static2map2set === null) {
      setStatic2Map2Set(population_now);
    } else {
      setStatic2Map2Set(population_now);
    }
  };
  const Link2m = () => {
    return (
      <Link to="/map" data-tip="統計の種類を切り替えます">
        <ReactTooltip
          effect="float"
          type="info"
          place="right"
          delayShow={1000}
        />{" "}
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
      </Link>
    );
  };

  return (
    <>
      {static2map2set === population_now && (
        <h2 onClick={map2button}>
          <Link2m />
          人口
          <br />
          （今）
          <br />
          　
        </h2>
      )}
      {static2map2set === population_old && (
        <h2 onClick={map2button}>
          <Link2m />
          人口
          <br />
          （昔）
          <br />
          　
        </h2>
      )}
      {static2map2set === agedpop_now && (
        <h2 onClick={map2button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map2set === agedpop_old && (
        <h2 onClick={map2button}>
          <Link2m />
          高齢
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map2set === worker23_now && (
        <h2 onClick={map2button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map2set === worker23_old && (
        <h2 onClick={map2button}>
          <Link2m />
          就業
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map2set === daypop_now && (
        <h2 onClick={map2button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （今）
        </h2>
      )}
      {static2map2set === daypop_old && (
        <h2 onClick={map2button}>
          <Link2m />
          昼間
          <br />
          人口
          <br />
          （昔）
        </h2>
      )}
      {static2map2set === sales_now && (
        <h2 onClick={map2button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （今）
        </h2>
      )}
      {static2map2set === sales_old && (
        <h2 onClick={map2button}>
          <Link2m />
          販売
          <br />
          金額
          <br />
          （昔）
        </h2>
      )}
      {static2map2set === null && (
        <h2 onClick={map2button}>
          <Link2m />
          地図
          <br />
          表示
          <br />
          　
        </h2>
      )}
    </>
  );
};
