import React from "react";
// import logo from "./regtra_logo2.svg";
import { Link } from "react-router-dom";
import tsukaikata from "./meta_howto_20220328.js";
import howto_1 from "./img/howto_1.webp";
import howto_2 from "./img/howto_2.webp";
import howto_3 from "./img/howto_3.webp";
import howto_4 from "./img/howto_4.webp";
import howto_5 from "./img/howto_5.webp";

export const Howto = () => {
  // console.log(tsukaikata);

  return (
    <>
      <div className="absolute z-20 max-h-full font-mono text-white w-full ">
        <div className="absolute z-20 mt-14 bg-gradient-to-r from-white/90 via-white/80 to-white/90 px-10 max-h-[calc(100vh-80px)] w-full overflow-auto">
          <section class="container mx-auto px-6 p-10">
            <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">
              ジオグラフの使い方
            </h2>
            <div class="flex items-center flex-wrap mb-20 text-left">
              <div class="w-full md:w-1/2">
                <h4 class="text-3xl text-gray-800 font-bold mb-3">
                  {tsukaikata[1].komidashi}
                </h4>
                <p class="text-gray-600 mb-8">{tsukaikata[1].setsumei_1}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[1].setsumei_2}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[1].setsumei_3}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[1].setsumei_4}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[1].setsumei_5}</p>
              </div>
              <div className="w-full md:w-1/2">
                <div className="p-2">
                  <img
                    src={howto_1}
                    alt="Monitoring"
                    className="object-center w-full h-auto"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center flex-wrap mb-20 text-left">
              <div class="w-full md:w-1/2">
                <div className="p-2">
                  <img
                    src={howto_2}
                    alt="Monitoring"
                    className="object-center w-full h-auto"
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 pl-10">
                <h4 class="text-3xl text-gray-800 font-bold mb-3">
                  {tsukaikata[2].komidashi}
                </h4>
                <p class="text-gray-600 mb-8">{tsukaikata[2].setsumei_1}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[2].setsumei_2}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[2].setsumei_3}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[2].setsumei_4}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[2].setsumei_5}</p>
              </div>
            </div>

            <div class="flex items-center flex-wrap mb-20 text-left">
              <div class="w-full md:w-1/2">
                <h4 class="text-3xl text-gray-800 font-bold mb-3">
                  {tsukaikata[3].komidashi}
                </h4>
                <p class="text-gray-600 mb-8">{tsukaikata[3].setsumei_1}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[3].setsumei_2}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[3].setsumei_3}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[3].setsumei_4}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[3].setsumei_5}</p>
              </div>
              <div class="w-full md:w-1/2">
                <div className="p-2">
                  <img
                    src={howto_3}
                    alt="Monitoring"
                    className="object-center w-full h-auto"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center flex-wrap mb-20 text-left">
              <div class="w-full md:w-1/2">
                <div className="p-2">
                  <img
                    src={howto_4}
                    alt="Monitoring"
                    className="object-center w-full h-auto"
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 pl-10">
                <h4 class="text-3xl text-gray-800 font-bold mb-3">
                  {tsukaikata[4].komidashi}
                </h4>
                <p class="text-gray-600 mb-8">{tsukaikata[4].setsumei_1}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[4].setsumei_2}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[4].setsumei_3}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[4].setsumei_4}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[4].setsumei_5}</p>
              </div>
            </div>

            <div class="flex items-center flex-wrap mb-20 text-left">
              <div class="w-full md:w-1/2">
                <h4 class="text-3xl text-gray-800 font-bold mb-3">
                  {tsukaikata[5].komidashi}
                </h4>
                <p class="text-gray-600 mb-8">{tsukaikata[5].setsumei_1}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[5].setsumei_2}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[5].setsumei_3}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[5].setsumei_4}</p>
                <p class="text-gray-600 mb-8">{tsukaikata[5].setsumei_5}</p>
              </div>
              <div class="w-full md:w-1/2">
                <div className="p-2">
                  <a href="/select">
                    <img
                      src={howto_5}
                      alt="Monitoring"
                      className="object-center w-full h-auto"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* 

  <ol className="relative mt-14  border-l border-gray-200 dark:border-gray-700 text-left max-h-[calc(100vh-240px)]">
    <li className="mb-10 ml-6">            
        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-200 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <svg className="w-3 h-3 text-blue-600 dark:text-blue-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
        </span>
        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">初期視点に戻る <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">機能</span></h3>
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">初期視点に戻る方法です。</time>
        <div>初期視点に戻るためには、グラフ以外の部分をダブルクリックしてください。</div>
    </li>
</ol>

<ol className="relative mt-14  border-l border-gray-200 dark:border-gray-700 text-left max-h-[calc(100vh-240px)]">
    <li className="mb-10 ml-6">            
        <span className="absolute flex items-center justify-center w-6 h-6 bg-green-200 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-green-900">
            <svg className="w-3 h-3 text-green-600 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
        </span>
        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">データが表示されない場合 <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800 ml-3">解説・補足</span></h3>
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">データが表示されない場合の解説です。</time>
        <div>データがない市町村の場合、警告が表示されます。他の市町村を選んでください。全国表示の一部は提供していません。</div>
    </li>
</ol>

  <ol className="relative mt-14  border-l border-gray-200 dark:border-gray-700 text-left max-h-[calc(100vh-240px)]">
    <li className="mb-10 ml-6">            
        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-200 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
            <svg className="w-3 h-3 text-blue-600 dark:text-blue-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
        </span>
        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">グラフの高さを変える <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">機能</span></h3>
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">グラフの高さを変える方法です。</time>
        <div>グラフの高さを変えるには、左側凡例の下の設定よりお進みください。</div>
    </li>
</ol> */}

          <Link to="/">Home</Link>
        </div>
      </div>
    </>
  );
};
