import { useContext } from "react";
// import {MultiSetting} from "./kashikabuttons";
// import { population_now, population_old } from "./kashikasetting";
// import { photo, palemap, stdmap, blankmap, engmap, oldphoto, oldmap } from "./cesiumsetting";
// import Sdgs1 from "./img/sdg_1.webp";
// import Sdgs2 from "./img/sdg_2.webp";
// import Sdgs3 from "./img/sdg_3.webp";
// import Sdgs4 from "./img/sdg_4.webp";
// import Sdgs5 from "./img/sdg_5.webp";
// import Sdgs6 from "./img/sdg_6.webp";
// import Sdgs7 from "./img/sdg_7.webp";
// import Sdgs8 from "./img/sdg_8.webp";
// import Sdgs9 from "./img/sdg_9.webp";
// import Sdgs10 from "./img/sdg_10.webp";
// import Sdgs11 from "./img/sdg_11.webp";
// import Sdgs12 from "./img/sdg_12.webp";
// import Sdgs13 from "./img/sdg_13.webp";
// import Sdgs14 from "./img/sdg_14.webp";
// import Sdgs15 from "./img/sdg_15.webp";
// import Sdgs16 from "./img/sdg_16.webp";
// import Sdgs17 from "./img/sdg_17.webp";
// import Sdgs18 from "./img/sdg_18.webp";

import Chiri1 from "./img/01_tikei.svg";
import Chiri2 from "./img/02_kikou.svg";
import Chiri3 from "./img/03_tikyuukannkyou.svg";
import Chiri4 from "./img/04_koutuu.svg";
import Chiri5 from "./img/05_tuusin.svg";
import Chiri6 from "./img/06_zinkou.svg";
import Chiri7 from "./img/07_minzoku.svg";
import Chiri8 from "./img/08_nougyou.svg";
import Chiri9 from "./img/09_ringyou.svg";
import Chiri10 from "./img/10_suisangyou.svg";
import Chiri11 from "./img/11_sigenn_enerugi.svg";
import Chiri12 from "./img/12_kougyou.svg";
import Chiri13 from "./img/13_kougyou.svg";
import Chiri14 from "./img/14_dai3zisangyou.svg";
import Chiri15 from "./img/15_roudou.svg";
import Chiri16 from "./img/16_kankou.svg";
import Chiri17 from "./img/17_keizai.svg";
import Chiri18 from "./img/18_boueki.svg";
import Chiri19 from "./img/19_kokusai.svg";
import Chiri20 from "./img/20_sekainokuniguni.svg";

// import logo1 from "./img/faq_1.webp";
// import logo2 from "./img/faq_2.webp";
// import logo3 from "./img/faq_3.webp";
// import logo4 from "./img/faq_4.webp";
// import logo5 from "./img/faq_5.webp";

// import { Tags } from "./tags";
import { Link } from "react-router-dom";
import { Theme1Set } from "./App";
import faqmeta from "./meta_faq_20220328";

// SDGsモーダル
const Sdgsicon = ({ icon, settheme }) => {
  const { theme1set, setTheme1Set } = useContext(Theme1Set);
  let to = "/tags/" + settheme;
  return (
    <div
      className="w-full bg-white sahdow-lg overflow-hidden flex flex-col md:flex-row"
      onClick={() => setTheme1Set(settheme)}
    >
      <div className="w-full">
        <Link to={to} tag="sdgs1">
          <img className="object-center w-full h-full" src={icon} alt="sdgs" />
        </Link>
      </div>
    </div>
  );
};

export function FAQ1({ show, setShow, title }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  const metapre = faqmeta.filter((r) => r.torf == 1);
  const meta = metapre.filter((r) => r.genre == title);
  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "90%",
              padding: "",
              background: "#fff",
            }}
            className="h-[90vh] overflow-y-auto z-100"
          >
            <div>
              <div className="bg-white py-6 sm:py-8 lg:py-12">
                <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                  <div className="mb-10 md:mb-16">
                    <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
                      FAQ: {title}
                    </h2>
                    <p className="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
                      {title}のよくある質問と回答です
                    </p>
                  </div>

                  <div className="grid sm:grid-cols-1 xl:grid-cols-1 gap-8 sm:gap-y-10">
                    {meta.map(function (r) {
                      return (
                        <>
                          <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
                            <span className="w-8 h-8 inline-flex justify-center items-center bg-indigo-500 text-white rounded-full absolute -top-4 left-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>

                            <>
                              <h3 className="text-indigo-500 text-lg md:text-xl font-semibold mb-3">
                                {r.question}
                              </h3>
                              <p className="text-gray-500 text-left">
                                {r.answer_1}
                              </p>
                              {r.link_2 && (
                                <a href={r.link_2}>
                                  <p className="text-blue-700 underline">
                                    {r.answer_2}
                                  </p>
                                </a>
                              )}
                              {!r.link_2 && (
                                <p className="text-gray-500 text-left">
                                  {r.answer_2}
                                </p>
                              )}
                              {r.link_3 && (
                                <a href={r.link_3}>
                                  <p className="text-blue-700 underline">
                                    {r.answer_3}
                                  </p>
                                </a>
                              )}
                              {!r.link_3 && (
                                <p className="text-gray-500 text-left">
                                  {r.answer_3}
                                </p>
                              )}
                              {r.link_4 && (
                                <a href={r.link_4}>
                                  <p className="text-blue-700 underline">
                                    {r.answer_4}
                                  </p>
                                </a>
                              )}
                              {!r.link_4 && (
                                <p className="text-gray-500 text-left">
                                  {r.answer_4}
                                </p>
                              )}
                            </>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }} className="text-black">
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

// 地理総合モーダル
const Sougoubutton = ({ settheme, link }) => {
  const { setTheme1Set } = useContext(Theme1Set);
  let to = "/tags/" + settheme;

  return (
    <div
      className="w-full bg-white sahdow-lg overflow-hidden flex flex-col md:flex-row"
      onClick={() => setTheme1Set(settheme)}
    >
      <div className="w-full">
        {link && (
          <Link to={to} tag="sdgs1">
            <button className="relative w-full inline-flex items-center justify-center p-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500  text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-md group-hover:bg-opacity-0">
                <div className="m-auto">{settheme}</div>
              </div>
            </button>
          </Link>
        )}
        {!link && (
          <button className="relative w-full inline-flex items-center justify-center p-0 my-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 text-white focus:ring-4 ">
            <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 rounded-lg group-hover:bg-opacity-0">
              <div className="m-auto">{settheme}</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export function FAQ2({ show, setShow }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "90%",
              padding: "",
              background: "#fff",
            }}
            className="h-[90vh] overflow-y-auto z-100"
          >
            <div>
              <div>
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-8">
                  <div className="text-center pb-12">
                    <h2 className="text-base font-bold text-indigo-600">
                      地理総合のテーマから選びましょう
                    </h2>
                    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                      地理総合から選ぶ
                    </h1>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                    <Sougoubutton
                      settheme={"地図や地理情報システム"}
                      link={true}
                    />
                    <Sougoubutton
                      settheme={"地球的課題と国際協力"}
                      link={true}
                    />
                    <Sougoubutton
                      settheme={"結びつきを深める現代世界"}
                      link={true}
                    />
                    <Sougoubutton settheme={"自然環境と防災"} link={true} />
                    <Sougoubutton
                      settheme={"生活文化の多様性と国際理解"}
                      link={false}
                    />
                    <Sougoubutton
                      settheme={"生活圏の調査と地域の展望"}
                      link={true}
                    />
                  </div>
                </section>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

// 地理探究モーダル
const Tankyubutton = ({ settheme, link }) => {
  const { setTheme1Set } = useContext(Theme1Set);
  let to = "/tags/" + settheme;

  return (
    <div
      className="w-full bg-white sahdow-lg overflow-hidden flex flex-col md:flex-row"
      onClick={() => setTheme1Set(settheme)}
    >
      <div className="w-full">
        {link && (
          <Link to={to} tag="sdgs1">
            <button className="relative w-full inline-flex items-center justify-center p-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500  text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-md group-hover:bg-opacity-0">
                <div className="m-auto">{settheme}</div>
              </div>
            </button>
          </Link>
        )}
        {!link && (
          <button className="relative w-full inline-flex items-center justify-center p-0 my-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 hover:text-white text-white focus:ring-4 ">
            <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 rounded-lg group-hover:bg-opacity-0">
              <div className="m-auto">{settheme}</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export function FAQ3({ show, setShow }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "90%",
              padding: "",
              background: "#fff",
            }}
            className="h-[90vh] overflow-y-auto z-100"
          >
            <div>
              <div>
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-8">
                  <div className="text-center pb-12">
                    <h2 className="text-base font-bold text-indigo-600">
                      地理探究のテーマから選びましょう
                    </h2>
                    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                      地理探究から選ぶ
                    </h1>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                    <Tankyubutton settheme={"自然環境"} link={true} />
                    <Tankyubutton
                      settheme={"生活文化，民族・宗教"}
                      link={true}
                    />
                    <Tankyubutton settheme={"資源，産業"} link={true} />
                    <Tankyubutton
                      settheme={"現代世界の地域区分"}
                      link={false}
                    />
                    <Tankyubutton settheme={"交通・通信，観光"} link={true} />
                    <Tankyubutton settheme={"現代世界の諸地域"} link={false} />
                    <Tankyubutton settheme={"人口，都市・村落"} link={true} />
                    <Tankyubutton
                      settheme={"持続可能な国土像の探究"}
                      link={true}
                    />
                  </div>
                </section>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

// 地理的事象モーダル
const Chiritekibutton = ({ settheme, link, img }) => {
  const { setTheme1Set } = useContext(Theme1Set);
  let to = "/tags/" + settheme;

  return (
    <div
      className="w-full bg-white sahdow-lg overflow-hidden flex flex-col md:flex-row"
      onClick={() => setTheme1Set(settheme)}
    >
      <div className="w-full">
        {link && (
          <Link to={to} tag="sdgs1">
            <button className="relative w-full inline-flex items-center justify-center p-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-md group-hover:bg-opacity-0">
                <div>
                  <img src={img} className="w-12 mx-2 my-auto" />
                </div>
                <div className="my-auto ml-5">{settheme}</div>
              </div>
            </button>
          </Link>
        )}
        {!link && (
          <button className="relative w-full inline-flex items-center justify-center p-0 my-1 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 text-white focus:ring-4 ">
            <div className="relative flex w-full px-4 py-2 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 rounded-lg group-hover:bg-opacity-0">
              <div>
                <img src={img} className="w-12 mx-2 my-auto" />
              </div>
              <div className="my-auto ml-5">{settheme}</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export function FAQ4({ show, setShow }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "90%",
              padding: "",
              background: "#fff",
            }}
            className="h-[90vh] overflow-y-auto z-100"
          >
            <div>
              <div>
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-8">
                  <div className="text-center pb-12">
                    <h2 className="text-base font-bold text-indigo-600">
                      地理的事象のテーマから選びましょう
                    </h2>
                    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                      地理的事象から選ぶ
                    </h1>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                    <Chiritekibutton
                      settheme={"地形"}
                      link={true}
                      img={Chiri1}
                    />
                    <Chiritekibutton
                      settheme={"気候"}
                      link={true}
                      img={Chiri2}
                    />
                    <Chiritekibutton
                      settheme={"環境問題"}
                      link={true}
                      img={Chiri3}
                    />
                    <Chiritekibutton
                      settheme={"交通"}
                      link={true}
                      img={Chiri4}
                    />
                    <Chiritekibutton
                      settheme={"通信"}
                      link={false}
                      img={Chiri5}
                    />
                    <Chiritekibutton
                      settheme={"人口"}
                      link={true}
                      img={Chiri6}
                    />
                    <Chiritekibutton
                      settheme={"民族"}
                      link={true}
                      img={Chiri7}
                    />
                    <Chiritekibutton
                      settheme={"農業"}
                      link={true}
                      img={Chiri8}
                    />
                    <Chiritekibutton
                      settheme={"林業"}
                      link={true}
                      img={Chiri9}
                    />
                    <Chiritekibutton
                      settheme={"水産業"}
                      link={true}
                      img={Chiri10}
                    />
                    <Chiritekibutton
                      settheme={"資源エネルギー"}
                      link={false}
                      img={Chiri11}
                    />
                    <Chiritekibutton
                      settheme={"鉱業"}
                      link={true}
                      img={Chiri12}
                    />
                    <Chiritekibutton
                      settheme={"工業"}
                      link={true}
                      img={Chiri13}
                    />
                    <Chiritekibutton
                      settheme={"第３次産業"}
                      link={true}
                      img={Chiri14}
                    />
                    <Chiritekibutton
                      settheme={"労働"}
                      link={true}
                      img={Chiri15}
                    />
                    <Chiritekibutton
                      settheme={"観光"}
                      link={true}
                      img={Chiri16}
                    />
                    <Chiritekibutton
                      settheme={"経済"}
                      link={true}
                      img={Chiri17}
                    />
                    <Chiritekibutton
                      settheme={"貿易"}
                      link={false}
                      img={Chiri18}
                    />
                    <Chiritekibutton
                      settheme={"国際機関"}
                      link={false}
                      img={Chiri19}
                    />
                    <Chiritekibutton
                      settheme={"世界の国々"}
                      link={true}
                      img={Chiri20}
                    />
                  </div>
                </section>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

// 出典モーダル
const Syuttenbutton = ({ settheme, link }) => {
  const { setTheme1Set } = useContext(Theme1Set);
  let to = "/tags/" + settheme;
  return (
    <div
      className="w-full bg-white sahdow-lg overflow-hidden flex flex-col md:flex-row"
      onClick={() => setTheme1Set(settheme)}
    >
      <div className="w-full">
        {link && (
          <Link to={to} tag="sdgs1">
            <button className="relative w-full inline-flex items-center justify-center p-1.5 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 text-white focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-800">
              <span className="relative w-full px-5 py-6 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-md group-hover:bg-opacity-0">
                {settheme}
              </span>
            </button>
          </Link>
        )}
        {!link && (
          <button className="relative w-full inline-flex items-center justify-center p-1.5 overflow-hidden text-lg lg:text-2xl font-medium rounded-lg group text-white ">
            <span className="relative w-full px-5 py-6 transition-all ease-in duration-75 bg-gradient-to-r from-black/50 via-black/70 to-black/90 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-800/50 dark:focus:ring-green-800/50 rounded-md ">
              {settheme}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export function FAQ5({ show, setShow }) {
  // const { theme1set, setTheme1Set } = useContext(Theme1Set);
  if (show) {
    return (
      <>
        <div
          id="overlay"
          onClick={() => setShow(false)}
          className="top-0 left-0 w-full h-full"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: " flex",
            alignItems: " center",
            justifyContent: " center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "90%",
              padding: "",
              background: "#fff",
            }}
            className="h-[90vh] overflow-y-auto z-100"
          >
            <div>
              <div>
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-8">
                  <div className="text-center pb-12">
                    <h2 className="text-base font-bold text-indigo-600">
                      出典のテーマから選びましょう
                    </h2>
                    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                      出典から選ぶ
                    </h1>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                    <Syuttenbutton settheme={"国勢調査"} link={true} />
                    <Syuttenbutton settheme={"経済センサス"} link={true} />
                    <Syuttenbutton settheme={"商業統計調査"} link={true} />
                    <Syuttenbutton settheme={"国土数値情報"} link={true} />
                    <Syuttenbutton settheme={"農林業センサス"} link={true} />
                  </div>
                </section>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShow(false)}>閉じる</button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
