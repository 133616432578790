export default 
[
  {
    "genre": "SDGs",
    "midashi_english": "Sustainable Development Goals",
    "midashi_japanese": "SDGsに合わせた目次です",
    "count": "全17分類"
  },
  {
    "genre": "高校地理総合",
    "midashi_english": "High school ”Geography Synthesis”",
    "midashi_japanese": "高校”地理総合”の単元に合わせた目次です",
    "count": "全6分類"
  },
  {
    "genre": "高校地理探究",
    "midashi_english": "High school \"Geography Exploration",
    "midashi_japanese": "高校”地理探究”の単元に合わせた目次です",
    "count": "全8分類"
  },
  {
    "genre": "地理的事象",
    "midashi_english": "Geographical events",
    "midashi_japanese": "地理的事象に合わせた目次です",
    "count": "全20分類"
  },
  {
    "genre": "出典",
    "midashi_english": "source",
    "midashi_japanese": "出典に合わせた目次です",
    "count": "全7分類"
  }
]