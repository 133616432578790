import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo1 from "./img/select1.webp";
import logo2 from "./img/select2.webp";
import logo3 from "./img/select3.webp";
import logo4 from "./img/select4.webp";
import logo5 from "./img/select5.webp";
import selectwebp from "./regtra_select.webp";
import {
  Modalsdgs,
  Modalsougou,
  Modaltankyu,
  Modalchiriteki,
  Modalsyutten,
} from "./regtra_selectmodal";
import { Header } from "./App";
import meta_theme from "./meta_theme_20220328";

const Panel = ({ img, title, subtitle, description, themenum }) => {
  return (
    <>
      <div
        className="
          cursor-pointer
          bg-tani_white
          shadow-md
          h-[40vh]
          rounded-3xl
          flex flex-col
          justify-around
          items-center
          overflow-hidden
          sm:flex-row sm:h-[23vh] 
          lg:w-[48vw] mx-[1vw] my-[1vh]
        "
      >
        <img
          className="h-1/2 w-full sm:h-full sm:w-5/12 object-cover"
          src={img}
          alt="panel"
        />
        <div
          className="
            flex-1
            w-full
            flex flex-col
            items-baseline
            justify-around
            h-1/2
            pl-6
            sm:h-full sm:items-baseline sm:w-1/2
          "
        >
          <div className="flex flex-col justify-start items-baseline">
            <h1 className="text-4xl font-normal mb-0 text-gray-600 font-sans">
              {title}
            </h1>
            <span className="text-xs text-indigo-300 mt-0">{subtitle}</span>
          </div>
          <p className="text-xs text-gray-500 w-4/5">{description}</p>
          <div className="w-full flex justify-between items-center">
            <h1 className="font-bold text-gray-500">{themenum}</h1>
            <button className="bg-gray-700 mr-5 text-white px-3 py-1 rounded-sm shadow-md">
              選択
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const Select = () => {
  const [showsdgs, setShowsdgs] = useState(false);
  const [showsougou, setShowsougou] = useState(false);
  const [showtankyu, setShowtankyu] = useState(false);
  const [showchiriteki, setShowchiriteki] = useState(false);
  const [showsyutten, setShowsyutten] = useState(false);

  // console.log(meta_theme);

  return (
    <>
      <div className="absolute z-30 font-mono text-black w-full h-screen bg-tani_white">
        <Link to="/">
          <div className="h-1/6 flex flex-wrap flex-row">
            <div className="z-30 w-1/12 bg-tani_orange">
            </div>
            <div className="z-30 w-11/12 bg-tani_blue text-left text-tani_white text-6xl flex items-center">
              地域公共交通体力診断<br/>
            </div>
          </div>
        </Link>

      <div className="w-5/6" onClick={()=>setShowsougou(true)}>
          <img src={selectwebp} className="h-5/6"/>
      </div>

        <Modalsdgs show={showsdgs} setShow={setShowsdgs} />
        <Modalsougou show={showsougou} setShow={setShowsougou} />
        <Modaltankyu show={showtankyu} setShow={setShowtankyu} />
        <Modalchiriteki show={showchiriteki} setShow={setShowchiriteki} />
        <Modalsyutten show={showsyutten} setShow={setShowsyutten} />

        {/* <div className="absolute z-20 min-w-full font-bold text-sm lg:text-base text-gray-100 bg-gradient-to-r from-blue-600 to-blue-800  focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700 py-1 text-center">
          © 2022{" "}
          <a
            href="http://www.jsce.or.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            JSCE
          </a>{" "}
          ＆{" "}
          <a
            href="https://www.kashika.or.jp/geograph/"
            target="_blank"
            rel="noopener noreferrer"
          >
            都市構造可視化推進機構
          </a>
        </div> */}
      </div>
    </>
  );
};
