import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useLayoutEffect,
} from "react";
import { Viewer, ImageryLayer } from "resium";
import {
  Cartesian3,
  Rectangle,
  Camera,
  Entity as cesiumEntity,
  HeadingPitchRange,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Math as Cesium_Math,
  // Cartographic,
  // Cartesian2,
  // VerticalOrigin,
  // HorizontalOrigin,
  // Color,
} from "cesium";
import "cesium/Source/Widgets/widgets.css";
// import CesiumNavigation from "cesium-navigation-es6";
import {
  RendouFunc,
  SyukurenFunc,
  Rotate1Func,
  Rotate2Func,
  FullscreenFunc,
  FullviwerFunc,
} from "./cesiumfunction";
// import { Static2Map2Func, Static2Map3Func, Gtfs2Map1Func } from "./kashikafunction";
import {
  FullviewerSet,
  RendouSet,
  SyukurenSet,
  Map1Set,
  Map2Set,
  Zoomlevel1Set,
  Zoomlevel2Set,
  Static2Map1Set,
  Static2Map2Set,
  Rotate1Set,
  Rotate2Set,
  Area1Set,
  Area2Set,
  Theme1Set,
  Theme2Set,
} from "./App";
// import {
//   population_now,
//   population_old,
//   agedpop_now,
//   agedpop_old,
//   worker23_now,
//   worker23_old,
//   daypop_now,
//   daypop_old,
//   sales_now,
//   sales_old,
//  } from "./kashikasetting";
//  import Dexie from 'dexie';
//  import { useLiveQuery } from 'dexie-react-hooks';

// import { instruction } from "./kashikasetting";
// import workerpool from "workerpool";

// import { useCsvToMap1Func, CsvToMap2Func } from "./kashikafunction";
// import { CsvToMap1 } from "./kashikafunction";
// import { set } from "react-hook-form";
import { lod } from "./kashikasetting";
// import { metadata } from "./kashikasetting";
import { MetadataSet } from "./App";
import {
  // mesh3rdRect3_kakudai, mesh3rdRect3_kakudai_hanrei, mesh3rdRect3,
  // mesh2ndRect3,
  mesh3rdRect4,
  mesh2ndRect4,
} from "./kashikafunction";
// import { useParams } from "react-router-dom";
// import { useMatch } from "react-router-dom";
import { Ratio1Set, Ratio2Set } from "./App";
import { Year1Set, Year2Set } from "./App";
import { Zoomhanrei1Set, Zoomhanrei2Set } from "./App";
import { ModeSet } from "./App";
// import cesiumNavMixin from '../../dist/index';
import cesiumNavMixin from "@znemz/cesium-navigation";
import "./cesiumnavigation.css";
import { resolvePath } from "react-router-dom";
// import { calcMeshCode } from "./kashikafunction";

const position = Cartesian3.fromDegrees(135.0707383, 35.7117244, 100);
// Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2OGE2MjZlOC1mMzhiLTRkZjQtOWEwZi1jZTE0MWY0YzhlMTAiLCJpZCI6MjU5LCJpYXQiOjE2NDM3MjU1NzZ9.ptZ5tVXvMmuWRC0WhjtYTg-17nQh14fgxBsx0HJiVXQ";

// let population_now_1;
// let population_old_1;
// let agedpop_now_1;
// let agedpop_old_1;
// let worker23_now_1;
// let worker23_old_1;
// let daypop_now_1;
// let daypop_old_1;
// let sales_now_1;
// let sales_old_1;

// let population_now_2;
// let population_old_2;
// let agedpop_now_2;
// let agedpop_old_2;
// let worker23_now_2;
// let worker23_old_2;
// let daypop_now_2;
// let daypop_old_2;
// let sales_now_2;
// let sales_old_2;

// let data1995;
// let data2000;
// let data2005;
// let data2010;
// let data2015;

let data_year1_1;
let data_year2_1;
let data_year3_1;
let data_year4_1;
let data_year5_1;

let kakudai_data_year1_1;
let kakudai_data_year2_1;
let kakudai_data_year3_1;
let kakudai_data_year4_1;
let kakudai_data_year5_1;

let data_year1_2;
let data_year2_2;
let data_year3_2;
let data_year4_2;
let data_year5_2;

let kakudai_data_year1_2;
let kakudai_data_year2_2;
let kakudai_data_year3_2;
let kakudai_data_year4_2;
let kakudai_data_year5_2;

let filecheck = 0;

// export const db = new Dexie('kashikaDb');
// db.version(1).stores({
//   csvall: '++id, dataname, csvall',
//   // csvcity: '++id, dataname, cityname, csv'
// });

// var pool = workerpool.pool();

export const CesiumContainer = (props) => {
  var extent = Rectangle.fromDegrees(122, 20, 153, 50);
  Camera.DEFAULT_VIEW_RECTANGLE = extent;
  Camera.DEFAULT_VIEW_FACTOR = 0;
  const rfcs1 = useRef(null);
  const rfcs2 = useRef(null);
  const { fullviewerset, setFullviewerSet } = useContext(FullviewerSet);
  const { metadataset, setMetadataSet } = useContext(MetadataSet);
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const [mousecount, setMouseCount] = useState(true);
  const [filecheckcount, setFilecheckCount] = useState(false);

  const { zoomlevel1set, setZoomlevel1Set } = useContext(Zoomlevel1Set);
  const { zoomlevel2set, setZoomlevel2Set } = useContext(Zoomlevel2Set);
  const { zoomhanrei1set, setZoomhanrei1Set } = useContext(Zoomhanrei1Set);
  const { zoomhanrei2set, setZoomhanrei2Set } = useContext(Zoomhanrei2Set);
  const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
  const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);

  const { area1set, setArea1Set } = useContext(Area1Set);
  const { area2set, setArea2Set } = useContext(Area2Set);
  const { theme1set, setTheme1Set } = useContext(Theme1Set);
  const { theme2set, setTheme2Set } = useContext(Theme2Set);

  const { ratio1set, setRatio1Set } = useContext(Ratio1Set);
  const { ratio2set, setRatio2Set } = useContext(Ratio2Set);

  const { year1set, setYear1Set } = useContext(Year1Set);
  const { year2set, setYear2Set } = useContext(Year2Set);

  const { modeset, setModeSet } = useContext(ModeSet);

  // console.log(modeset);


  let metadata_1;
  let year_1;
  let area_1;
  let csv_name_1;



  // 左（rfcs1）初期設定 ///////////////////////////
  useLayoutEffect(() => {
    // useLayoutEffect(() => {
    if (rfcs1.current && rfcs1.current.cesiumElement) {
      let viewer = rfcs1.current.cesiumElement;
      // data1995 = viewer.entities.add(new cesiumEntity());
      // data2000 = viewer.entities.add(new cesiumEntity());
      // data2005 = viewer.entities.add(new cesiumEntity());
      // data2010 = viewer.entities.add(new cesiumEntity());
      // data2015 = viewer.entities.add(new cesiumEntity());
      // console.log(data1995);

      data_year1_1 = viewer.entities.add(new cesiumEntity());
      data_year2_1 = viewer.entities.add(new cesiumEntity());
      data_year3_1 = viewer.entities.add(new cesiumEntity());
      data_year4_1 = viewer.entities.add(new cesiumEntity());
      data_year5_1 = viewer.entities.add(new cesiumEntity());

      data_year1_1.show = false;
      data_year2_1.show = false;
      data_year3_1.show = false;
      data_year4_1.show = false;
      data_year5_1.show = true;

      kakudai_data_year1_1 = viewer.entities.add(new cesiumEntity());
      kakudai_data_year2_1 = viewer.entities.add(new cesiumEntity());
      kakudai_data_year3_1 = viewer.entities.add(new cesiumEntity());
      kakudai_data_year4_1 = viewer.entities.add(new cesiumEntity());
      kakudai_data_year5_1 = viewer.entities.add(new cesiumEntity());

      // console.log(rfcs1.current.cesiumElement.imageryLayers);

      let viewer1 = rfcs1.current.cesiumElement;
      // population_now_1 = viewer1.entities.add(new cesiumEntity());
      // population_old_1 = viewer1.entities.add(new cesiumEntity());
      // agedpop_now_1 = viewer1.entities.add(new cesiumEntity());
      // agedpop_old_1 = viewer1.entities.add(new cesiumEntity());
      // worker23_now_1 = viewer1.entities.add(new cesiumEntity());
      // worker23_old_1 = viewer1.entities.add(new cesiumEntity());
      // daypop_now_1 = viewer1.entities.add(new cesiumEntity());
      // daypop_old_1 = viewer1.entities.add(new cesiumEntity());
      // sales_now_1 = viewer1.entities.add(new cesiumEntity());
      // sales_old_1 = viewer1.entities.add(new cesiumEntity());

      rfcs1.current.cesiumElement._cesiumWidget._creditContainer.style.display =
        "none";





      // population_old_1.show = true;

      // console.log(area1set);
      // console.log(population_old_1);
  //     return () => {};
  //   }
  // }, []);

  // // 左（rfcs1）初期設定２ ///////////////////////////
  // useEffect(() => {
  //   if (rfcs1.current && rfcs1.current.cesiumElement) {
      // // 視点の高さに応じて、zoomlevelsetを設定する（マウスホイール連動版：動作します）
      // var handler = new ScreenSpaceEventHandler(rfcs1.current.cesiumElement.scene.canvas);
      // handler.setInputAction(function(wheelment) {
      //   // console.log(zoomlevel1set);
      //   var height=rfcs1.current.cesiumElement.camera.positionCartographic.height;
      //   if(height< lod[4]){
      //     // console.log("拡大");
      //     setZoomlevel1Set(4)
      //     if(rendouset){
      //       setZoomlevel2Set(4)
      //     }
      //   } else if(height>= lod[4]){
      //     // console.log("全景");
      //     setZoomlevel1Set(1)
      //     setZoomhanrei1Set(null)
      //     if(rendouset){
      //       setZoomlevel2Set(1)
      //       setZoomhanrei2Set(null)
      //     }
      //   }
      // }, ScreenSpaceEventType.WHEEL);

      // 視点の高さに応じて、zoomlevelsetを設定する（２秒ごと確認版：動作します）
      const intervalId = setInterval(() => {
        var height =
          rfcs1.current.cesiumElement.camera.positionCartographic.height;
        if (height < lod[4]) {
          // console.log("拡大");
          setZoomlevel1Set(4);
          if (rendouset) {
            setZoomlevel2Set(4);
          }
          // console.log("拡大");
        } else if (height >= lod[4]) {
          // console.log("全景");
          setZoomlevel1Set(1);
          setZoomhanrei1Set(null);
          if (rendouset) {
            setZoomlevel2Set(1);
            setZoomhanrei2Set(null);
          }
          // console.log("全景");
        }
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };

      // レンダリング解像度を下げる
      // rfcs1.current.cesiumElement.resolutionScale = 0.5;

      // return () => {
      // }
    }
  }, []);

  // 左（rfcs1）初期設定２ ///////////////////////////
  useEffect(() => {
    if (rfcs1.current && rfcs1.current.cesiumElement) {
      // ナビゲーションボタンの追加
      if (!cesiumNavMixin) {
        console.error("cesiumNavMixin is undefined");
      } else {
        rfcs1.current.cesiumElement.extend(cesiumNavMixin, {});
      }
      return () => {
        // console.log(rfcs1.current.cesiumElement.cesiumNavigation);
      };
    }
  }, []);

  // 左（rfcs1）可視化（テーマ、都市、タブの切り替え時） ///////////////////////////
  useEffect(() => {
    if (rfcs1.current && rfcs1.current.cesiumElement) {
      // let viewer = rfcs1.current.cesiumElement;

      // 表示中のものを削除
      // rfcs1.current.cesiumElement.entities.removeAll();
      // 表示中のものを削除【終】
      // console.log("可視化の準備");

      const metadata = static2map1set;
      // const metadatatmp = JSON.stringify(static2map1set);
      // const metadata = JSON.parse(metadatatmp);
      const year = year1set || metadata.year5;

      let area = "福岡県糸島市";
      if (area1set) {
        area = area1set.normalize("NFD");
        // console.log(area);
      }

      // let csv_name;
      let csv_name = "人口総数×人口総数";
        if (metadata.csv_filename) {
          csv_name = metadata.csv_filename.normalize("NFD");
        }

        if (csv_name) {
          // console.log(csv_name);
        // const csvfilepath =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   year +
        // //   ".csv";
        // const csvfilepath_year1_1 =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   metadata.year1 +
        //   ".csv";
        // const csvfilepath_year2_1 =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   metadata.year2 +
        //   ".csv";
        // const csvfilepath_year3_1 =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   metadata.year3 +
        //   ".csv";
        // const csvfilepath_year4_1 =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   metadata.year4 +
        //   ".csv";
        // const csvfilepath_year5_1 =
        //   "/csv/" +
        //   metadata.no +
        //   "/" +
        //   area +
        //   "_" +
        //   csv_name +
        //   "_" +
        //   metadata.year5 +
        //   ".csv";


        function csvfecth(rfcs1, dataname, no, year, area, csv_name){
          // console.log(rfcs1);
          // console.log(dataname);
          // console.log(no);
          // console.log(year);
          // console.log(area);
          // console.log(csv_name);

          fetch( "/csv/" +
          no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          year +
          ".csv"
          , {})
          // fetch(filepath, {})
            // const csvfecth = () => fetch("/csv/11-2/福岡県篠栗町_人口総数×昼夜間人口比_2015.csv",{})
            .then((r) => {
              if (!r.ok) {
                throw new Error();
              }
              return r.text();
            })
            .then(
              (r) => {
                // console.log(r);
                if(!r.startsWith("<")){
                  // setFilecheckCount(true)
                  filecheck = filecheck + 1
                } else {
                }
                return r
              }
            )
            .then(
              (r) =>
                // if(r.indexOf('\r\n')>0){
                r.split("\r\n")
              // } else {
              //   r.split('\n')
              // }
            )
            .then((r) => r.map((r) => r.split(",")))
            .then((meshdata) => {
              // console.log(meshdata[0][0]);
              // console.log(metadata);
              if (meshdata[0][0].startsWith("<")) {
                // window.alert("この地域にはデータがありません。市町村名を確認してください。")
                // console.log("データなし");
              } else if (meshdata[0][0].length == 6) {
                // console.log(metadata);
                var min = Number(metadata.kokudo_min);
                // var dataname = data_year5_1;
                var pagedata;
                var shikii1 = Number(metadata.kokudo_shikii1);
                var shikii2 = Number(metadata.kokudo_shikii2);
                var shikii3 = Number(metadata.kokudo_shikii3);
                var hanrei1_color = metadata.hanrei1_color;
                var hanrei2_color = metadata.hanrei2_color;
                var hanrei3_color = metadata.hanrei3_color;
                var hanrei4_color = metadata.hanrei4_color;
                var ratio = Number(ratio1set);
                if (rfcs1.current && rfcs1.current.cesiumElement) {
                  for (let i = 0, len = meshdata.length; i < len; i++) {
                    if (meshdata[i][1] > min) {
                      rfcs1.current.cesiumElement.entities.add(
                        mesh2ndRect4(
                          meshdata[i][0],
                          meshdata[i][1],
                          meshdata[i][1],
                          dataname,
                          pagedata,
                          shikii1,
                          shikii2,
                          shikii3,
                          hanrei1_color,
                          hanrei2_color,
                          hanrei3_color,
                          hanrei4_color,
                          ratio,
                          1,
                          metadata.kokudo_ratio
                        )
                      );
                    }
                  }
                  var flyto = flyto || true;
                  if (flyto) {
                    rfcs1.current.cesiumElement.zoomTo(
                      rfcs1.current.cesiumElement.entities
                    );
                    // rfcs1.current.cesiumElement.zoomTo(rfcs1.current.cesiumElement.entities)
                  }
                }
                // } else if(meshdata[0][0].length == 6) {
                // 2次メッシュの表示拡張用
              } else {
                var min = Number(0);
                // var dataname = data_year5_1;
                var pagedata;
                var shikii1 = Number(metadata.shikii1);
                var shikii2 = Number(metadata.shikii2);
                var shikii3 = Number(metadata.shikii3);
                var hanrei1_color = metadata.hanrei1_color;
                var hanrei2_color = metadata.hanrei2_color;
                var hanrei3_color = metadata.hanrei3_color;
                var hanrei4_color = metadata.hanrei4_color;
                var ratio = Number(ratio1set);
                if (rfcs1.current && rfcs1.current.cesiumElement) {
                  for (let i = 0, len = meshdata.length; i < len; i++) {
                    if (meshdata[i][1] > min) {
                      // 仮設定２
                      // meshdata[i][1] = meshdata[i][1];
                      rfcs1.current.cesiumElement.entities.add(
                        mesh3rdRect4(
                          meshdata[i][0],
                          meshdata[i][1],
                          meshdata[i][1],
                          dataname,
                          pagedata,
                          shikii1,
                          shikii2,
                          shikii3,
                          hanrei1_color,
                          hanrei2_color,
                          hanrei3_color,
                          hanrei4_color,
                          ratio,
                          1,
                          metadata.ratio
                        )
                      );
                    }
                  }
                  // console.log("変換！");

                  var flyto = flyto || true;
                  if (flyto) {
                    rfcs1.current.cesiumElement.zoomTo(
                      rfcs1.current.cesiumElement.entities
                    );
                  }
                }
              }
            }
            )
            // .catch(err => alert(err))
          }
            ;



        // const csvdata = csvfecth(csvfilepath);
        const csvdata_year1_1 = csvfecth(rfcs1, data_year1_1, metadata.no, metadata.year1, area, csv_name);
        const csvdata_year2_1 = csvfecth(rfcs1, data_year2_1, metadata.no, metadata.year2, area, csv_name);
        const csvdata_year3_1 = csvfecth(rfcs1, data_year3_1, metadata.no, metadata.year3, area, csv_name);
        const csvdata_year4_1 = csvfecth(rfcs1, data_year4_1, metadata.no, metadata.year4, area, csv_name);
        const csvdata_year5_1 = csvfecth(rfcs1, data_year5_1, metadata.no, metadata.year5, area, csv_name);   
             
      }



      return () => {
        // // 表示中のものを削除
        rfcs1.current.cesiumElement.entities.removeAll();
        // // 表示中のものを削除【終】
      };
    }
  }, [
    static2map1set, 
    area1set, 
    modeset,
    ratio1set
    ]);









  // // 左（rfcs1）可視化（倍率、年次の切り替え時） ///////////////////////////
  // useEffect(() => {
  //   if (rfcs1.current && rfcs1.current.cesiumElement) {
  //     let viewer = rfcs1.current.cesiumElement;

  //     // 表示中のものを削除
  //     rfcs1.current.cesiumElement.entities.removeAll();
  //     // 表示中のものを削除【終】

  //     const metadatatmp = JSON.stringify(static2map1set);
  //     const metadata = JSON.parse(metadatatmp);
  //     const year = year1set || metadata.year5

  //     let area = "福岡県糸島市";
  //     if(area1set){
  //       area = area1set.normalize( 'NFD' );
  //     }
  //     let csv_name;
  //     if(metadata.csv_filename){
  //       csv_name = metadata.csv_filename.normalize( 'NFD' );
  //     }

  //     const csvfilepath ="/csv/" + metadata.no + "/" + area + "_" + csv_name + "_" + year + ".csv";

  //     // const csvfilepath ="/csv/" + metadata.no + "/" + area1set + "_" + metadata.csv_filename + "_" + year + ".csv";

  //     const csvfecth = () => fetch(csvfilepath,{})
  //     // const csvfecth = () => fetch("/csv/11-2/福岡県篠栗町_人口総数×昼夜間人口比_2015.csv",{})
  //       .then((r)=>{
  //         if (!r.ok) {throw new Error();}
  //         return r.text();
  //       })
  //       .then((r)=>r.split('\r\n'))
  //       .then((r)=>r.map((r)=>r.split(',')))
  //       .then((meshdata) => {

  //         if(meshdata[0][0].length == 6){
  //           // console.log(metadata);
  //           var min = Number(metadata.kokudo_min);
  //           var dataname = data_year5_1;
  //           var pagedata;
  //           var shikii1 = Number(metadata.kokudo_shikii1);
  //           var shikii2 = Number(metadata.kokudo_shikii2);
  //           var shikii3 = Number(metadata.kokudo_shikii3);
  //           var hanrei1_color = metadata.hanrei1_color;
  //           var hanrei2_color = metadata.hanrei2_color;
  //           var hanrei3_color = metadata.hanrei3_color;
  //           var hanrei4_color = metadata.hanrei4_color;
  //           var ratio = Number(ratio1set);
  //           if ( rfcs1.current && rfcs1.current.cesiumElement ) {
  //             for(let i = 0, len = meshdata.length; i< len ; i++){
  //               if (meshdata[i][1]>min)
  //               {
  //                 rfcs1.current.cesiumElement.entities.add(
  //                 mesh2ndRect4(meshdata[i][0],meshdata[i][1],meshdata[i][1],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio,1,metadata.kokudo_ratio)
  //                 );
  //               }
  //             }
  //             var flyto = flyto || false;
  //             if(flyto){
  //               rfcs1.current.cesiumElement.zoomTo(rfcs1.current.cesiumElement.entities)
  //             }
  //           }
  //         // } else if(meshdata[0][0].length == 6) {
  //           // 2次メッシュの表示拡張用
  //         } else {
  //           var min = Number(0);
  //           var dataname = data_year5_1;
  //           // console.log(data_year5_1);
  //           var pagedata;
  //           var shikii1 = Number(metadata.shikii1);
  //           var shikii2 = Number(metadata.shikii2);
  //           var shikii3 = Number(metadata.shikii3);
  //           var hanrei1_color = metadata.hanrei1_color;
  //           var hanrei2_color = metadata.hanrei2_color;
  //           var hanrei3_color = metadata.hanrei3_color;
  //           var hanrei4_color = metadata.hanrei4_color;
  //           var ratio = Number(ratio1set);
  //           if ( rfcs1.current && rfcs1.current.cesiumElement ) {
  //             for(let i = 0, len = meshdata.length; i< len ; i++){
  //               if (meshdata[i][1]>min)
  //               {
  //                   // 仮設定２
  //                   // meshdata[i][1] = meshdata[i][1];
  //                 rfcs1.current.cesiumElement.entities.add(
  //                 mesh3rdRect4(meshdata[i][0],meshdata[i][1],meshdata[i][1],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio,1,metadata.ratio)
  //                 );
  //               }
  //             }
  //             var flyto = flyto || false;
  //             if(flyto){
  //               rfcs1.current.cesiumElement.zoomTo(rfcs1.current.cesiumElement.entities)
  //             }
  //           }
  //         }
  //       }
  //     )
  //     csvfecth(csvfilepath);
  //     // csvfecth(filepath_year1_1);
  //     // csvfecth(filepath_year5_1);
  //     return () => {

  //     // // 表示中のものを削除
  //     // rfcs1.current.cesiumElement.entities.removeAll();
  //     // // 表示中のものを削除【終】
  //       // console.log(data1995);
  //     }
  //   }
  // }, [ratio1set]);

  // // 左（rfcs1）統計表示年の配列を更新 ///////////////////////////
  // useEffect(() => {
  //   let year1s = [];
  //   if(static2map1set.year1){year1s.push(static2map1set.year1)}
  //   if(static2map1set.year2){year1s.push(static2map1set.year2)}
  //   if(static2map1set.year3){year1s.push(static2map1set.year3)}
  //   if(static2map1set.year4){year1s.push(static2map1set.year4)}
  //   if(static2map1set.year5){year1s.push(static2map1set.year5)}
  //   setYear1Set(year1s)
  // }, [static2map1set]);

  // 統計データ表示 //////////////
  // const data1 = useCsvToMap1Func(rfcs1, population_old_1, static2map1set, area1set);
  // const data2 = CsvToMap2Func(rfcs2, population_old_2, static2map2set, area2set);
  // const data3 = CsvToMap1(rfcs1, population_old_1, "/csv/11-1/"+area1set+"_人口総数×人口総数_2015.csv", area1set);

  // // 表示のonoff　動作するが、今回はOFFにする　20220226 //////////////
  // useEffect(() => {
  //   if(zoomlevel1set==1){
  //     data_year5_1.show = true;
  //     kakudai_data_year5_1.show = false;
  //   } else if (zoomlevel1set==4){
  //     data_year5_1.show = false;
  //     kakudai_data_year5_1.show = true;
  //   }
  //   return function(){
  //   }
  // }, [zoomlevel1set]);

  // ダブルクリックでズームする機能 //////////////
  useEffect(() => {
    window.setTimeout(function () {
      if (rfcs1.current && rfcs1.current.cesiumElement) {
        let viewer = rfcs1.current.cesiumElement;
        var handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
        handler.setInputAction(function (e) {
          viewer.trackedEntity = undefined;
          setRotate1Set(false);
          setRotate2Set(false);

          var picked = viewer.scene.pick(e.position);
          // console.log(picked);
          if (picked) {
            var pickedchara = String(picked.id._name);
            var pickedarr = pickedchara.split(",");
            // console.log(pickedarr);
            var lat = Number(pickedarr[0]);
            var lon = Number(pickedarr[1]);
            var meshcode = Number(pickedarr[2]);
            viewer.camera.flyTo({
              destination: Cartesian3.fromDegrees(lat, lon, 1000.0),
              orientation: {
                heading: Cesium_Math.toRadians(0),
                pitch: Cesium_Math.toRadians(-90),
                roll: 0.0,
              },
            });

            // setZoomCount(pickedarr)
            setTimeout(function () {
              setZoomhanrei1Set(pickedarr);
              setZoomlevel1Set(4);
              if (rendouset) {
                setZoomlevel2Set(4);
              }
              // picked.id.entityCollection.show = false;
              // picked.id.entityCollection.show = false;
            }, 2000);
          } else {
            // viewer.entities.show = true;
            // setZoomlevel1Set(1);
            if (rendouset) {
              // setZoomlevel2Set(1);
            }
            viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));
            // setZoomhanrei1Set(null);
          }
        }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        // console.log("ダブルクリックでズームする機能");

        // // 左クリックで緯度経度を取得する処理（動作しますが重いので一旦外してます）
        //     rfcs1.current.cesiumElement.canvas.addEventListener('click',
        //     function(e) {
        //       if(modeset == 2 || modeset == 3){
        //         var mousePosition = new Cartesian2(e.clientX - rfcs1.current.cesiumElement.canvas.width, e.clientY);
        //       } else {
        //         var mousePosition = new Cartesian2(e.clientX, e.clientY);
        //       }
        //         var ellipsoid = rfcs1.current.cesiumElement.scene.globe.ellipsoid;
        //         var cartesian = rfcs1.current.cesiumElement.camera.pickEllipsoid(mousePosition, ellipsoid);
        //         if (cartesian) {
        //             //位置情報を管理するオブジェクトcartographicを取得
        //             var cartographic = Cartographic.fromCartesian(cartesian);
        //             //緯度経度を小数点5桁で取得
        //             var lon = Cesium_Math.toDegrees(cartographic.longitude);
        //             var lat = Cesium_Math.toDegrees(cartographic.latitude);

        //         }
        //         // console.log(lon);
        //         // console.log(lat);
        //         var mesh1 = calcMeshCode(lon, lat, 3)
        //         // console.log(mesh1);
        //     }, false
        // );
      }
    }, 0);
  }, []);

  // // ダブルクリックでズームした時に壁を立てる機（動作しますが、連動関係の仕様があやふや） //////////////
  // useEffect(() => {
  //   if (rfcs1.current && rfcs1.current.cesiumElement && zoomhanrei1set) {
  //     // console.log(kakudai_data_year5_1);
  //     if(kakudai_data_year5_1._children.length>0){
  //     kakudai_data_year5_1._children[kakudai_data_year5_1._children.length-1].show = false;
  //   }
  //     var lon1 = Number(zoomhanrei1set[0])-0.00625
  //     var lon2 = Number(zoomhanrei1set[0])+0.00625
  //     var lat1 = Number(zoomhanrei1set[1])-0.004166
  //     var lat2 = Number(zoomhanrei1set[1])+0.004166
  //     rfcs1.current.cesiumElement.entities.add({
  //       parent: kakudai_data_year5_1,
  //       name: lon1+lat1,
  //       wall: {
  //         positions: Cartesian3.fromDegreesArray(
  //           [lon1,lat1,lon1,lat2,lon2,lat2,lon2,lat1,lon1,lat1]
  //         ),
  //         maximumHeights:[100,100,100,100,100],
  //         material:Color.RED
  //       }
  //     })
  //   }
  //   return () => {
  //   }
  // }, [zoomhanrei1set,zoomhanrei2set]);

  // // データを一式追加しておく処理 //////////////
  //   useEffect(() => {
  //     if (rfcs1.current && rfcs1.current.cesiumElement) {
  //     Static2Map2Func(rfcs1, population_old_1, static2map1set, area1set);
  //     Static2Map2Func(rfcs1, population_now_1, population_now, area1set, false );
  //     // Gtfs2Map1Func(rfcs1, population_old_1, static2map1set, area1set);
  //   }

  //     window.setTimeout(function(){
  //     if (rfcs1.current && rfcs1.current.cesiumElement) {
  //       Static2Map2Func(rfcs1, agedpop_now_1, agedpop_now, area1set, false );
  //       Static2Map2Func(rfcs1, agedpop_old_1, agedpop_old, area1set, false );
  //       Static2Map2Func(rfcs1, worker23_now_1, worker23_now, area1set, false );
  //       Static2Map2Func(rfcs1, worker23_old_1, worker23_old, area1set, false );
  //       Static2Map2Func(rfcs1, daypop_now_1, daypop_now, area1set, false );
  //       Static2Map2Func(rfcs1, daypop_old_1, daypop_old, area1set, false );
  //       Static2Map2Func(rfcs1, sales_now_1, sales_now, area1set, false );
  //       Static2Map2Func(rfcs1, sales_old_1, sales_old, area1set, false );
  //       console.log("データ一式追加処理チェック");
  //     }
  //   }, 2000);

  //   return () => {
  //     rfcs1.current.cesiumElement.entities.removeAll();
  //   }

  //   }, [area1set]);

  // 可視化ONOFF（左） ////////////////////
  useEffect(() => {
    if (rfcs1.current && rfcs1.current.cesiumElement) {
      const metadatatmp = JSON.stringify(static2map1set);
      const metadata = JSON.parse(metadatatmp);

      if (year1set === metadata.year1) {
        data_year1_1.show = true;
        data_year2_1.show = false;
        data_year3_1.show = false;
        data_year4_1.show = false;
        data_year5_1.show = false;
      } else if (year1set === metadata.year2) {
        data_year1_1.show = false;
        data_year2_1.show = true;
        data_year3_1.show = false;
        data_year4_1.show = false;
        data_year5_1.show = false;
      } else if (year1set === metadata.year3) {
        data_year1_1.show = false;
        data_year2_1.show = false;
        data_year3_1.show = true;
        data_year4_1.show = false;
        data_year5_1.show = false;
      } else if (year1set === metadata.year4) {
        data_year1_1.show = false;
        data_year2_1.show = false;
        data_year3_1.show = false;
        data_year4_1.show = true;
        data_year5_1.show = false;
      } else if (year1set === metadata.year5) {
        data_year1_1.show = false;
        data_year2_1.show = false;
        data_year3_1.show = false;
        data_year4_1.show = false;
        data_year5_1.show = true;
      } else if (year1set === 0) {
        data_year1_1.show = false;
        data_year2_1.show = false;
        data_year3_1.show = false;
        data_year4_1.show = false;
        data_year5_1.show = true;
      } else if (year1set === 3000) {
        data_year1_1.show = false;
        data_year2_1.show = false;
        data_year3_1.show = false;
        data_year4_1.show = false;
        data_year5_1.show = false;

        // } else if (static2map1set === agedpop_now){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = true;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === agedpop_old){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = true;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === worker23_now){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = true;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === worker23_old){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = true;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === daypop_now){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = true;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === daypop_old){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = true;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
        // } else if (static2map1set === sales_now){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = true;
        //   sales_old_1.show = false;
        // } else if (static2map1set === sales_old){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = true;
        // } else if (static2map1set === null){
        //   population_now_1.show = false;
        //   population_old_1.show = false;
        //   agedpop_now_1.show = false;
        //   agedpop_old_1.show = false;
        //   worker23_now_1.show = false;
        //   worker23_old_1.show = false;
        //   daypop_now_1.show = false;
        //   daypop_old_1.show = false;
        //   sales_now_1.show = false;
        //   sales_old_1.show = false;
      }
    }
    return () => {};
  }, [static2map1set, year1set]);

  // 右（rfcs2）設定 ///////////////////////////
  useLayoutEffect(() => {
    if (rfcs2.current && rfcs2.current.cesiumElement) {
      let viewer3 = rfcs2.current.cesiumElement;
      let viewer2 = rfcs2.current.cesiumElement;
      data_year1_2 = viewer2.entities.add(new cesiumEntity());
      data_year2_2 = viewer2.entities.add(new cesiumEntity());
      data_year3_2 = viewer2.entities.add(new cesiumEntity());
      data_year4_2 = viewer2.entities.add(new cesiumEntity());
      data_year5_2 = viewer2.entities.add(new cesiumEntity());

      data_year1_2.show = false;
      data_year2_2.show = false;
      data_year3_2.show = false;
      data_year4_2.show = false;
      data_year5_2.show = true;

      kakudai_data_year1_2 = viewer2.entities.add(new cesiumEntity());
      kakudai_data_year2_2 = viewer2.entities.add(new cesiumEntity());
      kakudai_data_year3_2 = viewer2.entities.add(new cesiumEntity());
      kakudai_data_year4_2 = viewer2.entities.add(new cesiumEntity());
      kakudai_data_year5_2 = viewer2.entities.add(new cesiumEntity());

      // データごとのparentを一式登録
      // population_now_2 = viewer2.entities.add(new cesiumEntity());
      // population_old_2 = viewer2.entities.add(new cesiumEntity());
      // agedpop_now_2 = viewer2.entities.add(new cesiumEntity());
      // agedpop_old_2 = viewer2.entities.add(new cesiumEntity());
      // worker23_now_2 = viewer2.entities.add(new cesiumEntity());
      // worker23_old_2 = viewer2.entities.add(new cesiumEntity());
      // daypop_now_2 = viewer2.entities.add(new cesiumEntity());
      // daypop_old_2 = viewer2.entities.add(new cesiumEntity());
      // sales_now_2 = viewer2.entities.add(new cesiumEntity());
      // sales_old_2 = viewer2.entities.add(new cesiumEntity());

      // // 初期表示データを読み込み
      // Static2Map2Func(rfcs2, population_now_2, static2map2set);
      // console.log("右レイアウトエフェクトチェック");

      rfcs2.current.cesiumElement._cesiumWidget._creditContainer.style.display =
        "none";

      // 初期表示データを表示
      // population_now_2.show = true;

      // // 視点の高さに応じて、zoomlevelsetを設定する（ホイール版：動作します）
      // var handler = new ScreenSpaceEventHandler(rfcs2.current.cesiumElement.scene.canvas);
      // handler.setInputAction(function(wheelment) {
      //   // console.log(zoomlevel1set);
      //   var height=rfcs2.current.cesiumElement.camera.positionCartographic.height;
      //   if(height< lod[4]){
      //     // console.log("拡大");
      //     setZoomlevel2Set(4)
      //     if(rendouset){
      //       setZoomlevel1Set(4)
      //     }
      //   } else if(height>= lod[4]){
      //     // console.log("全景");
      //     setZoomlevel2Set(1)
      //     setZoomhanrei2Set(null)
      //     if(rendouset){
      //       setZoomlevel1Set(1)
      //       setZoomhanrei1Set(null)
      //     }
      //   }
      // }, ScreenSpaceEventType.WHEEL);

      // 視点の高さに応じて、zoomlevelsetを設定する（２秒ごと確認版：動作します）
      const intervalId = setInterval(() => {
        var height =
          rfcs2.current.cesiumElement.camera.positionCartographic.height;
        if (height < lod[4]) {
          // console.log("拡大");
          setZoomlevel2Set(4);
          if (rendouset) {
            setZoomlevel1Set(4);
          }
          // console.log("拡大");
        } else if (height >= lod[4]) {
          // console.log("全景");
          setZoomlevel2Set(1);
          setZoomhanrei2Set(null);
          if (rendouset) {
            setZoomlevel1Set(1);
            setZoomhanrei1Set(null);
          }
          // console.log("全景");
        }
      }, 2000);
      return () => {
        clearInterval(intervalId);
      };

      // if (!cesiumNavMixin) {
      //   console.error('cesiumNavMixin is undefined');
      // } else {
      //   // extend our view by the cesium navigation mixin
      //   rfcs2.current.cesiumElement.extend(cesiumNavMixin, {});
      // }
    }
  }, []);

  // useEffect(() => {
  //   if (rfcs2.current && rfcs2.current.cesiumElement) {
  //     // console.log("右エフェクトチェック");
  //   }
  // }, []);

  // 右（rfcs2）可視化（テーマ、都市、タブの切り替え時） ///////////////////////////
  useEffect(() => {
    if (rfcs2.current && rfcs2.current.cesiumElement) {
      // let viewer = rfcs2.current.cesiumElement;

      const metadatatmp = JSON.stringify(static2map2set);
      const metadata = JSON.parse(metadatatmp);
      const year = year2set || metadata.year5;
      // console.log(metadata);
      // console.log(area2set);

      let area = "福岡県糸島市";
      if (area2set) {
        area = area2set.normalize("NFD");
      }
      let csv_name;
      if (metadata.csv_filename) {
        csv_name = metadata.csv_filename.normalize("NFD");
      }

      if (csv_name) {
        const csvfecth = (filepath, dataname2) =>
          fetch(filepath, {})
            // const csvfecth = () => fetch("/csv/11-2/福岡県篠栗町_人口総数×昼夜間人口比_2015.csv",{})
            .then((r) => {
              if (!r.ok) {
                throw new Error();
              }
              return r.text();
            })
            .then((r) => r.split("\r\n"))
            .then((r) => r.map((r) => r.split(",")))
            .then((meshdata) => {
              if (meshdata[0][6] === " meta tags") {
                window.alert("この地域にはデータがありません。市町村名を確認してください。")
              } else if (meshdata[0][0].length == 6) {
                // console.log(metadata);
                var min = Number(metadata.kokudo_min);
                // var dataname = data_year5_2;
                var pagedata;
                var shikii1 = Number(metadata.kokudo_shikii1);
                var shikii2 = Number(metadata.kokudo_shikii2);
                var shikii3 = Number(metadata.kokudo_shikii3);
                var hanrei1_color = metadata.hanrei1_color;
                var hanrei2_color = metadata.hanrei2_color;
                var hanrei3_color = metadata.hanrei3_color;
                var hanrei4_color = metadata.hanrei4_color;
                var ratio = Number(ratio1set);
                if (rfcs2.current && rfcs2.current.cesiumElement) {
                  for (let i = 0, len = meshdata.length; i < len; i++) {
                    if (meshdata[i][1] > min) {
                      rfcs2.current.cesiumElement.entities.add(
                        mesh2ndRect4(
                          meshdata[i][0],
                          meshdata[i][1],
                          meshdata[i][1],
                          dataname2,
                          pagedata,
                          shikii1,
                          shikii2,
                          shikii3,
                          hanrei1_color,
                          hanrei2_color,
                          hanrei3_color,
                          hanrei4_color,
                          ratio,
                          1,
                          metadata.kokudo_ratio
                        )
                      );
                    }
                  }
                  var flyto = flyto || true;
                  if (flyto) {
                    rfcs2.current.cesiumElement.zoomTo(
                      rfcs2.current.cesiumElement.entities
                    );
                  }
                }
                // } else if(meshdata[0][0].length == 6) {
                // 2次メッシュの表示拡張用
              } else {
                var min = Number(0);
                // var dataname = data_year5_2;
                var kakudai_dataname = kakudai_data_year5_2;
                var pagedata;
                var shikii1 = Number(metadata.shikii1);
                var shikii2 = Number(metadata.shikii2);
                var shikii3 = Number(metadata.shikii3);
                var hanrei1_color = metadata.hanrei1_color;
                var hanrei2_color = metadata.hanrei2_color;
                var hanrei3_color = metadata.hanrei3_color;
                var hanrei4_color = metadata.hanrei4_color;
                var ratio = Number(ratio1set);
                if (rfcs2.current && rfcs2.current.cesiumElement) {
                  for (let i = 0, len = meshdata.length; i < len; i++) {
                    if (meshdata[i][1] > min) {
                      // 仮設定２
                      // meshdata[i][1] = meshdata[i][1];
                      rfcs2.current.cesiumElement.entities.add(
                        mesh3rdRect4(
                          meshdata[i][0],
                          meshdata[i][1],
                          meshdata[i][1],
                          dataname2,
                          pagedata,
                          shikii1,
                          shikii2,
                          shikii3,
                          hanrei1_color,
                          hanrei2_color,
                          hanrei3_color,
                          hanrei4_color,
                          ratio,
                          1,
                          metadata.ratio
                        )
                      );

                      // // 拡大時に全メッシュと全凡例を描画（重いけど動きます）
                      // rfcs2.current.cesiumElement.entities.add(
                      //   mesh3rdRect3_kakudai(meshdata[i][0],meshdata[i][1]*metadata.ratio,meshdata[i][1],kakudai_dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,0.0001,0.7)
                      //   );
                      // rfcs2.current.cesiumElement.entities.add(
                      //   mesh3rdRect3_kakudai_hanrei(meshdata[i][0],meshdata[i][1]*metadata.ratio,meshdata[i][1],kakudai_dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,0.0001,0.7,rfcs2)
                      //   );
                    }
                  }
                  var flyto = flyto || true;
                  if (flyto) {
                    rfcs2.current.cesiumElement.zoomTo(
                      rfcs2.current.cesiumElement.entities
                    );
                  }
                }
              }
            });
        // const csvdata = csvfecth();

        const csvfilepath =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          year +
          ".csv";
        const csvfilepath_year1_2 =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          metadata.year1 +
          ".csv";
        const csvfilepath_year2_2 =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          metadata.year2 +
          ".csv";
        const csvfilepath_year3_2 =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          metadata.year3 +
          ".csv";
        const csvfilepath_year4_2 =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          metadata.year4 +
          ".csv";
        const csvfilepath_year5_2 =
          "/csv/" +
          metadata.no +
          "/" +
          area +
          "_" +
          csv_name +
          "_" +
          metadata.year5 +
          ".csv";

        const csvdata_year1_2 = csvfecth(csvfilepath_year1_2, data_year1_2);
        const csvdata_year2_2 = csvfecth(csvfilepath_year2_2, data_year2_2);
        const csvdata_year3_2 = csvfecth(csvfilepath_year3_2, data_year3_2);
        const csvdata_year4_2 = csvfecth(csvfilepath_year4_2, data_year4_2);
        const csvdata_year5_2 = csvfecth(csvfilepath_year5_2, data_year5_2);
      }
      return () => {
        // 表示中のものを削除
        rfcs2.current.cesiumElement.entities.removeAll();
        // 表示中のものを削除【終】
      };
    }
  }, [
    static2map2set, 
    area2set, 
    modeset, 
    ratio1set
  ]);

  // // 左（rfcs2）可視化（倍率、年次の切り替え時） ///////////////////////////
  // useEffect(() => {
  //   if (rfcs2.current && rfcs2.current.cesiumElement) {
  //     let viewer = rfcs2.current.cesiumElement;

  //     const metadatatmp = JSON.stringify(static2map2set);
  //     const metadata = JSON.parse(metadatatmp);
  //     const year = year2set || metadata.year5

  //     let area = "福岡県糸島市";
  //     if(area2set){
  //       area = area2set.normalize( 'NFD' );
  //     }
  //     let csv_name;
  //     if(metadata.csv_filename){
  //       csv_name = metadata.csv_filename.normalize( 'NFD' );
  //     }

  //     const csvfilepath ="/csv/" + metadata.no + "/" + area + "_" + csv_name + "_" + year + ".csv";

  //     // const csvfilepath ="/csv/" + metadata.no + "/" + area1set + "_" + metadata.csv_filename + "_" + year + ".csv";

  //     const csvfecth = () => fetch(csvfilepath,{})
  //     // const csvfecth = () => fetch("/csv/11-2/福岡県篠栗町_人口総数×昼夜間人口比_2015.csv",{})
  //       .then((r)=>{
  //         if (!r.ok) {throw new Error();}
  //         return r.text();
  //       })
  //       .then((r)=>r.split('\r\n'))
  //       .then((r)=>r.map((r)=>r.split(',')))
  //       .then((meshdata) => {

  //         if(meshdata[0][0].length == 6){
  //           // console.log(metadata);
  //           var min = Number(metadata.kokudo_min);
  //           var dataname = data_year5_2;
  //           var pagedata;
  //           var shikii1 = Number(metadata.kokudo_shikii1);
  //           var shikii2 = Number(metadata.kokudo_shikii2);
  //           var shikii3 = Number(metadata.kokudo_shikii3);
  //           var hanrei1_color = metadata.hanrei1_color;
  //           var hanrei2_color = metadata.hanrei2_color;
  //           var hanrei3_color = metadata.hanrei3_color;
  //           var hanrei4_color = metadata.hanrei4_color;
  //           var ratio = Number(ratio1set);
  //           if ( rfcs2.current && rfcs2.current.cesiumElement ) {
  //               for(let i = 0, len = meshdata.length; i< len ; i++){
  //                 if (meshdata[i][1]>min)
  //                 {
  //                   rfcs2.current.cesiumElement.entities.add(
  //                   mesh2ndRect4(meshdata[i][0],meshdata[i][1],meshdata[i][1],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio,1,metadata.kokudo_ratio)
  //                   );
  //                 }
  //               }
  //               var flyto = flyto || false;
  //               if(flyto){
  //                 rfcs2.current.cesiumElement.zoomTo(rfcs2.current.cesiumElement.entities)
  //               }
  //             }
  //         // } else if(meshdata[0][0].length == 6) {
  //           // 2次メッシュの表示拡張用
  //         } else {
  //           var min = Number(0);
  //           var dataname = data_year5_2;
  //           var kakudai_dataname = kakudai_data_year5_2;
  //           var pagedata;
  //           var shikii1 = Number(metadata.shikii1);
  //           var shikii2 = Number(metadata.shikii2);
  //           var shikii3 = Number(metadata.shikii3);
  //           var hanrei1_color = metadata.hanrei1_color;
  //           var hanrei2_color = metadata.hanrei2_color;
  //           var hanrei3_color = metadata.hanrei3_color;
  //           var hanrei4_color = metadata.hanrei4_color;
  //           var ratio = Number(ratio1set);
  //           if ( rfcs2.current && rfcs2.current.cesiumElement ) {
  //             for(let i = 0, len = meshdata.length; i< len ; i++){
  //               if (meshdata[i][1]>min)
  //               {
  //                   // 仮設定２
  //                   // meshdata[i][1] = meshdata[i][1];
  //                 rfcs2.current.cesiumElement.entities.add(
  //                 mesh3rdRect4(meshdata[i][0],meshdata[i][1],meshdata[i][1],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio,1,metadata.ratio)
  //                 );

  //                 // // 拡大時に全メッシュに全凡例を入れる（重いけど動きます）
  //                 // rfcs2.current.cesiumElement.entities.add(
  //                 //   mesh3rdRect3_kakudai(meshdata[i][0],meshdata[i][1]*metadata.ratio,meshdata[i][1],kakudai_dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,0.0001,0.7)
  //                 //   );

  //                 // rfcs2.current.cesiumElement.entities.add(
  //                 //   mesh3rdRect3_kakudai_hanrei(meshdata[i][0],meshdata[i][1]*metadata.ratio,meshdata[i][1],kakudai_dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,0.0001,0.7,rfcs2)
  //                 //   );

  //               }
  //             }
  //             var flyto2 = flyto || false;
  //             if(flyto2){
  //               rfcs2.current.cesiumElement.zoomTo(rfcs2.current.cesiumElement.entities)
  //             }
  //           }
  //         }
  //       }
  //     )
  //     const csvdata = csvfecth();
  //     return () => {

  //     // 表示中のものを削除
  //     rfcs2.current.cesiumElement.entities.removeAll();
  //     // 表示中のものを削除【終】

  //     }
  //   }
  // }, [ratio1set, modeset]);

  // // 表示のonoff 動作しますが、今回はOFF 20220226//////////////
  // useEffect(() => {
  //   if(zoomlevel2set == 1){
  //     data_year5_2.show = true;
  //     kakudai_data_year5_2.show = false;
  //   } else if (zoomlevel2set == 4){
  //     data_year5_2.show = false;
  //     kakudai_data_year5_2.show = true;
  //   }
  //   return function(){
  //   }
  // }, [zoomlevel2set]);

  // ダブルクリックでズームする機能 //////////////
  useEffect(() => {
    window.setTimeout(function () {
      if (rfcs2.current && rfcs2.current.cesiumElement) {
        let viewer = rfcs2.current.cesiumElement;
        var handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
        handler.setInputAction(function (e) {
          viewer.trackedEntity = undefined;
          setRotate1Set(false);
          setRotate2Set(false);

          var picked = viewer.scene.pick(e.position);
          if (picked) {
            var pickedchara = String(picked.id._name);
            var pickedarr = pickedchara.split(",");
            var latcn = Number(pickedarr[0]);
            var loncn = Number(pickedarr[1]);
            // var meshcode = Number(pickedarr[2])
            viewer.camera.flyTo({
              destination: Cartesian3.fromDegrees(latcn, loncn, 1000.0),
              orientation: {
                heading: Cesium_Math.toRadians(0),
                pitch: Cesium_Math.toRadians(-90),
                roll: 0.0,
              },
            });
            // setZoomCount(pickedarr)
            setTimeout(function () {
              setZoomhanrei2Set(pickedarr);
              setZoomlevel2Set(4);
              if (rendouset) {
                setZoomlevel1Set(4);
              }
              // picked.id.entityCollection.show = false;
            }, 2000);
          } else {
            // viewer.entities.show = true;
            setZoomlevel2Set(1);
            if (rendouset) {
              setZoomlevel1Set(1);
            }
            viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));
            setZoomhanrei2Set(null);
          }
        }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        // console.log("ダブルクリックでズームする機能");

        // // 左クリックで緯度経度を取得する処理（動作しますが、重いので一旦解除中）
        // rfcs2.current.cesiumElement.canvas.addEventListener('click',
        // function(e) {
        //   // console.log(e);
        //   // console.log(rfcs2);
        //     var mousePosition = new Cartesian2(e.clientX, e.clientY);
        //     var ellipsoid = rfcs2.current.cesiumElement.scene.globe.ellipsoid;
        //     var cartesian = rfcs2.current.cesiumElement.camera.pickEllipsoid(mousePosition, ellipsoid);
        //     if (cartesian) {
        //         //位置情報を管理するオブジェクトcartographicを取得
        //         var cartographic = Cartographic.fromCartesian(cartesian);
        //         //緯度経度を小数点5桁で取得
        //         var lon = Cesium_Math.toDegrees(cartographic.longitude);
        //         var lat = Cesium_Math.toDegrees(cartographic.latitude);

        //     }
        //     // console.log(lon);
        //     // console.log(lat);
        //     var mesh1 = calcMeshCode(lon, lat, 3)
        //     // console.log(mesh1);

        //   }, false
        //   );
      }
    }, 0);
  }, []);

  // // データを一式追加しておく処理 //////////////
  // useEffect(() => {
  //   // 初期表示データを読み込み
  //   Static2Map2Func(rfcs2, population_now_2, static2map2set, area2set);
  //   Static2Map2Func(rfcs2, population_old_2, population_now, area2set, false );
  //   return () => {
  //     rfcs2.current.cesiumElement.entities.removeAll();
  //   }
  //   }, [area2set]);

  // useEffect(() => {
  //   window.setTimeout(function(){
  //   if (rfcs2.current && rfcs2.current.cesiumElement) {
  //     Static2Map2Func(rfcs2, agedpop_now_2, agedpop_now, area2set, false );
  //     Static2Map2Func(rfcs2, agedpop_old_2, agedpop_old, area2set, false );
  //     Static2Map2Func(rfcs2, worker23_now_2, worker23_now, area2set, false );
  //     Static2Map2Func(rfcs2, worker23_old_2, worker23_old, area2set, false );
  //     Static2Map2Func(rfcs2, daypop_now_2, daypop_now, area2set, false );
  //     Static2Map2Func(rfcs2, daypop_old_2, daypop_old, area2set, false );
  //     Static2Map2Func(rfcs2, sales_now_2, sales_now, area2set, false );
  //     Static2Map2Func(rfcs2, sales_old_2, sales_old, area2set, false );
  //     console.log("データ一式追加処理チェック");
  //   }
  // }, 2100);

  // return () => {
  //   rfcs2.current.cesiumElement.entities.removeAll();
  // }
  // }, [area2set]);

  // 可視化ONOFF（左） ////////////////////
  useEffect(() => {
    if (rfcs2.current && rfcs2.current.cesiumElement) {
      const metadatatmp2 = JSON.stringify(static2map2set);
      const metadata2 = JSON.parse(metadatatmp2);

      // console.log(year2set);
      // console.log(metadata2);

      if (year2set === metadata2.year1) {
        data_year1_2.show = true;
        data_year2_2.show = false;
        data_year3_2.show = false;
        data_year4_2.show = false;
        data_year5_2.show = false;
      } else if (year2set === metadata2.year2) {
        data_year1_2.show = false;
        data_year2_2.show = true;
        data_year3_2.show = false;
        data_year4_2.show = false;
        data_year5_2.show = false;
      } else if (year2set === metadata2.year3) {
        data_year1_2.show = false;
        data_year2_2.show = false;
        data_year3_2.show = true;
        data_year4_2.show = false;
        data_year5_2.show = false;
      } else if (year2set === metadata2.year4) {
        data_year1_2.show = false;
        data_year2_2.show = false;
        data_year3_2.show = false;
        data_year4_2.show = true;
        data_year5_2.show = false;
      } else if (year2set === metadata2.year5) {
        data_year1_2.show = false;
        data_year2_2.show = false;
        data_year3_2.show = false;
        data_year4_2.show = false;
        data_year5_2.show = true;
      } else if (year2set === 0) {
        data_year1_2.show = false;
        data_year2_2.show = false;
        data_year3_2.show = false;
        data_year4_2.show = false;
        data_year5_2.show = true;
      } else if (year2set === 3000) {
        data_year1_2.show = false;
        data_year2_2.show = false;
        data_year3_2.show = false;
        data_year4_2.show = false;
        data_year5_2.show = false;

        // } else if(static2map2set === population_now){
        //   population_now_2.show = true;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === population_old){
        //   population_now_2.show = false;
        //   population_old_2.show = true;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === agedpop_now){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = true;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === agedpop_old){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = true;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === worker23_now){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = true;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === worker23_old){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = true;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === daypop_now){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = true;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === daypop_old){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = true;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
        // } else if (static2map2set === sales_now){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = true;
        //   sales_old_2.show = false;
        // } else if (static2map2set === sales_old){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = true;
        // } else if (static2map2set === null){
        //   population_now_2.show = false;
        //   population_old_2.show = false;
        //   agedpop_now_2.show = false;
        //   agedpop_old_2.show = false;
        //   worker23_now_2.show = false;
        //   worker23_old_2.show = false;
        //   daypop_now_2.show = false;
        //   daypop_old_2.show = false;
        //   sales_now_2.show = false;
        //   sales_old_2.show = false;
      }
    }
    return () => {};
  }, [static2map2set, year2set]);

  // // 可視化ONOFF（右） ////////////////////
  // useLayoutEffect(() => {
  //   if (rfcs2.current && rfcs2.current.cesiumElement) {
  //     if(static2map2set === population_now){
  //       console.log("ONです");
  //       population_now_2.show = true;
  //       population_old_2.show = false;
  //     } else if (static2map2set === population_old){
  //       console.log("OFFです");
  //       population_now_2.show = false;
  //       population_old_2.show = true;
  //     }
  //   }
  //   return () => {
  //   }
  // },[static2map2set])

  // フルスクリーンエフェクト ////////////////////
  FullscreenFunc(rfcs1, rfcs2);
  // 左右連動エフェクト ////////////////////
  RendouFunc(rfcs1, rfcs2);
  // 縮尺合わせるエフェクト ////////////////////
  SyukurenFunc(rfcs1, rfcs2);
  // 回転エフェクト（左） ////////////////////
  Rotate1Func(rfcs1);
  // 回転エフェクト（右） ////////////////////
  Rotate2Func(rfcs2);
  // ２画面１画面切り替え ////////////////////
  const style = FullviwerFunc();
  // ナビゲーション追加用エフェクト /////////////////
  useEffect(() => {
    if (rfcs1.current && rfcs1.current.cesiumElement) {
      // // ナビゲーションは改良中の模様。様子見。
      //     const navoptions = {};
      // navoptions.defaultResetView = Rectangle.fromDegrees(135, 35, 134, 34);
      // navoptions.enableCompass= true;
      // navoptions.enableZoomControls= true;
      // navoptions.enableDistanceLegend= true;
      // navoptions.enableCompassOuterRing= true;
      // navoptions.resetTooltip = "リセット";
      // navoptions.zoomInTooltip = "拡大";
      // navoptions.zoomOutTooltip = "縮小";
      // new CesiumNavigation(rfcs1.current.cesiumElement, navoptions);
    }
  }, []);

  return (
    <>
      <Viewer
        ref={rfcs1}
        className={style}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        vrButton={false}
        homeButton={false}
        timeline={false}
        navigationHelpButton={false}
        fullscreenButton={false}
        baseLayerPicker={false}
        geocoder={false}
        imageryProvider={false}
        scene3DOnly={true}
        animation={false}
        creditContainer={null}
        selectionIndicator={false}
        sceneModePicker={false}
        infoBox={false}
        shadows={false}
      >
        <ImageryLayer imageryProvider={map1set} />
        {/* <Entity position={position} name="Tokyo">
      <PointGraphics pixelSize={10} />
      <EntityDescription>
        <h1>Hello, world.</h1>
        <p>JSX is available here!</p>
      </EntityDescription>
    </Entity> */}
      </Viewer>

      <Viewer
        ref={rfcs2}
        className="w-2/4 mr-auto"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        vrButton={false}
        homeButton={false}
        timeline={false}
        navigationHelpButton={false}
        baseLayerPicker={false}
        fullscreenButton={false}
        geocoder={false}
        imageryProvider={false}
        scene3DOnly={true}
        animation={false}
        creditContainer={null}
        selectionIndicator={false}
        sceneModePicker={false}
        infoBox={false}
        shadows={false}
      >
        <ImageryLayer imageryProvider={map2set} />
        {/* <Entity position={position} name="Tokyo">
      <PointGraphics pixelSize={10} />
      <EntityDescription>
        <h1>Hello, world.</h1>
        <p>JSX is available here!</p>
      </EntityDescription>
    </Entity> */}
      </Viewer>
    </>
  );
};
