import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Viewer,
  Entity,
  PointGraphics,
  EntityDescription,
  ImageryLayer,
} from "resium";
import {
  Cartesian3,
  Cartesian2,
  SceneMode,
  Math as Cesium_Math,
  Rectangle,
  Camera,
  Cartographic,
  Ellipsoid,
  Transforms,
  HeadingPitchRange,
  Matrix4,
  Fullscreen,
  Color,
  ImageMaterialProperty,
  HorizontalOrigin,
  VerticalOrigin,
  ScreenSpaceEventType,
  ScreenSpaceEventHandler,
} from "cesium";
import { Entity as cesiumEntity } from "cesium";
import { FullscreenSet } from "./App";
import { MetadataSet } from "./App";
import { RendouSet } from "./App";
import { SyukurenSet } from "./App";
import { Rotate1Set } from "./App";
import { Rotate2Set } from "./App";
import { Map1Set } from "./App";
import { Map2Set } from "./App";
import { Area1Set } from "./App";
import { Area2Set } from "./App";
import { Static2Map1Set } from "./App";
import { Static2Map2Set } from "./App";
import { instruction } from "./kashikasetting";
import { list } from "postcss";
import localforage from "localforage";

import { population_now, population_old, metadata } from "./kashikasetting";
import { datalist, datalist2 } from "./kashikasetting";
import useSWR from "swr";
import { Zoomhanrei1Set, Zoomhanrei2Set } from "./App";
import Imagetest from "./img/sdg_1.webp";
import Imagetest2 from "./img/01_tikei.svg";

var array = [];
var header = [];

// 緯度経度をメッシュコードに変換する関数
export function calcMeshCode(lon, lat, resolution) {
  var p = Math.floor((lat * 60) / 40);
  var a = (lat * 60) % 40;
  var q = Math.floor(a / 5);
  var b = a % 5;
  var r = Math.floor((b * 60) / 30);
  var c = (b * 60) % 30;
  var s = Math.floor(c / 15);
  var d = c % 15;
  var t = Math.floor(d / 7.5);

  var u = Math.floor(lon - 100);
  var f = lon - 100 - u;
  var v = Math.floor((f * 60) / 7.5);
  var g = (f * 60) % 7.5;
  var w = Math.floor((g * 60) / 45);
  var h = (g * 60) % 45;
  var x = Math.floor(h / 22.5);
  var i = h % 22.5;
  var y = Math.floor(i / 11.25);

  var m = s * 2 + (x + 1);
  var n = t * 2 + (y + 1);

  //1次メッシュ
  var mesh = "" + p + u;
  //2次メッシュ
  if (resolution >= 2) {
    mesh = mesh + q + v;
    //3次メッシュ
    if (resolution >= 3) {
      mesh = mesh + r + w;
      // 1/2メッシュ
      if (resolution >= 4) {
        mesh = mesh + m;
        // 1/4メッシュ
        if (resolution >= 5) {
          mesh = mesh + n;
        }
      }
    }
  }

  return mesh;
}

// メタデータ読み込み【端末保存版】 ////////////////////
export const LoadMetadataFunc = () => {
  const { metadataset, setMetadataSet } = useContext(MetadataSet);

  // 指示メッシュ取得【並列処理１】
  const citymesh = () =>
    fetch(metadata, {})
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.text();
      })
      .then((text) => {
        var tmp = text.split("\n");
        header = tmp[0].split(",");
        var newtmp = tmp.map(function (value) {
          value = value.split(",");
          return value;
        });
        return newtmp;
      })
      .then((newtmp) => {
        for (let i = 0; i < newtmp.length; i++) {
          var tmpline = newtmp[i];
          array[i] = [];
          for (let j = 0; j < tmpline.length; j++) {
            array[i][header[j]] = tmpline[j];
          }
        }
        return array;
        // localforage.setItem("meta",array);
      })
      .then((array) => {
        // console.log(array);
        setMetadataSet(array);
      });
  citymesh();
};

/// /// 3次メッシュ3列の地面での枠線表示 /////////////////////////
export function mesh3rdRect3_kakudai(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  var r = parseInt(strMeshcoe.slice(6, 7));
  var w = parseInt(strMeshcoe.slice(7, 8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;
  var latcpre = lat + 0.008333333 / 2;
  var loncpre = lon + 0.0125 / 2;
  var alphatmp = alpha || 1;

  var latc = latcpre.toPrecision(6);
  var lonc = loncpre.toPrecision(6);

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    parent: dataname,
    // id:meshcode3rd ,
    // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    name: lonc + "," + latc + "," + meshcode3rd + "," + h + "," + c,
    wall: {
      positions: Cartesian3.fromDegreesArray([
        Number(lon),
        Number(lat),
        Number(lon),
        Number(lat + 0.008333333),
        Number(lon + 0.0125),
        Number(lat + 0.008333333),
        Number(lon + 0.0125),
        Number(lat),
        Number(lon),
        Number(lat),
      ]),
      maximumHeights: [100, 100, 100, 100, 100],
      // minimumHeights: [0, 0],
      outline: true,
      outlineColor: Color.LIGHTGRAY,
      outlineWidth: 40,
      material: Color.fromCssColorString(color1).withAlpha(alphatmp),

      // material : Color.fromCssColorString("#FFFFFF1a"),
      translucent: true,
    },
  };
  return ans_entities;
}
/// ///////////////////////// 3次メッシュ3列の地面での枠線表示 ///

/// /// 3次メッシュ3列の地面での凡例表示 /////////////////////////
export function mesh3rdRect3_kakudai_hanrei(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha,
  rfcs2
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  var r = parseInt(strMeshcoe.slice(6, 7));
  var w = parseInt(strMeshcoe.slice(7, 8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;
  var latcpre = lat + 0.008333333 / 2;
  var loncpre = lon + 0.0125 / 2;
  var alphatmp = alpha || 1;

  var latc = latcpre.toPrecision(6);
  var lonc = loncpre.toPrecision(6);

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    // parent: dataname,
    // // id:meshcode3rd ,
    // // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    // name : lonc+","+latc+","+meshcode3rd+","+h+","+c,
    // position: Cartesian3.fromDegrees(lon, lat+0.0008333333),
    // // position: Cartesian3.fromDegrees(lon+0.0125, lat+0.008333333),
    //   label: {
    //     text: "テスト中"+"です",
    //     // image: Imagetest,
    //     sizeInMeters: true,
    //     horizontalOrigin: HorizontalOrigin.LEFT,
    //     // scale: 0.2,
    //     verticalOrigin: VerticalOrigin.BOTTOM,
    //     // height: 500,
    //   },
    // billboard: {
    //   image: Imagetest,
    //   sizeInMeters: true,
    //   horizontalOrigin: HorizontalOrigin.LEFT,
    //   scale: 0.2,
    //   verticalOrigin: VerticalOrigin.BOTTOM,
    //   height: 500,
    // },
    // wall: {
    //   positions: Cartesian3.fromDegreesArray(
    //     [Number(lon),Number(lat),Number(lon),Number(lat+0.008333333),Number(lon+0.0125),Number(lat),Number(lon),Number(lat)]
    //   ),
    //   maximumHeights: [100,100,100,100],
    //   // minimumHeights: [0, 0],
    //   outline: true,
    //   outlineColor: Color.LIGHTGRAY,
    //   outlineWidth: 40,
    //   material : new ImageMaterialProperty({
    //     image: drawText("こんにちは"),
    //     color: Color.fromCssColorString(color1).withAlpha(alphatmp)
    //   }),
    //   // material : Color.fromCssColorString(color1).withAlpha(alphatmp),
    //   // material : Color.fromCssColorString("#FFFFFF1a"),
    //   translucent : true,
    // },
  };

  var viewer = rfcs2.current.cesiumElement;

  var canvas = document.createElement("canvas");
  canvas.width = 500;
  canvas.height = 500;

  var svgString =
    '<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400">' +
    '<foreignObject width="100%" height="100%">' +
    '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size:40px; color: #FF0">' +
    "凡例" +
    '<span style="color:white; text-shadow:0 0 2px blue;">' +
    "</span>" +
    '<a href="https://google.co.jp"><p>' +
    meshcode3rd +
    "</p></a>" +
    "</div>" +
    "</foreignObject>" +
    "</svg>";

  var image = new Image();
  image.src =
    "data:image/svg+xml;base64," +
    window.btoa(unescape(encodeURIComponent(svgString)));

  //Need to wait for image to load before proceeding to draw
  image.onload = function () {
    canvas.getContext("2d").drawImage(image, 0, 0);

    viewer.entities.add({
      parent: dataname,
      id: strMeshcoe + 1000000000,
      position: Cartesian3.fromDegrees(loncpre, latcpre),
      billboard: {
        image: canvas,
        sizeInMeters: true,
        // horizontalOrigin: HorizontalOrigin.LEFT,
        // scale: 0.2,
        // verticalOrigin: VerticalOrigin.BOTTOM,
        // height: 500,
      },
      description: "<p>This is a cupcake that can be modified.</p>",
    });

    // viewer.entities.add(
    //   {
    //     parent: dataname,
    //     // id:meshcode3rd ,
    //     // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    //     name : lonc+","+latc+","+meshcode3rd+","+h+","+c,
    //     wall: {
    //       positions: Cartesian3.fromDegreesArray(
    //         [Number(lon),Number(lat),Number(lon),Number(lat+0.008333333),Number(lon+0.0125),Number(lat+0.008333333),Number(lon+0.0125),Number(lat),Number(lon),Number(lat)]
    //       ),
    //       maximumHeights: [100,100,100,100,100],
    //       // minimumHeights: [0, 0],
    //       outline: true,
    //       outlineColor: Color.LIGHTGRAY,
    //       outlineWidth: 40,
    //       material : Color.fromCssColorString(color1).withAlpha(alphatmp),

    //       // material : Color.fromCssColorString("#FFFFFF1a"),
    //       translucent : true,
    //     },
    // });
  };

  return ans_entities;
}
/// ///////////////////////// 3次メッシュ3列の地面での凡例表示 ///

/// /// 3次メッシュ3列の可視化・デフォルト倍率設定の追加・20220218 /////////////////////////
export function mesh3rdRect4(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha,
  defaultratio
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  var r = parseInt(strMeshcoe.slice(6, 7));
  var w = parseInt(strMeshcoe.slice(7, 8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;
  var latc = lat + 0.008333333 / 2;
  var lonc = lon + 0.0125 / 2;
  var alphatmp = alpha || 1;

  // var latc = latcpre.toPrecision(6)
  // var lonc = loncpre.toPrecision(6)

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    parent: dataname,
    // id:meshcode3rd ,
    // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    name: lonc + "," + latc + "," + meshcode3rd + "," + h + "," + c,
    rectangle: {
      coordinates: Rectangle.fromDegrees(
        lon,
        lat,
        lon + 0.0125,
        lat + 0.008333333
      ),
      height: 0,
      extrudedHeight: h * defaultratio * ratio,
      material: Color.fromCssColorString(color1).withAlpha(alphatmp),
      // material : Color.fromRgba( color_gbra )
      outline: true,
      outlineColor: "white",
      outlineWidth: 1,
      // shadows : ShadowMode.ENABLED
    },
  };
  return ans_entities;
}
/// ///////////////////////// 3次メッシュ3列の可視化 ///

/// /// 3次メッシュ3列の可視化 /////////////////////////
export function mesh3rdRect3(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  var r = parseInt(strMeshcoe.slice(6, 7));
  var w = parseInt(strMeshcoe.slice(7, 8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;
  var latcpre = lat + 0.008333333 / 2;
  var loncpre = lon + 0.0125 / 2;
  var alphatmp = alpha || 1;

  var latc = latcpre.toPrecision(6);
  var lonc = loncpre.toPrecision(6);

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    parent: dataname,
    // id:meshcode3rd ,
    // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    name: lonc + "," + latc + "," + meshcode3rd + "," + h + "," + c,
    rectangle: {
      coordinates: Rectangle.fromDegrees(
        lon,
        lat,
        lon + 0.0125,
        lat + 0.008333333
      ),
      height: 0,
      extrudedHeight: h * ratio,
      material: Color.fromCssColorString(color1).withAlpha(alphatmp),
      // material : Color.fromRgba( color_gbra )
      outline: true,
      outlineColor: "white",
      outlineWidth: 1,
      // shadows : ShadowMode.ENABLED
    },
  };
  return ans_entities;
}
/// ///////////////////////// 3次メッシュ3列の可視化 ///

/// /// 2次メッシュ3列の可視化 /////////////////////////
export function mesh2ndRect4(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha,
  defaultratio
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  // var r = parseInt(strMeshcoe.slice(6,7));
  // var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12;
  var lon = u + 100 + v * 0.125;

  var latc = lat + 0.08333333 / 2;
  var lonc = lon + 0.125 / 2;
  // var latcpre = lat + 0.08333333/2
  // var loncpre = lon + 0.125/2
  // var latc = latcpre.toPrecision(6)
  // var lonc = loncpre.toPrecision(6)

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    parent: dataname,
    // id:meshcode3rd ,
    // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    name: lonc + "," + latc + "," + meshcode3rd + "," + h + "," + c,
    rectangle: {
      coordinates: Rectangle.fromDegrees(
        lon,
        lat,
        lon + 0.125,
        lat + 0.08333333
      ),
      height: 0,
      extrudedHeight: defaultratio * h * ratio,
      material: Color.fromCssColorString(color1),
      // material : Color.fromRgba( color_gbra )
      outline: true,
      outlineColor: "white",
      outlineWidth: 1,
      // shadows : ShadowMode.ENABLED
    },
  };
  return ans_entities;
}
/// ///////////////////////// 2次メッシュ3列の可視化 ///

/// /// 2次メッシュ3列の可視化 /////////////////////////
export function mesh2ndRect3(
  meshcode3rd,
  h,
  c,
  dataname,
  pagedata,
  shikii1,
  shikii2,
  shikii3,
  hanrei1_color,
  hanrei2_color,
  hanrei3_color,
  hanrei4_color,
  ratio,
  alpha,
  defaultratio
) {
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0, 2));
  var u = parseInt(strMeshcoe.slice(2, 4));
  var q = parseInt(strMeshcoe.slice(4, 5));
  var v = parseInt(strMeshcoe.slice(5, 6));
  // var r = parseInt(strMeshcoe.slice(6,7));
  // var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12;
  var lon = u + 100 + v * 0.125;

  var latc = lat + 0.08333333 / 2;
  var lonc = lon + 0.125 / 2;
  // var latcpre = lat + 0.08333333/2
  // var loncpre = lon + 0.125/2
  // var latc = latcpre.toPrecision(6)
  // var lonc = loncpre.toPrecision(6)

  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }

  var ans_entities = {
    parent: dataname,
    // id:meshcode3rd ,
    // description : "<p>"+meshcode3rd+"</p><p>"+h+"</p><p>"+c+"</p>",
    name: lonc + "," + latc + "," + meshcode3rd + "," + h + "," + c,
    rectangle: {
      coordinates: Rectangle.fromDegrees(
        lon,
        lat,
        lon + 0.125,
        lat + 0.08333333
      ),
      height: 0,
      extrudedHeight: h * ratio,
      material: Color.fromCssColorString(color1),
      // material : Color.fromRgba( color_gbra )
      outline: true,
      outlineColor: "white",
      outlineWidth: 1,
      // shadows : ShadowMode.ENABLED
    },
  };
  return ans_entities;
}
/// ///////////////////////// 2次メッシュ3列の可視化 ///

// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！
// 今ここ作業中！！

// 統計表示（左）最終版！【20220124】 ////////////////////
export const CsvToMap1 = (rfcs1, datanow, dataurl, area1set, flyto) => {
  const csvfecth = () =>
    fetch(dataurl, {})
      .then((r) => {
        if (!r.ok) {
          throw new Error();
        }
        return r.text();
      })
      .then((r) => r.split("\r\n"))
      .then((r) => r.map((r) => r.split(",")))
      .then((meshdata) => {
        // // とりあえず移動を開始
        //     var strMeshcoe = String(meshdata[0][0]);
        //     var p = parseInt(strMeshcoe.slice(0,2));
        //     var u = parseInt(strMeshcoe.slice(2,4));
        //     var q = parseInt(strMeshcoe.slice(4,5));
        //     var v = parseInt(strMeshcoe.slice(5,6));
        //     // var r = parseInt(strMeshcoe.slice(6,7));
        //     // var w = parseInt(strMeshcoe.slice(7,8));
        //     var lat = p / 1.5 + q / 12;
        //     var lon = u + 100 + v * 0.125;
        //     // var lat = p / 1.5 + q / 12 + r / 120;
        //     // var lon = u + 100 + v * 0.125 + w * 0.0125;
        //     rfcs1.current.cesiumElement.camera.flyTo({
        //       destination: Cartesian3.fromDegrees(lon, lat, 500000),
        //   });
        //   // とりあえず移動を開始【終】

        // 表示中のものを削除
        rfcs1.current.cesiumElement.entities.removeAll();
        // 表示中のものを削除【終】

        // 仮設定１
        var min = Number(0);
        var dataname = datanow;
        var pagedata;
        var shikii1 = Number(4000);
        var shikii2 = Number(2000);
        var shikii3 = Number(1000);
        var hanrei1_color = "red";
        var hanrei2_color = "orange";
        var hanrei3_color = "yellow";
        var hanrei4_color = "gray";
        var ratio = Number(1);
        if (rfcs1.current && rfcs1.current.cesiumElement) {
          for (let i = 0, len = meshdata.length; i < len; i++) {
            if (meshdata[i][1] > min) {
              // 仮設定２
              meshdata[i][2] = meshdata[i][1];
              rfcs1.current.cesiumElement.entities.add(
                mesh3rdRect3(
                  meshdata[i][0],
                  meshdata[i][1],
                  meshdata[i][2],
                  dataname,
                  pagedata,
                  shikii1,
                  shikii2,
                  shikii3,
                  hanrei1_color,
                  hanrei2_color,
                  hanrei3_color,
                  hanrei4_color,
                  ratio
                )
              );
            }
          }
          if (flyto) {
            rfcs1.current.cesiumElement.flyTo(
              rfcs1.current.cesiumElement.entities
            );
          }
        }
      });

  var flyto = flyto || true;

  var testdata = csvfecth();
  // console.log(testdata);

  // const {area1set, setArea1Set} = useContext(Area1Set);
  // console.log(datanow);

  const fetchercsv_data = (url) =>
    fetch(url)
      .then((r) => r.text())
      .then((r) => r.split("\r\n"))
      .then((r) => r.map((r) => r.split(",")))
      .then((meshdata) => {
        // // とりあえず移動を開始
        //     var strMeshcoe = String(meshdata[0][0]);
        //     var p = parseInt(strMeshcoe.slice(0,2));
        //     var u = parseInt(strMeshcoe.slice(2,4));
        //     var q = parseInt(strMeshcoe.slice(4,5));
        //     var v = parseInt(strMeshcoe.slice(5,6));
        //     // var r = parseInt(strMeshcoe.slice(6,7));
        //     // var w = parseInt(strMeshcoe.slice(7,8));
        //     var lat = p / 1.5 + q / 12;
        //     var lon = u + 100 + v * 0.125;
        //     // var lat = p / 1.5 + q / 12 + r / 120;
        //     // var lon = u + 100 + v * 0.125 + w * 0.0125;
        //     rfcs1.current.cesiumElement.camera.flyTo({
        //       destination: Cartesian3.fromDegrees(lon, lat, 500000),
        //   });
        //   // とりあえず移動を開始【終】

        // 表示中のものを削除
        rfcs1.current.cesiumElement.entities.removeAll();
        // 表示中のものを削除【終】

        // 仮設定１
        var min = Number(0);
        var dataname = datanow;
        var pagedata;
        var shikii1 = Number(4000);
        var shikii2 = Number(2000);
        var shikii3 = Number(1000);
        var hanrei1_color = "red";
        var hanrei2_color = "orange";
        var hanrei3_color = "yellow";
        var hanrei4_color = "gray";
        var ratio = Number(1);
        if (rfcs1.current && rfcs1.current.cesiumElement) {
          for (let i = 0, len = meshdata.length; i < len; i++) {
            if (meshdata[i][1] > min) {
              // 仮設定２
              meshdata[i][2] = meshdata[i][1];
              rfcs1.current.cesiumElement.entities.add(
                mesh3rdRect3(
                  meshdata[i][0],
                  meshdata[i][1],
                  meshdata[i][2],
                  dataname,
                  pagedata,
                  shikii1,
                  shikii2,
                  shikii3,
                  hanrei1_color,
                  hanrei2_color,
                  hanrei3_color,
                  hanrei4_color,
                  ratio
                )
              );
            }
          }
          if (flyto) {
            rfcs1.current.cesiumElement.flyTo(
              rfcs1.current.cesiumElement.entities
            );
          }
        }
      });

  var flyto = flyto || true;

  // function Csvdata_get() {
  //   const { data, error} = useSWR(["/csv/11-1/"+area1set+"_人口総数×人口総数_2015.csv",1], fetchercsv_data)

  //   if (error) return console.log("エラー");
  //   if (!data) return console.log("読み込み中");
  //   if (data) return console.log("読み込み完了");
  // }
  // const csv1 = Csvdata_get();
  // console.log(csv1);
};
//////////////////// 統計表示（左） //

// 統計表示（左）改良版！【端末保存版】 ////////////////////
export const useCsvToMap1Func = (rfcs1, datanow, dataurl, area1set, flyto) => {
  // const {area1set, setArea1Set} = useContext(Area1Set);

  const fetchercsv_data = (url) =>
    fetch(url)
      .then((r) => r.text())
      .then((r) => r.split("\r\n"))
      .then((r) => r.map((r) => r.split(",")))
      .then((meshdata) => {
        // // とりあえず移動を開始
        //     var strMeshcoe = String(meshdata[0][0]);
        //     var p = parseInt(strMeshcoe.slice(0,2));
        //     var u = parseInt(strMeshcoe.slice(2,4));
        //     var q = parseInt(strMeshcoe.slice(4,5));
        //     var v = parseInt(strMeshcoe.slice(5,6));
        //     // var r = parseInt(strMeshcoe.slice(6,7));
        //     // var w = parseInt(strMeshcoe.slice(7,8));
        //     var lat = p / 1.5 + q / 12;
        //     var lon = u + 100 + v * 0.125;
        //     // var lat = p / 1.5 + q / 12 + r / 120;
        //     // var lon = u + 100 + v * 0.125 + w * 0.0125;
        //     rfcs1.current.cesiumElement.camera.flyTo({
        //       destination: Cartesian3.fromDegrees(lon, lat, 500000),
        //   });
        //   // とりあえず移動を開始【終】

        // 表示中のものを削除
        rfcs1.current.cesiumElement.entities.removeAll();
        // 表示中のものを削除【終】

        // 仮設定１
        var min = Number(0);
        var dataname = datanow;
        var pagedata;
        var shikii1 = Number(4000);
        var shikii2 = Number(2000);
        var shikii3 = Number(1000);
        var hanrei1_color = "red";
        var hanrei2_color = "orange";
        var hanrei3_color = "yellow";
        var hanrei4_color = "gray";
        var ratio = Number(1);
        if (rfcs1.current && rfcs1.current.cesiumElement) {
          for (let i = 0, len = meshdata.length; i < len; i++) {
            if (meshdata[i][1] > min) {
              // 仮設定２
              meshdata[i][2] = meshdata[i][1];
              rfcs1.current.cesiumElement.entities.add(
                mesh3rdRect3(
                  meshdata[i][0],
                  meshdata[i][1],
                  meshdata[i][2],
                  dataname,
                  pagedata,
                  shikii1,
                  shikii2,
                  shikii3,
                  hanrei1_color,
                  hanrei2_color,
                  hanrei3_color,
                  hanrei4_color,
                  ratio
                )
              );
            }
          }
          if (flyto) {
            rfcs1.current.cesiumElement.zoomTo(
              rfcs1.current.cesiumElement.entities
            );
          }
        }
      });

  var flyto = flyto || true;

  // function Csvdata_get() {
  //   const { data, error} = useSWR(["/csv/11-1/"+area1set+"_人口総数×人口総数_2015.csv",1], fetchercsv_data)

  //   if (error) return console.log("エラー");
  //   if (!data) return console.log("読み込み中");
  //   if (data) return console.log("読み込み完了");
  // }
  // const csv1 = Csvdata_get();
  // console.log(csv1);
};
//////////////////// 統計表示（左） //

// 統計表示（右）改良版！【端末保存版】 ////////////////////
export const CsvToMap2Func = (rfcs1, datanow, dataurl, area1set, flyto) => {
  // const {area1set, setArea1Set} = useContext(Area1Set);

  const fetchercsv_data2 = (url) =>
    fetch(url)
      .then((r) => r.text())
      .then((r) => r.split("\r\n"))
      .then((r) => r.map((r) => r.split(",")))
      .then((meshdata) => {
        // 移動（追加の場合はこの１行を削除）
        rfcs1.current.cesiumElement.entities.removeAll();
        // 仮設定１
        var min = Number(0);
        var dataname = datanow;
        var pagedata;
        var shikii1 = Number(4000);
        var shikii2 = Number(2000);
        var shikii3 = Number(1000);
        var hanrei1_color = "red";
        var hanrei2_color = "orange";
        var hanrei3_color = "yellow";
        var hanrei4_color = "gray";
        var ratio = Number(1);
        if (rfcs1.current && rfcs1.current.cesiumElement) {
          for (let i = 0, len = meshdata.length; i < len; i++) {
            if (meshdata[i][1] > min) {
              // 仮設定２
              meshdata[i][2] = meshdata[i][1];
              rfcs1.current.cesiumElement.entities.add(
                mesh3rdRect3(
                  meshdata[i][0],
                  meshdata[i][1],
                  meshdata[i][2],
                  dataname,
                  pagedata,
                  shikii1,
                  shikii2,
                  shikii3,
                  hanrei1_color,
                  hanrei2_color,
                  hanrei3_color,
                  hanrei4_color,
                  ratio
                )
              );
            }
          }
          if (flyto) {
            rfcs1.current.cesiumElement.zoomTo(
              rfcs1.current.cesiumElement.entities
            );
          }
        }
      });

  var flyto = flyto || true;

  // function Csvdata_get() {
  //   const { data, error} = useSWR(["/csv/11-1/"+area1set+"_人口総数×人口総数_2015.csv",2], fetchercsv_data2)

  //   if (error) return console.log("エラー");
  //   if (!data) return console.log("読み込み中");
  //   if (data) return console.log("読み込み完了");
  //   // if (data) {
  //   //   return data;
  //   // }
  // }
  // const csv2 = Csvdata_get();
  // console.log(csv2);
};
//////////////////// 統計表示（左） //

// 統計表示（左）改良版！【端末保存版】 ////////////////////
export const Static2Map3Func = (rfcs1, datanow, dataurl, area1set, flyto) => {
  // const {area1set, setArea1Set} = useContext(Area1Set);

  const fetchercsv_data = (url) =>
    fetch(url)
      .then((r) => r.text())
      .then((r) => r.split("\r\n"))
      .then((r) => r.map((r) => r.split(",")))
      .then((meshdata) => {
        // 仮設定１
        var min = Number(0);
        var dataname = datanow;
        var pagedata;
        var shikii1 = Number(4000);
        var shikii2 = Number(2000);
        var shikii3 = Number(1000);
        var hanrei1_color = "red";
        var hanrei2_color = "orange";
        var hanrei3_color = "yellow";
        var hanrei4_color = "gray";
        var ratio = Number(1);

        if (rfcs1.current && rfcs1.current.cesiumElement) {
          for (let i = 0, len = meshdata.length; i < len; i++) {
            if (meshdata[i][1] > min) {
              // 仮設定２
              meshdata[i][2] = meshdata[i][1];
              rfcs1.current.cesiumElement.entities.add(
                mesh3rdRect3(
                  meshdata[i][0],
                  meshdata[i][1],
                  meshdata[i][2],
                  dataname,
                  pagedata,
                  shikii1,
                  shikii2,
                  shikii3,
                  hanrei1_color,
                  hanrei2_color,
                  hanrei3_color,
                  hanrei4_color,
                  ratio
                )
              );
            }
          }
          if (flyto) {
            rfcs1.current.cesiumElement.zoomTo(
              rfcs1.current.cesiumElement.entities
            );
          }
        }
      });

  var flyto = flyto || true;

  // function Csvdata_get() {
  //   const { data} = useSWR("/csv/11-1/"+area1set+"_人口総数×人口総数_2015.csv", fetchercsv_data)

  //   if (data) {
  //     return data;
  //   }
  // }
  // const csv1 = Csvdata_get();
  // console.log(csv1);

  //   // const { data1, error1} = useSWR(population_now, fetchercsv)
  //   // // if (error) return <div>Failed to load</div>
  //   // // if (!data) return <div>Loading...</div>
  //   // if (data1) {
  //   //   console.log(data1);
  //   // }

  //   // 指示メッシュ取得【並列処理１】
  //   const citymesh = () => fetch(instruction, {})
  //     .then((response) => {
  //       if (!response.ok) {throw new Error();}
  //       return response.text();
  //     })
  //   // 指定都市分を抽出【並列処理１】
  //     .then((text) => {
  //       var tmp = text.split('\n');
  //       var citymeshlist;
  //       // console.log(area1set || "福岡県糸島市");
  //       citymeshlist = tmp.filter(function(value){
  //         return value.includes(area1set);
  //       })
  //         return citymeshlist
  //     })
  //   // データcsv取得【並列処理２】
  //     .then((citymeshlist) =>{
  //       fetch(dataurl, {})
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error();
  //         }
  //         return response.text(); // あるいは response.json()
  //       })
  //   // データcsv整形【並列処理２】
  //       .then((text) => {
  //         let map = new Map();
  //         var tmp = text.split('\r\n');
  //         for(let i = 0, len = tmp.length; i< len ; i++){
  //           map.set(tmp[i].split(',')[0],tmp[i].split(',')[1])
  //           }
  //           return map;
  //         })
  //   // 指定都市分のみ抽出
  //       .then((map) => {
  //         var mesh;
  //         var meshdata = [];
  //         for(let i = 0, len = citymeshlist.length; i< len ; i++){
  //           mesh = citymeshlist[i].split(',')[0];
  //           let meshtmp = map.get(mesh);
  //           if(meshtmp){
  //             meshdata.push([mesh,meshtmp])
  //           }
  //           }
  //           return meshdata
  //         })
  //   // 可視化
  //         .then((meshdata) => {
  //                               // 仮設定１
  //                               var min = Number(0);
  //                               var dataname = datanow;
  //                               var pagedata;
  //                               var shikii1 = Number(4000);
  //                               var shikii2 = Number(2000);
  //                               var shikii3 = Number(1000);
  //                               var hanrei1_color = "red";
  //                               var hanrei2_color = "orange";
  //                               var hanrei3_color = "yellow";
  //                               var hanrei4_color = "gray";
  //                               var ratio = Number(1);

  //         if ( rfcs1.current && rfcs1.current.cesiumElement ) {
  //           for(let i = 0, len = meshdata.length; i< len ; i++){
  //             if (meshdata[i][1]>min) {
  //                               // 仮設定２
  //                               meshdata[i][2] = meshdata[i][1];
  //               rfcs1.current.cesiumElement.entities.add(
  //               mesh3rdRect3(meshdata[i][0],meshdata[i][1],meshdata[i][2],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio)
  //               );
  //             }
  //           }
  //           if(flyto){
  //           rfcs1.current.cesiumElement.flyTo(rfcs1.current.cesiumElement.entities)
  //           }
  //       }
  //     })
  //     // データonoff
  //         .then(() => {
  //           let nowdata = datalist2.filter(data => data.url === dataurl)
  //           if(dataurl === nowdata[0].url){
  //             console.log("ここまできた！");
  //           }
  //         }).catch((reason) => { // エラー
  //       });
  //     }).catch((reason) => { // エラー
  //   });
  // citymesh();
  //   return () => {
  //   }
};
//////////////////// 統計表示（左） //

// 統計表示（左）改良版！ ////////////////////
export const Static2Map2Func = (rfcs1, datanow, dataurl, area1set, flyto) => {
  // const {area1set, setArea1Set} = useContext(Area1Set);
  var flyto = flyto || true;
  // 指示メッシュ取得【並列処理１】
  const citymesh = () =>
    fetch(instruction, {})
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.text();
      })
      // 指定都市分を抽出【並列処理１】
      .then((text) => {
        var tmp = text.split("\n");
        var citymeshlist;
        // console.log(area1set || "福岡県糸島市");
        citymeshlist = tmp.filter(function (value) {
          return value.includes(area1set);
        });
        return citymeshlist;
      })
      // データcsv取得【並列処理２】
      .then((citymeshlist) => {
        fetch(dataurl, {})
          .then((response) => {
            if (!response.ok) {
              throw new Error();
            }
            return response.text(); // あるいは response.json()
          })
          // データcsv整形【並列処理２】
          .then((text) => {
            let map = new Map();
            var tmp = text.split("\r\n");
            for (let i = 0, len = tmp.length; i < len; i++) {
              map.set(tmp[i].split(",")[0], tmp[i].split(",")[1]);
            }
            return map;
          })
          // 指定都市分のみ抽出
          .then((map) => {
            var mesh;
            var meshdata = [];
            for (let i = 0, len = citymeshlist.length; i < len; i++) {
              mesh = citymeshlist[i].split(",")[0];
              let meshtmp = map.get(mesh);
              if (meshtmp) {
                meshdata.push([mesh, meshtmp]);
              }
            }
            return meshdata;
          })
          // 可視化
          .then((meshdata) => {
            // 仮設定１
            var min = Number(0);
            var dataname = datanow;
            var pagedata;
            var shikii1 = Number(4000);
            var shikii2 = Number(2000);
            var shikii3 = Number(1000);
            var hanrei1_color = "red";
            var hanrei2_color = "orange";
            var hanrei3_color = "yellow";
            var hanrei4_color = "gray";
            var ratio = Number(1);

            if (rfcs1.current && rfcs1.current.cesiumElement) {
              for (let i = 0, len = meshdata.length; i < len; i++) {
                if (meshdata[i][1] > min) {
                  // 仮設定２
                  meshdata[i][2] = meshdata[i][1];
                  rfcs1.current.cesiumElement.entities.add(
                    mesh3rdRect3(
                      meshdata[i][0],
                      meshdata[i][1],
                      meshdata[i][2],
                      dataname,
                      pagedata,
                      shikii1,
                      shikii2,
                      shikii3,
                      hanrei1_color,
                      hanrei2_color,
                      hanrei3_color,
                      hanrei4_color,
                      ratio
                    )
                  );
                }
              }
              // flyto
              if (flyto) {
                rfcs1.current.cesiumElement.flyTo(
                  rfcs1.current.cesiumElement.entities
                );
              }
            }
          })
          // データonoff
          .then(() => {
            let nowdata = datalist2.filter((data) => data.url === dataurl);
            if (dataurl === nowdata[0].url) {
              // console.log("ここまできた！");
            }
          })
          .catch((reason) => {
            // エラー
          });
      })
      .catch((reason) => {
        // エラー
      });
  citymesh();
  return () => {};
};
//////////////////// 統計表示（左） //

// GTFS表示（左）改良版！ ////////////////////
export const Gtfs2Map1Func = (rfcs1, datanow, dataurl, area1set) => {
  // const {area1set, setArea1Set} = useContext(Area1Set);

  let lon_1;
  let lat_1;
  let lon_2;
  let lat_2;
  let freq;

  // 指示メッシュ取得【並列処理１】
  const citymesh = () =>
    fetch("./geojson/yamako.geojson", {})
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      // 指定都市分を抽出【並列処理１】
      .then((json) => {
        // 緯度経度１
        lon_1 = json.features[0].geometry.coordinates[0][0];
        lat_1 = json.features[0].geometry.coordinates[0][1];
        // 緯度経度２
        lon_2 = json.features[0].geometry.coordinates[1][0];
        lat_2 = json.features[0].geometry.coordinates[1][1];
        // 本数
        freq = json.features[0].properties.frequency;
      });
  //       return citymeshlist
  //   })
  // // データcsv取得【並列処理２】
  //   .then((citymeshlist) =>{
  //     fetch(dataurl, {})
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error();
  //       }
  //       return response.text(); // あるいは response.json()
  //     })
  // // データcsv整形【並列処理２】
  //     .then((text) => {
  //       let map = new Map();
  //       var tmp = text.split('\r\n');
  //       for(let i = 0, len = tmp.length; i< len ; i++){
  //         map.set(tmp[i].split(',')[0],tmp[i].split(',')[1])
  //         }
  //         return map;
  //       })
  // // 指定都市分のみ抽出
  //     .then((map) => {
  //       var mesh;
  //       var meshdata = [];
  //       for(let i = 0, len = citymeshlist.length; i< len ; i++){
  //         mesh = citymeshlist[i].split(',')[0];
  //         let meshtmp = map.get(mesh);
  //         if(meshtmp){
  //           meshdata.push([mesh,meshtmp])
  //         }
  //         }
  //         return meshdata
  //       })
  // // 可視化
  //       .then((meshdata) => {
  //                             // 仮設定１
  //                             var min = Number(0);
  //                             var dataname = datanow;
  //                             var pagedata;
  //                             var shikii1 = Number(4000);
  //                             var shikii2 = Number(2000);
  //                             var shikii3 = Number(1000);
  //                             var hanrei1_color = "red";
  //                             var hanrei2_color = "orange";
  //                             var hanrei3_color = "yellow";
  //                             var hanrei4_color = "gray";
  //                             var ratio = Number(1);

  //       if ( rfcs1.current && rfcs1.current.cesiumElement ) {
  //         for(let i = 0, len = meshdata.length; i< len ; i++){
  //           if (meshdata[i][1]>min) {
  //                             // 仮設定２
  //                             meshdata[i][2] = meshdata[i][1];
  //             rfcs1.current.cesiumElement.entities.add(
  //             mesh3rdRect3(meshdata[i][0],meshdata[i][1],meshdata[i][2],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio)
  //             );
  //           }
  //         }
  //         rfcs1.current.cesiumElement.flyTo(rfcs1.current.cesiumElement.entities)
  //     }
  //   })
  //   // データonoff
  //       .then(() => {
  //         let nowdata = datalist2.filter(data => data.url === dataurl)
  //         if(dataurl === nowdata[0].url){
  //           console.log("ここまできた！");
  //         }
  //       }).catch((reason) => { // エラー
  //     });
  //   }).catch((reason) => { // エラー
  // });
  citymesh();
  return () => {};
};
//////////////////// 統計表示（左） //

// 回転エフェクト（左） ////////////////////
export const Rotate1Func = (rfcs1) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const [mousecount, setMouseCount] = useState(true);
  useEffect(() => {
    function rotate() {
      rfcs1.current.cesiumElement.scene.camera.rotateRight(0.005);
    }
    function rotate1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        if (rotate1set) {
          // if(rendouset || syukurenset){
          //   setRendouSet(false);
          //   setSyukurenSet(false);
          //   setRotate1Set(false);
          // } else {
          var windowPosition = new Cartesian2(
            rfcs1.current.cesiumElement.container.clientWidth / 2,
            rfcs1.current.cesiumElement.container.clientHeight / 2
          );
          var ellipsoid = rfcs1.current.cesiumElement.scene.globe.ellipsoid;
          var cartesian = rfcs1.current.cesiumElement.camera.pickEllipsoid(
            windowPosition,
            ellipsoid
          );
          var carto2 = Cartographic.fromCartesian(cartesian);
          var center_lon = Cesium_Math.toDegrees(carto2.longitude);
          var center_lat = Cesium_Math.toDegrees(carto2.latitude);
          var cameraposition = rfcs1.current.cesiumElement.camera.position;

          var center = Cartesian3.fromDegrees(center_lon, center_lat, 0);
          var transform = Transforms.eastNorthUpToFixedFrame(center);

          rfcs1.current.cesiumElement.scene.camera.lookAtTransform(
            transform,
            cameraposition
          );

          rfcs1.current.cesiumElement.clock.onTick.addEventListener(rotate);
          // }
        }
      }
    }
    rotate1();
    return () => {
      if (rotate1set) {
        rfcs1.current.cesiumElement.clock.onTick.removeEventListener(rotate);
        rfcs1.current.cesiumElement.camera.lookAtTransform(Matrix4.IDENTITY);
      } else {
        // console.log("何もしない");
      }
    };
  }, [rotate1set]);
};
//////////////////// 回転エフェクト（左） //

// 回転エフェクト（右） ////////////////////
export const Rotate2Func = (rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);
  const [mousecount, setMouseCount] = useState(true);
  useEffect(() => {
    function rotate() {
      rfcs2.current.cesiumElement.scene.camera.rotateRight(0.005);
    }
    function rotate2(e) {
      if (rfcs2.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        if (rotate2set) {
          // if(rendouset || syukurenset){
          //   setRendouSet(false);
          //   setSyukurenSet(false);
          //   setRotate2Set(false);
          // } else {
          var windowPosition = new Cartesian2(
            rfcs2.current.cesiumElement.container.clientWidth / 2,
            rfcs2.current.cesiumElement.container.clientHeight / 2
          );
          var ellipsoid = rfcs2.current.cesiumElement.scene.globe.ellipsoid;
          var cartesian = rfcs2.current.cesiumElement.camera.pickEllipsoid(
            windowPosition,
            ellipsoid
          );
          var carto2 = Cartographic.fromCartesian(cartesian);
          var center_lon = Cesium_Math.toDegrees(carto2.longitude);
          var center_lat = Cesium_Math.toDegrees(carto2.latitude);
          var cameraposition = rfcs2.current.cesiumElement.camera.position;

          var center = Cartesian3.fromDegrees(center_lon, center_lat, 0);
          var transform = Transforms.eastNorthUpToFixedFrame(center);

          rfcs2.current.cesiumElement.scene.camera.lookAtTransform(
            transform,
            cameraposition
          );

          rfcs2.current.cesiumElement.clock.onTick.addEventListener(rotate);
          // }
        }
      }
    }
    rotate2();
    return () => {
      if (rotate2set) {
        rfcs2.current.cesiumElement.clock.onTick.removeEventListener(rotate);
        rfcs2.current.cesiumElement.camera.lookAtTransform(Matrix4.IDENTITY);
      } else {
        // console.log("何もしない");
      }
    };
  }, [rotate2set]);
};
//////////////////// 回転エフェクト（右） //

// 縮尺合わせるエフェクト ////////////////////
export const SyukurenFunc = (rfcs1, rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const [mousecount, setMouseCount] = useState(true);

  useEffect(() => {
    if (
      rfcs1.current &&
      rfcs1.current.cesiumElement &&
      rfcs2.current &&
      rfcs2.current.cesiumElement
    ) {
      // console.log("縮尺連動ファンクション起動確認");

      rfcs1.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("左にマウスオン（縮尺合わせ）");
          setMouseCount(true);
        }
      );
      rfcs2.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("右にマウスオン（縮尺合わせ）");
          setMouseCount(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    var masterCamera = rfcs1.current.cesiumElement.scene.camera;
    var slaveCamera = rfcs2.current.cesiumElement.scene.camera;
    // console.log("左右連動ifの前（縮尺合わせ）");

    function rendou1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        var mastercarto = Cartographic.fromCartesian(masterCamera.position);
        var masterlon = Cesium_Math.toDegrees(mastercarto.longitude);
        var masterlat = Cesium_Math.toDegrees(mastercarto.latitude);
        var slavecarto = Cartographic.fromCartesian(slaveCamera.position);
        var slaveheight = slavecarto.height;
        var syukurenposition = Cartesian3.fromDegrees(
          masterlon,
          masterlat,
          slaveheight
        );

        masterCamera.setView({
          destination: {
            x: syukurenposition.x,
            y: syukurenposition.y,
            z: syukurenposition.z,
          },
          orientation: {
            heading: slaveCamera.heading,
            pitch: slaveCamera.pitch,
            roll: slaveCamera.roll,
          },
        });
      }
    }
    function rendou2(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        var slavecarto = Cartographic.fromCartesian(slaveCamera.position);
        var slavelon = Cesium_Math.toDegrees(slavecarto.longitude);
        var slavelat = Cesium_Math.toDegrees(slavecarto.latitude);
        var mastercarto = Cartographic.fromCartesian(masterCamera.position);
        var masterheight = mastercarto.height;
        var syukurenposition = Cartesian3.fromDegrees(
          slavelon,
          slavelat,
          masterheight
        );

        slaveCamera.setView({
          destination: {
            x: syukurenposition.x,
            y: syukurenposition.y,
            z: syukurenposition.z,
          },
          orientation: {
            heading: masterCamera.heading,
            pitch: masterCamera.pitch,
            roll: masterCamera.roll,
          },
        });
      }
    }
    const rendou = () => {
      if (syukurenset) {
        // console.log("連動オン設定");
        if (
          rfcs1.current &&
          rfcs1.current.cesiumElement &&
          rfcs2.current &&
          rfcs2.current.cesiumElement
        ) {
          if (mousecount) {
            // console.log("左の動きを右に伝える");
            rfcs1.current.cesiumElement.scene.preRender.addEventListener(
              rendou2
            );
          }
          if (!mousecount) {
            // console.log("右の動きを左に伝える");
            rfcs2.current.cesiumElement.scene.preRender.addEventListener(
              rendou1
            );
          }
        }
      } else {
        // console.log("連動オフ設定");
      }
    };

    rendou();

    return () => {
      // console.log("連動解除");
      if (mousecount) {
        rfcs1.current.cesiumElement.scene.preRender.removeEventListener(
          rendou2
        );
      }
      if (!mousecount) {
        rfcs2.current.cesiumElement.scene.preRender.removeEventListener(
          rendou1
        );
      }
    };
  }, [syukurenset, mousecount]);
};
//////////////////// 縮尺合わせるエフェクト //

// 左右連動エフェクト ////////////////////
export const RendouFunc = (rfcs1, rfcs2) => {
  const { rendouset, setRendouSet } = useContext(RendouSet);
  const { map1set, setMap1Set } = useContext(Map1Set);
  const { map2set, setMap2Set } = useContext(Map2Set);
  const [mousecount, setMouseCount] = useState(true);
  const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
  const { rotate2set, setRotate2Set } = useContext(Rotate2Set);

  useEffect(() => {
    if (
      rfcs1.current &&
      rfcs1.current.cesiumElement &&
      rfcs2.current &&
      rfcs2.current.cesiumElement
    ) {
      // console.log("左右連動エフェクトフラグ付与チェック");
      rfcs1.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("左にマウスオン");
          setMouseCount(true);
        }
      );
      rfcs2.current.cesiumElement.scene.canvas.addEventListener(
        "mouseenter",
        function (e) {
          // console.log("右にマウスオン");
          setMouseCount(false);
        }
      );
    }
  }, []);
  useEffect(() => {
    var masterCamera = rfcs1.current.cesiumElement.scene.camera;
    var slaveCamera = rfcs2.current.cesiumElement.scene.camera;
    // console.log("左右連動ifの前");

    function rendou1(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        // if (rotate1set || rotate2set){
        //   setRotate1Set(false);
        //   setRotate2Set(false);
        masterCamera.setView({
          destination: {
            x: slaveCamera.position.x,
            y: slaveCamera.position.y,
            z: slaveCamera.position.z,
          },
          orientation: {
            heading: slaveCamera.heading,
            pitch: slaveCamera.pitch,
            roll: slaveCamera.roll,
          },
        });
        // }
      }
    }
    function rendou2(e) {
      if (rfcs1.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        // if (rotate1set || rotate2set){
        //   setRotate1Set(false);
        //   setRotate2Set(false);
        slaveCamera.setView({
          destination: {
            x: masterCamera.position.x,
            y: masterCamera.position.y,
            z: masterCamera.position.z,
          },
          orientation: {
            heading: masterCamera.heading,
            pitch: masterCamera.pitch,
            roll: masterCamera.roll,
          },
        });
        // }
      }
    }
    const rendou = () => {
      if (rendouset) {
        // console.log("連動オン設定");
        if (
          rfcs1.current &&
          rfcs1.current.cesiumElement &&
          rfcs2.current &&
          rfcs2.current.cesiumElement
        ) {
          if (mousecount) {
            // console.log("左の動きを右に伝える");
            rfcs1.current.cesiumElement.scene.preRender.addEventListener(
              rendou2
            );
          }
          if (!mousecount) {
            // console.log("右の動きを左に伝える");
            rfcs2.current.cesiumElement.scene.preRender.addEventListener(
              rendou1
            );
          }
        }
      } else {
        // console.log("連動オフ設定");
      }
    };
    rendou();
    return () => {
      // console.log("連動解除");
      if (mousecount) {
        rfcs1.current.cesiumElement.scene.preRender.removeEventListener(
          rendou2
        );
      }
      if (!mousecount) {
        rfcs2.current.cesiumElement.scene.preRender.removeEventListener(
          rendou1
        );
      }
    };
  }, [rendouset, mousecount]);
};
//////////////////// 左右連動エフェクト //

// ゴミ箱

// // 統計表示（左） ////////////////////
// export const Static2Map1Func = (rfcs1, datanow) => {
//   const { rendouset, setRendouSet } = useContext(RendouSet);
//   const { syukurenset, setSyukurenSet } = useContext(SyukurenSet);
//   const { map1set, setMap1Set } = useContext(Map1Set);
//   const { map2set, setMap2Set } = useContext(Map2Set);
//   const { static2map1set, setStatic2Map1Set } = useContext(Static2Map1Set);
//   const { static2map2set, setStatic2Map2Set } = useContext(Static2Map2Set);
//   const { rotate1set, setRotate1Set } = useContext(Rotate1Set);
//   const [ mousecount, setMouseCount ] = useState(true);

//   // var datanow;
//   console.log(datanow);

//   useEffect(() => {
// // 指示メッシュ取得【並列処理１】
//     const citymesh = () => fetch(instruction, {})
//     .then((response) => {
//         if (!response.ok) {
//             throw new Error();
//         }
//         return response.text(); // あるいは response.json()
//     })
// // 指定都市分を抽出【並列処理１】
//     .then((text) => {
//       var tmp = text.split('\n');
//       var citymeshlist;
//       citymeshlist = tmp.filter(function(value){
//         return value.includes("福岡県糸島市");
//       })
//         return citymeshlist
//     })
// // データcsv取得【並列処理２】
//     .then((citymeshlist) =>{
//       fetch(static2map1set, {})
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error();
//         }
//         return response.text(); // あるいは response.json()
//       })
// // データcsv整形【並列処理２】
//       .then((text) => {
//         let map = new Map();
//         var tmp = text.split('\r\n');
//         for(let i = 0, len = tmp.length; i< len ; i++){
//           map.set(tmp[i].split(',')[0],tmp[i].split(',')[1])
//           }
//           return map;
//         })
// // 指定都市分のみ抽出
//       .then((map) => {
//         var mesh;
//         var meshdata = [];
//         for(let i = 0, len = citymeshlist.length; i< len ; i++){
//           mesh = citymeshlist[i].split(',')[0];
//           let meshtmp = map.get(mesh);
//           if(meshtmp){
//             meshdata.push([mesh,meshtmp])
//           }
//           }
//           return meshdata
//         })
// // 可視化
//         .then((meshdata) => {
//                               // 仮設定１
//                               var min = Number(0);
//                               var dataname = datanow;
//                               var pagedata;
//                               var shikii1 = Number(4000);
//                               var shikii2 = Number(2000);
//                               var shikii3 = Number(1000);
//                               var hanrei1_color = "red";
//                               var hanrei2_color = "orange";
//                               var hanrei3_color = "yellow";
//                               var hanrei4_color = "gray";
//                               var ratio = Number(1);

// if (rfcs1.current && rfcs1.current.cesiumElement
//   // && rfcs2.current && rfcs2.current.cesiumElement
//   ) {

//       for(let i = 0, len = meshdata.length; i< len ; i++){
//         if (meshdata[i][1]>min) {
//                               // 仮設定２
//                               meshdata[i][2] = meshdata[i][1];
//             rfcs1.current.cesiumElement.entities.add(
//               mesh3rdRect3(meshdata[i][0],meshdata[i][1],meshdata[i][2],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio)
//             );
//             }
//           // if (dataArr[1]>min) {
//           //   viewer5.entities.add(
//           //     mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2],dataname,pagedata,shikii1,shikii2,shikii3,hanrei1_color,hanrei2_color,hanrei3_color,hanrei4_color,ratio)
//           //   );
//           //   }
//           }
//           rfcs1.current.cesiumElement.zoomTo(rfcs1.current.cesiumElement.entities)
//       }})
//       .catch((reason) => {
//           // エラー
//       });

//     })
//     .catch((reason) => {
//         // エラー
//     });
//     citymesh();

// console.log(static2map1set);
// console.log("統計のファンクション");

//   return () => {

//     // if(rotate1set){
//     //   rfcs1.current.cesiumElement.clock.onTick.removeEventListener(rotate);
//     //   rfcs1.current.cesiumElement.camera.lookAtTransform(Matrix4.IDENTITY);
//     // } else {
//     //   console.log("何もしない");
//     // }
//   }
// }, []);

// useEffect((props) => {

// if (rfcs1.current && rfcs1.current.cesiumElement
//   // && rfcs2.current && rfcs2.current.cesiumElement
//   ) {
//   if(static2map1set === population_now){
//     console.log(datanow);
//     rfcs1.current.cesiumElement.entities.show = true;
//     // datanow.show = true
//   } else {
//     console.log(datanow);
//     rfcs1.current.cesiumElement.entities.show = false;
//     // datanow.show = false
//   }

//   console.log(static2map1set);
//   console.log(rfcs1.current.cesiumElement.entities);

//     return () => {
//       // if(static2map1set === population_now){
//       //   rfcs1.current.cesiumElement.entities.show = true;
//       // } else {
//       //   rfcs1.current.cesiumElement.entities.show = false;
//       // }
//     }
//   }
//   }, [static2map1set]);

// }
// //////////////////// 統計表示（左） //
