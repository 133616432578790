export default 
[
  {
    "noold202201": "2-1",
    "no": "02-01",
    "oldno": 202,
    "title": "日本の農業の現状",
    "sdgs_no": 2,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "総農家数×総農家数.kmz",
    "date": 9899,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "40～",
    "hanrei2_name": "20～40",
    "hanrei3_name": "10～20",
    "hanrei4_name": "0～10",
    "height_ratio": "1戸:100m",
    "category_data": "",
    "shikii1": 40,
    "shikii2": 20,
    "shikii3": 10,
    "min": 0,
    "ratio": 100,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "2000～",
    "kokudo_hanrei2_name": "1000～2000",
    "kokudo_hanrei3_name": "500～1000",
    "kokudo_hanrei4_name": "100～500",
    "kokudo_height_ratio": "1戸:25m",
    "kokudo_shikii1": 2000,
    "kokudo_shikii2": 1000,
    "kokudo_shikii3": 500,
    "kokudo_min": 100,
    "kokudo_ratio": 25,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "農業が発展した地域とは，どのような地域を指すだろう。農家数の多さ，生産額の高さ，農地面積の広さなど指標は多様である。現地の特産品は何か，どのような形態の農業を行っているかに留意してデータを見つめたい。",
    "tenkei_mode": 7,
    "tenkei_city": "静岡県島田市",
    "tenkei_city_2": "北海道帯広市",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "茶の生産が盛んな静岡県島田市では，棒グラフが高く，かつ赤いメッシュが目立つ。この見た目からも農業生産が活発に行われていることがイメージできる。しかし，1メッシュあたりの総農家数が多ければ多いほど，一農家あたりの農地面積が小さく，生産効率が高い状態にあるとは言いがたい。千葉県や茨城県などの首都圏でも同じ状況が見られる一方で，一農家あたりの農地面積が格段に広い北海道では，軒並み棒グラフは灰色であり，高くなっている棒もほぼ見られない。このように，メッシュデータという特性に留意してデータを見ていく必要がある。",
    "height_origin": "農林業センサス",
    "height_origin_note": "農家数とは、経営耕地面積が10ａ以上、又は、経営耕地面積が10ａ未満であっても過去１年間の農産物販売金額が15万円以上あった、世帯の数です。",
    "color_origin": "農林業センサス",
    "color_origin_note": "農家数とは、経営耕地面積が10ａ以上、又は、経営耕地面積が10ａ未満であっても過去１年間の農産物販売金額が15万円以上あった、世帯の数です。",
    "height_origin_url_name": "農林業センサスの概要",
    "height_origin_url": "https://www.maff.go.jp/j/tokei/kouhyou/noucen/index.html",
    "height_origin_url2_name": "農林業センサスの用語の定義",
    "height_origin_url2": "https://www.maff.go.jp/j/tokei/kouhyou/noucen/gaiyou/attach/pdf/index-5.pdf",
    "color_origin_url_name": "農林業センサスの概要",
    "color_origin_url": "https://www.maff.go.jp/j/tokei/kouhyou/noucen/index.html",
    "color_origin_url2_name": "農林業センサスの用語の定義",
    "color_origin_url2": "https://www.maff.go.jp/j/tokei/kouhyou/noucen/gaiyou/attach/pdf/index-5.pdf",
    "hanrei_height_name": "総農家数（戸）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "総農家数（戸）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※100戸以上で表示",
    "hanrei_color_name": "総農家数（戸）",
    "title_height_name": "総農家数",
    "title_color_name": "総農家数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 1,
    "飢餓をゼロに": 1,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 1,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 1,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "総農家数×総農家数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-88",
    "no": "03-01",
    "oldno": 388,
    "title": "病床数の分布",
    "sdgs_no": 3,
    "year_display": 2020,
    "year_syousai": 2020,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "病床数×病床数.kmz",
    "date": 9743,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "200～",
    "hanrei2_name": "20～200",
    "hanrei3_name": "1～20",
    "hanrei4_name": "0～1",
    "height_ratio": "1床:20m",
    "category_data": "",
    "shikii1": 200,
    "shikii2": 20,
    "shikii3": 1,
    "min": 0,
    "ratio": 20,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "2,000～",
    "kokudo_hanrei2_name": "200～2,000",
    "kokudo_hanrei3_name": "20～200",
    "kokudo_hanrei4_name": "0～20",
    "kokudo_height_ratio": "1床:6m",
    "kokudo_shikii1": 2000,
    "kokudo_shikii2": 200,
    "kokudo_shikii3": 20,
    "kokudo_min": 0,
    "kokudo_ratio": 6,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2020,
    "try": "病床数が多い地域は、どこなのだろうか。その歴史的な背景もあわせて考察してみよう。",
    "tenkei_mode": 7,
    "tenkei_city": "佐賀県嬉野市",
    "tenkei_city_2": "佐賀県佐賀市",
    "tenkei_lon": 129.98,
    "tenkei_lat": 33,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "佐賀県嬉野市は、県西部に位置する人口2万５千人ほどの町である。国立病院を筆頭に４病院があり、かつてより治療のために人々が訪れる医療の町であると言える。人口23万人超の県庁所在地佐賀市と比較し、2万５千人の嬉野市の病床数の多さは顕著である。江戸時代に来日したドイツ人医師シーボルトも詳しく記している嬉野温泉は、湯治場としての歴史が古く自然も豊かである。そうしたことから嬉野市は、近年では「ワーケーション天国」としてシティプロモーションに取り組むなど、温泉を楽しみながら、仕事や治療のために滞在してもらうための活性化策を進めている。",
    "height_origin": "国土数値情報",
    "height_origin_note": "医療法に基づく「病院」「（一般）診療所」の病床数の合計値です。",
    "color_origin": "国土数値情報",
    "color_origin_note": "医療法に基づく「病院」「（一般）診療所」の病床数の合計値です。",
    "height_origin_url_name": "国土数値情報：医療機関データ",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P04-v3_0.html",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "国土数値情報：医療機関データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P04-v3_0.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "病床数（床）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "病床数（床）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "病床数（床）",
    "title_height_name": "病床数",
    "title_color_name": "病床数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "病床数×病床数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-86",
    "no": "03-02",
    "oldno": 36,
    "title": "医療へのアクセス",
    "sdgs_no": 3,
    "year_display": 2015,
    "year_syousai": 2020,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×病床数.kmz",
    "date": 9745,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "200～",
    "hanrei2_name": "20～200",
    "hanrei3_name": "1～20",
    "hanrei4_name": "0～1",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 200,
    "shikii2": 20,
    "shikii3": 1,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "2,000～",
    "kokudo_hanrei2_name": "200～2,000",
    "kokudo_hanrei3_name": "20～200",
    "kokudo_hanrei4_name": "0～20",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 2000,
    "kokudo_shikii2": 200,
    "kokudo_shikii3": 20,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "人口に対して、病床数の多い町はどこなのだろうか。その地域の歴史、背景も合わせて考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "鹿児島県指宿市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.6,
    "tenkei_lat": 31.1,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "砂蒸し風呂で有名な指宿温泉を有する鹿児島県指宿市は、人口4万人ながら、国立病院機構の指宿医療センターをはじめ病床数も大きな病院がいくつか存在している。伝統ある温泉地は「湯治場」として、病気やけがの治癒や健康増進のための機能を果たすため、かつてより人を集めてきたが、近代に入り、そうした温泉地で軍などの療養所が建てられる例も見られた。近年では、観光と医療を結び付けた医療ツーリズムに関する厚生労働省の実証事業のもと、健診・健康増進プログラム・先進医療など外国人向けの医療提供の可能性が模索されており、指宿市内でも「リゾート滞在型陽子線がん治療」の提供が始められている。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国土数値情報",
    "color_origin_note": "医療法に基づく「病院」「（一般）診療所」の病床数の合計値です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：医療機関データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P04-v3_0.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "病床数（床）",
    "title_height_name": "人口総数",
    "title_color_name": "病床数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×病床数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-82",
    "no": "03-03",
    "oldno": 8102,
    "title": "少子化の現状",
    "sdgs_no": 3,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×年少人口.kmz",
    "date": 9749,
    "hanrei1_color": "#e3c800",
    "hanrei2_color": "#98fb98",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e3c800",
    "kml2_color": "#98fb98",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "20～",
    "hanrei2_name": "10～20",
    "hanrei3_name": "5～10",
    "hanrei4_name": "0～5",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 20,
    "shikii2": 10,
    "shikii3": 5,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "20～",
    "kokudo_hanrei2_name": "10～20",
    "kokudo_hanrei3_name": "5～10",
    "kokudo_hanrei4_name": "0～5",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 20,
    "kokudo_shikii2": 10,
    "kokudo_shikii3": 5,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "年少人口割合の高いメッシュは，どのように分布しているだろうか。自分が暮らす地域の人口と年少人口割合の関係を見える化してみよう。特徴のあるメッシュは，どのような場所なのか確認してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "愛知県長久手市・みよし市・日進市・東郷町",
    "tenkei_city_2": "",
    "tenkei_lon": 137,
    "tenkei_lat": 35,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "愛知県長久手市は、2005年「愛・地球博」のメイン会場になった頃から宅地開発が進み、人口増を受けて合併を経て町制から市制へ移行した。令和2年国勢調査によると、全国の平均年齢47.9歳に対し、長久手市の平均年齢は39.7歳となっており、全国で最も平均年齢が低い自治体である。同様に単独で市制移行したみよし市も平均年齢42.3歳となっている。両市の他にも、日進市43.2歳、東郷町44.5歳など、自動車産業の本拠地である豊田市と自動車販売の本拠地である名古屋市に挟まれた地域には、子育て世代の流入が続き平均年齢の低い自治体が多い。そのなかでも名古屋市と豊田市を結ぶ東部丘陵線の駅をもつ長久手市はリニアモーターカーによって両市への交通の利便性が高く、子育て世帯に人気が高いと推察される。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国勢調査",
    "color_origin_note": "年少人口（0～14歳人口）は、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "0-14歳人口の割合（％）",
    "title_height_name": "人口総数",
    "title_color_name": "年少人口割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×年少人口",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-83",
    "no": "03-04",
    "oldno": 8103,
    "title": "高齢化の現状",
    "sdgs_no": 3,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×老年人口割合.kmz",
    "date": 9748,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#00bfff",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#00bfff",
    "csvmode": "color_shikii",
    "hanrei1_name": "45～",
    "hanrei2_name": "30～45",
    "hanrei3_name": "15～30",
    "hanrei4_name": "0～15",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 45,
    "shikii2": 30,
    "shikii3": 15,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "45～",
    "kokudo_hanrei2_name": "30～45",
    "kokudo_hanrei3_name": "15～30",
    "kokudo_hanrei4_name": "0～15",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 45,
    "kokudo_shikii2": 30,
    "kokudo_shikii3": 15,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "少子高齢化化が進むなか、65歳以上の老年人口が増える地域はどのようなところだろうか、将来推計人口のデータから見える化し、それはなぜか考察してみよう。また、「生産年齢人口の未来予測」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "山口県周防大島町",
    "tenkei_city_2": "",
    "tenkei_lon": 132.15,
    "tenkei_lat": 33.8,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "山口県周防大島町は、全国の市町村（人口1万人以上）で最も高齢化率が高い。2020年9月末の高齢化率は54.1%,、75歳以上の後期高齢化率は32.8%であり、総人口も減少している。主要産業の一つであるミカンの全国的な価格下落により高度成長期から人口流出が始まり、1976年に大島大橋が架かり本州と繋がったことが人口流出に拍車をかけた。そのなかで行政は高齢者が元気で生き生きと暮らせる環境づくりを掲げるとともに、UIターンを検討する人に対してお試し生活を行う住宅を準備する等、移住・定住支援策を積極的に行っている。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国勢調査",
    "color_origin_note": "老年人口（65歳以上人口）は、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "65歳以上人口の割合（％）",
    "title_height_name": "人口総数",
    "title_color_name": "老年人口割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×老年人口割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-84",
    "no": "03-05",
    "oldno": 36,
    "title": "子どもの増減予測",
    "sdgs_no": 3,
    "year_display": "2025,2050",
    "year_syousai": "2025,2050",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "将来の0-14歳人口×0-14歳人口増減率.kmz",
    "date": 9747,
    "hanrei1_color": "#e3c800",
    "hanrei2_color": "#98fb98",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e3c800",
    "kml2_color": "#98fb98",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "100～",
    "hanrei2_name": "75～100",
    "hanrei3_name": "50～75",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:10m",
    "category_data": "",
    "shikii1": 100,
    "shikii2": 75,
    "shikii3": 50,
    "min": 10,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "100～",
    "kokudo_hanrei2_name": "75～100",
    "kokudo_hanrei3_name": "50～75",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:1m",
    "kokudo_shikii1": 100,
    "kokudo_shikii2": 75,
    "kokudo_shikii3": 50,
    "kokudo_min": 100,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 2025,
    "year5": 2050,
    "try": "少子化が進むなか、０ー14歳人口が増える地域はどのようなところだろうか、将来推計人口のデータから見える化し、それはなぜか考察してみよう。また、「生産年齢人口の未来予測」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "沖縄県豊見城市",
    "tenkei_city_2": "",
    "tenkei_lon": 127.7,
    "tenkei_lat": 26,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "沖縄県豊見城市は那覇市の南に隣接している。1972年の沖縄返還・本土復帰ののち、那覇市のベッドタウンとして急激に人口が増加し、2002年に自治体として初めて村から市制施行された。市制施行以来、埋立事業や企業誘致が成果を収めたことで企業数と人口が増加している。豊見城市の合計特殊出生率は1985年からの30年間2.0前後で推移しており、全国平均1.3～1.4を大きく上回り、比較的若年世代が多い。その豊見城市においても、2025年と2050年のグラフを比較すると人口総数・年少人口割合が共に低くなることがわかる。現時点で人口増加をしている地域においても、将来的に人口減少と少子高齢化が進むことが予測される。",
    "height_origin": "国土数値情報",
    "height_origin_note": "将来の10～14歳人口です。将来の推計人口が10人以上のメッシュのみ表示しています。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "将来の10～14歳人口の、2015年10～14歳人口に対する割合です。",
    "height_origin_url_name": "国土数値情報 1kmメッシュ別将来推計人口データ（H30国政局推計）",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-mesh1000h30.html",
    "height_origin_url2_name": "将来人口の試算方法について",
    "height_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/mesh500_1000_h30.pdf",
    "color_origin_url_name": "国土数値情報 1kmメッシュ別将来推計人口データ（H30国政局推計）",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-mesh1000h30.html",
    "color_origin_url2_name": "将来人口の試算方法について",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/mesh500_1000_h30.pdf",
    "hanrei_height_name": "0-14歳人口推計",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "※10人以上で表示",
    "kokudo_hanrei_height_name": "0-14歳人口推計",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "0-14歳人口の2015年比増減率(%)",
    "title_height_name": "年少人口推計",
    "title_color_name": "その増減率",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "将来の0-14歳人口×0-14歳人口増減率",
    "データ更新20220305": "将来人口の小数桁，国土エラー",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-85",
    "no": "03-06",
    "oldno": 36,
    "title": "生産年齢人口の増減予測",
    "sdgs_no": 3,
    "year_display": "2025,2050",
    "year_syousai": "2025,2050",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "将来の15-64歳人口×15-64歳人口増減率.kmz",
    "date": 9746,
    "hanrei1_color": "#e3c800",
    "hanrei2_color": "#98fb98",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e3c800",
    "kml2_color": "#98fb98",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "100～",
    "hanrei2_name": "75～100",
    "hanrei3_name": "50～75",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:2m",
    "category_data": "",
    "shikii1": 100,
    "shikii2": 75,
    "shikii3": 50,
    "min": 10,
    "ratio": 2,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "100～",
    "kokudo_hanrei2_name": "75～100",
    "kokudo_hanrei3_name": "50～75",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:0.2m",
    "kokudo_shikii1": 100,
    "kokudo_shikii2": 75,
    "kokudo_shikii3": 50,
    "kokudo_min": 100,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 2025,
    "year5": 2050,
    "try": "生産年齢人口が増えると予測される地域はどのようなところだろうか。また、生産年齢人口が急激に減少すると予測される地域はどのようなところだろうか。「少子化の未来予測」「高齢化の未来予測」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "神奈川県川崎市",
    "tenkei_city_2": "",
    "tenkei_lon": 139.6,
    "tenkei_lat": 35.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "川崎市は東京都と横浜市の間に位置し、利便性の高い街として知られる。川崎市の中央に位置する東横線武蔵小杉駅周辺は、以前京浜工業地帯の一角として工場やその社宅が多いエリアだったが、バブル崩壊以降、工場の撤退や移転が相次いだ。その跡地に再開発が進められ、タワーマンションの建設と商業施設等の都市基盤の整備が行われた。同様に川崎駅西口も、工場地帯跡地の再開発で大型商業施設ができ、その周辺にタワーマンションの建設が集中した。これらの再開発と首都圏へのアクセスの良さが、子育て世帯を中心に川崎市での住宅需要を高めたと考えられる。",
    "height_origin": "国土数値情報",
    "height_origin_note": "将来の15～64歳人口です。将来の推計人口が10人以上のメッシュのみ表示しています。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "将来の15-64歳人口の、2015年15-64歳人口に対する割合です。",
    "height_origin_url_name": "国土数値情報 1kmメッシュ別将来推計人口データ（H30国政局推計）",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-mesh1000h30.html",
    "height_origin_url2_name": "将来人口の試算方法について",
    "height_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/mesh500_1000_h30.pdf",
    "color_origin_url_name": "国土数値情報 1kmメッシュ別将来推計人口データ（H30国政局推計）",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-mesh1000h30.html",
    "color_origin_url2_name": "将来人口の試算方法について",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/mesh500_1000_h30.pdf",
    "hanrei_height_name": "15-64歳人口推計",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "※10人以上で表示",
    "kokudo_hanrei_height_name": "15-64歳人口推計",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "15-64歳人口の2015年比増減率(%)",
    "title_height_name": "生産年齢人口推計",
    "title_color_name": "その増減率",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "将来の15-64歳人口×15-64歳人口増減率",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-81",
    "no": "03-07",
    "oldno": 8101,
    "title": "大家族・核家族・単身世帯",
    "sdgs_no": 3,
    "year_display": "1995,2015",
    "year_syousai": "1995,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "世帯総数×世帯当たり人口.kmz",
    "date": 9750,
    "hanrei1_color": "#ff0000",
    "hanrei2_color": "#ffb6c1",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#ff0000",
    "kml2_color": "#ffb6c1",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "4～",
    "hanrei2_name": "3～4",
    "hanrei3_name": "2～3",
    "hanrei4_name": "0～2",
    "height_ratio": "1世帯:2m",
    "category_data": "",
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 2,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "4～",
    "kokudo_hanrei2_name": "3～4",
    "kokudo_hanrei3_name": "2～3",
    "kokudo_hanrei4_name": "0～2",
    "kokudo_height_ratio": "1世帯:0.2m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1995,
    "year5": 2015,
    "try": "世帯総数に対する単身世帯の割合が高い地域はどこだろうか。「若年単身世帯」「壮年単身世帯」「「高齢単身世帯」等、単身世帯の割合が高い地域の理由は一律ではない。その地域の背景も考え、考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "茨城県つくば市",
    "tenkei_city_2": "",
    "tenkei_lon": 140,
    "tenkei_lat": 35.9,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "つくば市は茨城県の南西部に位置し、筑波山地域以外は関東ローム層に覆われた平坦な地形である。つくば市は筑波大学を中心とした研究学園都市を形成しており、大学とつくば駅の間には青く突出したグラフが並び、一人暮らしの学生が大学周辺に下宿しているエリアだと考えられる。また、筑波大学の周辺の水色の部分は、官民の研究機関が集積したエリアで、従事する核家族世帯が多くなっていると考えられる。古くからの農村地域が広がる市の北西部には桃色のエリアが広がり、つくば市が研究学園都市となる以前から居住している世帯だと考えられ、世帯人員が多いことがうかがえる。",
    "height_origin": "国勢調査",
    "height_origin_note": "世帯総数は、調査時に常住している場所で調査する方法（常住地方式）による世帯数をいいます。",
    "color_origin": "国勢調査を加工して作成",
    "color_origin_note": "１世帯あたりの人口総数。世帯総数は、調査時に常住している場所で調査する方法（常住地方式）による世帯数をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "世帯総数（世帯）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "世帯総数（世帯）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "1世帯あたり人口（人/世帯）",
    "title_height_name": "世帯総数",
    "title_color_name": "1世帯の平均人数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "世帯総数×世帯当たり人口",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "最新ファイルを登録",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-87",
    "no": "03-91",
    "oldno": 36,
    "title": "高齢者と福祉施設",
    "sdgs_no": 3,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "高齢者数×高齢者福祉施設数.kmz",
    "date": 9744,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "3～",
    "hanrei2_name": "2～3",
    "hanrei3_name": "1～2",
    "hanrei4_name": 0,
    "height_ratio": "1人:4m",
    "category_data": "",
    "shikii1": 3,
    "shikii2": 2,
    "shikii3": 1,
    "min": 0,
    "ratio": 4,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "10～",
    "kokudo_hanrei2_name": "5～10",
    "kokudo_hanrei3_name": "1～5",
    "kokudo_hanrei4_name": 0,
    "kokudo_height_ratio": "1人:0.4m",
    "kokudo_shikii1": 10,
    "kokudo_shikii2": 5,
    "kokudo_shikii3": 1,
    "kokudo_min": 0,
    "kokudo_ratio": 0.4,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "高齢者福祉施設数の多いメッシュは，どのように分布しているだろうか。自分が暮らす地域の高齢者人口と高齢者福祉施設数の関係を見える化してみよう。特徴のあるメッシュは，どのような場所なのか確認してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "国勢調査",
    "height_origin_note": "65歳以上の人口。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "老人福祉施設、老人憩の家、老人休養ホーム、有料老人ホームの合計値",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：福祉施設データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P14.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "高齢者数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "高齢者数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "高齢者福祉施設数（所）",
    "title_height_name": "高齢者数",
    "title_color_name": "高齢者福祉施設数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 0,
    "パートナーシップで目標を達成しよう": 0,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 0,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 0,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 0,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "高齢者数×高齢者福祉施設数",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "4-81",
    "no": "04-01",
    "oldno": "???",
    "title": "小学校への通いやすさ",
    "sdgs_no": 4,
    "year_display": 2015,
    "year_syousai": 2013,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "年少人口×小学校までの距離.kmz",
    "date": 9699,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "4,000～",
    "hanrei2_name": "2,000～4,000",
    "hanrei3_name": "1,000～2,000",
    "hanrei4_name": "0～1,000",
    "height_ratio": "1人:7m",
    "category_data": "",
    "shikii1": 4000,
    "shikii2": 2000,
    "shikii3": 1000,
    "min": 0,
    "ratio": 7,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "4,000～",
    "kokudo_hanrei2_name": "2,000～4,000",
    "kokudo_hanrei3_name": "1,000～2,000",
    "kokudo_hanrei4_name": "0～1,000",
    "kokudo_height_ratio": "1人:0.7m",
    "kokudo_shikii1": 4000,
    "kokudo_shikii2": 2000,
    "kokudo_shikii3": 1000,
    "kokudo_min": 10,
    "kokudo_ratio": 0.7,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "文部科学省によると、小学生の適正な通学距離はおおむね4km以内とされている。小学生にとって小学校に通いやすく住みやすい地域は、どのようなところだろうか。 また 小学校までの通学距離が遠い地域は、どのようなところであるだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "大阪府大阪市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "大阪市の小学校の児童数は、昭和33年度に約35万人でピークを迎えた後は減少し続け、昭和54年度の約24万2千人から見て、令和元年度には約11万5千人と半減しており、この10年間で見ても低い水準で推移している。しかしながら、昭和54年度には290校であった学校数が、令和元年度には287校と、児童数の減少に比して、学校数が減少していないことから、1校あたりの児童数の小規模化が進んでいる。全国的に小学校の統廃合が進むなか、大阪市では小学校数が概ね維持されていることが、通学距離の近い要因となっている。ただ、この大阪市も、学校配置の適正化の取組みを進めているところである。",
    "height_origin": "国勢調査",
    "height_origin_note": "0歳から14歳までの人口です。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "メッシュの中心から最寄りの小学校までの距離です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：小学校の位置",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P29.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "0-14歳人口",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "0-14歳人口",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※10人以上で表示",
    "hanrei_color_name": "小学校までの距離（m）",
    "title_height_name": "年少人口",
    "title_color_name": "小学校までの距離",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 1,
    "質の高い教育をみんなに": 1,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "年少人口×小学校までの距離",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "4-82",
    "no": "04-02",
    "oldno": "???",
    "title": "中学校への通いやすさ",
    "sdgs_no": 4,
    "year_display": 2015,
    "year_syousai": 2013,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "年少人口×中学校までの距離.kmz",
    "date": 9698,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "6,000～",
    "hanrei2_name": "3,000～6,000",
    "hanrei3_name": "1,500～3,000",
    "hanrei4_name": "0～1,500",
    "height_ratio": "1人:7m",
    "category_data": "",
    "shikii1": 6000,
    "shikii2": 3000,
    "shikii3": 1500,
    "min": 0,
    "ratio": 7,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "6,000～",
    "kokudo_hanrei2_name": "3,000～6,000",
    "kokudo_hanrei3_name": "1,500～3,000",
    "kokudo_hanrei4_name": "0～1,500",
    "kokudo_height_ratio": "1人:0.7m",
    "kokudo_shikii1": 6000,
    "kokudo_shikii2": 3000,
    "kokudo_shikii3": 1500,
    "kokudo_min": 10,
    "kokudo_ratio": 0.7,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "文部科学省によると、中学生の適正な通学距離はおおむね6km以内とされている。中学生にとって小学校に通いやすく住みやすい地域は、どのようなところだろうか。 また 中学校までの通学距離が遠い地域は、どのようなところであるだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "神奈川県横浜市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "神奈川県横浜市はの児童数は昭和55年度、生徒数は昭和61年度をピークに減少している。また、義務教育人口推計（平成29年度時点）によると、平成35年度（実際は令和5年度）には児童数165,122 人、生徒数72,855 人となり、ピーク時と比べて児童数は約58％、生徒数は約 53％程度になることが見込まれていた。全体的に少子化傾向にあるが、しかし、北部・臨海部など市内の一部地域では、児童・生徒数の急増により教室不足などの問題を抱える学校も発生している。横浜市は、「小学校２㎞以内、中学校３㎞以内、原則徒歩通学を基準とする規定を定めており、文部科学省が示す通学距離よりも短く設定してある。",
    "height_origin": "国勢調査",
    "height_origin_note": "0歳から14歳までの人口です、",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "メッシュの中心から最寄りの中学校までの距離です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：中学校の位置",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P29.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "0-14歳人口",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "0-14歳人口",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※10人以上で表示",
    "hanrei_color_name": "中学校までの距離（m）",
    "title_height_name": "年少人口",
    "title_color_name": "中学校までの距離",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 1,
    "質の高い教育をみんなに": 1,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "年少人口×中学校までの距離",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "4-83",
    "no": "04-03",
    "oldno": "???",
    "title": "図書館へのアクセス",
    "sdgs_no": 4,
    "year_display": 2015,
    "year_syousai": 2013,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "人口総数×図書館までの距離.kmz",
    "date": 9697,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "10,000～",
    "hanrei2_name": "5,000～10,000",
    "hanrei3_name": "2,500～5,000",
    "hanrei4_name": "0～2,500",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 10000,
    "shikii2": 5000,
    "shikii3": 2500,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "10,000～",
    "kokudo_hanrei2_name": "5,000～10,000",
    "kokudo_hanrei3_name": "2,500～5,000",
    "kokudo_hanrei4_name": "0～2,500",
    "kokudo_height_ratio": "1人:1m",
    "kokudo_shikii1": 10000,
    "kokudo_shikii2": 5000,
    "kokudo_shikii3": 2500,
    "kokudo_min": 0,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "人口総数と図書館までの距離の関係を見える化してみよう。人口総数が多いメッシュは，図書館までの距離がどれくらいの範囲にあるだろうか。人口が少ないメッシュではどうだろうか。",
    "tenkei_mode": 7,
    "tenkei_city": "宮城県仙台市",
    "tenkei_city_2": "埼玉県さいたま市",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "仙台市では，都市中心部の人口が多いメッシュでは図書館までの距離が2.5kmまでであるが，その外側では人口は多いが2.5～5kmと離れていることが読み取れる。一方，さいたま市では，全体的に2.5kmまでのメッシュが多く，図書館へのアクセスが良いことが読み取れる。これは，仙台市が東北の中心都市として，中心部から郊外に拡大したため，図書館は古くからの市街地に分布しているのに対し，さいたま市は，平成の合併でできた都市で，合併以前から浦和市・大宮市・与野市・岩槻市の図書館があり，市域にまんべんなく多くの図書館が分布しているためである。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "メッシュの中心から最寄りの図書館までの距離です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：図書館の位置（文化施設データ）",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P27.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "図書館までの距離（m）",
    "title_height_name": "人口総数",
    "title_color_name": "図書館までの距離",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 1,
    "質の高い教育をみんなに": 1,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×図書館までの距離",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "5-1",
    "no": "05-01",
    "oldno": 9203,
    "title": "働く人々の男女比",
    "sdgs_no": 5,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業従業者数×全産業従業者数男女比.kmz",
    "date": 9599,
    "hanrei1_color": "#0033cc",
    "hanrei2_color": "#00bfff",
    "hanrei3_color": "#ffb6c1",
    "hanrei4_color": "#ff0000",
    "kml1_color": "#0033cc",
    "kml2_color": "#00ccff",
    "kml3_color": "#ff66cc",
    "kml4_color": "#ff0000",
    "csvmode": "color_shikii",
    "hanrei1_name": "150～",
    "hanrei2_name": "100～150",
    "hanrei3_name": "50～100",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 150,
    "shikii2": 100,
    "shikii3": 50,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "150～",
    "kokudo_hanrei2_name": "100～150",
    "kokudo_hanrei3_name": "50～100",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 150,
    "kokudo_shikii2": 100,
    "kokudo_shikii3": 50,
    "kokudo_min": 100,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "働く人々が多いところはどのようなところだろうか。その男女比にはどのような傾向があるだろうか。全産業従業者数とその男女比の関係から見える化してみよう。傾向が読み取れたら，その理由も考えてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "愛知県名古屋市・豊田市",
    "tenkei_city_2": "",
    "tenkei_lon": 137,
    "tenkei_lat": 34.7,
    "tenkei_height": 70000,
    "tenkei_heading": 0,
    "tenkei_pitch": -50,
    "tenkei_roll": 0,
    "kaisetsu": "名古屋市と豊田市では，面積は豊田市の方が大きいが，全産業従業者数を見ると，名古屋市が全体的に高いグラフが多いのに対して，豊田市は南西部に高いグラフが見られる。全産業従業者数男女比の色を見ると、名古屋市では赤色系統も存在感があり女性の割合が一定あるが、豊田市では青色系統が支配的で男性の割合がほとんどを占める傾向が読み取れる。これは，名古屋市は，中心地機能が高い大都市で第3次産業の従業者が多いのに対して，豊田市は，自動車工業を中心とする工業都市で，第2次産業の従業者が多いためと考えられる。",
    "height_origin": "経済センサス（活動調査）",
    "height_origin_note": "全産業（公務を除く）の従業者数です。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業（公務を除く）の、女性従業者数に対する男性従業者数の割合です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "全産業従業者数",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業従業者数",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "女性に対する男性の割合（％）",
    "title_height_name": "従業者総数",
    "title_color_name": "その男女比",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 1,
    "ジェンダー平等を実現しよう": 1,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 1,
    "平和と公正をすべての人に": 1,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 1,
    "工業": 1,
    "第３次産業": 1,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業従業者数×全産業従業者数男女比",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "6-1",
    "no": "06-01",
    "oldno": 8101,
    "title": "上水道の整備",
    "sdgs_no": 6,
    "year_display": 2015,
    "year_syousai": 2010,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "世帯総数×上水道.kmz",
    "date": 9499,
    "hanrei1_color": "#0033cc",
    "hanrei2_color": "#00bfff",
    "hanrei3_color": "#84a2d4",
    "hanrei4_color": "#bed2c3",
    "kml1_color": "#0033cc",
    "kml2_color": "#00bfff",
    "kml3_color": "#84a2d4",
    "kml4_color": "#bed2c3",
    "csvmode": "color_shikii",
    "hanrei1_name": "上水道",
    "hanrei2_name": "公営簡易水道",
    "hanrei3_name": "非公営簡易水道",
    "hanrei4_name": "その他",
    "height_ratio": "1世帯:2m",
    "category_data": 1,
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 2,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "上水道",
    "kokudo_hanrei2_name": "公営簡易水道",
    "kokudo_hanrei3_name": "非公営簡易水道",
    "kokudo_hanrei4_name": "その他",
    "kokudo_height_ratio": "1世帯:0.2m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "上水道普及率が高い地域には、どのような特徴があるだろうか。また、普及率が低い地域は、どのような地域だろうか。普及率が低い理由も探ってみよう。",
    "tenkei_mode": "",
    "tenkei_city": "熊本県嘉島町",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "2020年3月時点で都道府県別の上水道普及率（公営簡易水道を含む）を見ると、大都市圏と近畿地方において普及率が高いこと、地方圏で低めの値が出ていること、そしておおよそ9割を超えた普及率に達していることが確認できる。その中で、熊本県は、普及率が全国ワースト１位の88.5％にとどまっている。しかしこれは、社会生活インフラの整備が遅れているからではなく、地下水をくみ上げた飲用井戸を使用する世帯が多く、上水道を必要としない場所が多いためである。この熊本県でかつて、豊富な地下水で生活用水を賄い「全国で唯一水道のない町」をアピールしてきたのが嘉島町である。熊本市の南東に隣接する嘉島町は、町内各所から阿蘇の伏流水が湧き出し、環境省の平成の名水百選に「六嘉湧水群・浮島」が選ばれるなど地下水に恵まれている。ただ、近年、ニュータウンの造成により簡易水道が整備されている。",
    "height_origin": "国勢調査",
    "height_origin_note": "世帯総数は、調査時に常住している場所で調査する方法（常住地方式）による世帯数をいいます。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "上水道の種類。上水道、公営の簡易水道、非公営の簡易水道、その他（自家用水道や井戸など）です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：上水道関連施設データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P21.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "世帯総数（世帯）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "世帯総数（世帯）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "上水道の種類",
    "title_height_name": "世帯総数",
    "title_color_name": "上水道の種類",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 1,
    "安全な水とトイレを世界中に": 1,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "世帯総数×上水道",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "7-1",
    "no": "07-01",
    "oldno": "",
    "title": "風力発電に適したところ",
    "sdgs_no": 7,
    "year_display": "1991～2020",
    "year_syousai": "1991～2020",
    "year_nentsuki": "月",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "月平均風速×月最多風向.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "南東",
    "hanrei2_name": "北東",
    "hanrei3_name": "南西",
    "hanrei4_name": "北西",
    "height_ratio": "1m/秒:3000m",
    "category_data": 1,
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 5000,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "南東",
    "kokudo_hanrei2_name": "北東",
    "kokudo_hanrei3_name": "南西",
    "kokudo_hanrei4_name": "北西",
    "kokudo_height_ratio": "1m/秒:20,000m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 0,
    "kokudo_ratio": 20000,
    "year1": "",
    "year2": "1月",
    "year3": "4月",
    "year4": "7月",
    "year5": "10月",
    "try": "風力発電に適した場所はどのような場所だろうか。また、風力発電に適した条件とはどのような条件だろうか。",
    "tenkei_mode": "",
    "tenkei_city": "秋田県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "風力発電には、風速が比較的強い状態が安定し、かつ、風向が変わりにくい場所が適している。秋田県の日本海沿岸は一年中風が強く、特に男鹿半島付近では年間を通じて西寄りの風になっているなど、風力発電に好条件である。秋田県の風力による発電量は、全国３位の97.7万MWh(2020年度)で、秋田市と能代市の沖合では、国内初となる洋上風力発電所の建設が進んでいる。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "1991年～2020年の平均風速（秒速）を、1月・4月・7月・10月それぞれで算出したものです。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "1991年～2020年の平均風向を、1月・4月・7月・10月それぞれで算出したもの。南東（東・東南東・南東・南南東）、南西（南・南南西・南西・西南西）、北東（北・北北東・北東・東北東）、北西（西・西北西・北西・北北西）で分類しています。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "平均風速（m/秒）",
    "hanrei_height_name_2": "(1991～2020年)",
    "hanrei_height_name_3": "（1,4,7,10月）",
    "kokudo_hanrei_height_name": "平均風速（m/秒）",
    "kokudo_hanrei_height_name_2": "(1991～2020年)",
    "kokudo_hanrei_height_name_3": "（1,4,7,10月）",
    "hanrei_color_name": "月最多風向（1,4,7,10月）",
    "title_height_name": "各月の平均風速",
    "title_color_name": "月最多風向",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 1,
    "エネルギーをみんなにそしてクリーンに": 1,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 1,
    "つくる責任つかう責任": 1,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 1,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "月平均風速×月最多風向",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-82",
    "no": "08-01",
    "oldno": 98101,
    "title": "雇用を生み出すところ",
    "sdgs_no": 8,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業従業者数×全産業事業所数.kmz",
    "date": 9250,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "200～",
    "hanrei2_name": "100～200",
    "hanrei3_name": "10～100",
    "hanrei4_name": "0～10",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 200,
    "shikii2": 100,
    "shikii3": 10,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "500～",
    "kokudo_hanrei2_name": "100～500",
    "kokudo_hanrei3_name": "10～100",
    "kokudo_hanrei4_name": "0～10",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 500,
    "kokudo_shikii2": 100,
    "kokudo_shikii3": 10,
    "kokudo_min": 100,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "事業所の数に対して従業員が多い地域はどこだろうか。全産業事業所数と全産業事業所従業員数の関係を見える化し、何が雇用を生み出しているか、地域の特徴を調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "千葉県浦安市",
    "tenkei_city_2": "",
    "tenkei_lon": 139.9,
    "tenkei_lat": 35.5,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "きわめて高いグラフの位置は東京ディズニーリゾートおよびリゾートホテルが立ち並ぶ舞浜地域である。東京に隣接しながらも、リゾートとしての空間を要素を演出したアーバンリゾートゾーンが形成されており、国内外から非常に多くの来訪者がある。大型商業施設には、小売店や飲食店、シネマコンプレックスなどが立ち並び、サービス産業が集積していることが読み取れる。世界規模のアーバンリゾートのさらなる振興をはかることで、サービス産業に関連したさまざまな雇用がさらに生み出され、地域の魅力をさらに高めようとする取組が進行している。",
    "height_origin": "経済センサス",
    "height_origin_note": "全産業（公務を除く）の従業者数です。",
    "color_origin": "経済センサス",
    "color_origin_note": "全産業（公務を除く）の事業所数です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "全産業従業者数",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業従業者数",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "全産業事業所数（事業所）",
    "title_height_name": "従業者総数",
    "title_color_name": "事業所総数",
    "sdgs1": 1,
    "貧困をなくそう": 1,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 1,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業従業者数×全産業事業所数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "最新ファイルを登録",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-83",
    "no": "08-02",
    "oldno": 9998101,
    "title": "第1次産業の雇用を生み出すところ",
    "sdgs_no": 8,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "第1次産業従業者数×第1次産業従業者数の割合.kmz",
    "date": 9249,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "20～",
    "hanrei2_name": "10～20",
    "hanrei3_name": "5～10",
    "hanrei4_name": "0～5",
    "height_ratio": "1人:150m",
    "category_data": "",
    "shikii1": 20,
    "shikii2": 10,
    "shikii3": 5,
    "min": 10,
    "ratio": 150,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "20～",
    "kokudo_hanrei2_name": "10～20",
    "kokudo_hanrei3_name": "5～10",
    "kokudo_hanrei4_name": "0～5",
    "kokudo_height_ratio": "1人:75m",
    "kokudo_shikii1": 20,
    "kokudo_shikii2": 10,
    "kokudo_shikii3": 5,
    "kokudo_min": 100,
    "kokudo_ratio": 75,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "第一次産業に従事している人の割合が高い地域はどこだろうか。第一次産業従業員数と全従業員数に占める割合から見える化してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "宮崎県小林市",
    "tenkei_city_2": "",
    "tenkei_lon": 131,
    "tenkei_lat": 31.8,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "宮崎県小林市は霧島連山の水や自然を最大限に活かしながら、宮崎牛、みやざき地頭鳥やオリジナルブランド豚など上質な肉が多数生産される「畜産大国」として発展した。宮崎牛は、小林市のふるさと納税の返礼品として人気である。また、小林市は宮崎ブランド認証品「めろめろメロン」の愛称で知られる最高級メロンや「太陽のタマゴ」で知られる完熟マンゴーの産地でもあり、六次産業化にも取り組んでいる。市作成の「小林市就農パンフレット」には「儲かる農業を体得し、実現できるよう支援する」と記載されている。",
    "height_origin": "経済センサス",
    "height_origin_note": "第1次産業従業者数。第1次産業従業者数が10人以上のメッシュのみ表示しています。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業従業者数に占める第1次産業従業者数の割合を示しています。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "第1次産業",
    "hanrei_height_name_2": "従業者数（人）",
    "hanrei_height_name_3": "※10人以上で表示",
    "kokudo_hanrei_height_name": "第1次産業",
    "kokudo_hanrei_height_name_2": "従業者数（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "第1次産業従業者数の割合（％）",
    "title_height_name": "第1次産業従業者数",
    "title_color_name": "その割合",
    "sdgs1": 1,
    "貧困をなくそう": 1,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 1,
    "林業": 1,
    "水産業": 1,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "第1次産業従業者数×第1次産業従業者数の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-84",
    "no": "08-03",
    "oldno": 9998101,
    "title": "第2次産業の雇用を生み出すところ",
    "sdgs_no": 8,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "第2次産業従業者数×第2次産業従業者数の割合.kmz",
    "date": 9248,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "50～",
    "hanrei2_name": "25～50",
    "hanrei3_name": "10～25",
    "hanrei4_name": "0～10",
    "height_ratio": "1人:5m",
    "category_data": "",
    "shikii1": 50,
    "shikii2": 25,
    "shikii3": 10,
    "min": 10,
    "ratio": 5,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "50～",
    "kokudo_hanrei2_name": "25～50",
    "kokudo_hanrei3_name": "10～25",
    "kokudo_hanrei4_name": "0～10",
    "kokudo_height_ratio": "1人:0.5m",
    "kokudo_shikii1": 50,
    "kokudo_shikii2": 25,
    "kokudo_shikii3": 10,
    "kokudo_min": 100,
    "kokudo_ratio": 0.5,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "第二次産業に従事している人の割合が高い地域はどこだろうか。第二次産業従業員数と全従業員数に占める割合から見える化してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "茨城県日立市",
    "tenkei_city_2": "",
    "tenkei_lon": 140.6,
    "tenkei_lat": 36.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "茨城県日立市は、日立鉱山から発展した工業都市で、日立製作所の創業の地として広く知られている。典型的な企業城下町として発展してきた日立市には、部品や関連製品をつくる優れた技術をもった中小の製造業者が集中している。企業城下町のなかには、産業の空洞化に伴い、地域の雇用が失われて人口が流出していく地域が散見される。そのため、日立市は協力企業の自立を促進・支援し、特定の企業に頼らない多角的な経営ができるように、さまざまな技術を持った地場企業が集まる新たな「ものづくり都市」を目指している。",
    "height_origin": "経済センサス",
    "height_origin_note": "第2次産業従業者数。第2次産業従業者数が10人以上のメッシュのみ表示しています。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業従業者数に占める第2次産業従業者数の割合を示しています。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "第2次産業",
    "hanrei_height_name_2": "従業者数（人）",
    "hanrei_height_name_3": "※10人以上で表示",
    "kokudo_hanrei_height_name": "第2次産業",
    "kokudo_hanrei_height_name_2": "従業者数（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "第2次産業従業者数の割合（％）",
    "title_height_name": "第2次産業従業者数",
    "title_color_name": "その割合",
    "sdgs1": 1,
    "貧困をなくそう": 1,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 1,
    "工業": 1,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "第2次産業従業者数×第2次産業従業者数の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-85",
    "no": "08-04",
    "oldno": 8101,
    "title": "第3次産業の雇用を生み出すところ",
    "sdgs_no": 8,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "第3次産業従業者数×第3次産業従業者数の割合.kmz",
    "date": 9247,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "80～",
    "hanrei2_name": "40～80",
    "hanrei3_name": "20～40",
    "hanrei4_name": "0～20",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 80,
    "shikii2": 40,
    "shikii3": 20,
    "min": 10,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "80～",
    "kokudo_hanrei2_name": "40～80",
    "kokudo_hanrei3_name": "20～40",
    "kokudo_hanrei4_name": "0～20",
    "kokudo_height_ratio": "1人:0.12m",
    "kokudo_shikii1": 80,
    "kokudo_shikii2": 40,
    "kokudo_shikii3": 20,
    "kokudo_min": 100,
    "kokudo_ratio": 0.12,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "第三次産業に従事している人の割合が高い地域はどこだろうか。第三次産業従業員数と全従業員数に占める割合から見える化してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "富山県富山市",
    "tenkei_city_2": "",
    "tenkei_lon": 137.2,
    "tenkei_lat": 36.5,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "富山市は「平成の大合併」により富山県の約30％の面積を占め、南部には飛騨高地や北アルプス立山連峰がそびえる。赤く高いグラフは中心市街地の商業エリアを示す。また、西側で赤く高いグラフは主に富山大学付属病院（医療）を指す。さらに、南東部の立山連峰にある赤い場所は山小屋（観光）を指す。グレーのグラフは工業団地で、第二次産業が多いエリアだとわかる。富山市は豊富な水資源を背景に工場立地が進んでいる。グラフから、富山市は特定の産業に特化しているわけではなく、多様な業種のバランスがとれた産業構造を有していると考えられる。",
    "height_origin": "経済センサス",
    "height_origin_note": "第3次産業従業者数。第3次産業従業者数が10人以上のメッシュのみ表示しています。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業従業者数に占める第3次産業従業者数の割合を示しています。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "第3次産業",
    "hanrei_height_name_2": "従業者数（人）",
    "hanrei_height_name_3": "※10人以上で表示",
    "kokudo_hanrei_height_name": "第3次産業",
    "kokudo_hanrei_height_name_2": "従業者数（人）",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "第3次産業従業者数の割合（％）",
    "title_height_name": "第3次産業従業者数",
    "title_color_name": "その割合",
    "sdgs1": 1,
    "貧困をなくそう": 1,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "第3次産業従業者数×第3次産業従業者数の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-90",
    "no": "08-05",
    "oldno": "",
    "title": "宿泊容量の分布",
    "sdgs_no": 8,
    "year_display": 2010,
    "year_syousai": 2010,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "高さ・色とも非商用",
    "kml_file": "宿泊容量×宿泊容量.kmz",
    "date": 9242,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "300～",
    "hanrei2_name": "200～300",
    "hanrei3_name": "100～200",
    "hanrei4_name": "0～100",
    "height_ratio": "1人:10m",
    "category_data": "",
    "shikii1": 300,
    "shikii2": 200,
    "shikii3": 100,
    "min": 0,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "750～",
    "kokudo_hanrei2_name": "500～750",
    "kokudo_hanrei3_name": "250～500",
    "kokudo_hanrei4_name": "0～250",
    "kokudo_height_ratio": "1人:10m",
    "kokudo_shikii1": 750,
    "kokudo_shikii2": 500,
    "kokudo_shikii3": 250,
    "kokudo_min": 0,
    "kokudo_ratio": 10,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2010,
    "try": "どのような場所に宿泊施設が立ち並んでいるとイメージできるだろう。宿泊容量の大きい場所は，観光地や国際会議場のような，泊まりがけで訪れる人々が集まる施設を抱えていることが想像できる。実際はどうだろう？",
    "tenkei_mode": "",
    "tenkei_city": "京都府京都市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "京都市では，観光地が集中している市街地東部に宿泊容量が大きく偏っている。中心部から東側にかけては放射状に広がっているが，西側では宿泊施設そのものは見られるが宿泊容量は格段に低い。ここまでは数値的な統計データとして読み取ることができる。その理由として，京都における観光地がコンパクトに密集していること，観光ルートがワンパターン化していること，もしくは宿泊容量の少ない西部は古民家が立ち並ぶ地域であり大規模な宿泊施設を開業することが困難というような様々な可能性が考えられる。多様な観点から情報を収集し，理由の考察を進めたい。",
    "height_origin": "国土数値情報",
    "height_origin_note": "宿泊施設の収容人数です。",
    "color_origin": "国土数値情報",
    "color_origin_note": "宿泊施設の収容人数です。",
    "height_origin_url_name": "国土数値情報：宿泊容量メッシュデータ",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P09.html",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "国土数値情報：宿泊容量メッシュデータ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P09.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "宿泊容量（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "宿泊容量（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "宿泊容量（人）",
    "title_height_name": "宿泊容量",
    "title_color_name": "宿泊容量",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 1,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "宿泊容量×宿泊容量",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-89",
    "no": "08-06",
    "oldno": "",
    "title": "宿泊容量の分布と集客施設",
    "sdgs_no": 8,
    "year_display": 2010,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "高さ・色とも非商用",
    "kml_file": "宿泊容量×集客施設.kmz",
    "date": 9243,
    "hanrei1_color": "#e3c800",
    "hanrei2_color": "#98fb98",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e3c800",
    "kml2_color": "#98fb98",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "5～",
    "hanrei2_name": "3～5",
    "hanrei3_name": "1～3",
    "hanrei4_name": "0～1",
    "height_ratio": "1人:10m",
    "category_data": "",
    "shikii1": 5,
    "shikii2": 3,
    "shikii3": 1,
    "min": 0,
    "ratio": 10,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "5～",
    "kokudo_hanrei2_name": "3～5",
    "kokudo_hanrei3_name": "1～3",
    "kokudo_hanrei4_name": "0～1",
    "kokudo_height_ratio": "1人:3m",
    "kokudo_shikii1": 5,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 1,
    "kokudo_min": 0,
    "kokudo_ratio": 3,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2010,
    "try": "身近な地域の中で，宿泊施設が集まっているのはどのような場所だろう。概ね，駅や空港といった交通の要所が目立つのではないだろうか。実際に集客施設と宿泊容量の分布は因果関係があるのかを見てみよう。",
    "tenkei_mode": "",
    "tenkei_city": "大阪府",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "大阪府では，オフィス街や繁華街が続く難波，梅田，新大阪といった大阪市の中心部が府内で最も宿泊容量の大きな地区となっており，観光客とビジネス客の宿泊を多く受け入れていると想像できる。それに次いで，巨大テーマパークを有する大阪市此花区が，宿泊容量の大きな地区となっている。一方，泉佐野市とその周辺市町では，宿泊容量の大きな地区になってはいるものの，集客施設までの距離が他の地区とは大きく異なり遠くなっている。この例は，集客施設である関西国際空港が海上に浮かぶ人工島であるためだが，同様に宿泊施設が近接できない例を見つけたい。",
    "height_origin": "国土数値情報",
    "height_origin_note": "宿泊施設の収容人数です。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "メッシュの中心から最寄りの集客施設までの距離です。ここでの集客施設とは、アトラクションや展示会など催事が開催できる施設で、劇場・演劇場、展示場、体育館・観覧場、その他（主にテーマパーク）です。",
    "height_origin_url_name": "国土数値情報：宿泊容量メッシュデータ",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P09.html",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "国土数値情報：集客施設データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P33.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "宿泊容量（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "宿泊容量（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "集客施設までの距離（km）",
    "title_height_name": "宿泊容量",
    "title_color_name": "集客施設までの距離",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 1,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "宿泊容量×集客施設",
    "データ更新20220305": "23区問題，国土は非表示に",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-86",
    "no": "08-91",
    "oldno": 8101,
    "title": "正規雇用・非正規雇用（男性）",
    "sdgs_no": 8,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "就業者数（男性）×正規雇用の割合.kmz",
    "date": 9246,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "0.8～",
    "hanrei2_name": "0.4～0.8",
    "hanrei3_name": "0.2～0.4",
    "hanrei4_name": "0～0.2",
    "height_ratio": "1人:2m",
    "category_data": "",
    "shikii1": 0.8,
    "shikii2": 0.4,
    "shikii3": 0.2,
    "min": 0,
    "ratio": 2,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "0.8～",
    "kokudo_hanrei2_name": "0.4～0.8",
    "kokudo_hanrei3_name": "0.2～0.4",
    "kokudo_hanrei4_name": "0～0.2",
    "kokudo_height_ratio": "1人:0.2m",
    "kokudo_shikii1": 0.8,
    "kokudo_shikii2": 0.4,
    "kokudo_shikii3": 0.2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "未定",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "人口分布の変化に典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "国勢調査",
    "height_origin_note": "当該メッシュに居住している就業者のうち、男性の数です。",
    "color_origin": "国勢調査を加工して作成",
    "color_origin_note": "当該メッシュに居住している男性の就業者のうち、正規職員・従業者の割合です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "就業者数：男性（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "就業者数：男性（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "正規職員・従業者の割合：男性（％）",
    "title_height_name": "就業者数：男性",
    "title_color_name": "正規職員・従業者の割合：男性",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 0,
    "パートナーシップで目標を達成しよう": 0,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 0,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 0,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 0,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "就業者数（男性）×正規雇用の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-87",
    "no": "08-92",
    "oldno": 8101,
    "title": "正規雇用・非正規雇用（女性）",
    "sdgs_no": 8,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "就業者数（女性）×正規雇用の割合.kmz",
    "date": 9245,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "0.8～",
    "hanrei2_name": "0.4～0.8",
    "hanrei3_name": "0.2～0.4",
    "hanrei4_name": "0～0.2",
    "height_ratio": "1人:2m",
    "category_data": "",
    "shikii1": 0.8,
    "shikii2": 0.4,
    "shikii3": 0.2,
    "min": 0,
    "ratio": 2,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "0.8～",
    "kokudo_hanrei2_name": "0.4～0.8",
    "kokudo_hanrei3_name": "0.2～0.4",
    "kokudo_hanrei4_name": "0～0.2",
    "kokudo_height_ratio": "1人:0.2m",
    "kokudo_shikii1": 0.8,
    "kokudo_shikii2": 0.4,
    "kokudo_shikii3": 0.2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "未定",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "人口分布の変化に典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "国勢調査",
    "height_origin_note": "当該メッシュに居住している就業者のうち、女性の数。です。",
    "color_origin": "国勢調査を加工して作成",
    "color_origin_note": "当該メッシュに居住している女性の就業者のうち、正規職員・従業者の割合です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "就業者数：女性（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "就業者数：女性（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "正規職員・従業者の割合：女性（％）",
    "title_height_name": "就業者数：女性",
    "title_color_name": "正規職員・従業者の割合：女性",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 0,
    "パートナーシップで目標を達成しよう": 0,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 0,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 0,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 0,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "就業者数（女性）×正規雇用の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "8-88",
    "no": "08-93",
    "oldno": 8188,
    "title": "正規雇用・非正規雇用",
    "sdgs_no": 8,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "就業者数×正規雇用の割合.kmz",
    "date": 9244,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "60～",
    "hanrei2_name": "40～60",
    "hanrei3_name": "20～40",
    "hanrei4_name": "0～20",
    "height_ratio": "1人:2m",
    "category_data": "",
    "shikii1": 0.6,
    "shikii2": 0.4,
    "shikii3": 0.2,
    "min": 0,
    "ratio": 2,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "60～",
    "kokudo_hanrei2_name": "40～60",
    "kokudo_hanrei3_name": "20～40",
    "kokudo_hanrei4_name": "0～20",
    "kokudo_height_ratio": "1人:0.2m",
    "kokudo_shikii1": 0.6,
    "kokudo_shikii2": 0.4,
    "kokudo_shikii3": 0.2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "未定",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "人口分布の変化に典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "国勢調査",
    "height_origin_note": "当該メッシュに居住している全就業者数です。",
    "color_origin": "国勢調査を加工して作成",
    "color_origin_note": "当該メッシュに居住している全就業者のうち、正規職員・従業者の割合です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "就業者数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "就業者数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "正規職員・従業者の割合（％）",
    "title_height_name": "就業者数",
    "title_color_name": "正規職員・従業者の割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 0,
    "パートナーシップで目標を達成しよう": 0,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 0,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 0,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 0,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "就業者数×正規雇用の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "9-1",
    "no": "09-01",
    "oldno": 102,
    "title": "企業が集まるところ",
    "sdgs_no": 9,
    "year_display": "2001,2014",
    "year_syousai": "2001,2014",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業事業所数×全産業事業所数.kmz",
    "date": 9199,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "100～",
    "hanrei2_name": "50～100",
    "hanrei3_name": "25～50",
    "hanrei4_name": "0～25",
    "height_ratio": "1事業所:10m",
    "category_data": "",
    "shikii1": 100,
    "shikii2": 50,
    "shikii3": 25,
    "min": 10,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "1000～",
    "kokudo_hanrei2_name": "500～1000",
    "kokudo_hanrei3_name": "250～500",
    "kokudo_hanrei4_name": "100～250",
    "kokudo_height_ratio": "1事業所:2m",
    "kokudo_shikii1": 1000,
    "kokudo_shikii2": 500,
    "kokudo_shikii3": 250,
    "kokudo_min": 100,
    "kokudo_ratio": 2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 2001,
    "year5": 2014,
    "try": "企業はどのような地区に集まる傾向にあるだろう。朝の通勤ラッシュで多くの人々が移動する先となっている地区は，企業が集まっていると言える。どのような業種はどのような場所に集まるか，立地の理由を考察したい。",
    "tenkei_mode": 6,
    "tenkei_city": "茨城県つくば市・土浦市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "茨城県つくば市と土浦市について，2001年と2014年を比較すると，つくば市では中心部の西側で企業数が増加していることが分かる。この地区には2005年に開業した「つくばエクスプレス」の「研究学園駅」が開業しており，企業数が2005年以降に増加していったと考えられる。一方，「つくばエクスプレス」の開業によって都心とつくば市の経由地であった土浦市は，企業数が大きく減少している。このように，鉄道や道路の新設や廃止といった公共交通機関の変化によって，企業数の増減が左右されるという側面がある。他の地域の考察の際にも留意したい。",
    "height_origin": "経済センサス",
    "height_origin_note": "全産業（公務を除く）の事業所数です。",
    "color_origin": "経済センサス等",
    "color_origin_note": "全産業（公務を除く）の事業所数です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "hanrei_height_name": "全産業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "※100所以上で表示",
    "hanrei_color_name": "全産業事業所数（事業所）",
    "title_height_name": "事業所総数",
    "title_color_name": "事業所総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 1,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業事業所数×全産業事業所数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "最新ファイルを登録",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "9-81",
    "no": "09-02",
    "oldno": 80981,
    "title": "企業が集まるところの変化",
    "sdgs_no": 9,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業事業所数×事業所数の増減率.kmz",
    "date": 9150,
    "hanrei1_color": "#ff0000",
    "hanrei2_color": "#ffb6c1",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#ff0000",
    "kml2_color": "#ff66cc",
    "kml3_color": "#00ccff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "120 ～",
    "hanrei2_name": "100 ～ 120",
    "hanrei3_name": "80 ～ 100",
    "hanrei4_name": "～ 80",
    "height_ratio": "1事業所:10m",
    "category_data": "",
    "shikii1": 120,
    "shikii2": 100,
    "shikii3": 80,
    "min": 10,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "120 ～",
    "kokudo_hanrei2_name": "100 ～ 120",
    "kokudo_hanrei3_name": "80 ～ 100",
    "kokudo_hanrei4_name": "～ 80",
    "kokudo_height_ratio": "1事業所:2m",
    "kokudo_shikii1": 120,
    "kokudo_shikii2": 100,
    "kokudo_shikii3": 80,
    "kokudo_min": 100,
    "kokudo_ratio": 2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "事業所数の増減を見える化することで、その背景を調べてみよう。また、「生産年齢人口が増える街・減る街」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県福岡市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.45,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "現在、福岡市は二つの大規模再開発を進行中である。ひとつは「天神ビッグバン」で、2024年までの10年間で九州最大の繁華街である天神エリアの先進的なビルの建て替えや雇用の創出を目指している。もうひとつは「博多コネクティッド」で、博多駅周辺の先進的なビルへの建て替えやさらなる賑わい創出をすることで、都市機能の向上を目指している。きわめて高いグラフは、まさに天神エリアや博多駅周辺を指している。福岡市が目指す「グローバル創業都市」に向けた創業支援もあり、ビジネスチャンスと捉える企業が福岡市にますます集まると予想される。",
    "height_origin": "経済センサス",
    "height_origin_note": "全産業（公務を除く）の事業所数です。",
    "color_origin": "経済センサス（基礎調査）",
    "color_origin_note": "全産業（公務を除く）の事業所数の2009年に対する増減率です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "hanrei_height_name": "全産業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "※100所以上で表示",
    "hanrei_color_name": "2009年比の増減率（％）",
    "title_height_name": "事業所総数",
    "title_color_name": "その増減率",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 1,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業事業所数×事業所数の増減率",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "最新ファイルを登録",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "9-2",
    "no": "09-03",
    "oldno": 34,
    "title": "工場が集まるところ",
    "sdgs_no": 9,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "製造業事業所数×全産業事業所数.kmz",
    "date": 9198,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "100～",
    "hanrei2_name": "50～100",
    "hanrei3_name": "25～50",
    "hanrei4_name": "0～25",
    "height_ratio": "1事業所:100m",
    "category_data": "",
    "shikii1": 100,
    "shikii2": 50,
    "shikii3": 25,
    "min": 0,
    "ratio": 100,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "1000～",
    "kokudo_hanrei2_name": "500～1000",
    "kokudo_hanrei3_name": "250～500",
    "kokudo_hanrei4_name": "100～250",
    "kokudo_height_ratio": "1事業所:10m",
    "kokudo_shikii1": 1000,
    "kokudo_shikii2": 500,
    "kokudo_shikii3": 250,
    "kokudo_min": 100,
    "kokudo_ratio": 10,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "企業にはいくつもの種類がある。そのうち，とくに製品を生産する工場に絞って立地を見つめると，原材料や製品の輸送，人件費や土地の安さなど，何を求め，どのような場所に集まっているのだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "北海道小樽市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "棒グラフが赤い地域は，企業数が多いことを指している。棒グラフが赤くかつ高い地域は，企業数が多い場所でかつ，何らかの工場が多く立地しているであろうことを示している。棒グラフが高いものの，赤ではなく橙色となっている地区は，企業数は少ないものの工場が占める割合が高いことを示している。つまり，大規模な工場が立地しているため企業数そのものは少なくなっていると考えられる。小樽市では海沿いを中心に工場が立地していることが分かるが，何を製造する工場が立地しているか，北海道での位置付け，地形との関わりなど立地を踏まえて考察したい。",
    "height_origin": "経済センサス",
    "height_origin_note": "製造業の事業所数です。",
    "color_origin": "経済センサス（基礎調査）",
    "color_origin_note": "全産業（公務を除く）の事業所数です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2014/yougo.html",
    "hanrei_height_name": "製造業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "製造業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "※100所以上で表示",
    "hanrei_color_name": "全産業事業所数（事業所）",
    "title_height_name": "製造業事業所数",
    "title_color_name": "事業所総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 1,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "製造業事業所数×全産業事業所数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "9-86",
    "no": "09-04",
    "oldno": 8101,
    "title": "第２次産業が集まるところ",
    "sdgs_no": 9,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業事業所数×第2次産業事業所数の割合.kmz",
    "date": 9149,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "60～",
    "hanrei2_name": "30～60",
    "hanrei3_name": "10～30",
    "hanrei4_name": "0～10",
    "height_ratio": "1事業所:10m",
    "category_data": "",
    "shikii1": 60,
    "shikii2": 30,
    "shikii3": 10,
    "min": 10,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "60～",
    "kokudo_hanrei2_name": "30～60",
    "kokudo_hanrei3_name": "10～30",
    "kokudo_hanrei4_name": "0～10",
    "kokudo_height_ratio": "1事業所:2m",
    "kokudo_shikii1": 60,
    "kokudo_shikii2": 30,
    "kokudo_shikii3": 10,
    "kokudo_min": 100,
    "kokudo_ratio": 2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "第二次産業事業所数の割合が高い地域はどこだろうか。第二次産業事業所数と全事業所数に占める割合から見える化してみよう。「第二次産業の雇用を生み出すところ」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "新潟県燕市",
    "tenkei_city_2": "",
    "tenkei_lon": 138.9,
    "tenkei_lat": 37.5,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "新潟県燕市は新潟県のほぼ中央に位置する、高度で多様な金属加工技術が集まる日本有数の「モノづくりのまち」である。冬の農閑期の副業として江戸時代に始まった和釘づくりが「燕市のモノづくり」のルーツである。現在、特にスプーンやフォークなどの金属製品洋食器において日本国内生産シェア95％以上を占め、あらゆるキッチンツールの一大産地となっている。ノーベル賞の晩餐会や、APECの各国首脳へのお土産として燕市の製品が採用されるなど、そのクオリティの高さは世界的な評価を得ている。（参考：燕市ホームページ）",
    "height_origin": "経済センサス",
    "height_origin_note": "全産業（公務を除く）の事業所数です。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業（公務を除く）の事業所数に対する第2次産業事業所数の割合です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "全産業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "※100所以上で表示",
    "hanrei_color_name": "第2次産業事業所数の割合（％）",
    "title_height_name": "事業所総数",
    "title_color_name": "第2次産業の割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 1,
    "工業": 1,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業事業所数×第2次産業事業所数の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "9-87",
    "no": "09-05",
    "oldno": 8101,
    "title": "第３次産業が集まるところ",
    "sdgs_no": 9,
    "year_display": 2016,
    "year_syousai": 2016,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "全産業事業所数×第3次産業事業所数の割合.kmz",
    "date": 9148,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "80～",
    "hanrei2_name": "60～80",
    "hanrei3_name": "40～60",
    "hanrei4_name": "0～40",
    "height_ratio": "1事業所:10m",
    "category_data": "",
    "shikii1": 80,
    "shikii2": 60,
    "shikii3": 40,
    "min": 10,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "80～",
    "kokudo_hanrei2_name": "60～80",
    "kokudo_hanrei3_name": "40～60",
    "kokudo_hanrei4_name": "0～40",
    "kokudo_height_ratio": "1事業所:2m",
    "kokudo_shikii1": 80,
    "kokudo_shikii2": 60,
    "kokudo_shikii3": 40,
    "kokudo_min": 100,
    "kokudo_ratio": 2,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2016,
    "try": "第三次産業事業所数の割合が高い地域はどこだろうか。第三次産業事業所数と全事業所数に占める割合から見える化してみよう。「第三次産業の雇用を生み出すところ」との関連も調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県福岡市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.45,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "福岡市は第三次産業に特化した産業構造をもつ都市である。その背景には、一級河川がないことにより、十分な工業用水や工業用地が確保できず、第二次産業を主体とする工業都市には向かなかったという歴史がある。スーパーコンパクトシティの強みや行政の働きかけから、福岡市へ本社機能を移転する企業や福岡市へ進出・拡大してオフィスをかまえる情報通信業も多い。また、多くの卸売・小売業が集まる福岡市は人気買い物スポットも多く、ご当地グルメの魅力に惹かれ、国内外から数多くの訪問者を集めていた。",
    "height_origin": "経済センサス",
    "height_origin_note": "全産業（公務を除く）の事業所数です。",
    "color_origin": "経済センサス（活動調査）を加工して作成",
    "color_origin_note": "全産業（公務を除く）の事業所数に対する第3次産業事業所数の割合です。",
    "height_origin_url_name": "経済センサスの目的",
    "height_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "height_origin_url2_name": "経済センサスの用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "color_origin_url_name": "経済センサスの目的",
    "color_origin_url": "https://www.stat.go.jp/data/e-census/guide/about/purpose.html",
    "color_origin_url2_name": "経済センサスの用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/e-census/2016/kekka/k_yougo.html",
    "hanrei_height_name": "全産業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "全産業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "※100所以上で表示",
    "hanrei_color_name": "第3次産業事業所数の割合（％）",
    "title_height_name": "事業所総数",
    "title_color_name": "第3次産業の割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "全産業事業所数×第3次産業事業所数の割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "10-1",
    "no": "10-01",
    "oldno": 36,
    "title": "進む国際化",
    "sdgs_no": 10,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "外国人人口×人口総数.kmz",
    "date": 9099,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "4,000～",
    "hanrei2_name": "2,000～4,000",
    "hanrei3_name": "1,000～2,000",
    "hanrei4_name": "0～1,000",
    "height_ratio": "1人:50m",
    "category_data": "",
    "shikii1": 4000,
    "shikii2": 2000,
    "shikii3": 1000,
    "min": 0,
    "ratio": 50,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "400,000～",
    "kokudo_hanrei2_name": "200,000～400,000",
    "kokudo_hanrei3_name": "100,000～200,000",
    "kokudo_hanrei4_name": "100～100,000",
    "kokudo_height_ratio": "1人:4m",
    "kokudo_shikii1": 400000,
    "kokudo_shikii2": 200000,
    "kokudo_shikii3": 100000,
    "kokudo_min": 100,
    "kokudo_ratio": 4,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "国際化が進んでいる地域はどのようなところだろうか。外国人と共生する街を見える化してみよう。外国人人口総数が多いメッシュがどのようなところか調べてみよう。人口総数との関係も見てみよう。",
    "tenkei_mode": "",
    "tenkei_city": "東京都東京23区",
    "tenkei_city_2": "",
    "tenkei_lon": 140,
    "tenkei_lat": 32.7,
    "tenkei_height": 400000,
    "tenkei_heading": 0,
    "tenkei_pitch": -40,
    "tenkei_roll": 0,
    "kaisetsu": "東京都で外国人人口総数のグラフがずば抜けて高いメッシュがある。この場所を確認すると，新宿区の新大久保駅周辺であり，Google MapsやGoogle Earthのストリートビューで見てみると，通りに沿ってエスニック料理店などが並んでいることがわかる。大久保界隈は，東アジア，東南アジア，南アジア出身のさまざまな人々が暮らすエスニックタウンとなっており，買い物客などで賑わっている。地方では，外国人人口総数のグラフが高いが，人口総数は少ないメッシュもあり，この場所を確認すると，大学の国際寮などが見つかることもある。",
    "height_origin": "国勢調査",
    "height_origin_note": "日本国内に常住する外国人数です。ただし，次の者は調査の対象から除外しています。① 外国政府の外交使節団・領事機関の構成員（随員を含む。）及びその家族 ② 外国軍隊の軍人・軍属及びその家族",
    "color_origin": "国勢調査",
    "color_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "外国人人口（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "外国人人口（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※100人以上で表示",
    "hanrei_color_name": "人口総数（人）",
    "title_height_name": "外国人人口",
    "title_color_name": "人口総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 1,
    "質の高い教育をみんなに": 1,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 1,
    "人や国の不平等をなくそう": 1,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 1,
    "平和と公正をすべての人に": 1,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 1,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 1,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 1,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 1,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 1,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "外国人人口×人口総数",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-1",
    "no": "11-01",
    "oldno": 101,
    "title": "人口分布の変化",
    "sdgs_no": 11,
    "year_display": "1995,2015",
    "year_syousai": "1995,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×人口総数.kmz",
    "date": 8999,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "4,000～",
    "hanrei2_name": "2,000～4,000",
    "hanrei3_name": "1,000～2,000",
    "hanrei4_name": "0～1,000",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 4000,
    "shikii2": 2000,
    "shikii3": 1000,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "400,000～",
    "kokudo_hanrei2_name": "200,000～400,000",
    "kokudo_hanrei3_name": "100,000～200,000",
    "kokudo_hanrei4_name": "10,000～100,000",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 400000,
    "kokudo_shikii2": 200000,
    "kokudo_shikii3": 100000,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1995,
    "year5": 2015,
    "try": "人口分布は，どのように変化しているだろうか。人口総数から自分が暮らす地域の人口分布とその変化を見える化してみよう。特徴のあるメッシュは，どのような場所なのか確認してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "人口分布の変化に典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国勢調査",
    "color_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "人口総数（人）",
    "title_height_name": "人口総数",
    "title_color_name": "人口総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 1,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×人口総数",
    "データ更新20220305": "国土表示数が少ない－＞スプレッドシートで対応",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-87",
    "no": "11-02",
    "oldno": 83,
    "title": "人口が増えた町・減った町",
    "sdgs_no": 11,
    "year_display": "2000,2015",
    "year_syousai": "2000,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×人口増減割合.kmz",
    "date": 8947,
    "hanrei1_color": "#e3c800",
    "hanrei2_color": "#98fb98",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e3c800",
    "kml2_color": "#98fb98",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "100～",
    "hanrei2_name": "75～100",
    "hanrei3_name": "50～75",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 100,
    "shikii2": 75,
    "shikii3": 50,
    "min": 10,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "100～",
    "kokudo_hanrei2_name": "75～100",
    "kokudo_hanrei3_name": "50～75",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 100,
    "kokudo_shikii2": 75,
    "kokudo_shikii3": 50,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 2000,
    "year5": 2015,
    "try": "全国的に少子高齢化が進み、人口減少傾向にあるなか、人口が増えた町はどのようなところだろうか。また、その理由を探ってみよう。逆に、大きく人口が減った町も探ってみよう。",
    "tenkei_mode": "",
    "tenkei_city": "千葉県流山市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "現在の人口が約20万5千人である千葉県流山市は、平成15年の人口は約15万人であった。平成17年8月24日に開業したつくばエクスプレスをきっかけに、都心から30分以内でアクセスでき豊かな自然もある住環境が魅力となり、人口を増やしたと考えられる。ただ、人口増の要因として、「母になるなら、流山市」をキャッチコピーに進めてきた、流山市の子育て支援施策も見逃せない。認可保育所の新設・増設、さらには駅前送迎保育ステーションを設置し、各送迎保育ステーションと市内の保育園をバスで結び、登園・降園するシステムを導入している。このような手厚い子育て支援が功を奏して、子育て世代が大量に転入してきたために、一気に人口が増えたのである。流山市の合計特殊出生率も、全国平均1.43に対して1.62まで上昇している。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。人口総数が10人以上のメッシュのみ表示しています。",
    "color_origin": "国勢調査",
    "color_origin_note": "1995年の人口総数に対する2015年の人口総数の増減割合です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "※10人以上で表示",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "1995年に対する人口増減割合(％)",
    "title_height_name": "人口総数",
    "title_color_name": "人口増減割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 1,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×人口増減割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-2",
    "no": "11-03",
    "oldno": 19,
    "title": "人口の都心回帰",
    "sdgs_no": 11,
    "year_display": "1995,2015",
    "year_syousai": "1995,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が再配付不可",
    "kml_file": "人口総数×昼夜間人口比.kmz",
    "date": 8998,
    "hanrei1_color": "#ff0000",
    "hanrei2_color": "#ffb6c1",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#ff0000",
    "kml2_color": "#ff66cc",
    "kml3_color": "#00ccff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "150～",
    "hanrei2_name": "100～150",
    "hanrei3_name": "50～100",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 150,
    "shikii2": 100,
    "shikii3": 50,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "150～",
    "kokudo_hanrei2_name": "100～150",
    "kokudo_hanrei3_name": "50～100",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 150,
    "kokudo_shikii2": 100,
    "kokudo_shikii3": 50,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1995,
    "year5": 2015,
    "try": "大都市の人口分布はどのように変化しているだろうか。人口総数の変化から見える化してみよう。1995年～2015年の各年で人口総数が多いメッシュはどのようなところだろうか。昼夜間人口比から読み取ろう。",
    "tenkei_mode": 6,
    "tenkei_city": "宮城県仙台市",
    "tenkei_city_2": "",
    "tenkei_lon": 140.5,
    "tenkei_lat": 38,
    "tenkei_height": 30000,
    "tenkei_heading": 40,
    "tenkei_pitch": -35,
    "tenkei_roll": 0,
    "kaisetsu": "仙台市では，人口総数の高いグラフの固まりが見られるが，1995年では外側の昼夜間人口比が低い色のグラフが目立っており，郊外の住宅地域で人口が多いことがわかる。しかし，2015年では中央部の昼夜間人口比が高い色のグラフが高く伸びており，都心の業務地域での人口増加，すなわち人口の都心回帰が進んでいることが読み取れる。2015年で一番高い場所を確認すると，中高層のマンションが並んでいることがわかる。仙台市では，人口を市街地に集約させるコンパクトシティを目指す取り組みが行われている。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国勢調査・経済センサスリンク統計",
    "color_origin_note": "夜間人口に対する昼間人口の比率です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "夜間人口と昼間人口の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2010/users-g/word6.html",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "昼夜間人口比（％）",
    "title_height_name": "人口総数",
    "title_color_name": "昼夜間人口比",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 1,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 1,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×昼夜間人口比",
    "データ更新20220305": "国土表示が少ない",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-3",
    "no": "11-04",
    "oldno": 22,
    "title": "コンパクトシティ",
    "sdgs_no": 11,
    "year_display": "1995,2015",
    "year_syousai": 2019,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "人口総数×公共交通利用圏.kmz",
    "date": 8997,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "駅・バス利用圏",
    "hanrei2_name": "駅利用圏",
    "hanrei3_name": "バス利用圏",
    "hanrei4_name": "公共交通利用圏外",
    "height_ratio": "1人:1m",
    "category_data": 1,
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "駅・バス利用圏",
    "kokudo_hanrei2_name": "駅利用圏",
    "kokudo_hanrei3_name": "バス利用圏",
    "kokudo_hanrei4_name": "公共交通利用圏外",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1995,
    "year5": 2015,
    "try": "地方都市の人口分布はどうあるべきだろうか。人口総数と公共交通利用圏の関係から見える化してみよう。人口総数のグラフの高さを，駅・バス利用圏とそれ以外の色に着目して，二つの都市で比較してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市・飯塚市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.3,
    "tenkei_lat": 33.15,
    "tenkei_height": 50000,
    "tenkei_heading": 10,
    "tenkei_pitch": -45,
    "tenkei_roll": 0,
    "kaisetsu": "糸島市と飯塚市は，福岡市の郊外に位置し，福岡市中心部からの通勤圏にある。人口総数のグラフは，糸島市では中央部の駅・バス利用圏の色のメッシュが高いが，飯塚市では周辺部のバス利用圏の色のメッシュが高くなっている。糸島市の方が，鉄道駅周辺に人口がコンパクトに集まっていることがわかる。今後，長期的に人口減少が続くなかで，人口が分散していると，バスなどの公共交通整備に経費がかかる。人口を，交通の利便性の良い地域に集めてまとめるコンパクトシティに取り組む自治体が増えている。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "駅利用圏域：鉄道駅より半径１kmの範囲が含まれているメッシュです。バス停利用圏域：バス停より半径300mの範囲が含まれているメッシュです。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：駅",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-N05-v1_3.html",
    "color_origin_url2_name": "国土数値情報：バス",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P11.html",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "公共交通利用圏",
    "title_height_name": "人口総数",
    "title_color_name": "公共交通利用圏",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 1,
    "エネルギーをみんなにそしてクリーンに": 1,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 1,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×公共交通利用圏",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-88",
    "no": "11-05",
    "oldno": 22,
    "title": "高齢者の免許返納問題を考える",
    "sdgs_no": 11,
    "year_display": 2015,
    "year_syousai": 2019,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "75歳以上人口×公共交通利用圏.kmz",
    "date": 8946,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "駅・バス利用圏",
    "hanrei2_name": "駅利用圏",
    "hanrei3_name": "バス利用圏",
    "hanrei4_name": "公共交通利用圏外",
    "height_ratio": "1人:8m",
    "category_data": 1,
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 8,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "駅・バス利用圏",
    "kokudo_hanrei2_name": "駅利用圏",
    "kokudo_hanrei3_name": "バス利用圏",
    "kokudo_hanrei4_name": "公共交通利用圏外",
    "kokudo_height_ratio": "1人:0.8m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 0,
    "kokudo_ratio": 0.8,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "高齢者の免許自主返納が高い地域は、どのようなところだろうか。また、返納率が低い地域は、どのようなところだろうか。その理由も含めて探ってみよう。",
    "tenkei_mode": "",
    "tenkei_city": "和歌山県",
    "tenkei_city_2": "",
    "tenkei_lon": 130.3,
    "tenkei_lat": 33.15,
    "tenkei_height": 50000,
    "tenkei_heading": 10,
    "tenkei_pitch": -45,
    "tenkei_roll": 0,
    "kaisetsu": "警察庁『令和2年版運転免許統計』によると、高齢者の免許返納の割合が最も少なかったのが「和歌山県」で11.6％であり、「山梨県」「高知県」「茨城県」「熊本県」と続く。免許返納率が全国トップ「東京都」と「和歌山県」には2.5倍ほどの差が生じ、大都市圏のほうが返納の割合は高くなっている。全国的な免許返納をしない理由の多くが、「車が必要である」と答えており、公共交通機関が充実していないため移動手段としての車が手放せないことが考えられる。そうしたなかで、和歌山県では、免許の自主返納に応じてタクシー利用の割引などの特典を受けられるなどの政策で、自主返納を促している。",
    "height_origin": "国勢調査",
    "height_origin_note": "年齢が75歳以上の人口です。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "駅利用圏域：鉄道駅より半径１kmの範囲が含まれているメッシュ。バス停利用圏域：バス停より半径300mの範囲が含まれているメッシュ。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国土数値情報：駅",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-N05-v1_3.html",
    "color_origin_url2_name": "国土数値情報：バス",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P11.html",
    "hanrei_height_name": "75歳以上人口",
    "hanrei_height_name_2": "（人）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "75歳以上人口",
    "kokudo_hanrei_height_name_2": "（人）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "公共交通利用圏",
    "title_height_name": "75歳以上人口",
    "title_color_name": "公共交通利用圏",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "75歳以上人口×公共交通利用圏",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-86",
    "no": "11-06",
    "oldno": 8106,
    "title": "どこでモノが売れたのか",
    "sdgs_no": 11,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "小売業販売額×小売業販売額.kmz",
    "date": 8948,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "30～",
    "hanrei2_name": "20～30",
    "hanrei3_name": "10～20",
    "hanrei4_name": "0～10",
    "height_ratio": "1千万円:5m",
    "category_data": "",
    "shikii1": 30,
    "shikii2": 20,
    "shikii3": 10,
    "min": 0,
    "ratio": 5,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "30～",
    "kokudo_hanrei2_name": "20～30",
    "kokudo_hanrei3_name": "10～20",
    "kokudo_hanrei4_name": "0～10",
    "kokudo_height_ratio": "1千万円:1m",
    "kokudo_shikii1": 30,
    "kokudo_shikii2": 20,
    "kokudo_shikii3": 10,
    "kokudo_min": 0,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "商業集積には、駅周辺型、市街地型、住宅地背景型、ローサイド型等が挙げられる。小売業の事業所数と年間販売額の見える化により、商業集積の型による傾向を調べてみよう。",
    "tenkei_mode": "",
    "tenkei_city": "静岡県御殿場市",
    "tenkei_city_2": "",
    "tenkei_lon": 138.9,
    "tenkei_lat": 35.2,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "御殿場市は静岡県の東部に位置し、富士山を間近に見ることができる。きわめて高いグラフがある地点にはアウトレットモールが立地しており、郊外型の大型集客施設であることがわかる。このアウトレットモールは国内最大の面積を有するショッピングリゾートであり、高速のインターチェンジに近いことから首都圏など県外からの来客も多く、地域経済に大きく貢献している。他のメッシュは、市民の日常生活に関わる商業施設で、赤色の低いメッシュは大型店、黄色のメッシュは中小小売店が主であると推察される。",
    "height_origin": "商業統計調査",
    "height_origin_note": "小売業事業所における1年間の商品の販売額です。",
    "color_origin": "商業統計調査を加工して作成",
    "color_origin_note": "小売業事業所あたりの1年間の商品の販売額です。",
    "height_origin_url_name": "商業統計調査の概要",
    "height_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "height_origin_url2_name": "商業統計調査の用語の解説",
    "height_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "color_origin_url_name": "商業統計調査の概要",
    "color_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "color_origin_url2_name": "商業統計調査の用語の解説",
    "color_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "hanrei_height_name": "小売業年間販売額",
    "hanrei_height_name_2": "（千万円）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "小売業年間販売額",
    "kokudo_hanrei_height_name_2": "（千万円）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "事業所平均販売額（千万円/所）",
    "title_height_name": "小売業販売額",
    "title_color_name": "事業所平均額",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 1,
    "つくる責任つかう責任": 1,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 1,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "小売業販売額×小売業販売額",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-5",
    "no": "11-07",
    "oldno": 9014,
    "title": "郊外型ショッピングセンターの成長",
    "sdgs_no": 11,
    "year_display": "1985,2014",
    "year_syousai": "1985,2014",
    "year_nentsuki": "年",
    "sokuchi": "日本測地系・世界測地系(H26)",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "小売業販売額×小売業販売額伸率.kmz",
    "date": 8995,
    "hanrei1_color": "#ff0000",
    "hanrei2_color": "#ffb6c1",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#ff0000",
    "kml2_color": "#ff66cc",
    "kml3_color": "#00ccff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "50 ～",
    "hanrei2_name": "0 ～ 50",
    "hanrei3_name": "-50 ～ 0",
    "hanrei4_name": "～ -50",
    "height_ratio": "1千万円:5m",
    "category_data": "",
    "shikii1": 50,
    "shikii2": 0,
    "shikii3": -50,
    "min": 0,
    "ratio": 5,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "50 ～",
    "kokudo_hanrei2_name": "0 ～ 50",
    "kokudo_hanrei3_name": "-50 ～ 0",
    "kokudo_hanrei4_name": "～ -50",
    "kokudo_height_ratio": "1千万円:1m",
    "kokudo_shikii1": 50,
    "kokudo_shikii2": 0,
    "kokudo_shikii3": -50,
    "kokudo_min": 0,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1985,
    "year5": 2014,
    "try": "買い物はどこに行くだろうか。商業の変化を見える化してみよう。小売業年間販売額が多いメッシュは，1985年と2014年でどのように変化しただろうか。また，小売業年間販売額伸び率との関係はどうだろうか。",
    "tenkei_mode": 6,
    "tenkei_city": "秋田県大仙市",
    "tenkei_city_2": "",
    "tenkei_lon": 140.2,
    "tenkei_lat": 38.95,
    "tenkei_height": 35000,
    "tenkei_heading": 20,
    "tenkei_pitch": -25,
    "tenkei_roll": 0,
    "kaisetsu": "大仙市の1985年では，きわめて高いグラフがあり，この場所を確認すると，大曲駅前の商店街であることがわかる。2014年では，高いグラフはここから離れたメッシュに移り，この場所を確認すると，田んぼの中に大きなショッピングセンターが建っていることがわかる。1985年で高いグラフは，小売業年間販売額伸び率はマイナスだが，2014年で高いグラフは，伸び率は一番高い色で示されている。地方都市では，郊外に大きなショッピングセンターができたことにより，従来の中心市街地の商店街のシャッター通り化が進んでいる。",
    "height_origin": "商業統計調査",
    "height_origin_note": "小売業事業所における1年間の商品の販売額です。",
    "color_origin": "商業統計調査および国土数値情報（商業統計3次メッシュデータ）を加工して作成",
    "color_origin_note": "1985年の小売業年間販売額に対する2014年の小売業年間販売額の伸び率です。",
    "height_origin_url_name": "商業統計調査の概要",
    "height_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "height_origin_url2_name": "商業統計調査の用語の解説",
    "height_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "color_origin_url_name": "商業統計調査の概要",
    "color_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "color_origin_url2_name": "商業統計調査の用語の解説",
    "color_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "hanrei_height_name": "小売業年間販売額",
    "hanrei_height_name_2": "（千万円）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "小売業年間販売額",
    "kokudo_hanrei_height_name_2": "（千万円）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "小売業年間販売額伸び率（％）",
    "title_height_name": "小売業販売額",
    "title_color_name": "その伸び率",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 1,
    "つくる責任つかう責任": 1,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 1,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "小売業販売額×小売業販売額伸率",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-84",
    "no": "11-08",
    "oldno": 8104,
    "title": "居住地とモノが売れたところの関係",
    "sdgs_no": 11,
    "year_display": 2015,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "人口総数×小売業年間販売額.kmz",
    "date": 8950,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "400～",
    "hanrei2_name": "200～400",
    "hanrei3_name": "100～200",
    "hanrei4_name": "0～100",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 400,
    "shikii2": 200,
    "shikii3": 100,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "1000～",
    "kokudo_hanrei2_name": "500～1000",
    "kokudo_hanrei3_name": "250～500",
    "kokudo_hanrei4_name": "0～250",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 1000,
    "kokudo_shikii2": 500,
    "kokudo_shikii3": 250,
    "kokudo_min": 10000,
    "kokudo_ratio": 0.1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "居住地の分布と小売店には関連性があるのだろうか。居住地とモノが売れたところの関係性を見える化し、考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "石川県野々市市",
    "tenkei_city_2": "",
    "tenkei_lon": 136.6,
    "tenkei_lat": 36.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "石川県野々市市は、隣接する金沢市のベッドタウンとして発展した。城下町として知られる金沢市は、従来から市街地の人口密度が高いものの、背後には山がせまり、海側に低湿地が多いなど、大規模な再開発が困難な地域であった。一方、野々市市は全域が扇状地の下部に位置し、大学や郊外型大型店の進出といった開発が行われやすい地域であり、生活環境が整っていることから子育て世帯の移住も多くなっている。野々市市は赤いグラフが狭い地域に密集しており、多数の小売店が進出し、利便性が高いコンパクトシティとなっていることが読み取れる。",
    "height_origin": "国勢調査",
    "height_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "color_origin": "商業統計調査",
    "color_origin_note": "小売業事業所における1年間の商品の販売額です。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "商業統計調査の概要",
    "color_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "color_origin_url2_name": "商業統計調査の用語の解説",
    "color_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "hanrei_height_name": "人口総数（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "人口総数（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1万人以上で表示",
    "hanrei_color_name": "小売業年間販売額（千万円）",
    "title_height_name": "人口総数",
    "title_color_name": "小売業販売額",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 1,
    "つくる責任つかう責任": 1,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 1,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "人口総数×小売業年間販売額",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-4",
    "no": "11-09",
    "oldno": 105,
    "title": "鉄道・車と商業",
    "sdgs_no": 11,
    "year_display": 2014,
    "year_syousai": 2019,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "色が非商用",
    "kml_file": "小売業販売額×公共交通利用圏.kmz",
    "date": 8996,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "駅・バス利用圏",
    "hanrei2_name": "駅利用圏",
    "hanrei3_name": "バス利用圏",
    "hanrei4_name": "その他",
    "height_ratio": "1千万円:5m",
    "category_data": 1,
    "shikii1": 4,
    "shikii2": 3,
    "shikii3": 2,
    "min": 0,
    "ratio": 5,
    "kokudo_onoff": "",
    "kokudo_hanrei1_name": "駅・バス利用圏",
    "kokudo_hanrei2_name": "駅利用圏",
    "kokudo_hanrei3_name": "バス利用圏",
    "kokudo_hanrei4_name": "その他",
    "kokudo_height_ratio": "1千万円:1m",
    "kokudo_shikii1": 4,
    "kokudo_shikii2": 3,
    "kokudo_shikii3": 2,
    "kokudo_min": 0,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "買い物に行く時には，どのような交通手段を使うだろうか。交通手段と商業の関係を見える化してみよう。小売業年間販売額が多いメッシュは，どの公共交通利用圏にあるだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "新潟県長岡市",
    "tenkei_city_2": "",
    "tenkei_lon": 138.83,
    "tenkei_lat": 37.4,
    "tenkei_height": 45000,
    "tenkei_heading": 10,
    "tenkei_pitch": -85,
    "tenkei_roll": 0,
    "kaisetsu": "長岡市で小売業年間販売額が高いグラフは，二つある。一つは大きな川を挟んで東側の駅・バス利用圏のグラフが並ぶメッシュの中央で，この場所を確認すると，鉄道の長岡駅前でバスターミナルがあり，商店街が連なっていることがわかる。もう一つは，川の西側のバス利用圏のグラフが並ぶメッシュの一つで，この場所を確認すると，大きなショッピングセンターなどロードサイド型の大型店が並んでいることがわかる。ここは鉄道はなくバス利用圏だが，これを言い替えれば，マイカーによる買い物客が集まって来る場所と考えられる。",
    "height_origin": "商業統計調査",
    "height_origin_note": "小売業事業所における1年間の商品の販売額です。",
    "color_origin": "国土数値情報",
    "color_origin_note": "駅利用圏域：鉄道駅より半径１kmの範囲が含まれているメッシュです。バス停利用圏域：バス停より半径300mの範囲が含まれているメッシュです。",
    "height_origin_url_name": "商業統計調査の概要",
    "height_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "height_origin_url2_name": "商業統計調査の用語の解説",
    "height_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "color_origin_url_name": "国土数値情報：駅",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-N05-v1_3.html",
    "color_origin_url2_name": "国土数値情報：バス停",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P11.html",
    "hanrei_height_name": "小売業年間販売額",
    "hanrei_height_name_2": "（千万円）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "小売業年間販売額",
    "kokudo_hanrei_height_name_2": "（千万円）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "公共交通利用圏",
    "title_height_name": "小売業販売額",
    "title_color_name": "公共交通利用圏",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 1,
    "エネルギーをみんなにそしてクリーンに": 1,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 1,
    "産業と技術革新の基盤をつくろう": 1,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 1,
    "つくる責任つかう責任": 1,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 1,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 1,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "H26小売業販売額×公共交通利用圏",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-6",
    "no": "11-10",
    "oldno": 48,
    "title": "限界集落を探る",
    "sdgs_no": 11,
    "year_display": "1995,2015",
    "year_syousai": "1995,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "2015以外は高さが再配布不可",
    "kml_file": "65歳以上人口割合×人口総数.kmz",
    "date": 8994,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "1,000～",
    "hanrei2_name": "500～1,000",
    "hanrei3_name": "100～500",
    "hanrei4_name": "0～100",
    "height_ratio": "1%:200m",
    "category_data": "",
    "shikii1": 1000,
    "shikii2": 500,
    "shikii3": 100,
    "min": 0,
    "ratio": 200,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "100,000～",
    "kokudo_hanrei2_name": "50,000～100,000",
    "kokudo_hanrei3_name": "10,000～50,000",
    "kokudo_hanrei4_name": "0～10,000",
    "kokudo_height_ratio": "1%:2000m",
    "kokudo_shikii1": 100000,
    "kokudo_shikii2": 50000,
    "kokudo_shikii3": 10000,
    "kokudo_min": 10,
    "kokudo_ratio": 2000,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": 1995,
    "year5": 2015,
    "try": "メッシュデータでは，住民が極端に少ない例を示すことは難しい。そこで，人口減少と高齢化が急激に進んでいる地域を示した。どのような地域が限界集落の予備軍と考えられるのか共通点を見つけてみよう。",
    "tenkei_mode": 6,
    "tenkei_city": "広島県東広島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "棒グラフの色は1995年と2015年でほぼ変わらないことから，単位面積あたりの人口はほぼ変化がないことが分かる。赤くなっている市街地は，広島市内への利便性の良さから若い世代が多く暮らしている上，総合大学があることで住民の更新が繰り返される。中心部の周辺では，棒グラフの色は変化していないため大きな人口減少には至っていないが，棒グラフは高くなっているため，高齢化が進んでいる。一方，公共交通機関の衰退が進んだ北東部の地域では，棒グラフの色が灰色である割合が高まっており，人口減少と高齢化が進んでいる。",
    "height_origin": "国勢調査",
    "height_origin_note": "満年齢が65歳以上の人口が総人口に占める割合です。",
    "color_origin": "国勢調査",
    "color_origin_note": "人口総数は「常住人口」です。常住人口とは、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "65歳以上人口割合",
    "hanrei_height_name_2": "（％）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "65歳以上人口割合",
    "kokudo_hanrei_height_name_2": "（％）",
    "kokudo_hanrei_height_name_3": "※10%以上で表示",
    "hanrei_color_name": "人口総数（人）",
    "title_height_name": "老年人口率",
    "title_color_name": "人口総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "65歳以上人口割合×人口総数",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "3-1",
    "no": "11-11",
    "oldno": "",
    "title": "ニュータウンの高齢化",
    "sdgs_no": 11,
    "year_display": "1995,2005,2015",
    "year_syousai": "1995,2005,2015",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "2015以外は高さ・色ともに再配布不可",
    "kml_file": "65歳以上割合×65歳以上人口.kmz",
    "date": 9799,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "1,000～",
    "hanrei2_name": "500～1,000",
    "hanrei3_name": "0～500",
    "hanrei4_name": "",
    "height_ratio": "1%:200m",
    "category_data": "",
    "shikii1": 1000,
    "shikii2": 500,
    "shikii3": 0,
    "min": 0,
    "ratio": 200,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "5,000～",
    "kokudo_hanrei2_name": "1,000～5,000",
    "kokudo_hanrei3_name": "200～1,000",
    "kokudo_hanrei4_name": "0～200",
    "kokudo_height_ratio": "1%:2000m",
    "kokudo_shikii1": 5000,
    "kokudo_shikii2": 1000,
    "kokudo_shikii3": 200,
    "kokudo_min": 10,
    "kokudo_ratio": 2000,
    "year1": "",
    "year2": "",
    "year3": 1995,
    "year4": 2005,
    "year5": 2015,
    "try": "高齢化が特に進んでいる地域はどのようなところだろうか。65歳以上人口割合と65歳以上人口総数の変化から見える化してみよう。大規模住宅開発のため入居者の年齢層が近い場合，高齢化はどのように進むだろうか。",
    "tenkei_mode": 6,
    "tenkei_city": "東京都稲城市・多摩市・八王子市",
    "tenkei_city_2": "",
    "tenkei_lon": 139.35,
    "tenkei_lat": 35.45,
    "tenkei_height": 35000,
    "tenkei_heading": 0,
    "tenkei_pitch": -50,
    "tenkei_roll": 0,
    "kaisetsu": "多摩ニュータウンは，東から稲城市，多摩市，八王子市南部と東西に広がっている。1995年では，八王子市の郊外に65歳以上人口割合のグラフが高いメッシュがあり，中央部で65歳以上人口総数が多いが，多摩ニュータウンのメッシュは，割合も総数も低い。多摩ニュータウンでは，1995年から2015年にかけて，東から西に向かって順に65歳以上人口割合や総数が高くなる傾向が読み取れる。これは，多摩ニュータウン開発が，東京の都心に近い東側から始まったために，入居した年代にともなって高齢化が順に進んでいるからである。",
    "height_origin": "国勢調査",
    "height_origin_note": "満年齢が65歳以上の人口が総人口に占める割合です。",
    "color_origin": "国勢調査",
    "color_origin_note": "老年人口（65歳以上人口）は、調査時に常住している場所で調査する方法（常住地方式）による人口をいいます。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "65歳以上人口割合",
    "hanrei_height_name_2": "（％）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "65歳以上人口割合",
    "kokudo_hanrei_height_name_2": "（％）",
    "kokudo_hanrei_height_name_3": "※10%以上で表示",
    "hanrei_color_name": "65歳以上人口総数（人）",
    "title_height_name": "老年人口率",
    "title_color_name": "老年人口総数",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 1,
    "すべての人に健康と福祉を": 1,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 1,
    "住み続けられるまちづくりを": 1,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 1,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 1,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "65歳以上割合×65歳以上人口",
    "データ更新20220305": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "11-85",
    "no": "11-91",
    "oldno": 8105,
    "title": "どこでモノが売られているか",
    "sdgs_no": 11,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "小売業事業所数数×小売業売り場面積平均.kmz",
    "date": 8949,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "1,000～",
    "hanrei2_name": "200～1,000",
    "hanrei3_name": "50～200",
    "hanrei4_name": "0～50",
    "height_ratio": "1人:1m",
    "category_data": "",
    "shikii1": 1000,
    "shikii2": 200,
    "shikii3": 50,
    "min": 0,
    "ratio": 100,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "1,000～",
    "kokudo_hanrei2_name": "200～1,000",
    "kokudo_hanrei3_name": "50～200",
    "kokudo_hanrei4_name": "0～50",
    "kokudo_height_ratio": "1人:0.1m",
    "kokudo_shikii1": 1000,
    "kokudo_shikii2": 200,
    "kokudo_shikii3": 50,
    "kokudo_min": 0,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "世帯数は，どのように分布しているだろうか。自分が暮らす地域の世帯分布を見える化してみよう。特徴のあるメッシュは，どのような場所なのか確認してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "福岡県糸島市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "人口分布の変化に典型例はありません。様々な都市を比較してみましょう。",
    "height_origin": "商業統計調査",
    "height_origin_note": "小売業の事業所数です。",
    "color_origin": "商業統計調査",
    "color_origin_note": "１小売業事業所あたりの売り場面積です。",
    "height_origin_url_name": "商業統計調査の概要",
    "height_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "height_origin_url2_name": "商業統計調査の用語の解説",
    "height_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "color_origin_url_name": "商業統計調査の概要",
    "color_origin_url": "https://www.meti.go.jp/statistics/tyo/syougyo/gaiyo.html#menu01",
    "color_origin_url2_name": "商業統計調査の用語の解説",
    "color_origin_url2": "https://www.meti.go.jp/statistics/tyo/syougyo/result-4.html#menu05",
    "hanrei_height_name": "小売業事業所数",
    "hanrei_height_name_2": "（事業所）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "小売業事業所数",
    "kokudo_hanrei_height_name_2": "（事業所）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "１小売業事業所あたりの売り場面積（㎡/所）",
    "title_height_name": "小売業事業所数",
    "title_color_name": "小売業売り場面積平均",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 0,
    "パートナーシップで目標を達成しよう": 0,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 0,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 0,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 0,
    "高校地理探究": 0,
    "自然環境": 0,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 0,
    "地理的事象": 0,
    "地形": 0,
    "気候": 0,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "小売業事業所数数×小売業売り場面積平均",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "13-3",
    "no": "13-01",
    "oldno": "",
    "title": "真夏日の増加",
    "sdgs_no": 13,
    "year_display": "2000, 2010, 2020",
    "year_syousai": "2000, 2010, 2020",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "真夏日平均日数×差分.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#00bfff",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#00bfff",
    "csvmode": "color_shikii",
    "hanrei1_name": "+10～",
    "hanrei2_name": "+5～+10",
    "hanrei3_name": "0～+5",
    "hanrei4_name": "～0",
    "height_ratio": "1日:200m",
    "category_data": "",
    "shikii1": 10,
    "shikii2": 5,
    "shikii3": 0,
    "min": 0,
    "ratio": 200,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "+10～",
    "kokudo_hanrei2_name": "+5～+10",
    "kokudo_hanrei3_name": "0～+5",
    "kokudo_hanrei4_name": "～0",
    "kokudo_height_ratio": "1日:2,000m",
    "kokudo_shikii1": 10,
    "kokudo_shikii2": 5,
    "kokudo_shikii3": 0,
    "kokudo_min": 0,
    "kokudo_ratio": 2000,
    "year1": "",
    "year2": "",
    "year3": 2000,
    "year4": 2010,
    "year5": 2020,
    "try": "最高気温が30℃以上の真夏日が多いのはどこの地域だろうか。また、近年の変化がみられるのだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "長野県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "長野県は標高の差が大きく、盆地は昼と夜の寒暖差が大きい。盆地では７・８月を中心に真夏日が多く観測されてきたが、標高の高い高原は真夏日のほとんど無いさわやかな気候で、大町や信濃町の観測点では1980年代までは真夏日は数日であった。しかし、真夏日は年々増え続け、2010年代の真夏日は1980年代の約４倍に増加している。このような最高気温の上昇は全国各地でみられ、最高気温が40℃近い日も増えてきたため、気象庁は2007年から最高気温35℃以上の日を猛暑日と定義している。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "年間真夏日数の平均値を、1991～2000年、2001～2010年、2011～2020年で算出したものです。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "年真夏日数の平均値の増減を、1981～1990年と1991～2000年、1991年～2000年と2001～2010年、2001～2010年と2011～2020年で算出したものです。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "年間真夏日数",
    "hanrei_height_name_2": "前10年間平均",
    "hanrei_height_name_3": "（日）",
    "kokudo_hanrei_height_name": "年間真夏日数",
    "kokudo_hanrei_height_name_2": "前10年間平均",
    "kokudo_hanrei_height_name_3": "（日）",
    "hanrei_color_name": "10年前の前10年間平均値との差(日)",
    "title_height_name": "真夏日の平均日数",
    "title_color_name": "その増減",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "真夏日平均日数×差分",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "13-2",
    "no": "13-02",
    "oldno": "",
    "title": "夏の最高気温の上昇",
    "sdgs_no": 13,
    "year_display": "2000, 2010, 2020",
    "year_syousai": "2000, 2010, 2020",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "最高気温平均値×差分.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "+1～",
    "hanrei2_name": "0～+1",
    "hanrei3_name": "-1～0",
    "hanrei4_name": "～-1",
    "height_ratio": "1℃:500m",
    "category_data": "",
    "shikii1": 1,
    "shikii2": 0,
    "shikii3": -1,
    "min": 0,
    "ratio": 500,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "+1～",
    "kokudo_hanrei2_name": "0～+1",
    "kokudo_hanrei3_name": "-1～0",
    "kokudo_hanrei4_name": "～-1",
    "kokudo_height_ratio": "1℃:1,000m",
    "kokudo_shikii1": 1,
    "kokudo_shikii2": 0,
    "kokudo_shikii3": -1,
    "kokudo_min": 0,
    "kokudo_ratio": 1500,
    "year1": "",
    "year2": "",
    "year3": 2000,
    "year4": 2010,
    "year5": 2020,
    "try": "8月の最高気温が高いのはどこだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "青森県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "年次を〈2000年〉に設定してグラフの色に着目すると青色・水色が目立ち、1991年～2000年の最高気温平均値がそれ以前の10年間に比べて低くなっていることに気付く。これは全国的な傾向で、1993年の冷夏の影響を強く受けており、やませが吹き付ける北日本の太平洋側では大きく気温が下がっている。青森県でも、1990年代は太平洋側ほど気温が低い傾向がみられる。しかし、その後は一貫して最高気温の上昇が見られ、地球温暖化との関係も危惧されている。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "8月の日最高気温の平均値を、1991～2000年、2001～2010年、2011～2020年で算出したものです。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "8月の日最高気温の平均値の増減を、1981～1990年と1991～2000年、1991年～2000年と2001～2010年、2001～2010年と2011～2020年で算出したものです。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "8月の日最高気温",
    "hanrei_height_name_2": "前10年間平均",
    "hanrei_height_name_3": "（℃）",
    "kokudo_hanrei_height_name": "8月の日最高気温",
    "kokudo_hanrei_height_name_2": "前10年間平均",
    "kokudo_hanrei_height_name_3": "（℃）",
    "hanrei_color_name": "10年前の前10年間平均値との差(℃)",
    "title_height_name": "日最高気温の平均値",
    "title_color_name": "その増減",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "最高気温平均値×差分",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "13-1",
    "no": "13-03",
    "oldno": "",
    "title": "ヒートアイランド現象",
    "sdgs_no": 13,
    "year_display": "2000, 2010, 2020",
    "year_syousai": "2000, 2010, 2020",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "最低気温平均値×差分.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#00bfff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "+1～",
    "hanrei2_name": "0～+1",
    "hanrei3_name": "-1～0",
    "hanrei4_name": "～-1",
    "height_ratio": "1℃:500m",
    "category_data": "",
    "shikii1": 1,
    "shikii2": 0,
    "shikii3": -1,
    "min": 0,
    "ratio": 500,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "+1～",
    "kokudo_hanrei2_name": "0～+1",
    "kokudo_hanrei3_name": "-1～0",
    "kokudo_hanrei4_name": "～-1",
    "kokudo_height_ratio": "1℃:1,000m",
    "kokudo_shikii1": 1,
    "kokudo_shikii2": 0,
    "kokudo_shikii3": -1,
    "kokudo_min": 0,
    "kokudo_ratio": 1000,
    "year1": "",
    "year2": "",
    "year3": 2000,
    "year4": 2010,
    "year5": 2020,
    "try": "1月の最低気温が低いのはどこだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "兵庫県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "日本の冬の気候は、1990年代に暖冬が続いた一方、それ以降は暖冬が連続することは少なく、2003年、2006年、2012年、2013年などは比較的寒い冬になっている。そうしたなかで、都道府県庁所在地中心部にある観測点は、周辺の観測点の気温よりも上昇幅が多い傾向がみられる。背景の一つに考えられるのがヒートアイランド現象で、都市化によって放射冷却が抑えられるため、冬の最低気温にはヒートアイランド現象の影響が出やすいと言われている。神戸市は、1980年代から1月の日最低気温の平均値が上がり続けている数少ない観測点の一つである。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "1月の日最低気温の平均値を、1991～2000年、2001～2010年、2011～2020年で算出したものです。3次元グラフが地中に埋まって見えなくならないようにするため、実際の最低気温の数値に30を足して、グラフを表現しています（例えば実際の最低気温が-30℃の場合には高さが０ｍになります）。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "1月の日最低気温の平均値の増減を、1981～1990年と1991～2000年、1991年～2000年と2001～2010年、2001～2010年と2011～2020年で算出したものです。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "1月の日最低気温",
    "hanrei_height_name_2": "前10年間平均(℃)",
    "hanrei_height_name_3": "＊実数値に30加算",
    "kokudo_hanrei_height_name": "1月の日最低気温",
    "kokudo_hanrei_height_name_2": "前10年間平均(℃)",
    "kokudo_hanrei_height_name_3": "＊実数値に30加算",
    "hanrei_color_name": "10年前の前10年間平均値との差(℃)",
    "title_height_name": "日最低気温の平均値",
    "title_color_name": "その増減",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "最低気温平均値×差分",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "13-4",
    "no": "13-04",
    "oldno": "",
    "title": "年間降水量の増加",
    "sdgs_no": 13,
    "year_display": "2000, 2010, 2020",
    "year_syousai": "2000, 2010, 2020",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "年間降水量平均値×差分.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "+200～",
    "hanrei2_name": "0～+200",
    "hanrei3_name": "-200～0",
    "hanrei4_name": "～-200",
    "height_ratio": "1㎜:5m",
    "category_data": "",
    "shikii1": 200,
    "shikii2": 0,
    "shikii3": -200,
    "min": 0,
    "ratio": 5,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "+200～",
    "kokudo_hanrei2_name": "0～+200",
    "kokudo_hanrei3_name": "-200～0",
    "kokudo_hanrei4_name": "～-200",
    "kokudo_height_ratio": "1㎜:50m",
    "kokudo_shikii1": 200,
    "kokudo_shikii2": 0,
    "kokudo_shikii3": -200,
    "kokudo_min": 0,
    "kokudo_ratio": 50,
    "year1": "",
    "year2": "",
    "year3": 2000,
    "year4": 2010,
    "year5": 2020,
    "try": "降水量が多いのはどこの地域だろうか。また、降水量の平均値は時代によって変わるのだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "宮崎県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "日本国内の30年平均降水量は、毎年少しずつ増加している、しかし、10年平均で見ると、地域や局地的な豪雨による差が大きいことを見てとれる。宮崎県では、1990年代と2010年代に降水量が多くなっているが、九州南部への台風の年平均接近回数が、2000年代にはやや少なかったことも、影響しているとみられる。近年は、湿った風が入るたびに大雨になりやすく、県中部の神門では2011年・2012年の２年連続で6月の降水量が1000mmを超えている。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "年降水量の平均値を、1991～2000年、2001～2010年、2011～2020年で算出したものです。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "年間降水量の平均値の増減を、1981～1990年と1991～2000年、1991年～2000年と2001～2010年、2001～2010年と2011～2020年で算出したものです。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "年間降水量",
    "hanrei_height_name_2": "前10年間平均",
    "hanrei_height_name_3": "（㎜）",
    "kokudo_hanrei_height_name": "年間降水量",
    "kokudo_hanrei_height_name_2": "前10年間平均",
    "kokudo_hanrei_height_name_3": "（㎜）",
    "hanrei_color_name": "10年前の前10年間平均値との差(㎜)",
    "title_height_name": "降水量の平均値",
    "title_color_name": "その増減",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "年間降水量平均値×差分",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "13-5",
    "no": "13-05",
    "oldno": "",
    "title": "局地的豪雨",
    "sdgs_no": 13,
    "year_display": "2000, 2010, 2020",
    "year_syousai": "2000, 2010, 2020",
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "100mm以上降水日数×差分.kmz",
    "date": "",
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#00bfff",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#00bfff",
    "csvmode": "color_shikii",
    "hanrei1_name": "+1～",
    "hanrei2_name": "+0.5～+1",
    "hanrei3_name": "0～+0.5",
    "hanrei4_name": "～0",
    "height_ratio": "1日:5,000m",
    "category_data": "",
    "shikii1": 1,
    "shikii2": 0.5,
    "shikii3": 0,
    "min": 0,
    "ratio": 5000,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "+1～",
    "kokudo_hanrei2_name": "+0.5～+1",
    "kokudo_hanrei3_name": "0～+0.5",
    "kokudo_hanrei4_name": "～0",
    "kokudo_height_ratio": "1日:25,000m",
    "kokudo_shikii1": 1,
    "kokudo_shikii2": 0.5,
    "kokudo_shikii3": 0,
    "kokudo_min": 0,
    "kokudo_ratio": 25000,
    "year1": "",
    "year2": "",
    "year3": 2000,
    "year4": 2010,
    "year5": 2020,
    "try": "100mm以上の降水がある日数が多い地域はどこだろうか。また、日数は時代による変化があるのだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "高知県",
    "tenkei_city_2": "",
    "tenkei_lon": "",
    "tenkei_lat": "",
    "tenkei_height": "",
    "tenkei_heading": "",
    "tenkei_pitch": "",
    "tenkei_roll": "",
    "kaisetsu": "ゲリラ豪雨が話題になることが増え、都市部で豪雨が増加しているイメージもあるが、実際には南風が当たる山間部で、100mm以上の降水のある日数が多くなっている。近年では、栃木県・茨城県を中心に被害のあった平成27年の台風18号や、長野県に大きな被害を与えた令和元年の台風19号などは、いずれも上流域で大雨が降り続いたことが原因となっている。離れた地域の大雨にも注意して、防災計画を立てることが必要である。",
    "height_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "height_origin_note": "100㎜以上の降水があった日数の年平均値を、1991～2000年、2001～2010年、2011～2020年で算出したものです。",
    "color_origin": "気象庁〈過去の気象データ〉を加工して作成",
    "color_origin_note": "100㎜以上の降水があった平均日数の増減を、1981～1990年と1991～2000年、1991年～2000年と2001～2010年、2001～2010年と2011～2020年で算出したものです。",
    "height_origin_url_name": "気象庁 過去の気象データ",
    "height_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "気象庁 過去の気象データ",
    "color_origin_url": "https://www.data.jma.go.jp/gmd/risk/obsdl/index.php",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "降水100mm以上",
    "hanrei_height_name_2": "前10年間平均",
    "hanrei_height_name_3": "（日）",
    "kokudo_hanrei_height_name": "降水100mm以上",
    "kokudo_hanrei_height_name_2": "前10年間平均",
    "kokudo_hanrei_height_name_3": "（日）",
    "hanrei_color_name": "10年前の前10年間平均値との差(日)",
    "title_height_name": "大雨の平均日数",
    "title_color_name": "その増減",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 0,
    "働きがいも経済成長も": 0,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 1,
    "気候変動に具体的な対策を": 1,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 0,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 1,
    "csv_filename": "100mm以上降水日数×差分",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "14-1",
    "no": "14-01",
    "oldno": "",
    "title": "水揚げされる漁港と漁獲量",
    "sdgs_no": 14,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "属地陸揚量×属地陸揚量.kmz",
    "date": 8599,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "10,000～",
    "hanrei2_name": "1,000～10,000",
    "hanrei3_name": "100～1,000",
    "hanrei4_name": "0～100",
    "height_ratio": "1t:1m",
    "category_data": "",
    "shikii1": 10000,
    "shikii2": 1000,
    "shikii3": 100,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "10,000～",
    "kokudo_hanrei2_name": "1,000～10,000",
    "kokudo_hanrei3_name": "100～1,000",
    "kokudo_hanrei4_name": "1～100",
    "kokudo_height_ratio": "1t:1m",
    "kokudo_shikii1": 10000,
    "kokudo_shikii2": 1000,
    "kokudo_shikii3": 100,
    "kokudo_min": 1,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "海に面している各都道府県を入力し，都道府県内にある漁獲量が多い漁港の特徴を，考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "静岡県",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "漁獲量のデータには、「属人」と「属地」がある。ここで紹介している属地漁獲量とは、漁船の所属先に関係なく、どこの漁港で水揚げされたかを集計したものである。東に相模湾，西に遠州灘，中央で駿河湾に面する静岡県では、焼津港の漁獲量が突出している。焼津港は、江戸時代には幕府より船鑑札が与えられる「かつお漁」の漁港として栄え，昭和初期より遠洋漁業が主流であったため，冷凍倉庫などの大型設備が集まっている。そのため、重量のある「まぐろ」と「かつお」は、各漁船が所属している小さな漁港よりも焼津に集中的に水揚げされる。漁獲量は港湾設備，都市部に直結する交通網の整備等による影響が大きいことに注目させたい。",
    "height_origin": "漁港港勢の概要を加工して作成",
    "height_origin_note": "属地漁獲量とは、漁船の所属先に関係なく、どこの漁港で水揚げされたかを集計したものです。",
    "color_origin": "漁港港勢の概要を加工して作成",
    "color_origin_note": "属地漁獲量とは、漁船の所属先に関係なく、どこの漁港で水揚げされたかを集計したものです。",
    "height_origin_url_name": "漁港港勢の概要",
    "height_origin_url": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/",
    "height_origin_url2_name": "漁港港勢の利用について",
    "height_origin_url2": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/attach/pdf/index-10.pdf",
    "color_origin_url_name": "漁港港勢の概要",
    "color_origin_url": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/",
    "color_origin_url2_name": "漁港港勢の利用について",
    "color_origin_url2": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/attach/pdf/index-10.pdf",
    "hanrei_height_name": "属地陸揚量（t）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "属地陸揚量（t）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1t以上で表示",
    "hanrei_color_name": "属地陸揚量（t）",
    "title_height_name": "属地陸揚量",
    "title_color_name": "属地陸揚量",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 1,
    "飢餓をゼロに": 1,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 1,
    "海の豊かさを守ろう": 1,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 1,
    "気候": 1,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 1,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 1,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 1,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "属地陸揚量×属地陸揚量",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "14-2",
    "no": "14-02",
    "oldno": "",
    "title": "出漁拠点となる漁港と漁獲量",
    "sdgs_no": 14,
    "year_display": 2014,
    "year_syousai": 2014,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "属人陸揚量×属人陸揚量.kmz",
    "date": 8598,
    "hanrei1_color": "#e51400",
    "hanrei2_color": "#f0a30a",
    "hanrei3_color": "#e3c800",
    "hanrei4_color": "#647687",
    "kml1_color": "#e51400",
    "kml2_color": "#f0a30a",
    "kml3_color": "#e3c800",
    "kml4_color": "#647687",
    "csvmode": "color_shikii",
    "hanrei1_name": "10,000～",
    "hanrei2_name": "1,000～10,000",
    "hanrei3_name": "100～1,000",
    "hanrei4_name": "0～100",
    "height_ratio": "1t:1m",
    "category_data": "",
    "shikii1": 10000,
    "shikii2": 1000,
    "shikii3": 100,
    "min": 0,
    "ratio": 1,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "10,000～",
    "kokudo_hanrei2_name": "1,000～10,000",
    "kokudo_hanrei3_name": "100～1,000",
    "kokudo_hanrei4_name": "1～100",
    "kokudo_height_ratio": "1t:1m",
    "kokudo_shikii1": 10000,
    "kokudo_shikii2": 1000,
    "kokudo_shikii3": 100,
    "kokudo_min": 1,
    "kokudo_ratio": 1,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2014,
    "try": "海に面している各都道府県を入力し，都道府県内でも漁獲量に差が出る理由を，考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "静岡県",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "漁獲量のデータには、「属人」と「属地」がある。ここで紹介している属人漁獲量とは、各漁港に所属している漁船がどれほど水揚げしたかを集計したものである。東に相模湾，西に遠州灘，中央で駿河湾に面する静岡県には漁港が多い。そのなかでも最も漁獲量が多い焼津港は、遠洋漁業の大型「はえ縄漁」の基地として知られる。次に漁獲量が多い戸田港は、日本有数の大中型「まき網漁」の船団の基地となっている。戸田港に所属するこの船団は、さばなどを求めて太平洋沖で漁を行い、漁場に近い、銚子や気仙沼、八戸などに水揚げする。そのため、地元の漁港での水揚量は少ない。入り江の多い伊豆半島には、戸田港以外にも小規模な漁港が多く存在し、地魚の漁獲が多い。なかには、駿河湾の「さくらえび」や「しらす」、下田港の「きんめだい」など、ブランド化され、全国的に有名な魚種もある。これらは漁獲量は少ないため、漁獲量での比較では見えにくい。様々な一次情報を確認しながら探究させたい。",
    "height_origin": "漁港港勢の概要を加工して作成",
    "height_origin_note": "属人漁獲量とは、各漁港に所属している漁船がどれほど水揚げしたかを集計したものです。",
    "color_origin": "漁港港勢の概要を加工して作成",
    "color_origin_note": "属人漁獲量とは、各漁港に所属している漁船がどれほど水揚げしたかを集計したものです。",
    "height_origin_url_name": "漁港港勢の概要",
    "height_origin_url": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/",
    "height_origin_url2_name": "漁港港勢の利用について",
    "height_origin_url2": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/attach/pdf/index-10.pdf",
    "color_origin_url_name": "漁港港勢の概要",
    "color_origin_url": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/",
    "color_origin_url2_name": "漁港港勢の利用について",
    "color_origin_url2": "https://www.jfa.maff.go.jp/j/gyoko_gyozyo/g_zyoho_bako/kousei/attach/pdf/index-10.pdf",
    "hanrei_height_name": "属人陸揚量（t）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "属人陸揚量（t）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "※1t以上で表示",
    "hanrei_color_name": "属人陸揚量（t）",
    "title_height_name": "属人陸揚量",
    "title_color_name": "属人陸揚量",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 1,
    "飢餓をゼロに": 1,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 1,
    "海の豊かさを守ろう": 1,
    "sdgs15": 0,
    "陸の豊かさも守ろう": 0,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 1,
    "気候": 1,
    "環境問題": 0,
    "交通": 1,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 1,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 1,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "属人陸揚量×属人陸揚量",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "2-80",
    "no": "15-01",
    "oldno": 11,
    "title": "農林漁業を営む人々",
    "sdgs_no": 15,
    "year_display": 2015,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": 1,
    "kml_file": "農林漁業就業者世帯数×農林漁業就業者世帯割合.kmz",
    "date": 9898,
    "hanrei1_color": "#ff0000",
    "hanrei2_color": "#ffb6c1",
    "hanrei3_color": "#00bfff",
    "hanrei4_color": "#0033cc",
    "kml1_color": "#ff0000",
    "kml2_color": "#ff66cc",
    "kml3_color": "#00ccff",
    "kml4_color": "#0033cc",
    "csvmode": "color_shikii",
    "hanrei1_name": "20～",
    "hanrei2_name": "10～20",
    "hanrei3_name": "5～10",
    "hanrei4_name": "0～5",
    "height_ratio": "1世帯:400m",
    "category_data": "",
    "shikii1": 20,
    "shikii2": 10,
    "shikii3": 5,
    "min": 0,
    "ratio": 400,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "20～",
    "kokudo_hanrei2_name": "10～20",
    "kokudo_hanrei3_name": "5～10",
    "kokudo_hanrei4_name": "0～5",
    "kokudo_height_ratio": "1世帯:50m",
    "kokudo_shikii1": 20,
    "kokudo_shikii2": 10,
    "kokudo_shikii3": 5,
    "kokudo_min": 0,
    "kokudo_ratio": 50,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "第一次産業に従事している人が多いのはどのような地域だろうか。農林漁業に就く人の世帯数と一般世帯数に占める割合から見える化してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "北海道北見市",
    "tenkei_city_2": "",
    "tenkei_lon": 143.9,
    "tenkei_lat": 43.7,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "北見市は北海道の東部に位置する、オホーツク海側で最大の都市であり、農林水産業の魅力を活かした地域ブランド化を推進している。オホーツク海に面した赤く高いグラフの地域では、ホタテをはじめとする沿岸漁業が盛んである。また、西側の赤いグラフは豊かな森林がある大雪山麓で、林業のほか中山間地域で大規模経営体の農業も行われる。青いグラフの市街地周辺の赤いグラフは主に農業だと考えられる。日照時間が長く、少ない降水量と一日の寒暖差がある気候を活かした玉ねぎの生産が特に有名である。",
    "height_origin": "国勢調査",
    "height_origin_note": "農林漁業就業者世帯の一般世帯数。居住地で集計したものです。",
    "color_origin": "国勢調査を加工して作成",
    "color_origin_note": "農林漁業就業者世帯の一般世帯数を全ての一般世帯数で除したもの（割合）です 。",
    "height_origin_url_name": "国勢調査の概要",
    "height_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "height_origin_url2_name": "国勢調査の用語の定義",
    "height_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "color_origin_url_name": "国勢調査の概要",
    "color_origin_url": "https://www.stat.go.jp/data/kokusei/2020/gaiyou.html",
    "color_origin_url2_name": "国勢調査の用語の定義",
    "color_origin_url2": "https://www.stat.go.jp/data/kokusei/2015/users-g/pdf/04.pdf",
    "hanrei_height_name": "農林漁業就業者",
    "hanrei_height_name_2": "世帯数（世帯）",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "農林漁業就業者",
    "kokudo_hanrei_height_name_2": "世帯数（世帯）",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "農林漁業就業者世帯の割合（％）",
    "title_height_name": "農林漁業就業者世帯数",
    "title_color_name": "その割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 1,
    "飢餓をゼロに": 1,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 1,
    "海の豊かさを守ろう": 1,
    "sdgs15": 1,
    "陸の豊かさも守ろう": 1,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 1,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 0,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 1,
    "気候": 1,
    "環境問題": 0,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 1,
    "林業": 1,
    "水産業": 1,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 0,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 1,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 0,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "農林漁業就業者世帯数×農林漁業就業者世帯割合",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "15-2",
    "no": "15-02",
    "oldno": "",
    "title": "エコツーリズム",
    "sdgs_no": 15,
    "year_display": 2010,
    "year_syousai": 2015,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "高さ・色とも非商用",
    "kml_file": "宿泊容量×自然公園等.kmz",
    "date": 8498,
    "hanrei1_color": "#008000",
    "hanrei2_color": "#32cd32",
    "hanrei3_color": "#bed2c3",
    "hanrei4_color": "",
    "kml1_color": "#008000",
    "kml2_color": "#32cd32",
    "kml3_color": "#bed2c3",
    "kml4_color": "",
    "csvmode": "color_shikii",
    "hanrei1_name": "40～",
    "hanrei2_name": "20～40",
    "hanrei3_name": "5～20",
    "hanrei4_name": "※5%未満は表示せず",
    "height_ratio": "1人:10m",
    "category_data": "",
    "shikii1": 40,
    "shikii2": 20,
    "shikii3": "",
    "min": 0,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "40～",
    "kokudo_hanrei2_name": "20～40",
    "kokudo_hanrei3_name": "5～20",
    "kokudo_hanrei4_name": "※5%未満は表示せず",
    "kokudo_height_ratio": "1人:10m",
    "kokudo_shikii1": 40,
    "kokudo_shikii2": 20,
    "kokudo_shikii3": "",
    "kokudo_min": 0,
    "kokudo_ratio": 10,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2015,
    "try": "自然公園等面積割合が5％以上と大きく，かつ宿泊容量が多いメッシュは，どのような自然環境のところだろうか。",
    "tenkei_mode": "",
    "tenkei_city": "熊本県阿蘇郡市",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "宿泊容量が多いメッシュは，阿蘇山の中央火口と外輪山の間にリング上に広がるカルデラ底に多いことが読み取れる。ここに位置する阿蘇郡市の自治体に南麓の山都町（上益城郡）が加わり，阿蘇ジオパーク推進協議会を立ち上げ，この協議会が阿蘇エコツーリズム推進全体構想をまとめている。阿蘇の雄大な地形・地質景観と長い年月をかけて成立した草原の景観，それを守り続けてきた農家をはじめとした地域の人々の存在を，来訪者に伝えるような体験にこだわったエコツーリズムを目指している。災害にまつわる教訓，体験談，自然回復過程や復興の取り組みも伝承していこうとしている。",
    "height_origin": "国土数値情報",
    "height_origin_note": "宿泊施設の収容人数です。",
    "color_origin": "国土数値情報を加工して作成",
    "color_origin_note": "「自然公園地域」、「自然保全地域」、「鳥獣保護区」の3種類の地域を合計した面積の割合です（重複部分は除外）。ただし、面積の 割合が5%を超えるメッシュのみ表示しています。なお、国土表示（二次メッシュ）では、地域別表示で条件に合致した三次メッシュの値を集計した数値を用いています。",
    "height_origin_url_name": "国土数値情報：宿泊容量メッシュデータ",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P09.html",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "国土数値情報：自然公園地域データ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A10-v3_1.html",
    "color_origin_url2_name": "国土数値情報：自然保全地域データ",
    "color_origin_url2": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-A11.html",
    "hanrei_height_name": "宿泊容量（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "宿泊容量（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "自然公園等面積割合（%）",
    "title_height_name": "宿泊容量",
    "title_color_name": "自然公園等面積割合",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 1,
    "陸の豊かさも守ろう": 1,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 1,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 1,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "宿泊容量×自然公園等",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "23区問題",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "",
    "データエラー登録【作業リストになります】": ""
  },
  {
    "noold202201": "15-1",
    "no": "15-03",
    "oldno": "",
    "title": "山岳地域と観光業",
    "sdgs_no": 15,
    "year_display": 2010,
    "year_syousai": 2009,
    "year_nentsuki": "年",
    "sokuchi": "世界測地系",
    "mesh_size": "1km",
    "open_data": "高さが非商用",
    "kml_file": "宿泊容量×標高.kmz",
    "date": 8499,
    "hanrei1_color": "#008000",
    "hanrei2_color": "#32cd32",
    "hanrei3_color": "",
    "hanrei4_color": "",
    "kml1_color": "#008000",
    "kml2_color": "#32cd32",
    "kml3_color": "",
    "kml4_color": "",
    "csvmode": "color_shikii",
    "hanrei1_name": "1,400～",
    "hanrei2_name": "600～1,400",
    "hanrei3_name": "※600m未満で非表示",
    "hanrei4_name": "",
    "height_ratio": "1人:10m",
    "category_data": "",
    "shikii1": 1400,
    "shikii2": "",
    "shikii3": "",
    "min": 0,
    "ratio": 10,
    "kokudo_onoff": 1,
    "kokudo_hanrei1_name": "1,400～",
    "kokudo_hanrei2_name": "600～1,400",
    "kokudo_hanrei3_name": "※600m未満で非表示",
    "kokudo_hanrei4_name": "",
    "kokudo_height_ratio": "1人:10m",
    "kokudo_shikii1": 1400,
    "kokudo_shikii2": "",
    "kokudo_shikii3": "",
    "kokudo_min": 0,
    "kokudo_ratio": 10,
    "year1": "",
    "year2": "",
    "year3": "",
    "year4": "",
    "year5": 2010,
    "try": "各都道府県のデータを俯瞰しながら，山岳地域の宿泊容量の分布の差を考察してみよう。",
    "tenkei_mode": "",
    "tenkei_city": "長野県",
    "tenkei_city_2": "",
    "tenkei_lon": 130.15,
    "tenkei_lat": 33.4,
    "tenkei_height": 30000,
    "tenkei_heading": 0,
    "tenkei_pitch": -60,
    "tenkei_roll": 0,
    "kaisetsu": "スキー場や山小屋などの山岳リゾートが豊富な長野県であるが，県全域の標高が高めであるため，本データの基準である標高 600m 以上の地域にも，諏訪市役所（761m），伊那市役所（632m），大町市役所（726m）のような市街区域が存在する。そのため本データでは，いわゆる山岳リゾートとは異なる，ビジネスホテルなど通常の宿泊施設も含めて表示されていることに，注意が必要である。なお，3,000m 級の山々の山小屋には，北アルプスの「北穂高小屋」や「槍ヶ岳山荘」，「白馬山荘」などの夏のみ営業する小屋と，八ヶ岳の「高見石小屋」や「赤岳鉱泉」など通年営業している山小屋がある。山岳地域の観光業をさらに詳しく分析するなら，山脈やスキー場の位置と合わせて，夏と冬の宿泊容量の違いを調べるとよいだろう。",
    "height_origin": "国土数値情報",
    "height_origin_note": "宿泊施設の収容人数です。",
    "color_origin": "国土数値情報",
    "color_origin_note": "メッシュ内の標高の最低値です。値が600ｍ以上のメッシュのみ表示しています。",
    "height_origin_url_name": "国土数値情報：宿泊容量メッシュデータ",
    "height_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-P09.html",
    "height_origin_url2_name": "",
    "height_origin_url2": "",
    "color_origin_url_name": "国土数値情報：標高・傾斜度3次メッシュデータ",
    "color_origin_url": "https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-G04-a.html",
    "color_origin_url2_name": "",
    "color_origin_url2": "",
    "hanrei_height_name": "宿泊容量（人）",
    "hanrei_height_name_2": "",
    "hanrei_height_name_3": "",
    "kokudo_hanrei_height_name": "宿泊容量（人）",
    "kokudo_hanrei_height_name_2": "",
    "kokudo_hanrei_height_name_3": "",
    "hanrei_color_name": "範囲内で最低地点の標高（m）",
    "title_height_name": "宿泊容量",
    "title_color_name": "標高",
    "sdgs1": 0,
    "貧困をなくそう": 0,
    "sdgs2": 0,
    "飢餓をゼロに": 0,
    "sdgs3": 0,
    "すべての人に健康と福祉を": 0,
    "sdgs4": 0,
    "質の高い教育をみんなに": 0,
    "sdgs5": 0,
    "ジェンダー平等を実現しよう": 0,
    "sdgs6": 0,
    "安全な水とトイレを世界中に": 0,
    "sdgs7": 0,
    "エネルギーをみんなにそしてクリーンに": 0,
    "sdgs8": 1,
    "働きがいも経済成長も": 1,
    "sdgs9": 0,
    "産業と技術革新の基盤をつくろう": 0,
    "sdgs10": 0,
    "人や国の不平等をなくそう": 0,
    "sdgs11": 0,
    "住み続けられるまちづくりを": 0,
    "sdgs12": 0,
    "つくる責任つかう責任": 0,
    "sdgs13": 0,
    "気候変動に具体的な対策を": 0,
    "sdgs14": 0,
    "海の豊かさを守ろう": 0,
    "sdgs15": 1,
    "陸の豊かさも守ろう": 1,
    "sdgs16": 0,
    "平和と公正をすべての人に": 0,
    "sdgs17": 1,
    "パートナーシップで目標を達成しよう": 1,
    "高校地理総合": 0,
    "地図や地理情報システム": 0,
    "地球的課題と国際協力": 1,
    "結びつきを深める現代世界": 0,
    "自然環境と防災": 1,
    "生活文化の多様性と国際理解": 0,
    "生活圏の調査と地域の展望": 1,
    "高校地理探究": 0,
    "自然環境": 1,
    "生活文化，民族・宗教": 0,
    "資源，産業": 0,
    "現代世界の地域区分": 0,
    "交通・通信，観光": 1,
    "現代世界の諸地域": 0,
    "人口，都市・村落": 0,
    "持続可能な国土像の探究": 1,
    "地理的事象": 0,
    "地形": 1,
    "気候": 1,
    "環境問題": 1,
    "交通": 0,
    "通信": 0,
    "人口": 0,
    "民族": 0,
    "農業": 0,
    "林業": 0,
    "水産業": 0,
    "資源エネルギー": 0,
    "鉱業": 0,
    "工業": 0,
    "第３次産業": 0,
    "労働": 0,
    "観光": 1,
    "経済": 0,
    "貿易": 0,
    "国際機関": 0,
    "世界の国々": 0,
    "出典": 0,
    "国勢調査": 0,
    "経済センサス": 0,
    "商業統計調査": 0,
    "国土数値情報": 1,
    "農林業センサス": 0,
    "漁港港勢の概要": 0,
    "気象庁 過去の気象データ": 0,
    "csv_filename": "宿泊容量×標高",
    "データ更新20220305": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）": "",
    "登録済み残り作業（完了したらこのセルを完了の日付に更新）__1": "2022/03/08",
    "データエラー登録【作業リストになります】": ""
  }
]